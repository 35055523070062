import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { languageIs, loginModal } from "../atoms";
import { getCookie } from "../Cookie";

const PrivateRoute = ({ children }) => {
  const country = useRecoilValue(languageIs);
  const navigate = useNavigate();
  const auth = getCookie("token");
  const loginModalOn = useSetRecoilState(loginModal);

  useEffect(() => {
    if (!auth) navigate(`/`);
  }, [auth]);

  return auth ? children : loginModalOn(true);
};

export default PrivateRoute;
