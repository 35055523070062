import React from "react";
import { useEffect } from "react";

//components
import styled from "styled-components";

// img
import timerImg from "../img/timer.svg";

const Timer = ({ min, setMin, sec, setSec }) => {
  useEffect(() => {
    const timer = setInterval(() => {
      if (Number(sec) > 0) {
        setSec(Number(sec) - 1);
      }
      if (Number(sec) === 0) {
        if (Number(min) === 0) {
          clearInterval(timer);
        } else {
          setMin(Number(min) - 1);
          setSec(59);
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [min, setMin, sec, setSec]);

  return (
    <StTimer timerImg={timerImg}>
      Code valid for {min}:{sec}
    </StTimer>
  );
};

const StTimer = styled.div`
  font-family: "Regular";
  color: #2276dc;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-top: 12px;
  ::before {
    background: url(${(props) => props.timerImg}) no-repeat 0px 0px;
    content: "";
    display: inline-block;
    height: 12px;
    width: 12px;
    background-size: cover;
    margin-right: 4px;
  }
`;

export default Timer;
