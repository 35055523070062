import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { device } from "../hooks/device";
import { useRecoilState, useRecoilValue } from "recoil";
import { clickInfo, languageIs } from "../atoms";
import { useTranslation } from "react-i18next";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";

const ChartList = ({
  testerId,
  name,
  abnormals,
  check,
  cheats,
  testId,
  reportIndex,
  reportType,
  currentPage,
  pageListCount,
  isVerifiedEnvironment,
  isManualShot,
}) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d1_1";
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState("#3C9C9C");
  const [showState, setShowState] = useState();
  const [clickIndex, setClickIndex] = useRecoilState(clickInfo);
  const country = useRecoilValue(languageIs);

  const pageCount = pageListCount === undefined ? 10 : pageListCount;

  useEffect(() => {
    if (check === "confirmStatus") {
      setConfirm("#C83B38");
    } else if (check === "cautionStatus") {
      // setConfirm("#FFA800");
      setConfirm("#FFBC00");
    } else if (check === "normalStatus") {
      // setConfirm("#3C9C9C");
      setConfirm("#63B0B0");
    } else if (check === "errorStatus") {
      setConfirm("#E9B1Af");
    } else {
      // setConfirm("#BDBDBD");
      setConfirm("#D9D9D9");
    }

    if (check === "confirmStatus") {
      setShowState(t(`${baseLang}.tester1`));
    } else if (check === "cautionStatus") {
      setShowState(t(`${baseLang}.tester2`));
    } else if (check === "normalStatus") {
      setShowState(t(`${baseLang}.tester3`));
    } else if (check === "errorStatus") {
      // setShowState(t(`${baseLang}.tester4`));
      setShowState(t(`${baseLang}.tester5`));
    } else {
      setShowState(t(`${baseLang}.tester4`));
    }
  }, [check, confirm]);

  return (
    <StBody
      check={confirm}
      cheats={cheats}
      onClick={() => {
        navigate(`/reportSpecific/${testerId}/${testId}`);

        setClickIndex("");
      }}
    >
      <div className="MobileWrapper onlyForMobile">
        <div className="left">
          <span className="name">{name}</span>
          <div className="mobile">
            <div className="normal">
              {showState}
              {abnormals || cheats ? <div className="grey" /> : <></>}
              {abnormals || cheats ? (
                <div className="detect">
                  {(cheats !== 0) & (check !== "errorStatus") ? (
                    <div className="detectOne">
                      <div className="redButton" />
                      <div>{cheats}</div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {(abnormals !== 0) & (check !== "errorStatus") ? (
                    <div className="detectTwo">
                      <div className="yellowButton" />
                      <div>{abnormals}</div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="num">
          {check === "errorStatus" || check === "unusualStatus"
            ? ``
            : `${abnormals + cheats}`}
        </div>
      </div>
      {/* PC 리스트 */}
      {Math.floor(reportIndex / pageCount) + 1 === currentPage && (
        <DivTwoPartImprove
          display="none"
          pc_display="flex"
          position="relative"
          pc_width="100%"
          pc_height={64}
          pc_padding="16px 0px"
          pc_background_color="#ffffff"
          pc_box_sizing="border-box"
          pc_text_align="center"
          pc_align_items="center"
          border_bottom="1px solid #F0F1F2"
          cursor="pointer"
          pc_hover_background_color="rgba(34, 118, 220, 0.05)"
        >
          {reportType === "detect" && (
            <>
              {/* 이름 */}
              <DivTwoPartImprove
                pc_font_family="Regular"
                pc_font_style="normal"
                lineheight={24}
                pc_font_weight={600}
                overflow="hidden"
                white_space="nowrap"
                text_overflow="ellipsis"
                word_break="break-all"
                pc_flex="0 0 303px"
                pc_padding="0px 8px"
              >
                {name}
              </DivTwoPartImprove>
              {/* 상태 */}
              <DivTwoPartImprove
                display="flex"
                pc_flex="0 0 240px"
                pc_padding="0px 8px"
                justify="center"
                align_items="center"
              >
                <DivTwoPartImprove
                  display="flex"
                  height={35}
                  pc_min_width="60px"
                  pc_max_width="88"
                  padding="4px 16px"
                  justify="center"
                  align_items="center"
                  border_radius={24}
                  pc_box_sizing="border-box"
                  background_color={confirm}
                >
                  <DivTwoPartImprove
                    pc_font_family="Regular"
                    pc_font_size={15}
                    pc_font_style="normal"
                    lineheight={27}
                    pc_font_weight={600}
                    font_color="#FFF"
                  >
                    {showState}
                  </DivTwoPartImprove>
                </DivTwoPartImprove>
              </DivTwoPartImprove>
              {/* 확인요망 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_color="#C83B38"
                font_weight={400}
              >
                {cheats}
              </DivTwoPartImprove>
              {/* 주의요망 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_color="#ED893E"
                font_weight={400}
              >
                {abnormals}
              </DivTwoPartImprove>
              {/* 총 이벤트 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_weight={400}
              >
                {abnormals + cheats}
              </DivTwoPartImprove>
              {/* 주변환경 스캔 이상여부 */}
              <DivTwoPartImprove
                pc_flex="0 0 217px"
                pc_padding="0px 8px"
                font_weight={400}
                font_color={
                  isVerifiedEnvironment === false
                    ? "#C83B38"
                    : isManualShot === true
                    ? "#ED893E"
                    : ""
                }
              >
                {isVerifiedEnvironment === false
                  ? t(`${baseLang}.scan2`)
                  : isManualShot === true
                  ? t(`${baseLang}.scan1`)
                  : "-"}
              </DivTwoPartImprove>
            </>
          )}
          {reportType === "mid" && (
            <>
              {/* 이름 */}
              <DivTwoPartImprove
                pc_font_family="Regular"
                pc_font_style="normal"
                lineheight={24}
                pc_font_weight={600}
                overflow="hidden"
                white_space="nowrap"
                text_overflow="ellipsis"
                word_break="break-all"
                pc_flex="0 0 303px"
                pc_padding="0px 8px"
              >
                {name}
              </DivTwoPartImprove>
              {/* 상태 */}
              <DivTwoPartImprove
                display="flex"
                pc_flex="0 0 240px"
                pc_padding="0px 8px"
                justify="center"
                align_items="center"
              >
                <DivTwoPartImprove
                  display="flex"
                  height={35}
                  pc_min_width="60px"
                  pc_max_width="88"
                  padding="4px 16px"
                  justify="center"
                  align_items="center"
                  border_radius={24}
                  pc_box_sizing="border-box"
                  background_color={confirm}
                >
                  <DivTwoPartImprove
                    pc_font_family="Regular"
                    pc_font_size={15}
                    pc_font_style="normal"
                    lineheight={27}
                    pc_font_weight={600}
                    font_color="#FFF"
                  >
                    {showState}
                  </DivTwoPartImprove>
                  <DivTwoPartImprove />
                </DivTwoPartImprove>
              </DivTwoPartImprove>
              {/* 확인요망 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_color="#C83B38"
                font_weight={400}
              >
                {cheats}
              </DivTwoPartImprove>
              {/* 주의요망 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_color="#ED893E"
                font_weight={400}
              >
                {abnormals}
              </DivTwoPartImprove>
              {/* 총 이벤트 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_weight={400}
              >
                {abnormals + cheats}
              </DivTwoPartImprove>
              {/* 주변환경 스캔 이상여부 */}
              <DivTwoPartImprove
                pc_flex="0 0 217px"
                pc_padding="0px 8px"
                font_weight={400}
                font_color={
                  isVerifiedEnvironment === false
                    ? "#C83B38"
                    : isManualShot === true
                    ? "#ED893E"
                    : ""
                }
              >
                {isVerifiedEnvironment === false
                  ? t(`${baseLang}.scan2`)
                  : isManualShot === true
                  ? t(`${baseLang}.scan1`)
                  : "-"}
              </DivTwoPartImprove>
            </>
          )}
          {reportType === "normal" && (
            <>
              {/* 이름 */}
              <DivTwoPartImprove
                pc_font_family="Regular"
                pc_font_style="normal"
                lineheight={24}
                pc_font_weight={600}
                overflow="hidden"
                white_space="nowrap"
                text_overflow="ellipsis"
                word_break="break-all"
                pc_flex="0 0 303px"
                pc_padding="0px 8px"
              >
                {name}
              </DivTwoPartImprove>
              {/* 상태 */}
              <DivTwoPartImprove
                display="flex"
                pc_flex="0 0 240px"
                pc_padding="0px 8px"
                justify="center"
                align_items="center"
              >
                <DivTwoPartImprove
                  display="flex"
                  height={35}
                  pc_min_width="60px"
                  pc_max_width="88"
                  padding="4px 16px"
                  justify="center"
                  align_items="center"
                  border_radius={24}
                  pc_box_sizing="border-box"
                  background_color={confirm}
                >
                  <DivTwoPartImprove
                    pc_font_family="Regular"
                    pc_font_size={15}
                    pc_font_style="normal"
                    lineheight={27}
                    pc_font_weight={600}
                    font_color="#FFF"
                  >
                    {showState}
                  </DivTwoPartImprove>
                </DivTwoPartImprove>
              </DivTwoPartImprove>
              {/* 확인요망 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_color="#C83B38"
                font_weight={400}
              >
                {cheats}
              </DivTwoPartImprove>
              {/* 주의요망 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_color="#ED893E"
                font_weight={400}
              >
                {abnormals}
              </DivTwoPartImprove>
              {/* 총 이벤트 */}
              <DivTwoPartImprove
                pc_flex="0 0 140px"
                pc_padding="0px 8px"
                font_weight={400}
              >
                {abnormals + cheats}
              </DivTwoPartImprove>
              {/* 주변환경 스캔 이상여부 */}
              <DivTwoPartImprove
                pc_flex="0 0 217px"
                pc_padding="0px 8px"
                font_weight={400}
                font_color={
                  isVerifiedEnvironment === false
                    ? "#C83B38"
                    : isManualShot === true
                    ? "#ED893E"
                    : ""
                }
              >
                {isVerifiedEnvironment === false
                  ? t(`${baseLang}.scan2`)
                  : isManualShot === true
                  ? t(`${baseLang}.scan1`)
                  : "-"}
              </DivTwoPartImprove>
            </>
          )}
        </DivTwoPartImprove>
      )}
      {/* <div className="pc">
        <div className="normal">{showState}</div>

        <div className="detect">
          {(abnormals || cheats) && check !== "errorStatus" ? (
            <div>
              {cheats !== 0 && (
                <div className="detectOne">
                  <div className="redButton" />
                  <div>{cheats}</div>
                </div>
              )}
              {abnormals !== 0 && (
                <div className="detectTwo">
                  <div className="yellowButton" />
                  <div>{abnormals}</div>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div> */}
    </StBody>
  );
};

const StBody = styled.div`
  @media ${device.tabMob} {
    .pc {
      display: none;
    }
    .MobileWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100%;
      height: 76px;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 12px 24px;
      box-sizing: border-box;
      cursor: pointer;
      .grey {
        width: 0.5px;
        height: 12px;
        margin-left: 8px;
        background-color: #d6d9dd;
      }
      .detect {
        display: flex;
        justify-content: center;
        height: 18px;
        color: black;
      }
      .detectOne {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
      .detectTwo {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
      .redButton {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #c83b38;
        margin-right: 6px;
      }
      .yellowButton {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background-color: #ffa800;
        margin-right: 6px;
      }
      .small {
        font-size: 12px;
        margin-left: 3px;
      }
      .left {
        width: 80%;
        max-width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 48px;
      }
      .grey {
        width: 0.5px;
        height: 12px;
        background: #d6d9dd;
      }
      .pc {
        display: none;
      }
      .mobile {
        display: flex;
        justify-content: space-between;
        height: 18px;
        align-items: center;
        .normal {
          display: flex;
          align-items: center;
        }
      }
      .num {
        display: flex;
        align-items: baseline;
        font-size: 24px;
        font-family: "Medium";
      }
      .normal {
        width: 133px;
        font-size: 12px;
        font-family: "Medium";
        color: ${(props) => props.check};
      }
      .certificate {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 133px;
        height: 18px;
        font-size: 12px;
      }
    }
  }
  @media ${device.tablet} {
    .name {
      width: 100%;
      font-family: "Medium";
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all;
      text-overflow: ellipsis;
      display: inline-block;
      /* -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; */
    }
  }
  @media ${device.mobile} {
    .name {
      width: 211px;
      /* width: 80%; */
      font-family: "Medium";
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all;
      text-overflow: ellipsis;
      display: inline-block;
      /* -webkit-line-clamp: 2;
      -webkit-box-orient: vertical; */
    }
  }
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 25px;
    width: 188px;
    height: 256px; */
    box-sizing: border-box;
    background: #ffffff;
    /* box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px; */

    .onlyForMobile {
      display: none;
    }

    .small {
      font-size: 16px;
      margin-left: 3px;
    }
    .detect {
      display: flex;
      justify-content: center;
      margin-top: 6px;
      height: 24px;
      div {
        display: flex;
      }
    }
    .detectOne {
      display: flex;
      align-items: center;
    }
    .detectTwo {
      display: flex;
      align-items: center;
      margin-left: ${(props) => (props.cheats === 0 ? "0px" : "14px")};
    }
    .redButton {
      background-color: #c83b38;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 6px;
    }
    .yellowButton {
      background-color: #ffa800;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 6px;
    }
    cursor: pointer;
    .left {
      display: flex;
      flex-direction: column;
      margin-right: auto;
    }
    .mobile {
      display: none;
    }
    .name {
      max-width: 180px;
      margin-right: auto;
      height: 54px;
      line-height: 27px;
      font-size: 18px;
      font-family: "Medium";
      word-break: break-all;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .num {
      display: flex;
      justify-content: center;
      align-items: baseline;
      width: 133px;
      height: 59px;
      text-align: center;
      font-size: 40px;
      margin-top: 14px;
      font-family: "Medium";
    }
    .normal {
      width: 133px;
      height: 27px;
      text-align: center;
      margin-top: auto;
      font-size: 18px;
      font-family: "Medium";
      color: ${(props) => props.check};
    }
    .pc {
      margin-top: auto;
    }
    .certificate {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 133px;
      height: 18px;
      font-size: 12px;
    }
  }
`;

export default ChartList;
