import { KeyframeOptions, animate, useInView } from "framer-motion";
import { useLayoutEffect, useRef } from "react";

type AnimatedCounterProps = {
  from: number,
  to: number,
  animationOptions?: KeyframeOptions,
  comma?: boolean,
}

const AnimatedCounter = ({
  from, 
  to,
  animationOptions,
  comma
}: AnimatedCounterProps) => {
  const ref = useRef<HTMLSpanElement>(null);
  const inView = useInView(ref, { 
    margin: "-75px 0px 0px 0px",
  });

  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) return;
    if (!inView) return;

    element.textContent = String(from);

    const controls = animate(from, to, {
      duration: 1,
      ease: "easeOut",
      ...animationOptions,
      onUpdate(value) {
        element.textContent = 
          comma ? 
          Number(value.toFixed(0)).toLocaleString() : 
          value.toFixed(0);
      }
    })

    return () => {
      controls.stop();
    }

  }, [ref, inView, from, to]);

  return <span ref={ref} />;
}
 
export default AnimatedCounter;