import React from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { useTranslation } from "react-i18next";

const MessageDelete = ({ count, setDeleteModal, multipleDelete }) => {
  const { t } = useTranslation();
  const baseLang = "mypage.f3_7";
  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StMessageDelete>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        <div className="message">
          {t(`${baseLang}.body1`)} {count}
          {t(`${baseLang}.body2`)}
        </div>
        <div className="buttonBox">
          <div
            className="cancelButton"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            {t(`${baseLang}.button1`)}
          </div>
          <div className="columnBar onlyForMobile" />
          <div
            className="confirmButton"
            onClick={() => {
              multipleDelete();
            }}
          >
            {t(`${baseLang}.button2`)}
          </div>
        </div>
      </StMessageDelete>
    </>
  );
};

const StMessageDelete = styled.div`
  position: fixed;
  background-color: white;
  .message {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
  }
  @media ${device.pc} {
    top: 50%;
    left: 50%;
    z-index: 53;
    transform: translate(-50%, -50%);
    display: grid;
    flex-direction: column;
    width: 580px;
    padding: 60px;
    box-sizing: border-box;
    box-shadow: 0px 30px 80px 10px rgba(0, 0, 0, 0.2);
    gap: 48px;
    .onlyForMobile {
      display: none !important;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 21px;
      font-family: "medium";
      font-size: 17px;
      line-height: 25px;
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        cursor: pointer;
      }
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        background: #20315b;
        color: white;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    z-index: 53;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    width: 344px;
    /* height: 182px; */
    padding: 24px;
    box-sizing: border-box;
    border-radius: 24px;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      align-items: center;
      height: 36px;
      gap: 42.5px;
      margin-top: 24px;
      color: #0072de;
      .columnBar {
        width: 1px;
        height: 16px;
        background-color: #e6e6e6;
      }
      .cancelButton {
        width: 62px;
        text-align: center;
        cursor: pointer;
      }
      .confirmButton {
        width: 62px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
`;

export default MessageDelete;
