import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../Cookie";
import bannerDelete from "../img/bannerDelete.svg";
import rectangle from "../img/rectangle.png";
import arrowRight from "../img/arrowRight.svg";
import styled from "styled-components";
import useGetBannerInfo from "../hooks/useGetBannerInfo";
import { device } from "../hooks/device";
import { useRecoilValue } from "recoil";
import { languageIs, userlang } from "../atoms";

const Banner = ({
  setMyPageScroll,
  setSignUpChooseModal,
  setBannerIs,
  bannerIs,
}) => {
  const navigate = useNavigate();
  const token = getCookie("token");
  const country = useRecoilValue(languageIs);
  const { data } = useGetBannerInfo({
    lang: country.split("/")[1],
    country,
  });

  useEffect(() => {
    if (data?.hasActivatePromotion === false) {
      setBannerIs(false);
    } else if (!getCookie("banner") && data?.contents !== undefined) {
      setBannerIs(true);
    }
  }, [data]);

  return (
    <>
      <StBanner className="mobileBanner">
        <div />
        <div className="wrapper">
          <div
            className="promotion onlyForPc"
            dangerouslySetInnerHTML={{ __html: data?.contents?.a }}
          />
          {((data?.contents?.a.length > 0) & (data?.contents?.b1.length > 0)) |
          ((data?.contents?.a.length > 0) & (data?.contents?.b2.length > 0)) ? (
            <img src={rectangle} className="rectangle onlyForPc" alt="" />
          ) : (
            <></>
          )}
          <div
            className="promotion onlyForPCTab"
            dangerouslySetInnerHTML={{ __html: data?.contents?.b1 }}
          />
          {(data?.contents?.b1.length > 0) & (data?.contents?.b2.length > 0) ? (
            <div className="betweenB onlyForPCTab" />
          ) : (
            <></>
          )}
          <div
            className="promotion"
            dangerouslySetInnerHTML={{ __html: data?.contents?.b2 }}
          />
          {(data?.contents?.b1.length > 0) | (data?.contents?.b2.length > 0) ? (
            <img src={rectangle} className="rectangle onlyForPCTab" alt="" />
          ) : (
            <></>
          )}
          {data?.contents?.b2.length > 0 && (
            <img src={rectangle} className="rectangle onlyForMobile" alt="" />
          )}
          <div
            className="promotion link"
            dangerouslySetInnerHTML={{ __html: data?.contents?.c }}
            onClick={() => {
              if (token) {
                if (data?.contents?.link?.signIn === "proctormaticMypage") {
                  navigate(`/mypage`);
                  setMyPageScroll("accountInfo");
                } else {
                  navigate(`${data?.contents?.link?.signIn}`);
                }
              } else {
                if (data?.contents?.link?.signUp === "proctormaticSignup") {
                  setSignUpChooseModal(true);
                } else {
                  window.open("data?.contents?.link?.signUp");
                }
              }
            }}
          />
          <img src={arrowRight} className="arrowRight" alt="" />
        </div>
        <img
          src={bannerDelete}
          onClick={() => {
            setCookie("banner", true);
            setBannerIs(false);
          }}
          className="closing"
          alt=""
        />
      </StBanner>
    </>
  );
};

const StBanner = styled.div`
  background-color: black;
  display: grid;
  width: 100%;
  padding: 10px 18px 11px;
  box-sizing: border-box;
  font-size: 13px;
  font-family: "semibold";
  line-height: 19px;
  .wrapper {
    display: flex;
    align-items: center;
    .arrowRight {
      margin-left: 6px;
      width: 8px;
      height: 8px;
    }
  }
  .closing {
    margin: auto 0 auto auto;
    cursor: pointer;
  }

  @media ${device.pc} {
    grid-template-columns: 100px 1fr 100px;
    .wrapper {
      justify-content: center;
    }
    .onlyForMobile {
      display: none;
    }
  }
  @media ${device.tabMob} {
    height: 48px;
    grid-template-columns: 0px 1fr 14px;
    .wrapper {
      display: flex;
    }
    .onlyForPc {
      display: none !important;
    }
  }
  @media ${device.tablet} {
    .onlyForMobile {
      display: none;
    }
  }
  @media ${device.mobile} {
    .onlyForPCTab {
      display: none !important;
    }
  }
  .rectangle {
    width: 0.7px;
    height: 10px;
    margin: auto 13px;
  }
  .promotion {
    display: flex;
    font-family: "semibold";
  }
  .betweenB {
    width: 13px;
  }
  .mobileBannerText {
    display: flex;
    img {
      width: 8px;
      height: 8px;
      margin: auto 0 auto 5.7px;
    }
  }
  .link {
    font-family: "medium";
    cursor: pointer;
  }
  .orangeText {
    line-height: 19px;
    font-size: 13px;
    color: #e39125;
  }
  .blueText {
    color: #428efe;
  }
`;

export default Banner;
