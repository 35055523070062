import React from "react";
import styled from "styled-components";
import { device } from "../hooks/device";

// styled components

// img
import process from "../img/process.svg";
import Logo from "../img/Logo.svg";
import second from "../img/second.svg";
import third from "../img/third.svg";
import fourth from "../img/fourth.svg";
import ReactPlayer from "react-player";
// import { useEffect } from 'react';
import thumbnail from "../img/thumbnail.png";
import thumbnailEng from "../img/thumbnailEng.png";
import { useRef } from "react";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import DivTwoPart from "../atomic/atom/DivTwoPart";
import DivThreePart from "../atomic/atom/DivThreePart";
import TextThreePart from "../atomic/atom/TextThreePart";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import TextLine from "../atomic/atom/TextLine";

interface Home3Props {
  ref: React.MutableRefObject<undefined>;
}

const Home3: React.FC<Home3Props> = (props) => {
  const videos = useRef<HTMLVideoElement>(null);
  const language = useRecoilValue(languageIs);

  return (
    <StPageGrey ref={props.ref}>
      <div className="inbox">
        <div className="column">
          <div className="line">
            <img className="logo" src={Logo} alt="" />
            <TextLine text="Features" pc_fontsize={26} fontFamily="medium" />
          </div>
          <DivThreePart
            font_family="bold"
            font_size={54}
            pc_margin="90px 0 0 0"
            tab_font_size={24}
            tab_display="flex"
            tab_lineheight={41}
            tab_justify="center"
            tab_margin="50px 0 14px"
            mobile_display="grid"
            mobile_justify="center"
            mobile_font_size={24}
            mobile_lineheight={41}
            mobile_margin="50px 0 14px"
          >
            <TextTwoPart textEng="AI-Powered Online Exam&nbsp;" />
            <TextThreePart
              textEng="Proctoring Service"
              mobile_margin="0 auto"
            />
          </DivThreePart>
          <DivThreePart
            font_family="medium"
            font_size={32}
            font_color="#2276dc"
            pc_margin="14px 0 90px"
            pc_lineheight={47}
            tab_display="flex"
            tab_justify="center"
            tab_font_size={16}
            tab_font_family="bold"
            tab_lineheight={21}
            tab_margin="0px 0 50px"
            mobile_display="grid"
            mobile_justify="center"
            mobile_font_size={16}
            mobile_font_family="bold"
            mobile_lineheight={21}
            mobile_margin="0 0 50px"
          >
            <TextThreePart
              textEng="Say goodbye to manual&nbsp;"
              mobile_margin="0 auto"
            />
            <TextThreePart
              textEng="real-time video proctoring!"
              mobile_margin="0 auto"
            />
          </DivThreePart>
        </div>
        {/*
          * 티켓링크 : https://www.notion.so/edint/4-18-4-26-b1853f20482b40c3b5c2654765e95882?pvs=4
          * 주석작성자 : Noah
          * 주석작성일자 : 2024.04.30
          * 티켓내용 : 영문 버전 웹 기획 (4.18~4.26)
          * 주석설명 : Features 섹션 동영상 삭제 (주석처리)
        */}
        {/* <video
          ref={videos}
          className="brain"
          id="video"
          src={
            language === "/ko"
              ? "https://proctormatic-prod-s3-fe-asset-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/proctormaticHome3Video.mp4"
              : "https://proctormatic-prod-s3-fe-asset-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/proctormaticHome3VideoEng.mp4"
          }
          poster={language === "/ko" ? thumbnail : thumbnailEng}
          controls={true}
          width="100%"
          height="70%"
          muted={true}
        /> */}
        <div className="box">
          <div className="downBox">
            <img className="process" src={process} alt="" />
            <div>
              <div className="title">
                <TextTwoPart textEng="Easy proctoring session setup" />
              </div>
              <div className="subTitle">
                {/* 3분이면 OK! */}
                <TextTwoPart
                  textEng="Create in 3 minutes!"
                  tab_font_size_en={22}
                />
              </div>
              <div className="content">
                <TextTwoPart
                  textKor="시험 제목, 날짜 등 기본적인 정보 입력 후 결제까지 3분 안에 시험이 예약되며, 시험 진행을 위한 응시자 전용 URL(QR 코드 포함) 발급과 안내를 전달 받아 공유하면 끝"
                  textEng="Set up proctoring sessions in just 3 minutes after entering basic information. Then receive a dedicated URL(or a QR code) for test-takers along with instructions."
                />
              </div>
            </div>
          </div>
          <div className="downBox">
            <img className="process" alt="" src={second} />
            <div>
              <div className="title">
                <TextTwoPart textEng="From A to Z" />
              </div>
              <div className="subTitle">
                <TextTwoPart
                  textEng="Test-takers manage it all!"
                  tab_font_size_en={22}
                />
              </div>
              <div className="content">
                <TextTwoPart
                  textEng="Test-takers can use the Proctormatic mobile app for a seamless, 
                one-stop experience, including exam access, identity verification, 
                scanning their surroundings, camera setup, recording their exam,
                 and uploading the video."
                />
              </div>
            </div>
          </div>
          <div className="downBox">
            <img className="process" alt="" src={third} />
            <div>
              <div className="title">
                <TextTwoPart textEng="Automated Cheating Detection" />
              </div>
              <div className="subTitle">
                <DivThreePart mobile_display="grid">
                  <TextTwoPart
                    textEng="Experience our unique AI&nbsp;"
                    tab_font_size_en={22}
                  />
                  <TextTwoPart textEng="Solution!" tab_font_size_en={22} />
                </DivThreePart>
              </div>
              <div className="content">
                <TextTwoPart
                  textEng="Proctormatic uses AI video analysis to verify test 
                videos by detecting the test-taker's behavior,
                identifying objects, and flagging suspicious behaviors and cheating."
                />
              </div>
            </div>
          </div>
          <div className="downBox">
            <img className="process" alt="" src={fourth} />
            <div>
              <div className="title">
                <TextTwoPart textEng="Data-driven reports" />
              </div>
              <div className="subTitle">
                <DivThreePart mobile_display="grid">
                  <TextTwoPart
                    textEng="All about test-takers in&nbsp;"
                    tab_font_size_en={22}
                  />
                  <TextTwoPart textEng="one report!" tab_font_size_en={22} />
                </DivThreePart>
              </div>
              <div className="content">
                <TextTwoPart
                  textEng="After analysis, a summary report of the detection results
                 is emailed to the organizer, simplifying the verification process."
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StPageGrey>
  );
};

const StPageGrey = styled.div<Home3Props>`
  margin: auto auto auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f1f2;

  @media ${device.tabMob} {
    width: 100%;
    padding: 110px 24px;
    box-sizing: border-box;
    .brain {
      width: 100%;
      background-color: black;
    }
    .column {
      display: flex;
      flex-direction: column;
    }
    .line {
      display: flex;
      align-items: center;
    }
    .head {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
      margin: 50px 0 14px;
    }
    .h6 {
      font-size: 19px;
      font-family: "Medium";
    }
    .h7 {
      font-family: "Bold";
      font-size: 28px;
      line-height: 41px;
    }
    .grey3 {
      margin: 0 auto 50px;
      text-align: center;
      color: #2276dc;
      font-family: "Bold";
      font-size: 14px;
      line-height: 21px;
    }
    .logo {
      width: 28px;
      margin-right: 8px;
    }
    .box {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 32px 0;
      background-color: white;
      box-shadow: 10px 10px 36px rgba(0, 0, 0, 0.08);
    }
    .downBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 32px 24px;
      box-sizing: border-box;
      background-color: white;
      .title {
        font-family: "Bold";
        line-height: 24px;
      }
      .subTitle {
        font-family: "Bold";
        font-size: 24px;
        line-height: 36px;
        color: #2276dc;
        margin: 3px 0 20px;
      }
      .content {
        font-family: "regular";
        font-size: 15px;
        line-height: 22px;
      }
    }
    .process {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
  }
  @media ${device.tablet} {
    .onlyForMobile {
      display: none !important;
    }
  }
  @media ${device.pc} {
    width: 100%;
    .onlyForMobile {
      display: none !important;
    }
    .inbox {
      width: 1440px;
      padding: 140px 122px;
      box-sizing: border-box;
    }
    .mobile {
      display: none;
    }
    .brain {
      display: flex;
      width: 1192px;
      /* background-color: black; */
    }
    .column {
      display: flex;
      flex-direction: column;
      margin-right: auto;
    }
    .line {
      display: flex;
      align-items: center;
    }
    .h6 {
      font-size: 26px;
      font-family: "Medium";
    }
    .h7 {
      font-family: "Bold";
      font-size: 54px;
      line-height: 80px;
      margin-top: 90px;
    }
    .grey3 {
      /* margin */
      color: #2276dc;
      font-family: "Medium";
      font-size: 32px;
      line-height: 47px;
      margin: 16px 0 90px;
    }
    .logo {
      margin-right: 20px;
      width: 33px;
      height: 38px;
    }
    .box {
      display: grid;
      flex-direction: column;
      gap: 140px;
      width: 100%;
      padding: 140px 120px;
      background-color: white;
      box-sizing: border-box;
      box-shadow: 10px 10px 36px rgba(0, 0, 0, 0.08);
    }
    .downBox {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: white;
      .title {
        font-family: "Bold";
        font-size: 24px;
        line-height: 36px;
      }
      .subTitle {
        font-family: "Bold";
        font-size: 36px;
        line-height: 53px;
        color: #2276dc;
        margin: 10px 0 32px;
      }
      .content {
        font-family: "Regular";
        font-size: 22px;
        line-height: 33px;
      }
    }
    .process {
      width: 180px;
      height: 180px;
      margin-right: 64px;
    }
  }
`;

export default Home3;
