import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { device } from "../hooks/device";
import { ModalBackD } from "../components/Style";
import customAxios, { baseURL } from "../api/handler";
import dayjs from "dayjs";

// Component
import Action from "../components/Action";
import EventBar from "../components/EventBar";
import useScrollLockThree from "../hooks/useScrollLockThree";
import HoverToolTipBadge from "../components/HoverToolTipBadge";
import SelectedToolTipBadge from "../components/SelectedToolTipBadge";
import SendingConfirm from "../modal/SendingConfirm";
import SendingVideo from "../modal/ReportSpecificSendingVideo"
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SelectedToolTipModal, languageIs, userInfo } from "../atoms";
import sharing from "../img/sharing.svg";
import greyX from "../img/greyX.svg";
import { useTranslation } from "react-i18next";

{/**
  * 티켓링크 : https://www.notion.so/edint/WEB-533593ab88c14e858df9b9e8bc008e4e?pvs=4
  * 티켓ID : 77
  * 주석작성자 : Noah
  * 주석작성일자 : 2024.05.27
  * 티켓내용 : 결과 리포트 → ‘감지된 이벤트 영상’ 제공 리스트 갯수 및 전체 리스트 보기
  * 주석설명 : 감지된 이벤트 영상 전체 리스트 보기 모달 생성
  */}
const ActionMaximize = ({
  user,
  graphUnit,
  columnCount,
  setMaximizeModal,
}) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d3";
  const userData = useRecoilValue(userInfo);
  const uid = userData.hostUuid;
  const testerId = useParams().testerId;
  const testId = useParams().testId;
  const myVideo = useRef();
  const timeout = useRef(null);
  const scrollContainerRef = useRef(null);
  const scrollTimeout = useRef(null);
  const [testVideo, setTestVideo] = useState("");
  const [test, setTest] = useState();
  const [show, setShow] = useState(1);
  const [clickedEvent, setClickedEvent] = useState(0);
  const [auto, setAuto] = useState(false);
  const [startTime, setStartTime] = useState();
  const [duration, setDuration] = useState();
  const [scrollbarActive, setScrollbarActive] = useState(false);

  const [videoLink, setVideoLink] = useState();
  const [videoReady, setVideoReady] = useState(false);
  const [encodedVideoLink, setEncodedVideoLink] = useState();


  const [sendingVideoModal, setSendingVideoModal] = useState(false);
  const [sendingConfirmModal, setSendingConfirmModal] = useState(false);

  const country = useRecoilValue(languageIs);
  const setSelectedToolTip = useSetRecoilState(SelectedToolTipModal);

  const clearTimeOutForVideo = () => {
    clearTimeout(timeout.current);
  };

  const setPauTime = (endTime) => {
    timeout.current = setTimeout(() => {
      myVideo?.current?.pause();
    }, endTime);
  };

  const videoPlay = () => {
    setAuto(true);
  };

  const setCurTime = (time) => {
    myVideo.current.currentTime = time;
    myVideo.current.play();
  };

  useEffect(() => {
    const container = document.getElementById("scrollableContainer");
    if (container) {
      container.addEventListener("scroll", handleScrollbarVisibility);
      return () => {
        container.removeEventListener("scroll", handleScrollbarVisibility);
      };
    }
  }, []);

  const cheating = user?.testReport?.timetable?.filter(
    (user) => user?.level === "cheat"
  );
  const abnormal = user?.testReport?.timetable?.filter(
    (user) => user?.level === "abnormal"
  );

  /** 1초가 지난 뒤에 스크롤 바가 안보이게 하기 */
  const handleScrollbarVisibility = () => {
    if (!scrollbarActive) {
      setScrollbarActive(true);
    }
    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
      setScrollbarActive(false);
    }, 1000);
  };

  const videoExtract = () => {
    customAxios
      .get(
        `${baseURL}/api/v1/tests/${testId}/report?testerId=${testerId}&purpose=video-url`
      )
      .then(({ data }) => {
        setTestVideo(data);
        setTest(
          <video
            id="video"
            ref={myVideo}
            playsInline
            preload="auto"
            src={data.videoUrl}
            autoPlay={auto}
            className="userVideo"
            height="298px"
            width="530px"
            controls
          />
        );
      })
      .catch(() => {
        showToast({ message: t(`errorCode.unknown_server_error`) });
      });
  };

  /** 선택한 영상의 보낼 내용과 제목 */
  let sendingMail =
    `mailto:${user?.testReport?.uploader?.uploaderEmail}?body=%E2%80%BB%E2%80%BB%20%EC%95%84%EB%9E%98%EC%9D%98%20%EC%8B%9C%ED%97%98%20%EC%A0%95%EB%B3%B4%EC%99%80%20%EC%98%81%EC%83%81%20URL%20%EC%A0%95%EB%B3%B4%EB%A5%BC%20%ED%99%95%EC%9D%B8%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94%20%E2%80%BB%E2%80%BB` +
    "%0D%0D" +
    "-------------------------------------------------------------------" +
    "%0D%0D" +
    "-%20%EC%8B%9C%ED%97%98%20%EC%A0%9C%EB%AA%A9%0D%0A" + // 시험 제목
    `: ${user?.test?.testName}` +
    "%0D%0D" +
    "-%20%EC%8B%9C%ED%97%98%20%EB%82%A0%EC%A7%9C%EC%99%80%20%EC%8B%9C%EA%B0%84%0D%0A" + // 시험 날짜와 시간
    `: ${dayjs(user?.test?.testStartTime).format(
      "YYYY-MM-DD(ddd), A HH:mm"
    )} ~ ${dayjs(user?.test?.testEndTime).format("A HH:mm")}` +
    "%0D%0D" +
    "-%20%EC%A3%BC%EC%B5%9C%EC%9E%90%0D%0A" + // 주최자
    `: ${user?.test?.hostName} (${user?.test?.hostEmail})` +
    "%0A%0A" +
    `-%20%ED%99%95%EC%9D%B8%EC%9D%B4%20%ED%95%84%EC%9A%94%ED%95%9C%20%EC%9D%91%EC%8B%9C%20%EC%A4%91%20%EC%9D%B4%EB%B2%A4%ED%8A%B8%20%EC%98%81%EC%83%81%20URL%0D%0A` + // 확인이 필요한 응시 중 이벤트 영상
    `http://proctormatic.com${country}/video?${encodedVideoLink}%0A%0A` +
    "--------------------------------------------------------------------" +
    "%0D%0D";

  /** 선택한 영상의 링크 가져오기 */
  const selectedVideoSend = () => {
    customAxios
      .post(
        `${process.env.REACT_APP_MOBILE_ADDRESS}/api/v1/clip_video`,
        {
          owner_uid: uid,
          test_tid: testId,
          tester_id: testerId,
          start_time: startTime,
          duration: duration,
        },
        {
          timeout: 600000,
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(({ data }) => {
        setVideoLink(`http://proctormatic.com/video?` + data?.result);
        setEncodedVideoLink(encodeURIComponent(data?.result));
        setVideoReady(false);
        setSendingConfirmModal(false);
        setSendingVideoModal(true);
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };

  useEffect(() => {
    videoExtract();
  }, [user])

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StActionMaximize
        $clickedEvent={clickedEvent}
        graphUnit={graphUnit}
        show={show}
        videoLength={user?.testReport?.timeline?.testVideoLength}
      >
        {sendingVideoModal && (
          <SendingVideo
            videoLink={videoLink}
            setSendingVideoModal={setSendingVideoModal}
            sendingMail={sendingMail}
            setVideoReady={setVideoReady}
            videoReady={videoReady}
            testerEmail={user?.testReport?.uploader?.uploaderEmail}
          />
        )}
        {sendingConfirmModal && (
          <SendingConfirm
            setSendingConfirmModal={setSendingConfirmModal}
            selectedVideoSend={selectedVideoSend}
            videoReady={videoReady}
            setVideoReady={setVideoReady}
          />
        )}
        {/* 왼쪽 */}
        <div className="leftWrapper">
          {show === 1 && (
            <>
              {user?.testReport?.timetable?.length !== 0 ? (
                <div
                  ref={scrollContainerRef}
                  id="scrollableContainer"
                  className="videoLeftIn"
                >
                  {user?.testReport?.timetable?.map(
                    (value, index) => (
                      <Action
                        setClickedEvent={setClickedEvent}
                        clickedEvent={clickedEvent}
                        clearTimeOut={clearTimeOutForVideo}
                        setPauTime={setPauTime}
                        setCurTime={setCurTime}
                        videoUrl={testVideo?.videoUrl}
                        videoPlay={videoPlay}
                        key={index}
                        startTime={value.startTime}
                        endTime={value.endTime}
                        index={index}
                        text={value.sort}
                        timestamp={value.timestamp}
                        level={value.level}
                        setStartTime={setStartTime}
                        setDuration={setDuration}
                        detectedItem={value.detectedItem}
                        // 티켓ID 83 관련 추가
                        scrollContainerRef={scrollContainerRef}
                      />
                    )
                  )}
                </div>
              ) : (
                <div className="videoLeftInTwo">
                  {t(`${baseLang}.noissue`)}
                </div>
              )}
            </>
          )}
          {show === 2 && (
            <>
              {cheating?.length !== 0 ? (
                <div
                  ref={scrollContainerRef}
                  id="scrollableContainer"
                  className="videoLeftIn"
                >
                  {cheating?.map(
                    (value, index) => (
                      <Action
                        setClickedEvent={setClickedEvent}
                        clickedEvent={clickedEvent}
                        clearTimeOut={clearTimeOutForVideo}
                        setPauTime={setPauTime}
                        setCurTime={setCurTime}
                        videoUrl={testVideo?.videoUrl}
                        videoPlay={videoPlay}
                        key={index}
                        startTime={value.startTime}
                        endTime={value.endTime}
                        index={index}
                        text={value.sort}
                        timestamp={value.timestamp}
                        level={value.level}
                        setStartTime={setStartTime}
                        setDuration={setDuration}
                        detectedItem={value.detectedItem}
                        // 티켓ID 83 관련 추가
                        scrollContainerRef={scrollContainerRef}
                      />
                    )
                  )}
                </div>
              ) : (
                <div className="videoLeftInTwo">
                  {t(`${baseLang}.noissue`)}
                </div>
              )}
            </>
          )}
          {show === 3 && (
            <>
              {abnormal?.length !== 0 ? (
                <div
                  ref={scrollContainerRef}
                  id="scrollableContainer"
                  className="videoLeftIn"
                >
                  {abnormal?.map(
                    (value, index) => (
                      <Action
                        setClickedEvent={setClickedEvent}
                        clickedEvent={clickedEvent}
                        clearTimeOut={clearTimeOutForVideo}
                        setPauTime={setPauTime}
                        setCurTime={setCurTime}
                        videoUrl={testVideo?.videoUrl}
                        videoPlay={videoPlay}
                        key={index}
                        startTime={value.startTime}
                        endTime={value.endTime}
                        index={index}
                        text={value.sort}
                        timestamp={value.timestamp}
                        level={value.level}
                        setStartTime={setStartTime}
                        setDuration={setDuration}
                        detectedItem={value.detectedItem}
                        // 티켓ID 83 관련 추가
                        scrollContainerRef={scrollContainerRef}
                      />
                    )
                  )}
                </div>
              ) : (
                <div className="videoLeftInTwo">
                  {t(`${baseLang}.noissue`)}
                </div>
              )}
            </>
          )}
          <div className="videoSharingButton">
            <img src={sharing} />
            <div
              onClick={() => {
                if (clickedEvent !== 0) {
                  // selectedVideoSend();
                  setSendingConfirmModal(true);
                  // setSendingVideoModal(true);
                }
              }}
            >
              {t(`${baseLang}.share`)}
            </div>
          </div>
        </div>
        {/* 오른쪽 */}
        <div className="rightWrapper">
          <div className="rightMobile">
            <div className="modalHeader">
              <img
                className="cancelButton"
                src={greyX}
                onClick={() => {
                  setSelectedToolTip({isSelected: false});
                  setMaximizeModal(false);
                }}
              />
            </div>
            <div className="timeLinePart">
              <HoverToolTipBadge 
                videoLength={user?.testReport?.timeline?.testVideoLength}
              />
              <SelectedToolTipBadge 
                videoLength={user?.testReport?.timeline?.testVideoLength}
              />
              <div className="timeLineInfo">
                <div>{t(`${baseLang}.timeline`)}</div>
                <div className="rightInfo">
                  <div className="infoItemForm">
                    <div
                      className="circle"
                      style={{ background: "#C83B38" }}
                    />
                    <div>{t(`${baseLang}.urgent`)}</div>
                  </div>
                  <div className="infoItemForm">
                    <div
                      className="circle"
                      style={{ background: "#FFBC00" }}
                    />
                    <div>{t(`${baseLang}.recommend`)}</div>
                  </div>
                </div>
              </div>
              <div className="timeLineGraphInfo">
                <div className="fromStartToEnd">
                  <div>
                    {dayjs(
                      user?.testReport?.timeline?.testStartTime
                    ).format("A hh:mm")}
                  </div>
                  <div>
                    {dayjs(
                      user?.testReport?.timeline?.testEndTime
                    ).format("A hh:mm")}
                  </div>
                </div>
                <div className="barGraph">
                  <div className="unitBarPart">
                    {columnCount?.map((value, index) => (
                      <div className="unitBar" key={index}>
                        <div>{(graphUnit / 60) * (index + 1)}</div>
                        <div className="columnStick" key={index} />
                      </div>
                    ))}
                  </div>
                  <div className="eventBarPart">
                    {/** 티켓ID 83 관련 추가 */}
                    {show === 1 && (
                      <>
                        {user?.testReport?.timeline?.timelineTable?.map(
                          (value, index) => (
                            <EventBar
                              key={index}
                              start={value?.startTime}
                              end={value?.endTime}
                              level={value?.level}
                              videoLength={
                                user?.testReport?.timeline?.testVideoLength
                              }
                              // 티켓ID 83 관련 추가
                              eventIndex={index}
                              clearTimeOut={clearTimeOutForVideo}
                              setClickedEvent={setClickedEvent}
                              setPauTime={setPauTime}
                              setCurTime={setCurTime}
                              setStartTime={setStartTime}
                              setDuration={setDuration}
                            />
                          )
                        )}
                      </>
                    )}
                    {show === 2 && (
                      <>
                        {cheating?.map(
                          (value, index) => (
                            <EventBar
                              key={index}
                              start={value?.startTime}
                              end={value?.endTime}
                              level={value?.level}
                              videoLength={
                                user?.testReport?.timeline?.testVideoLength
                              }
                              // 티켓ID 83 관련 추가
                              eventIndex={index}
                              clearTimeOut={clearTimeOutForVideo}
                              setClickedEvent={setClickedEvent}
                              setPauTime={setPauTime}
                              setCurTime={setCurTime}
                              setStartTime={setStartTime}
                              setDuration={setDuration}
                            />
                          )
                        )}
                      </>
                    )}
                    {show === 3 && (
                      <>
                        {abnormal?.map(
                          (value, index) => (
                            <EventBar
                              key={index}
                              start={value?.startTime}
                              end={value?.endTime}
                              level={value?.level}
                              videoLength={
                                user?.testReport?.timeline?.testVideoLength
                              }
                              // 티켓ID 83 관련 추가
                              eventIndex={index}
                              clearTimeOut={clearTimeOutForVideo}
                              setClickedEvent={setClickedEvent}
                              setPauTime={setPauTime}
                              setCurTime={setCurTime}
                              setStartTime={setStartTime}
                              setDuration={setDuration}
                            />
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="rightShow">
              <div>{t(`${baseLang}.view`)} :&nbsp;&nbsp;</div>
              <div
                className="all"
                onClick={() => {
                  setShow(1);
                  // 티켓ID 83 관련 추가
                  setSelectedToolTip({isSelected: false});
                  setClickedEvent(0);
                }}
              >
                {t(`${baseLang}.all`)}
              </div>
              <div className="greyColumn" />
              <div
                className="cheating"
                onClick={() => {
                  setShow(2);
                  // 티켓ID 83 관련 추가
                  setSelectedToolTip({isSelected: false});
                  setClickedEvent(0);
                }}
              >
                {t(`${baseLang}.urgent`)}
              </div>
              <div className="greyColumn" />
              <div
                className="abnormal"
                onClick={() => {
                  setShow(3);
                  // 티켓ID 83 관련 추가
                  setSelectedToolTip({isSelected: false});
                  setClickedEvent(0);
                }}
              >
                {t(`${baseLang}.recommend`)}
              </div>
            </div>
          </div>
          <div className="videoInfo">
            {testVideo && test}
          </div>
        </div>
      </StActionMaximize>
    </>
  );
};

const StActionMaximize = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 30px 80px 10px rgba(0, 0, 0, 0.20);
  border-radius: 24px;
  .rightShow {
    .all {
      color: ${(props) => (props.show === 1 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .cheating {
      color: ${(props) => (props.show === 2 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
    .abnormal {
      color: ${(props) => (props.show === 3 ? "#2276DC" : "#AEAEAE")};
      cursor: pointer;
    }
  }
  .greyColumn {
    width: 1px;
    height: 16px;
    background-color: #c4c4c4;
    margin: 0 12px;
  }
  .modalHeader {
    display: flex;
    align-items: center;
    width: 100%;
    img {
      cursor: pointer;
    }
    .cancelButton {
      margin-left: auto;
    }
  }
  @media ${device.pc} {
    display: flex;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 86vw;
    height: 90vh;
    min-width: 1060px;
    min-height: 660px;
    .leftWrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      padding: 32px 32px 15px;
      border-radius: 24px 0 0 24px;
      background-color: #f0f1f2;
      .videoLeftIn {
        width: 100%;
        height: calc(90vh - 102px);
        min-height: 550px;
        overflow-x: hidden;
        border-radius: 10px;
        background-color: white;
        ::-webkit-scrollbar {
          width: 5px;
          height: 10px;
          margin-top: 100px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #c6c6c6;
          border-radius: 100px;
        }
        ::-webkit-scrollbar-track {
          border-radius: 1rem;
        }
      }
      .videoLeftInTwo {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bdbdbd;
        width: 100%;
        /* height: 268px; */
        flex-grow: 1;
        flex-basis: 0;
        overflow-x: hidden;
        border-radius: 10px;
        background-color: white;
      }
      .videoSharingButton {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 6px;
        margin: 23px auto 0;
        font-size: 16px;
        font-family: "medium";
        line-height: 24px;
        color: ${(props) =>
          props.$clickedEvent === 0 ? "rgba(34,118,220,0.5)" : "#2276DC"};
        cursor: ${(props) =>
          props.$clickedEvent === 0 ? "default" : "pointer"};
      }
    }
    .rightWrapper {
      display: flex;
      flex-direction: column;
      width: 50%;
      min-width: 530px;
      padding: 32px;
      height: 100%;
      .timeLinePart {
        margin-top: 50px;
        position: relative;
        .timeLineInfo {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-family: "regular";
          line-height: 18px;
          .rightInfo {
            display: grid;
            grid-template-columns: auto auto;
            gap: 12px;
            .infoItemForm {
              display: grid;
              grid-template-columns: auto auto;
              align-items: center;
              gap: 6px;
              .circle {
                width: 5px;
                height: 5px;
                border-radius: 5px;
              }
            }
          }
        }
        .timeLineGraphInfo{
          width: 100%;
          height: 56px;
          border-right: 1px solid #444444;
          border-left: 1px solid #444444;
          margin: 16px 0 24px;
          .fromStartToEnd {
            display: flex;
            justify-content: space-between;
            font-size: 10px;
            font-family: "regular";
            line-height: 15px;
            color: #818181;
            padding: 0 4px;
            box-sizing: border-box;
          }
          .barGraph {
            position: relative;
            width: 100%;
            height: 15px;
            margin-top: 26px;
            background: rgba(140, 224, 174, 0.3);
            overflow: hidden;
            .unitBarPart {
              position: absolute;
              z-index: 10;
              bottom: 0;
              left: 0;
              display: flex;
              width: 100%;
              .unitBar {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 1px;
                color: #aeaeae;
                font-size: 8px;
                line-height: 12px;
                margin-left: calc(
                  100% * ${(props) => props.graphUnit / props.videoLength}
                );
                .columnStick {
                  width: 1px;
                  height: 21px;
                  border-left: 1px dashed #aeaeae;
                  box-sizing: border-box;
                }
              }
            }
            .eventBarPart {
              position: absolute;
              /** 티켓ID 83 관련 추가 */
              /* z-index: 9; */
              z-index: 11;
              bottom: 15px;
              left: 0;
              display: flex;
              width: 100%;
            }
          }
        }
      }
      .rightShow {
        margin-top: 50px;
        display: flex;
        justify-content: end;
        align-items: center;
        font-family: "Regular";
        font-size: 16px;
      }
      .videoInfo {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 15px;
        video {
          width: 100%;
          /* height : 353px; */
          height: 80%;
          min-width: 529.5px;
          flex-grow: 1;
        }
      }
    }
    .rightShow {
      font-size: 16px;
      display: flex;
      align-items: center;
      margin-left: auto;
    }
    .userVideo {
      background-color: black;
    }
  }

  @media ${device.tabMob} {
    display: flex;
    flex-direction: column-reverse;
    justify-content: start;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 88.125vw;
    height: 90vh;
    min-width: 344px;
    ::-webkit-scrollbar {
      display: block !important;
      width: 5px;
      height: 10px;
      margin-top: 100px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #c6c6c6;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 1rem;
    }
    .leftWrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      box-sizing: border-box;
      padding: 12px 16px 0 16px;
      border-radius: 0 0 24px 24px;
      background-color: #f0f1f2;
      .videoLeftIn {
        width: 100%;
        box-sizing: border-box;
        height: 100%;
        flex-grow: 1;
        overflow-x: hidden;
        border-radius: 10px;
        background-color: white;
        flex-basis: 0px;
      }
      .videoLeftInTwo {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #bdbdbd;
        width: 100%;
        /* height: 244px; */
        flex-grow: 1;
        flex-basis: 0;
        overflow-x: hidden;
        border-radius: 10px;
        background-color: white;
      }
      .videoSharingButton {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 6px;
        margin: 20px auto 20px;
        font-size: 13px;
        font-family: "medium";
        line-height: 19px;
        color: #2276dc;
        opacity: ${(props) => (props.$clickedEvent === 0 ? "0.5" : "")};
        cursor: ${(props) =>
          props.$clickedEvent === 0 ? "default" : "pointer"};
      }
    }
    .rightWrapper {
      display: flex;
      flex-direction: column;
      padding: 16px 16px 0 16px;
      gap: 16px;
      .rightMobile {
        display: flex;
        flex-direction: row-reverse;
        .timeLinePart {
          display: none;
        }
        .rightShow {
          display: flex;
          justify-content: start;
          align-items: center;
          font-family: "Regular";
          font-size: 13px;
          width: calc(100% - 14px);
        }
      }
      .videoInfo {
        display: flex;
        flex-direction: row-reverse;
      }
      .userVideo {
        background-color: black;
        width: 100%;
        height: 100%;
        max-height: 289px;
      }
      .modalHeader {
        display: flex;
        justify-content: end;
        flex-basis: 14px;
      }
    }
  }
`;

export default ActionMaximize;