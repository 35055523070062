import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetQnACount = () => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getQnACount({ utc });
    return data;
  };
  return useQuery(["QnACount"], fetcher);
};

export default useGetQnACount;
