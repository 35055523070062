import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetIndividualRefund = () => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getIndividualRefund({ utc });
    return data;
  };
  return useQuery(["individualRefund"], fetcher);
};

export default useGetIndividualRefund;
