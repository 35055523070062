import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { languageIs } from "../atoms";

const Test = () => {
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const goBack = (e) => {
    // navigate(`/mypage`);
    e.preventDefault();
    console.log("dddddd");
    window.alert("dndndndnndn");
  };

  useEffect(() => {
    console.log("heyyy");
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  function onClickKGPayment() {
    const { IMP } = window;
    IMP.init("imp81670457");
    const data = {
      pg: "html5_inicis.INIpayTest", // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: "아임포트 테스트", // 주문명
      buyer_name: "luke", // 구매자 이름
      // buyer_tel: '01023221427',                     // 구매자 전화번호
      buyer_email: "swoo1429@gmail.com", // 구매자 이메일
      buyer_addr: "신사동 661-16", // 구매자 주소
      buyer_postcode: "06018", // 구매자 우편번호
      amount: 100,
      language: "ko",
      currency: "",
    };
    IMP.request_pay(data, callback);
  }

  function onClickDaNalPayment() {
    const { IMP } = window;
    IMP.init("imp81670457");
    const data = {
      pg: "danal_tpay.9810030929",
      //  card (신용카드)
      //  trans (실시간 계좌이체)
      //  vbank(가상계좌)
      //  phone(휴대폰 소액결제)
      //  cultureland (문화상품권)
      //  happymoney (해피머니)
      //  booknlife (도서문화상품권),                           // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: "아임포트 테스트", // 주문명
      // buyer_name: 'luke',                           // 구매자 이름
      // buyer_tel: '01023221427',                     // 구매자 전화번호
      // buyer_email: 'swoo1429@gmail.com',               // 구매자 이메일
      // buyer_addr: '신사동 661-16',                    // 구매자 주소
      // buyer_postcode: '06018',                      // 구매자 우편번호
      amount: 100,
      language: "ko",
      currency: "",
    };
    IMP.request_pay(data, callback);
  }

  function onClickTossPayments() {
    const { IMP } = window;
    IMP.init("imp81670457");
    const data = {
      pg: "tosspayments.iamporttest_3",
      //  card (신용카드)
      //  trans (실시간 계좌이체)
      //  vbank(가상계좌)
      //  phone(휴대폰 소액결제)
      //  cultureland (문화상품권)
      //  happymoney (해피머니)
      //  booknlife (도서문화상품권),                           // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: "아임포트 테스트", // 주문명
      // buyer_name: 'luke',                           // 구매자 이름
      // buyer_tel: '01023221427',                     // 구매자 전화번호
      // buyer_email: 'swoo1429@gmail.com',               // 구매자 이메일
      // buyer_addr: '신사동 661-16',                    // 구매자 주소
      // buyer_postcode: '06018',                      // 구매자 우편번호
      amount: 100,
      language: "ko",
      currency: "",
    };
    IMP.request_pay(data, callback);
  }

  function onClickNicePayments() {
    const { IMP } = window;
    IMP.init("imp81670457");
    const data = {
      pg: "nice.nictest00m",
      //  card (신용카드)
      //  trans (실시간 계좌이체)
      //  vbank(가상계좌)
      //  phone(휴대폰 소액결제)
      //  cultureland (문화상품권)
      //  happymoney (해피머니)
      //  booknlife (도서문화상품권),                           // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: "아임포트 테스트", // 주문명
      // buyer_name: 'luke',                           // 구매자 이름
      // buyer_tel: '01023221427',                     // 구매자 전화번호
      // buyer_email: 'swoo1429@gmail.com',               // 구매자 이메일
      // buyer_addr: '신사동 661-16',                    // 구매자 주소
      // buyer_postcode: '06018',                      // 구매자 우편번호
      amount: 100,
      language: "ko",
      currency: "",
    };
    IMP.request_pay(data, callback);
  }

  function onClickKakaoPayments() {
    const { IMP } = window;
    IMP.init("imp81670457");
    const data = {
      pg: "kakaopay.TC0ONETIME",
      //  card (신용카드)
      //  trans (실시간 계좌이체)
      //  vbank(가상계좌)
      //  phone(휴대폰 소액결제)
      //  cultureland (문화상품권)
      //  happymoney (해피머니)
      //  booknlife (도서문화상품권),                           // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: "아임포트 테스트", // 주문명
      // buyer_name: 'luke',                           // 구매자 이름
      // buyer_tel: '01023221427',                     // 구매자 전화번호
      // buyer_email: 'swoo1429@gmail.com',               // 구매자 이메일
      // buyer_addr: '신사동 661-16',                    // 구매자 주소
      // buyer_postcode: '06018',                      // 구매자 우편번호
      amount: 100,
      language: "ko",
      currency: "",
    };
    IMP.request_pay(data, callback);
  }

  function onClickToss() {
    const { IMP } = window;
    IMP.init("imp81670457");
    const data = {
      pg: "tosspay.tosstest",
      //  card (신용카드)
      //  trans (실시간 계좌이체)
      //  vbank(가상계좌)
      //  phone(휴대폰 소액결제)
      //  cultureland (문화상품권)
      //  happymoney (해피머니)
      //  booknlife (도서문화상품권),                           // PG사
      pay_method: "tosspay", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: "아임포트 테스트", // 주문명
      // buyer_name: 'luke',                           // 구매자 이름
      // buyer_tel: '01023221427',                     // 구매자 전화번호
      // buyer_email: 'swoo1429@gmail.com',               // 구매자 이메일
      // buyer_addr: '신사동 661-16',                    // 구매자 주소
      // buyer_postcode: '06018',                      // 구매자 우편번호
      amount: 100,
      language: "ko",
      currency: "",
    };
    IMP.request_pay(data, callback);
  }

  function onClickSmile() {
    const { IMP } = window;
    IMP.init("imp81670457");
    const data = {
      pg: "smilepay.cnstest25m",
      //  card (신용카드)
      //  trans (실시간 계좌이체)
      //  vbank(가상계좌)
      //  phone(휴대폰 소액결제)
      //  cultureland (문화상품권)
      //  happymoney (해피머니)
      //  booknlife (도서문화상품권),                           // PG사
      pay_method: "card", // 결제수단
      merchant_uid: `mid_${new Date().getTime()}`, // 주문번호
      name: "아임포트 테스트", // 주문명
      // buyer_name: 'luke',                           // 구매자 이름
      // buyer_tel: '01023221427',                     // 구매자 전화번호
      // buyer_email: 'swoo1429@gmail.com',               // 구매자 이메일
      // buyer_addr: '신사동 661-16',                    // 구매자 주소
      // buyer_postcode: '06018',                      // 구매자 우편번호
      amount: 100,
      language: "ko",
      currency: "",
    };
    IMP.request_pay(data, callback);
  }

  function callback(response) {
    const {
      success,
      merchant_uid,
      error_msg,
      imp_uid,
      pay_method,
      paid_amount,
      status,
      pg_provider,
      emb_pg_provider,
      buyer_name,
      buyer_email,
      buyer_tel,
      paid_at,
      receipt_url,
      pg_tid,
      card_name,
      card_number,
      currency,
    } = response;

    if (success) {
      alert("결제 성공");
      console.log("imp_uid :" + imp_uid);
      console.log("pay_method :" + pay_method);
      console.log("paid_amount :" + paid_amount);
      console.log("status :" + status);
      console.log("pg_provider :" + pg_provider);
      console.log("emb_pg_provider :" + emb_pg_provider);
      console.log("buyer_name :" + buyer_name);
      console.log("buyer_email :" + buyer_email);
      console.log("buyer_tel : " + buyer_tel);
      console.log("paid_at :" + paid_at);
      console.log("receipt_url :" + receipt_url);
      console.log("pg_tid :" + pg_tid);
      console.log("카드사 : " + card_name);
      console.log("카드번호 : " + card_number);
      console.log("통화 :" + currency);
    } else {
      alert(`결제 실패: ${error_msg}`);
    }
  }

  return (
    <StTest>
      <div>
        <div className="card">
          <div className="head">신용카드 결제</div>
          <div className="payment">
            <div>KG 이니시스 : </div>
            <div className="button" onClick={onClickKGPayment}>
              &nbsp;결제하기
            </div>
          </div>
          <div className="payment">
            <div>다날 : </div>
            <div className="button" onClick={onClickDaNalPayment}>
              &nbsp;결제하기
            </div>
          </div>
          <div className="payment">
            <div>토스 페이먼츠 : </div>
            <div className="button" onClick={onClickTossPayments}>
              &nbsp;결제하기
            </div>
          </div>
          <div className="payment">
            <div>나이스 페이먼츠 : </div>
            <div className="button" onClick={onClickNicePayments}>
              &nbsp;결제하기
            </div>
          </div>
        </div>
        <div className="card">
          <div className="head">간편 결제</div>
          <div className="payment">
            <div>카카오페이 : </div>
            <div className="button" onClick={onClickKakaoPayments}>
              &nbsp;결제하기
            </div>
          </div>
          <div className="payment">
            <div>토스 : </div>
            <div className="button" onClick={onClickToss}>
              &nbsp;결제하기
            </div>
          </div>
          <div className="payment">
            <div>스마일페이 : </div>
            <div className="button" onClick={onClickSmile}>
              &nbsp;결제하기
            </div>
          </div>
        </div>
      </div>
    </StTest>
  );
};

const StTest = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  .payment {
    display: flex;
    justify-content: space-between;
    width: 200px;
    .button {
      width: 70px;
      height: 20px;
      border-radius: 6px;
      background-color: #bdbdbd;
      color: white;
      cursor: pointer;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    .head {
      font-size: 20px;
      font-family: "bold";
    }
  }
`;

export default Test;
