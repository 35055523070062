import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../Cookie";

//style
import styled from "styled-components";

//components
import SignUpContainer from "../components/SignUpContainer";

// img
import greyX from "../img/delete.png";
import arrow from "../img/arrow.svg";

//hooks
import { device } from "../hooks/device";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { bannerOn, languageIs } from "../atoms";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { ModalBackThree } from "../components/Style";
import useScrollShow from "../hooks/useScrollShow";
import { useTranslation } from "react-i18next";

interface SignupProps {
  type: string;
  setSignUpInputModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModalLevel: React.Dispatch<React.SetStateAction<number>>;
  modalLevel: number;
  setEnterpriseSignupRequestComplete: React.Dispatch<
    React.SetStateAction<boolean>
  >;
}

interface SignupStyle {
  completionStatus: boolean;
  banneris: boolean;
  showScroll: boolean;
}

const SignUp: React.FC<SignupProps> = ({
  type,
  setSignUpInputModal,
  setModalLevel,
  modalLevel,
  setEnterpriseSignupRequestComplete,
}) => {
  const { t } = useTranslation();
  const baseLang = "login.e1.1";
  const navigate = useNavigate();
  const [showScroll, setShowScroll] = useState(false);
  const [titleOn, setTitleOn] = useState(true);
  const banneris = useRecoilValue(bannerOn);
  const [completionStatus, setCompletionStatus] = useState<boolean>(false);

  // 모달 필수 함수
  const goBack = () => {
    if (modalLevel === 1) {
      setSignUpInputModal(false);
    } else if (modalLevel === 2) {
      setModalLevel(1);
    }
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, [modalLevel]);

  useEffect(() => {
    if (getCookie("token")) {
      navigate(`/`);
    }
  }, []);

  // useScrollShow({ setShowScroll, scrollTimeout });
  useScrollLockThree();

  return (
    <>
      <ModalBackThree />
      <StSignUp
        completionStatus={completionStatus}
        banneris={banneris}
        showScroll={showScroll}
      >
        <div className="wrapper">
          <StModalHeader>
            <img
              className="mobileButton"
              src={arrow}
              alt=""
              onClick={() => {
                setSignUpInputModal(false);
              }}
            />
            {type === "individual" && titleOn === true ? (
              <div>{t(`login.e1_1.header`)}</div>
            ) : (
              <div>{t(`login.e1_2.header`)}</div>
            )}
            <img
              className="pcButton"
              src={greyX}
              alt=""
              onClick={() => {
                setSignUpInputModal(false);
              }}
            />
          </StModalHeader>
          <div className="inBox" id="scrollableContainer">
            <SignUpContainer
              completionStatus={completionStatus}
              setCompletionStatus={setCompletionStatus}
              type={type}
              setTitleOn={setTitleOn}
              setSignUpInputModal={setSignUpInputModal}
              setModalLevel={setModalLevel}
              modalLevel={modalLevel}
              setEnterpriseSignupRequestComplete={
                setEnterpriseSignupRequestComplete
              }
            />
          </div>
        </div>
      </StSignUp>
    </>
  );
};

const StSignUp = styled.div<SignupStyle>`
  position: fixed;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 56;
  box-sizing: border-box;
  box-shadow: 0px 30px 80px 10px rgba(0, 0, 0, 0.2);
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  @media ${device.pc} {
    width: 580px;
    height: ${(props) => (props.completionStatus ? "" : "calc(100% - 90px)")};
    padding: 60px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .wrapper {
      height: 100%;
    }
    .inBox {
      margin-top: 48px;
      height: calc(100% - 90px);
      overflow-x: hidden;
      padding-right: ${(props) => (props.showScroll ? "" : "5px")};
      ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        margin-top: 100px;
        display: ${(props) => (props.showScroll ? "" : "none")};
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
  }
  @media ${device.tabMob} {
    bottom: 0;
    padding: 0 24px 24px;
    z-index: 49;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
      width: 2.5px;
      height: 10px;
      margin-top: 100px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #c6c6c6;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 1rem;
    }
    .wrapper {
      height: -webkit-fill-available;
    }
  }
`;

const StModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  img {
    cursor: pointer;
  }
  @media ${device.pc} {
    font-size: 28px;
    font-family: "bold";
    line-height: 41px;
    .mobileButton {
      display: none;
    }
    .pcButton {
      width: 24px;
      height: 24px;
      margin-left: auto;
    }
  }
  @media ${device.tabMob} {
    padding: 24px 0;
    box-sizing: border-box;
    font-size: 19px;
    font-family: "bold";
    line-height: 28px;
    .mobileButton {
      width: 18px;
      height: 18px;
      margin-right: 15.6px;
    }
    .pcButton {
      display: none;
    }
  }
  .cancelButton {
    margin-left: auto;
  }
`;

export default SignUp;
