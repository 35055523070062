import { useEffect } from 'react'

const useModal = (close) => {
    
    const goBack = () =>{
        close(false);
    }

    useEffect(()=>{
        window.history.pushState(null,'',window.location.href);
        window.addEventListener('popstate',goBack)
        return ()=>{            
            window.removeEventListener("popstate", goBack);
        }
        // eslint-disable-next-line
    },[])
}

export default useModal