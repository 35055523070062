import React, { Dispatch, SetStateAction } from "react";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import Button from "../../../atomic/atom/Button";
import { useNavigate } from "react-router-dom";
import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import ModalHeader from "../../../atomic/molecule/ModalHeader";
import ConfirmButton from "../../../atomic/atom/ConfirmButton";
import { useTranslation } from "react-i18next";
import useScrollLockThree from "../../../hooks/useScrollLockThree";

interface RefundProps {
  setMyPageScroll: Dispatch<SetStateAction<string>>;
}

const RefundTemp = ({ setMyPageScroll }: RefundProps) => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g5_2_1_1";
  const navigate = useNavigate();

  // Noah
  useScrollLockThree();

  return (
    <ModalOneButtonTemplate>
      <ModalHeader title={t(`${baseLang}.title`)} />
      <DivTwoPartImprove
        display="grid"
        gap={24}
        margin="20px 0 24px 0"
        pc_margin="48px 0"
        pc_font_family="medium"
      >
        <TextLine text={t(`${baseLang}.body1`)} />
        <DivTwoPartImprove lineheight={24} pc_font_family="regular">
          <TextLine text={t(`${baseLang}.body2`)} />
          <TextLine
            text={t(`${baseLang}.customer`)}
            fontcolor="#2276DC"
            textdeco="underline"
            onclick={() => {
              navigate(`/mypage`);
              setMyPageScroll("customerCenter");
            }}
            cursor={true}
          />
          <TextLine text={t(`${baseLang}.body3`)} />
        </DivTwoPartImprove>
      </DivTwoPartImprove>
      <DivTwoPartImprove
        display="grid"
        grid_template_column="147px 1px 147px"
        pc_grid_template_column="204px 204px"
        justify="center"
        align_items="center"
        pc_gap={21}
        margin="0 auto"
      >
        <Button
          text={t(`${baseLang}.button1`)}
          onclick={() => window.history.back()}
          cursor="pointer"
          width="100%"
          pc_width="204px"
          pc_height={52}
          pc_fontsize={17}
          font_size={18}
          font_family="medium"
          border_radius={43}
          font_color="#0072DE"
          pc_fontcolor="black"
          pc_border="1px solid #000"
          border="none"
          backgroundcolor="white"
        />
        <DivTwoPartImprove
          width="1px"
          height={18}
          background_color="#E6E6E6"
          pc_display="none"
          margin="0 auto"
        />
        <Button
          text={t(`${baseLang}.button2`)}
          width="100%"
          onclick={() => {
            navigate(`/mypage`);
            setMyPageScroll("customerCenter");
          }}
          cursor="pointer"
          pc_width="204px"
          pc_height={52}
          pc_fontsize={17}
          font_size={18}
          font_color="#0072DE"
          pc_fontcolor="white"
          font_family="medium"
          border="none"
          border_radius={43}
          backgroundcolor="white"
          pc_backgroundcolor="#20315B"
        />
      </DivTwoPartImprove>
    </ModalOneButtonTemplate>
  );
};

export default RefundTemp;
