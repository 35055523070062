import { UseQueryResult, useQuery } from "react-query";
import getApi from "../../api/getApi";
import { GetTradeDetail } from "../../interface/apiInterface";

export interface tradeDetailRequest {
  tradeId: number;
  type: string;
}

const useGetTradeDetailInd = ({
  tradeId,
  type,
}: tradeDetailRequest): UseQueryResult<GetTradeDetail["detail"]> => {
  const fetcher = async () => {
    const response = await getApi.tradeDetailInd({ tradeId, type });
    return response.data.detail;
  };
  return useQuery(["tradeDetailInd", tradeId, type], fetcher, {
    enabled: Boolean(tradeId && type),
  });
};

export default useGetTradeDetailInd;
