import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReport = ({ testerId, testId, rawDataState }) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getReport({
      utc,
      testerId,
      testId,
      rawDataState,
    });
    return data;
  };
  return useQuery(["report", testId, testerId, rawDataState], fetcher);
};

export default useGetReport;
