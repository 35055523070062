import { useQuery } from "react-query";
import apis from "../api/getApi";
import useToast from "./useToast";

const useGetQnADetail = ({ uid, commentUpdate }) => {
  const showToast = useToast();

  const fetcher = () => {
    const utc = new Date().getTimezoneOffset();
    return new Promise((resolve, reject) => {
      apis
        .getQnADetail({ utc, uid })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          console.log(response);
          if (
            response?.data.errorCode === "HOST-001" ||
            response?.data.errorCode === "QUESTION-001"
          ) {
            showToast({
              message:
                "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
            });
          }
          reject(response);
        });
    });
  };
  return useQuery(["qna", commentUpdate, uid], fetcher);
};

export default useGetQnADetail;
