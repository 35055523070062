import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../Cookie";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { languageIs, userEmail, userInfo } from "../atoms";
import { useTranslation } from "react-i18next";
import useToast from "../hooks/useToast";

interface DropBoxProps {
  close: () => void;
}

const DropBox: React.FC<DropBoxProps> = ({ close }) => {
  const { t } = useTranslation();
  const showToast = useToast();
  const baseLang = "header";
  const navigate = useNavigate();
  const resetUserData = useResetRecoilState(userInfo);
  const resetUserEmailData = useResetRecoilState(userEmail);
  const user = useRecoilValue(userInfo);

  const userData = useRecoilValue(userInfo);

  return (
    <>
      <Back onClick={close} />
      <StDropBox>
        <div className="up">
          <div className="hello">
            <div className="namee">
              <span className="hiyo">{t(`${baseLang}.welcome`)}</span>
              {userData?.hostName}
            </div>
          </div>
          <div className="plan">
            {user?.hostPlan === "individualPlan" && t(`${baseLang}.individual`)}
            {user?.hostPlan === "memberPlan" && t(`${baseLang}.member`)}
            {user?.hostPlan === "managerPlan" && t(`${baseLang}.manager`)}
          </div>
        </div>
        <div className="grey" />
        <div
          className="line"
          onClick={() => {
            navigate(`/myexam`);
            close();
          }}
        >
          {t(`${baseLang}.myReport`)}
        </div>
        {user?.hostPlan === "managerPlan" && (
          <div
            className="line"
            onClick={() => {
              navigate(`/myCredit`);
              close();
            }}
          >
            {t(`${baseLang}.myCredit`)}
          </div>
        )}
        <div
          className="line"
          onClick={() => {
            navigate(`/invited`);
            close();
          }}
        >
          {t(`${baseLang}.inbox`)}
        </div>
        <div
          className="line"
          onClick={() => {
            navigate(`/qna/1`);
            close();
          }}
        >
          {t(`${baseLang}.qna`)}
        </div>
        <div
          className="line"
          onClick={() => {
            navigate(`/notice/1`);
            close();
          }}
        >
          {t(`${baseLang}.notice`)}
        </div>
        <div
          className="line"
          onClick={() => {
            navigate(`/myPage`);
            close();
          }}
        >
          {t(`${baseLang}.myAccount`)}
        </div>
        <div className="grey" />
        <div
          className="line"
          onClick={() => {
            removeCookie("token");
            navigate(`/`);
            close();
            resetUserData();
            resetUserEmailData();
            showToast({ message: t(`errorCode.logout`) });
          }}
        >
          {t(`${baseLang}.logout`)}
        </div>
      </StDropBox>
    </>
  );
};

const Back = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: auto;
`;

const StDropBox = styled.div`
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
  position: absolute;
  top: 30px;
  right: -30px;
  z-index: 20;
  width: 225px;
  min-height: 230px;
  box-sizing: border-box;
  background: #ffffff;
  padding: 14px 0;
  color: black;
  font-family: "Regular";
  .hello {
    display: flex;
  }
  .hiyo {
    color: black;
    font-family: "Regular";
  }
  .namee {
    font-family: "Bold";
  }
  .right {
    margin-left: 75px;
  }
  .line {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
  }
  .grey {
    height: 1px;
    width: 100%;
    margin: 4px 0;
    background-color: #dfdfdf;
    cursor: default;
  }
  .up {
    display: grid;
    flex-direction: column;
    gap: 3px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    padding: 12px;
    font-size: 14px;
    font-family: "medium";
    cursor: default;
  }
`;

export default DropBox;
