import React from "react";
import { Outlet } from "react-router-dom";

// styled components

// components
import ReSpUp from "../components/ReSpUp";

// pages

// modals

const ReportSpecific = ({ setModalOn, reSpLocation }) => {
  return (
    <>
      <ReSpUp setModalOn={setModalOn} reSpLocation={reSpLocation} />
      <Outlet />
    </>
  );
};

export default ReportSpecific;
