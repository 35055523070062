import React, { ReactNode } from "react";
import styled from "styled-components";
import { ModalBackD } from "../../components/Style";
import { device } from "../../hooks/device";

interface ModalProps {
  children?: ReactNode;
  width?: string;
  pc_height?: number;
  gap?: number;
  tab_width?: string;
  tab_gap?: number;
  overflow?: string;
}

const ModalOneButtonTemplate: React.FC<ModalProps> = (props) => {
  return (
    <>
      <ModalBackD modalOff={false} />
      <StModal
        width={props.width}
        pc_height={props.pc_height}
        gap={props.gap}
        tab_width={props.tab_width}
        tab_gap={props.tab_gap}
        overflow={props.overflow}
      >
        {props.children}
      </StModal>
    </>
  );
};

const StModal = styled.div<ModalProps>`
  position: fixed;
  left: 50%;
  z-index: 54;
  display: grid;
  width: ${(props) => (props.width ? props.width : "580px")};
  gap: ${(props) => props.gap}px;
  box-sizing: border-box;
  background-color: white;
  @media ${device.pc} {
    height: ${(props) => props.pc_height}px;
    overflow-x: ${(props) => props.overflow};
    transform: translate(-50%, -50%);
    top: 50%;
    padding: 60px;
  }
  @media ${device.tabMob} {
    transform: translate(-50%, 0%);
    bottom: 16px;
    width: 344px;
    gap: ${(props) => props.tab_gap}px;
    padding: 24px;
    border-radius: 24px;
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
  }
`;

export default ModalOneButtonTemplate;
