import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import NoticeTag from "../components/NoticeTag";
import Pagination from "../components/Pagination";
import arrow from "../img/arrow.svg";
import arrowLeft from "../img/ArrowLeft.png";
import arrowRight from "../img/ArrowRight.png";
import { languageIs } from "../atoms";
import { useRecoilValue } from "recoil";
import { device } from "../hooks/device";
import useGetNoticeList from "../hooks/useGetNoticeList";
import useGetNoticeCount from "../hooks/useGetNoticeCount";
import { useTranslation } from "react-i18next";

const Notice = ({ setMyPageScroll }) => {
  const { t } = useTranslation();
  const baseLang = "customer.h2_2_2";
  const navigate = useNavigate();
  const pageCount = Number(useParams().page);

  const { data: count } = useGetNoticeCount();

  const [showList, setShowList] = useState([]);

  // pagination
  const [pageCounting, setPageCount] = useState(); //
  const [pageShowing, setPageShowing] = useState([]);

  const { data } = useGetNoticeList({ nowPageUniversal: pageCount });

  let list = data?.noticeList;

  // 보여줄 페이지 받아오기
  useEffect(() => {
    setShowList(list);
  }, [list]);

  useEffect(() => {
    setPageCount(Math.ceil(count?.noticeCount / 15));
  }, [count?.noticeCount]);

  // 페이지네이션
  useEffect(() => {
    let temp = [];
    if ((pageCount <= 3) & (pageCounting > 4)) {
      setPageShowing([1, 2, 3, 4, 5]);
    } else if (pageCounting <= 4) {
      for (let i = 1; i <= pageCounting; i++) {
        temp.push(i);
      }
      setPageShowing(temp);
    } else {
      if (pageCounting <= 5) {
        for (let i = 1; i <= pageCounting; i++) {
          temp.push(i);
        }
        setPageShowing(temp);
      } else if (pageCounting > 5) {
        if (pageCount <= pageCounting - 2) {
          for (let i = pageCount - 2; i <= pageCount + 2; i++) {
            temp.push(i);
          }
          setPageShowing(temp);
        } else {
          for (let i = pageCounting - 4; i <= pageCounting; i++) {
            temp.push(i);
          }
          setPageShowing(temp);
        }
      }
    }
  }, [pageCounting, pageCount]);

  return (
    <>
      <StNotice>
        <div className="container">
          <div className="title">
            <img
              className="arrow"
              onClick={() => {
                navigate(`/myPage`);
                setMyPageScroll("customerCenter");
              }}
              src={arrow}
              alt=""
            />
            {t(`${baseLang}.title`)}
          </div>
          <div className="middleTitleBox">
            <img
              className="arrow"
              onClick={() => {
                navigate(`/myPage`);
                setMyPageScroll("customerCenter");
              }}
              src={arrow}
              alt=""
            />
            <div className="middleTitle">{t(`${baseLang}.notice`)}</div>
          </div>
          <div className="inbox">
            <div className="smallTitle">{t(`${baseLang}.notice`)}</div>
            <div className="thickBar" />
            {showList?.length > 0 ? (
              <div className="bottomBox">
                <div className="lastTag">
                  <div className="firstTag">{t(`${baseLang}.num`)}</div>
                  <div className="secondTag">
                    {t(`${baseLang}.noticeTitle`)}
                  </div>
                  <div className="thirdTag">{t(`${baseLang}.date`)}</div>
                </div>
                <div className="thinBar" />
                {showList?.map((value, index) => (
                  <div
                    onClick={() => {
                      navigate(`/noticeDetail/${value.noticeId}`);
                    }}
                    key={index}
                  >
                    <NoticeTag
                      category={value.category}
                      importance={value.isImportant}
                      title={value.title}
                      date={value.createdAt}
                      number={value.noticeId}
                    />
                    <div className="thinGreyBar" />
                  </div>
                ))}
              </div>
            ) : (
              <div className="nothing">{t(`${baseLang}.noPost`)}</div>
            )}
            {pageCounting > 1 && (
              <div className="pagination">
                {pageShowing?.map((value, index) => (
                  <div key={index}>
                    <Pagination
                      type="notice"
                      nowPage={pageCount}
                      page={value}
                    />
                  </div>
                ))}
                {pageCount !== 1 && (
                  <img
                    className="leftArrow"
                    onClick={() => {
                      navigate(`/notice/${pageCount - 1}`);
                    }}
                    src={arrowLeft}
                    alt=""
                  />
                )}
                {pageCount !== pageCounting && (
                  <img
                    className="rightArrow"
                    onClick={() => {
                      navigate(`/notice/${pageCount + 1}`);
                    }}
                    src={arrowRight}
                    alt=""
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </StNotice>
    </>
  );
};

const StNotice = styled.div`
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0;
    margin: 0 auto;
    background-color: #f0f1f2;
    min-height: calc(100% - 353px);
    .middleTitleBox {
      display: none;
    }
    .smallTitle {
      font-family: "medium";
      font-size: 28px;
      padding: 0 0 12px;
    }
    .pagination {
      position: relative;
      display: inline-flex;
      justify-content: space-between;
      margin: 60px auto;
      .rightArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        right: -50px;
        cursor: pointer;
      }
      .leftArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        left: -50px;
        cursor: pointer;
      }
    }
    .container {
      min-width: 1228px;
      margin: 0 auto;
      .inbox {
        width: 1228px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        margin: 60px auto 0;
        padding: 60px 64px;
        box-sizing: border-box;
        background-color: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        .nothing {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 400px;
          color: #bdbdbd;
          line-height: 19px;
          font-size: 16px;
          font-family: "regular";
        }
      }
    }
    .title {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      gap: 19px;
      font-size: 28px;
      font-family: "Bold";
      line-height: 41px;
      .arrow {
        cursor: pointer;
      }
    }
    .answer {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      font-family: "regular";
      width: 51px;
      height: 24px;
      background-color: #444444;
      color: #ffffff;
      border-radius: 4px;
      margin-left: auto;
    }
    .thickBar {
      width: 100%;
      height: 3px;
      background-color: black;
      margin-top: 24px;
    }
    .middleBox {
      display: flex;
      width: 100%;
      height: 56px;
      .ableTime {
        font-size: 16px;
        font-family: "bold";
        margin-right: 24px;
      }
      .date {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 592px;
        height: 56px;
        .weekday {
          font-size: 16px;
          font-family: "regular";
        }
        .weekend {
          font-size: 14px;
          font-family: "regular";
          color: #909090;
        }
      }
      .askButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 39px;
        background-color: #20315b;
        font-size: 13px;
        font-family: "medium";
        margin: auto 0 0 auto;
        color: white;
        border-radius: 20px;
      }
    }
    .bottomBox {
      .lastTag {
        display: flex;
        justify-content: space-between;
        .firstTag {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 52px;
          padding: 14px 24px;
          box-sizing: border-box;
        }
        .secondTagS {
          display: flex;
          /* justify-content: center; */
          align-items: center;
          width: 780px;
          height: 52px;
          padding: 14px 24px;
          box-sizing: border-box;
        }
        .secondTag {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 780px;
          height: 52px;
          padding: 14px 24px;
          box-sizing: border-box;
        }
        .thirdTag {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 52px;
          padding: 14px 24px;
          box-sizing: border-box;
        }
      }
      .thinBar {
        width: 100%;
        height: 1px;
        background-color: black;
      }
      .thinGreyBar {
        width: 100%;
        height: 1px;
        background-color: rgba(217, 217, 217, 0.5);
      }
    }
  }
  @media ${device.tabMob} {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    margin: 0 auto;
    background-color: #f0f1f2;
    min-height: 560px;
    .pagination {
      position: relative;
      display: inline-flex;
      justify-content: space-between;
      margin: 60px auto 48px;
      .rightArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        right: -50px;
        cursor: pointer;
      }
      .leftArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        left: -50px;
        cursor: pointer;
      }
    }
    .bottomBox {
      filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.08));
      border-radius: 24px;
      background-color: white;
      overflow: hidden;
      width: 100%;
      max-width: 731px;
    }
    .nothing {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 400px;
      color: #bdbdbd;
      line-height: 19px;
      font-size: 16px;
      font-family: "regular";
    }
    .container {
      width: 100%;
      max-width: 731px;
    }
    .middleTitleBox {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      gap: 12px;
      margin-top: 8px;
      padding: 16px 24px 24px;
      .middleTitle {
        font-size: 19px;
        font-family: "bold";
        line-height: 28px;
      }
      .arrow {
        width: 18px;
        height: 18px;
      }
    }
    .inbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .title {
      display: none;
    }
    .smallTitle {
      display: none;
    }
    .lastTag {
      display: none;
    }
    /* .thinBar{
                width: 100%;
                height: 1px;
                background-color: black;
            } */
    .thinGreyBar {
      width: 100%;
      height: 1px;
      background-color: rgba(217, 217, 217, 0.5);
    }
  }
`;

export default Notice;
