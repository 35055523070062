import { UseQueryResult, useQuery } from "react-query";
import getApi from "../../api/getApi";
import { GetCreditProps } from "../../interfaces";

const useGetCreditInfo = ({
  creditRefresh,
}: {
  creditRefresh: boolean;
}): UseQueryResult<GetCreditProps, unknown> => {
  const fetcher = async () => {
    const response = await getApi.getCreditInfo();
    return response.data.credit;
  };
  return useQuery(["individualCredit", creditRefresh], fetcher);
};

export default useGetCreditInfo;
