import React from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

const ModalPageBack = () => {
  return <StModalBack></StModalBack>;
};

const StModalBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  @media ${device.pc} {
    z-index: 50;
  }
  @media ${device.tabMob} {
    display: none;
  }
`;

export default ModalPageBack;
