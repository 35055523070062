import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportList = ({ lang, testId }) => {
  const fetcher = async () => {
    const { data } = await apis.getReportList({ uid: testId });
    return data;
  };
  return useQuery("reportList", fetcher);
};

export default useGetReportList;
