import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValueLoadable } from "recoil";
import { modalPadding } from "../atoms";

const ScrollRestoration = () => {
  const { pathname } = useLocation();
  const [page, setPage] = useState("");
  const nowPage = pathname.split("/"); // 현재 페이지
  const previousPage = page.split("/"); // 전 페이지

  // Noah, modal이 켜져있을 때는 스크롤 상단으로 이동X
  const modalOn = useRecoilValueLoadable(modalPadding);

  useEffect(() => {
    // if (previousPage.length > 2) {
    //   if (
    //     nowPage[nowPage.length - 2] === previousPage[previousPage.length - 1] ||
    //     nowPage[nowPage.length - 1] === previousPage[previousPage.length - 1]
    //   ) {
    //     console.log("1");
    //     setPage(pathname);
    //   } else {
    //     console.log("2");
    //     window.scrollTo(0, 0);
    //     setPage(pathname);
    //   }
    // } else {
    //   if (
    //     nowPage[nowPage.length - 2] === previousPage[previousPage.length - 1]
    //   ) {
    //     console.log("3");
    //     setPage(pathname);
    //   } else {
    //     console.log("4");
    //     window.scrollTo(0, 0);
    //     setPage(pathname);
    //   }
    // }
    if(!modalOn.contents) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);
  return null;
};

export default ScrollRestoration;
