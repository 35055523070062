import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import ModalHeader from "../../../atomic/molecule/ModalHeader";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import Button from "../../../atomic/atom/Button";
import useScrollLockThree from "../../../hooks/useScrollLockThree";
import { useTranslation } from "react-i18next";

const CreditChargeRequestConfirm = () => {
  const { t } = useTranslation();
  const baseLang = "examiner.m2_6_1";

  useScrollLockThree();
  return (
    <ModalOneButtonTemplate>
      <ModalHeader title={t(`${baseLang}.title`)} />
      <DivTwoPartImprove
        margin="24px 0"
        pc_margin="48px 0"
        lineheight={24}
        pc_lineheight={27}
        pc_font_size={18}
        pc_font_family="medium"
      >
        <DivTwoPartImprove display="grid">
          <TextLine text={t(`${baseLang}.body1`)} />
          <TextLine text={t(`${baseLang}.body2`)} />
        </DivTwoPartImprove>
        <br />
        <TextLine text={t(`${baseLang}.body3`)} />
      </DivTwoPartImprove>
      <Button
        text="OK"
        height={35}
        border="none"
        font_size={18}
        font_family="medium"
        font_color="#0072DE"
        pc_fontcolor="black"
        pc_border="1px solid #000"
        pc_border_radius={43}
        pc_height={52}
        backgroundcolor="white"
        margin="0 auto"
        onclick={() => {
          window.history.back();
          window.history.back();
        }}
      />
    </ModalOneButtonTemplate>
  );
};

export default CreditChargeRequestConfirm;
