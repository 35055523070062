import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { 
  HoverToolTip, 
  HoverToolTipModal, 
  SelectedToolTip, 
  SelectedToolTipModal, 
  clickInfo, 
  modalPadding 
} from "../atoms";
import styled from "styled-components";
import { device } from "../hooks/device";
import { useTranslation } from "react-i18next";

const Action = ({
  index,
  setClickedEvent,
  clickedEvent,
  text,
  timestamp,
  startTime,
  endTime,
  setPauTime,
  clearTimeOut,
  level,
  setCurTime,
  setStartTime,
  setDuration,
  detectedItem,
  scrollContainerRef,
}) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d3";
  const [clickIndex, setClickIndex] = useRecoilState(clickInfo);
  const clicked = clickIndex?.index;

  const [showLevel, setShowLevel] = useState();
  const [detect, setDetect] = useState("#3C9C9C");

  /** 티켓ID 77 관련 추가 */
  const modalOn = useRecoilValue(modalPadding);

  // 티켓ID 83 관련 추가
  let setHoverToolTip;
  let setSelectedToolTip;
  /** 티켓ID 77 관련 추가 */
  // const setHoverToolTip = useSetRecoilState(HoverToolTip);
  // const setSelectedToolTip = useSetRecoilState(SelectedToolTip);
  if(modalOn) {
    setHoverToolTip = useSetRecoilState(HoverToolTipModal);
    setSelectedToolTip = useSetRecoilState(SelectedToolTipModal);
  } else {
    setHoverToolTip = useSetRecoilState(HoverToolTip);
    setSelectedToolTip = useSetRecoilState(SelectedToolTip);
  }
  const scrollRef = useRef();

  useEffect(() => {
    if (level === "cheat") {
      setDetect("#C83B38");
    } else if (level === "abnormal") {
      setDetect("#FFBC00");
    } else {
      setDetect("#3C9C9C");
    }
  }, [level]);

  useEffect(() => {
    if (level === "cheat") {
      setShowLevel(t(`${baseLang}.urgent`));
    } else if (level === "abnormal") {
      setShowLevel(t(`${baseLang}.recommend`));
    }
  }, [level]);

  useEffect(() => {
    if(clickedEvent === index + 1) {
      const container = scrollContainerRef?.current;
      const target = scrollRef?.current;

      const containerRect = container?.getBoundingClientRect();
      const targetRect = target?.getBoundingClientRect();

      const scrollTop = targetRect.top - containerRect.top + container.scrollTop;

      container.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      })
    }
  }, [clickedEvent])

  return (
    <StBack
      ref={scrollRef}
      detect={detect}
      now={index + 1}
      clicked={clickedEvent}
      // 티켓ID 83 관련 추가
      onMouseEnter={() => {
        setHoverToolTip
        ({
          isHover: true, 
          start: startTime,
          end: endTime,
          level: level,
          index: index
        });
      }}
      // 티켓ID 83 관련 추가
      onMouseLeave={() => {
        setHoverToolTip({isHover: false});
      }}
      onClick={() => {
        setClickIndex({ index });
        setClickedEvent(index + 1);
        setCurTime(startTime);
        setStartTime(startTime);
        setDuration(endTime - startTime);
        clearTimeOut();
        setPauTime((endTime - startTime) * 1000);
        // resumeTime(startTime,(endTime-startTime)*1000)
        // 티켓ID 83 관련 추가
        setSelectedToolTip
        ({
          isSelected: true,
          start: startTime,
          end: endTime,
          level: level,
          index: index
        });
      }}
    >
      <div className="buckett">
        <span className="num">{String(index + 1).padStart(2, "0")}</span>
        <div className="upLine">
          <div className="action">
            {text} ({Math.floor(endTime - startTime)} sec) {detectedItem}
          </div>
          <div className="downLine">
            <div className="evaluate">{showLevel}</div>
            <div className="greyBar" />
            <div className="timess">{timestamp}</div>
          </div>
        </div>
      </div>
    </StBack>
  );
};

const StBack = styled.button`
  background-color: white;
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: ${(props) =>
    props.clicked === props.now ? "8px 7px" : "10px 10px"};
  box-sizing: border-box;
  border-radius: 10px;
  border: ${(props) =>
    props.clicked === props.now ? "2px solid #5C69E0" : "none"};
  line-height: 24px;
  .action {
    font-family: ${(props) =>
      props.clicked === props.now ? "bold" : "medium"};
    color: ${(props) => (props.clicked === props.now ? "#2638D6" : "")};
  }
  .upLine {
    color: #242424;
  }
  .buckett {
    display: flex;
    .num {
      color: ${(props) =>
        props.clicked === props.now ? "#2638D6" : "#818181"};
    }
  }
  .downLine {
    display: flex;
    align-items: center;
    .evaluate {
      color: ${(props) => props.detect};
      font-family: "Regular";
    }
    .greyBar {
      width: 0.5px;
      margin: 0 8px;
      height: 12px;
      background-color: #c4c4c4;
    }
    .timess {
      color: #818181;
    }
  }
  @media ${device.pc} {
    height: 77px;
    font-size: 16px;
    .buckett {
      display: grid;
      grid-template-columns: auto auto;
      gap: 16px;
      .num {
        font-family: ${(props) =>
          props.clicked === props.now ? "regular" : "light"};
      }
    }
    .upLine {
      display: grid;
      flex-direction: column;
      gap: 5px;
    }
  }
  @media ${device.tabMob} {
    min-height: 61px;
    .buckett {
      .num {
        font-size: 13px;
        margin-right: 8px;
        font-family: ${(props) =>
          props.clicked === props.now ? "Bold" : "Regular"};
      }
      .upLine {
        font-size: 13px;
      }
      .downLine {
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }
`;

export default Action;
