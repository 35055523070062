import { createGlobalStyle } from 'styled-components';
import './font.css';

const GlobalStyle = createGlobalStyle`
    :root{
        --MEDIUM: 'Medium';
        --BOLD: 'Bold';
        --REGULAR: 'Regular';
        --DEMILIGHT: 'DemiLight';
    }
    html{
        -ms-touch-action: manipulation;
        touch-action: manipulation;
    }
    body{
        font-family: 'Regular';
        font-size: 16px;
        -webkit-overflow-scrolling : touch !important;
        
    }
    input{
        -webkit-border-radius: 0;
    }
`;


export default GlobalStyle;
