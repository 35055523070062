import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetMemberCreditUseList = ({ uid, type, lang }) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getMemberCreditUseList({
      uid,
      utc,
      type,
      lang,
    });
    return data;
  };
  return useQuery(["memberUse", uid, type, lang], fetcher);
};

export default useGetMemberCreditUseList;
