export const korCode = [
    {
        "country": "가나",
        "iso": "GH",
        "number": "(+233)"
    },
    {
        "country": "가봉",
        "iso": "GA",
        "number": "(+241)"
    },
    {
        "country": "가이아나",
        "iso": "GY",
        "number": "(+592)"
    },
    {
        "country": "감비아",
        "iso": "GM",
        "number": "(+220)"
    },
    {
        "country": "건지",
        "iso": "GG",
        "number": "(+44)"
    },
    {
        "country": "과들루프",
        "iso": "GP",
        "number": "(+590)"
    },
    {
        "country": "과테말라",
        "iso": "GT",
        "number": "(+502)"
    },
    {
        "country": "괌",
        "iso": "GU",
        "number": "(+1)"
    },
    {
        "country": "그레나다",
        "iso": "GD",
        "number": "(+1)"
    },
    {
        "country": "그루지야",
        "iso": "GE",
        "number": "(+995)"
    },
    {
        "country": "그리스",
        "iso": "GR",
        "number": "(+30)"
    },
    {
        "country": "그린란드",
        "iso": "GL",
        "number": "(+299)"
    },
    {
        "country": "기니",
        "iso": "GQ",
        "number": "(+240)"
    },
    {
        "country": "기니",
        "iso": "GN",
        "number": "(+224)"
    },
    {
        "country": "기니비사우",
        "iso": "GW",
        "number": "(+245)"
    },
    {
        "country": "나미비아",
        "iso": "NA",
        "number": "(+264)"
    },
    {
        "country": "나이지리아",
        "iso": "NG",
        "number": "(+234)"
    },
    {
        "country": "남수단",
        "iso": "SS",
        "number": "(+211)"
    },
    {
        "country": "남아프리카",
        "iso": "ZA",
        "number": "(+27)"
    },
    {
        "country": "네덜란드",
        "iso": "NL",
        "number": "(+31)"
    },
    {
        "country": "네팔",
        "iso": "NP",
        "number": "(+977)"
    },
    {
        "country": "노르웨이",
        "iso": "NO",
        "number": "(+47)"
    },
    {
        "country": "뉴질랜드",
        "iso": "NZ",
        "number": "(+64)"
    },
    {
        "country": "뉴칼레도니아",
        "iso": "NC",
        "number": "(+687)"
    },
    {
        "country": "니우에",
        "iso": "NU",
        "number": "(+683)"
    },
    {
        "country": "니제르",
        "iso": "NE",
        "number": "(+227)"
    },
    {
        "country": "니카라과",
        "iso": "NI",
        "number": "(+505)"
    },
    {
        "country": "대만",
        "iso": "TW",
        "number": "(+886)"
    },
    {
        "country": "대한민국",
        "iso": "KR",
        "number": "(+82)"
    },
    {
        "country": "덴마크",
        "iso": "DK",
        "number": "(+45)"
    },
    {
        "country": "도미니카 공화국",
        "iso": "DO",
        "number": "(+1)"
    },
    {
        "country": "독일",
        "iso": "DE",
        "number": "(+49)"
    },
    {
        "country": "동티모르",
        "iso": "TL",
        "number": "(+670)"
    },
    {
        "country": "라오스",
        "iso": "LA",
        "number": "(+856)"
    },
    {
        "country": "라이베리아",
        "iso": "LR",
        "number": "(+231)"
    },
    {
        "country": "라트비아",
        "iso": "LV",
        "number": "(+371)"
    },
    {
        "country": "러시아 연방",
        "iso": "RU",
        "number": "(+7)"
    },
    {
        "country": "레바논",
        "iso": "LB",
        "number": "(+961)"
    },
    {
        "country": "레소토",
        "iso": "LS",
        "number": "(+266)"
    },
    {
        "country": "레위니옹",
        "iso": "RE",
        "number": "(+262)"
    },
    {
        "country": "루마니아",
        "iso": "RO",
        "number": "(+40)"
    },
    {
        "country": "룩셈부르크",
        "iso": "LU",
        "number": "(+352)"
    },
    {
        "country": "르완다",
        "iso": "RW",
        "number": "(+250)"
    },
    {
        "country": "리비아국",
        "iso": "LY",
        "number": "(+218)"
    },
    {
        "country": "리투아니아",
        "iso": "LT",
        "number": "(+370)"
    },
    {
        "country": "리히텐슈타인",
        "iso": "LI",
        "number": "(+423)"
    },
    {
        "country": "마다가스카르",
        "iso": "MG",
        "number": "(+261)"
    },
    {
        "country": "마르티니크",
        "iso": "MQ",
        "number": "(+596)"
    },
    {
        "country": "마셜 제도",
        "iso": "MH",
        "number": "(+692)"
    },
    {
        "country": "마요트",
        "iso": "YT",
        "number": "(+262)"
    },
    {
        "country": "마카오",
        "iso": "MO",
        "number": "(+853)"
    },
    {
        "country": "마케도니아",
        "iso": "MK",
        "number": "(+389)"
    },
    {
        "country": "말라위",
        "iso": "MW",
        "number": "(+265)"
    },
    {
        "country": "말레이시아",
        "iso": "MY",
        "number": "(+60)"
    },
    {
        "country": "말리",
        "iso": "ML",
        "number": "(+223)"
    },
    {
        "country": "맨 섬",
        "iso": "IM",
        "number": "(+44)"
    },
    {
        "country": "멕시코",
        "iso": "MX",
        "number": "(+52)"
    },
    {
        "country": "모나코",
        "iso": "MC",
        "number": "(+377)"
    },
    {
        "country": "모로코",
        "iso": "MA",
        "number": "(+212)"
    },
    {
        "country": "모리셔스",
        "iso": "MU",
        "number": "(+230)"
    },
    {
        "country": "모리타니아",
        "iso": "MR",
        "number": "(+222)"
    },
    {
        "country": "모잠비크",
        "iso": "MS",
        "number": "(+258)"
    },
    {
        "country": "몬테네그로",
        "iso": "ME",
        "number": "(+382)"
    },
    {
        "country": "몬트세라트",
        "iso": "MS",
        "number": "(+1)"
    },
    {
        "country": "몰도바",
        "iso": "MD",
        "number": "(+373)"
    },
    {
        "country": "몰디브",
        "iso": "MV",
        "number": "(+960)"
    },
    {
        "country": "몰타",
        "iso": "MT",
        "number": "(+356)"
    },
    {
        "country": "몽골리아",
        "iso": "MN",
        "number": "(+976)"
    },
    {
        "country": "미국",
        "iso": "US",
        "number": "(+1)"
    },
    {
        "country": "미국령 버진 제도",
        "iso": "VI",
        "number": "(+1340)"
    },
    {
        "country": "미얀마",
        "iso": "MM",
        "number": "(+95)"
    },
    {
        "country": "미크로네시아",
        "iso": "FM",
        "number": "(+691)"
    },
    {
        "country": "바누아투",
        "iso": "VU",
        "number": "(+678)"
    },
    {
        "country": "바레인",
        "iso": "BH",
        "number": "(+973)"
    },
    {
        "country": "바베이도스",
        "iso": "BB",
        "number": "(+1)"
    },
    {
        "country": "바하마",
        "iso": "",
        "number": "(+1)"
    },
    {
        "country": "방글라데시",
        "iso": "BD",
        "number": "(+880)"
    },
    {
        "country": "버뮤다",
        "iso": "BM",
        "number": "(+1)"
    },
    {
        "country": "베네수엘라",
        "iso": "VE",
        "number": "(+58)"
    },
    {
        "country": "베트남",
        "iso": "VN",
        "number": "(+84)"
    },
    {
        "country": "벨기에",
        "iso": "BE",
        "number": "(+32)"
    },
    {
        "country": "벨라루스",
        "iso": "BY",
        "number": "(+375)"
    },
    {
        "country": "벨리즈",
        "iso": "BZ",
        "number": "(+501)"
    },
    {
        "country": "보스니아 헤르체고비나",
        "iso": "BA",
        "number": "(+387)"
    },
    {
        "country": "보츠와나",
        "iso": "BR",
        "number": "(+55)"
    },
    {
        "country": "볼리비아",
        "iso": "BO",
        "number": "(+591)"
    },
    {
        "country": "부룬디",
        "iso": "BL",
        "number": "(+257)"
    },
    {
        "country": "부르키나파소",
        "iso": "BF",
        "number": "(+226)"
    },
    {
        "country": "부탄",
        "iso": "BT",
        "number": "(+975)"
    },
    {
        "country": "불가리아",
        "iso": "BG",
        "number": "(+359)"
    },
    {
        "country": "브라질",
        "iso": "BR",
        "number": "(+55)"
    },
    {
        "country": "브루나이",
        "iso": "BN",
        "number": "(+673)"
    },
    {
        "country": "사모아",
        "iso": "WS",
        "number": "(+685)"
    },
    {
        "country": "사우디 아라비아",
        "iso": "SA",
        "number": "(+966)"
    },
    {
        "country": "산마리노",
        "iso": "SM",
        "number": "(+378)"
    },
    {
        "country": "상투메 프린시페",
        "iso": "ST",
        "number": "(+239)"
    },
    {
        "country": "세네갈",
        "iso": "SN",
        "number": "(+221)"
    },
    {
        "country": "세르비아",
        "iso": "RS",
        "number": "(+381)"
    },
    {
        "country": "세이셸",
        "iso": "SC",
        "number": "(+248)"
    },
    {
        "country": "세인트 루시아",
        "iso": "LC",
        "number": "(+1)"
    },
    {
        "country": "세인트 키츠 네비스",
        "iso": "KN",
        "number": "(+1)"
    },
    {
        "country": "소말리아",
        "iso": "252",
        "number": "(+27)"
    },
    {
        "country": "솔로몬 제도",
        "iso": "SB",
        "number": "(+677)"
    },
    {
        "country": "수리남",
        "iso": "SR",
        "number": "(+597)"
    },
    {
        "country": "스리랑카",
        "iso": "LK",
        "number": "(+94)"
    },
    {
        "country": "스와질란드",
        "iso": "SZ",
        "number": "(+268)"
    },
    {
        "country": "스웨덴",
        "iso": "SE",
        "number": "(+46)"
    },
    {
        "country": "스위스",
        "iso": "CH",
        "number": "(+41)"
    },
    {
        "country": "스페인",
        "iso": "ES",
        "number": "(+34)"
    },
    {
        "country": "슬로바키아",
        "iso": "SK",
        "number": "(+421)"
    },
    {
        "country": "슬로베니아",
        "iso": "SI",
        "number": "(+386)"
    },
    {
        "country": "시에라리온",
        "iso": "SL",
        "number": "(+232)"
    },
    {
        "country": "싱가포르",
        "iso": "SG",
        "number": "(+65)"
    },
    {
        "country": "아랍 에미리트",
        "iso": "AE",
        "number": "(+971)"
    },
    {
        "country": "아루바",
        "iso": "AW",
        "number": "(+297)"
    },
    {
        "country": "아르메니아",
        "iso": "AM",
        "number": "(+374)"
    },
    {
        "country": "아르헨티나",
        "iso": "AR",
        "number": "(+54)"
    },
    {
        "country": "아이버리 코스트",
        "iso": "CL",
        "number": "(+225)"
    },
    {
        "country": "아이슬란드",
        "iso": "IS",
        "number": "(+354)"
    },
    {
        "country": "아이티",
        "iso": "H",
        "number": "(+509)"
    },
    {
        "country": "아일랜드",
        "iso": "IE",
        "number": "(+33)"
    },
    {
        "country": "아제르바이잔",
        "iso": "AZ",
        "number": "(+994)"
    },
    {
        "country": "아프가니스탄",
        "iso": "AF",
        "number": "(+93)"
    },
    {
        "country": "안도라",
        "iso": "AD",
        "number": "(+376)"
    },
    {
        "country": "안틸 제도",
        "iso": "AN",
        "number": "(+599)"
    },
    {
        "country": "알바니아",
        "iso": "AL",
        "number": "(+365)"
    },
    {
        "country": "알제리",
        "iso": "DZ",
        "number": "(+213)"
    },
    {
        "country": "앙골라",
        "iso": "AI",
        "number": "(+1)"
    },
    {
        "country": "에리트레아",
        "iso": "ER",
        "number": "(+291)"
    },
    {
        "country": "에콰도르",
        "iso": "EC",
        "number": "(+593)"
    },
    {
        "country": "에티오피아",
        "iso": "ET",
        "number": "(+251)"
    },
    {
        "country": "엘살바도르",
        "iso": "SV",
        "number": "(+503)"
    },
    {
        "country": "영국",
        "iso": "GB",
        "number": "(+44)"
    },
    {
        "country": "영국령 버진 제도",
        "iso": "VG",
        "number": "(+1284)"
    },
    {
        "country": "예멘",
        "iso": "YE",
        "number": "(+967)"
    },
    {
        "country": "오만",
        "iso": "OM",
        "number": "(+968)"
    },
    {
        "country": "오스트리아",
        "iso": "AT",
        "number": "(+43)"
    },
    {
        "country": "온두라스",
        "iso": "HN",
        "number": "(+504)"
    },
    {
        "country": "요르단",
        "iso": "JO",
        "number": "(+962)"
    },
    {
        "country": "우간다",
        "iso": "UG",
        "number": "(+256)"
    },
    {
        "country": "우루과이",
        "iso": "UY",
        "number": "(+598)"
    },
    {
        "country": "우즈베키스탄",
        "iso": "UZ",
        "number": "(+998)"
    },
    {
        "country": "우크라이나",
        "iso": "UA",
        "number": "(+380)"
    },
    {
        "country": "이라크",
        "iso": "IQ",
        "number": "(+964)"
    },
    {
        "country": "이스라엘",
        "iso": "IL",
        "number": "(+972)"
    },
    {
        "country": "이집트",
        "iso": "EG",
        "number": "(+20)"
    },
    {
        "country": "이탈리아",
        "iso": "IT",
        "number": "(+39)"
    },
    {
        "country": "인도",
        "iso": "IN",
        "number": "(+91)"
    },
    {
        "country": "인도네시아",
        "iso": "ID",
        "number": "(+62)"
    },
    {
        "country": "일본",
        "iso": "JP",
        "number": "(+81)"
    },
    {
        "country": "자메이카",
        "iso": "JM",
        "number": "(+1)"
    },
    {
        "country": "잠비아",
        "iso": "ZM",
        "number": "(+260)"
    },
    {
        "country": "저지",
        "iso": "JE",
        "number": "(+44)"
    },
    {
        "country": "중국",
        "iso": "CN",
        "number": "(+86)"
    },
    {
        "country": "중앙 아프리카 공화국",
        "iso": "CF",
        "number": "(+236)"
    },
    {
        "country": "지부티",
        "iso": "DJ",
        "number": "(+253)"
    },
    {
        "country": "지브롤터",
        "iso": "GL",
        "number": "(+350)"
    },
    {
        "country": "짐바브웨",
        "iso": "ZW",
        "number": "(+263)"
    },
    {
        "country": "차드",
        "iso": "TD",
        "number": "(+235)"
    },
    {
        "country": "체코 공화국",
        "iso": "CZ",
        "number": "(+420)"
    },
    {
        "country": "칠레",
        "iso": "CL",
        "number": "(+56)"
    },
    {
        "country": "카메룬",
        "iso": "CM",
        "number": "(+237)"
    },
    {
        "country": "카보베르데",
        "iso": "CV",
        "number": "(+238)"
    },
    {
        "country": "카자흐스탄",
        "iso": "KZ",
        "number": "(+7)"
    },
    {
        "country": "카타르",
        "iso": "QA",
        "number": "(+974)"
    },
    {
        "country": "캄보디아",
        "iso": "KH",
        "number": "(+855)"
    },
    {
        "country": "캐나다",
        "iso": "CA",
        "number": "(+1)"
    },
    {
        "country": "케냐",
        "iso": "KE",
        "number": "(+254)"
    },
    {
        "country": "케이맨 제도",
        "iso": "KY",
        "number": "(+1)"
    },
    {
        "country": "코모로",
        "iso": "KM",
        "number": "(+269)"
    },
    {
        "country": "코소보",
        "iso": "XK",
        "number": "(+383)"
    },
    {
        "country": "코스타리카",
        "iso": "CR",
        "number": "(+506)"
    },
    {
        "country": "콜롬비아",
        "iso": "CO",
        "number": "(+57)"
    },
    {
        "country": "콩고",
        "iso": "CG",
        "number": "(+242)"
    },
    {
        "country": "콩고 민주 공화국",
        "iso": "CD",
        "number": "(+243)"
    },
    {
        "country": "쿠웨이트",
        "iso": "KW",
        "number": "(+965)"
    },
    {
        "country": "쿡 제도",
        "iso": "CK",
        "number": "(+682)"
    },
    {
        "country": "크로아티아",
        "iso": "HR",
        "number": "(+385)"
    },
    {
        "country": "키르기스스탄",
        "iso": "KG",
        "number": "(+996)"
    },
    {
        "country": "키프로스",
        "iso": "CY",
        "number": "(+357)"
    },
    {
        "country": "타지키스탄",
        "iso": "TJ",
        "number": "(+992)"
    },
    {
        "country": "탄자니아 연합 공화국",
        "iso": "TX",
        "number": "(+255)"
    },
    {
        "country": "태국",
        "iso": "TH",
        "number": "(+66)"
    },
    {
        "country": "터크스 케이커스 제도",
        "iso": "TC",
        "number": "(+1)"
    },
    {
        "country": "터키",
        "iso": "TR",
        "number": "(+90)"
    },
    {
        "country": "토고",
        "iso": "TG",
        "number": "(+228)"
    },
    {
        "country": "통가",
        "iso": "TO",
        "number": "(+676)"
    },
    {
        "country": "투르크 메니스탄",
        "iso": "TM",
        "number": "(+993)"
    },
    {
        "country": "투발루",
        "iso": "TC",
        "number": "(+688)"
    },
    {
        "country": "튀니지",
        "iso": "TN",
        "number": "(+216)"
    },
    {
        "country": "트리니다드 토바고",
        "iso": "TT",
        "number": "(+1)"
    },
    {
        "country": "파나마",
        "iso": "PA",
        "number": "(+507)"
    },
    {
        "country": "파라과이",
        "iso": "PY",
        "number": "(+595)"
    },
    {
        "country": "파키스탄",
        "iso": "PK",
        "number": "(+92)"
    },
    {
        "country": "파푸아뉴기니",
        "iso": "PG",
        "number": "(+675)"
    },
    {
        "country": "팔레스타인",
        "iso": "PS",
        "number": "(+970)"
    },
    {
        "country": "페로 제도",
        "iso": "FO",
        "number": "(+298)"
    },
    {
        "country": "페루",
        "iso": "PE",
        "number": "(+51)"
    },
    {
        "country": "포르투갈",
        "iso": "PT",
        "number": "(+351)"
    },
    {
        "country": "폴란드",
        "iso": "PL",
        "number": "(+48)"
    },
    {
        "country": "푸에르토 리코",
        "iso": "PR",
        "number": "(+1)"
    },
    {
        "country": "프랑스",
        "iso": "FR",
        "number": "(+33)"
    },
    {
        "country": "프랑스령 기아나",
        "iso": "GF",
        "number": "(+594)"
    },
    {
        "country": "프랑스령 폴리네시아",
        "iso": "PF",
        "number": "(+689)"
    },
    {
        "country": "피지",
        "iso": "FJ",
        "number": "(+679)"
    },
    {
        "country": "핀란드",
        "iso": "FI",
        "number": "(+358)"
    },
    {
        "country": "필리핀",
        "iso": "PH",
        "number": "(+63)"
    },
    {
        "country": "헝가리",
        "iso": "HU",
        "number": "(+36)"
    },
    {
        "country": "호주",
        "iso": "AU ",
        "number": "(+61)"
    },
    {
        "country": "홍콩",
        "iso": "HK",
        "number": "(+852)"
    }
]

export const engCode = [
    {
        "country": "Afghanistan",
        "iso": "AF",
        "number": "(+93)"
    },
    {
        "country": "Albania",
        "iso": "AL",
        "number": "(+365)"
    },
    {
        "country": "Algeria",
        "iso": "DZ",
        "number": "(+213)"
    },
    {
        "country": "Andorra",
        "iso": "AD",
        "number": "(+376)"
    },
    {
        "country": "Argentina",
        "iso": "AR",
        "number": "(+54)"
    },
    {
        "country": "Armenia",
        "iso": "AM",
        "number": "(+374)"
    },
    {
        "country": "Aruba",
        "iso": "AW",
        "number": "(+297)"
    },
    {
        "country": "Angola",
        "iso": "AI",
        "number": "(+1)"
    },
    {
        "country": "Australia",
        "iso": "AU ",
        "number": "(+61)"
    },
    {
        "country": "Austria",
        "iso": "AT",
        "number": "(+43)"
    },
    {
        "country": "Azerbaijan",
        "iso": "AZ",
        "number": "(+994)"
    },
    {
        "country": "Bahamas",
        "iso": "",
        "number": "(+1)"
    },
    {
        "country": "Bahrain",
        "iso": "BH",
        "number": "(+973)"
    },
    {
        "country": "Bangladesh",
        "iso": "BD",
        "number": "(+880)"
    },
    {
        "country": "Barbados",
        "iso": "BB",
        "number": "(+1)"
    },
    {
        "country": "Belarus",
        "iso": "BY",
        "number": "(+375)"
    },
    {
        "country": "Belgium",
        "iso": "BE",
        "number": "(+32)"
    },
    {
        "country": "Belize",
        "iso": "BZ",
        "number": "(+501)"
    },
    {
        "country": "Bermuda",
        "iso": "BM",
        "number": "(+1)"
    },
    {
        "country": "Bhutan",
        "iso": "BT",
        "number": "(+975)"
    },
    {
        "country": "Bolivia",
        "iso": "BO",
        "number": "(+591)"
    },
    {
        "country": "Bosnia and Herzegovina",
        "iso": "BA",
        "number": "(+387)"
    },
    {
        "country": "Botswana",
        "iso": "BR",
        "number": "(+55)"
    },
    {
        "country": "Brazil",
        "iso": "BR",
        "number": "(+55)"
    },
    {
        "country": "Brunei",
        "iso": "BN",
        "number": "(+673)"
    },
    {
        "country": "Bulgaria",
        "iso": "BG",
        "number": "(+359)"
    },
    {
        "country": "Burkina Faso",
        "iso": "BF",
        "number": "(+226)"
    },
    {
        "country": "Burundi",
        "iso": "BL",
        "number": "(+257)"
    },
    {
        "country": "Cambodia",
        "iso": "KH",
        "number": "(+855)"
    },
    {
        "country": "Cameroon",
        "iso": "CM",
        "number": "(+237)"
    },
    {
        "country": "Canada",
        "iso": "CA",
        "number": "(+1)"
    },
    {
        "country": "Cabo Verde",
        "iso": "CV",
        "number": "(+238)"
    },
    {
        "country": "Cayman Islands",
        "iso": "KY",
        "number": "(+1)"
    },
    {
        "country": "Central African Republic",
        "iso": "CF",
        "number": "(+236)"
    },
    {
        "country": "Chad",
        "iso": "TD",
        "number": "(+235)"
    },
    {
        "country": "Chile",
        "iso": "CL",
        "number": "(+56)"
    },
    {
        "country": "People's Republic of China",
        "iso": "CN",
        "number": "(+86)"
    },
    {
        "country": "Colombia",
        "iso": "CO",
        "number": "(+57)"
    },
    {
        "country": "Comoros",
        "iso": "KM",
        "number": "(+269)"
    },
    {
        "country": "Cook Islands",
        "iso": "CK",
        "number": "(+682)"
    },
    {
        "country": "Costa Rica",
        "iso": "CR",
        "number": "(+506)"
    },
    {
        "country": "Croatia",
        "iso": "HR",
        "number": "(+385)"
    },
    {
        "country": "Cyprus",
        "iso": "CY",
        "number": "(+357)"
    },
    {
        "country": "Czech Republic",
        "iso": "CZ",
        "number": "(+420)"
    },
    {
        "country": "Democratic Republic of the Congo",
        "iso": "CD",
        "number": "(+243)"
    },
    {
        "country": "Denmark",
        "iso": "DK",
        "number": "(+45)"
    },
    {
        "country": "Djibouti",
        "iso": "DJ",
        "number": "(+253)"
    },
    {
        "country": "Dominican Republic",
        "iso": "DO",
        "number": "(+1)"
    },
    {
        "country": "Ecuador",
        "iso": "EC",
        "number": "(+593)"
    },
    {
        "country": "Egypt",
        "iso": "EG",
        "number": "(+20)"
    },
    {
        "country": "El Salvador",
        "iso": "SV",
        "number": "(+503)"
    },
    {
        "country": "Guinea",
        "iso": "GQ",
        "number": "(+240)"
    },
    {
        "country": "Eritrea",
        "iso": "ER",
        "number": "(+291)"
    },
    {
        "country": "Ethiopia",
        "iso": "ET",
        "number": "(+251)"
    },
    {
        "country": "Faroe Islands",
        "iso": "FO",
        "number": "(+298)"
    },
    {
        "country": "Fiji",
        "iso": "FJ",
        "number": "(+679)"
    },
    {
        "country": "Finland",
        "iso": "FI",
        "number": "(+358)"
    },
    {
        "country": "France",
        "iso": "FR",
        "number": "(+33)"
    },
    {
        "country": "Guyane",
        "iso": "GF",
        "number": "(+594)"
    },
    {
        "country": "polynesia",
        "iso": "PF",
        "number": "(+689)"
    },
    {
        "country": "Gabon",
        "iso": "GA",
        "number": "(+241)"
    },
    {
        "country": "Republic of The Gambia",
        "iso": "GM",
        "number": "(+220)"
    },
    {
        "country": "Georgia",
        "iso": "GE",
        "number": "(+995)"
    },
    {
        "country": "Germany",
        "iso": "DE",
        "number": "(+49)"
    },
    {
        "country": "Ghana",
        "iso": "GH",
        "number": "(+233)"
    },
    {
        "country": "Gibraltar",
        "iso": "GL",
        "number": "(+350)"
    },
    {
        "country": "Greece",
        "iso": "GR",
        "number": "(+30)"
    },
    {
        "country": "Greenland",
        "iso": "GL",
        "number": "(+299)"
    },
    {
        "country": "Grenada",
        "iso": "GD",
        "number": "(+1)"
    },
    {
        "country": "Guadeloupe",
        "iso": "GP",
        "number": "(+590)"
    },
    {
        "country": "Guam",
        "iso": "GU",
        "number": "(+1)"
    },
    {
        "country": "Guatemala",
        "iso": "GT",
        "number": "(+502)"
    },
    {
        "country": "Guernsey",
        "iso": "GG",
        "number": "(+44)"
    },
    {
        "country": "Guinea",
        "iso": "GN",
        "number": "(+224)"
    },
    {
        "country": "Guinea-Bissau",
        "iso": "GW",
        "number": "(+245)"
    },
    {
        "country": "Guyana",
        "iso": "GY",
        "number": "(+592)"
    },
    {
        "country": "Haiti",
        "iso": "H",
        "number": "(+509)"
    },
    {
        "country": "Honduras",
        "iso": "HN",
        "number": "(+504)"
    },
    {
        "country": "Hong Kong",
        "iso": "HK",
        "number": "(+852)"
    },
    {
        "country": "Hungary",
        "iso": "HU",
        "number": "(+36)"
    },
    {
        "country": "Iceland",
        "iso": "IS",
        "number": "(+354)"
    },
    {
        "country": "India",
        "iso": "IN",
        "number": "(+91)"
    },
    {
        "country": "Indonesia",
        "iso": "ID",
        "number": "(+62)"
    },
    {
        "country": "Iraq",
        "iso": "IQ",
        "number": "(+964)"
    },
    {
        "country": "Ireland",
        "iso": "IE",
        "number": "(+33)"
    },
    {
        "country": "Isle of Man",
        "iso": "IM",
        "number": "(+44)"
    },
    {
        "country": "Israel",
        "iso": "IL",
        "number": "(+972)"
    },
    {
        "country": "Ivory Coast",
        "iso": "CL",
        "number": "(+225)"
    },
    {
        "country": "Italy",
        "iso": "IT",
        "number": "(+39)"
    },
    {
        "country": "Jamaica",
        "iso": "JM",
        "number": "(+1)"
    },
    {
        "country": "Japan",
        "iso": "JP",
        "number": "(+81)"
    },
    {
        "country": "Jersey",
        "iso": "JE",
        "number": "(+44)"
    },
    {
        "country": "Jordan",
        "iso": "JO",
        "number": "(+962)"
    },
    {
        "country": "Kazakhstan",
        "iso": "KZ",
        "number": "(+7)"
    },
    {
        "country": "Kenya",
        "iso": "KE",
        "number": "(+254)"
    },
    {
        "country": "Kosovo",
        "iso": "XK",
        "number": "(+383)"
    },
    {
        "country": "Kuwait",
        "iso": "KW",
        "number": "(+965)"
    },
    {
        "country": "Kyrgyzstan",
        "iso": "KG",
        "number": "(+996)"
    },
    {
        "country": "Lao People's Democratic Republic",
        "iso": "LA",
        "number": "(+856)"
    },
    {
        "country": "Latvia",
        "iso": "LV",
        "number": "(+371)"
    },
    {
        "country": "Lebanon",
        "iso": "LB",
        "number": "(+961)"
    },
    {
        "country": "Lesotho",
        "iso": "LS",
        "number": "(+266)"
    },
    {
        "country": "Liberia",
        "iso": "LR",
        "number": "(+231)"
    },
    {
        "country": "Libya",
        "iso": "LY",
        "number": "(+218)"
    },
    {
        "country": "Liechtenstein",
        "iso": "LI",
        "number": "(+423)"
    },
    {
        "country": "Lithuania",
        "iso": "LT",
        "number": "(+370)"
    },
    {
        "country": "Luxembourg",
        "iso": "LU",
        "number": "(+352)"
    },
    {
        "country": "Macao",
        "iso": "MO",
        "number": "(+853)"
    },
    {
        "country": "Macedonia",
        "iso": "MK",
        "number": "(+389)"
    },
    {
        "country": "Madagascar",
        "iso": "MG",
        "number": "(+261)"
    },
    {
        "country": "Malawi",
        "iso": "MW",
        "number": "(+265)"
    },
    {
        "country": "Malaysia",
        "iso": "MY",
        "number": "(+60)"
    },
    {
        "country": "Maldives",
        "iso": "MV",
        "number": "(+960)"
    },
    {
        "country": "Mali",
        "iso": "ML",
        "number": "(+223)"
    },
    {
        "country": "Malta",
        "iso": "MT",
        "number": "(+356)"
    },
    {
        "country": "Marshall Islands",
        "iso": "MH",
        "number": "(+692)"
    },
    {
        "country": "Martinique",
        "iso": "MQ",
        "number": "(+596)"
    },
    {
        "country": "Mauritania",
        "iso": "MR",
        "number": "(+222)"
    },
    {
        "country": "Mauritius",
        "iso": "MU",
        "number": "(+230)"
    },
    {
        "country": "Mayotte",
        "iso": "YT",
        "number": "(+262)"
    },
    {
        "country": "Mexico",
        "iso": "MX",
        "number": "(+52)"
    },
    {
        "country": "Micronesia",
        "iso": "FM",
        "number": "(+691)"
    },
    {
        "country": "Moldove",
        "iso": "MD",
        "number": "(+373)"
    },
    {
        "country": "Monaco",
        "iso": "MC",
        "number": "(+377)"
    },
    {
        "country": "Mongolia",
        "iso": "MN",
        "number": "(+976)"
    },
    {
        "country": "Montenegro",
        "iso": "ME",
        "number": "(+382)"
    },
    {
        "country": "Montserrat",
        "iso": "MS",
        "number": "(+1)"
    },
    {
        "country": "Morocco",
        "iso": "MA",
        "number": "(+212)"
    },
    {
        "country": "Mozambique",
        "iso": "MS",
        "number": "(+258)"
    },
    {
        "country": "Myanmar",
        "iso": "MM",
        "number": "(+95)"
    },
    {
        "country": "Namibia",
        "iso": "NA",
        "number": "(+264)"
    },
    {
        "country": "Nepal",
        "iso": "NP",
        "number": "(+977)"
    },
    {
        "country": "Netherlands",
        "iso": "NL",
        "number": "(+31)"
    },
    {
        "country": "Nederlandse Antillen",
        "iso": "AN",
        "number": "(+599)"
    },
    {
        "country": "New Caledonia",
        "iso": "NC",
        "number": "(+687)"
    },
    {
        "country": "New Zealand",
        "iso": "NZ",
        "number": "(+64)"
    },
    {
        "country": "Nicaragua",
        "iso": "NI",
        "number": "(+505)"
    },
    {
        "country": "Niger",
        "iso": "NE",
        "number": "(+227)"
    },
    {
        "country": "Nigeria",
        "iso": "NG",
        "number": "(+234)"
    },
    {
        "country": "Niue",
        "iso": "NU",
        "number": "(+683)"
    },
    {
        "country": "Norway",
        "iso": "NO",
        "number": "(+47)"
    },
    {
        "country": "Oman",
        "iso": "OM",
        "number": "(+968)"
    },
    {
        "country": "Pakistan",
        "iso": "PK",
        "number": "(+92)"
    },
    {
        "country": "Palestine",
        "iso": "PS",
        "number": "(+970)"
    },
    {
        "country": "Panama",
        "iso": "PA",
        "number": "(+507)"
    },
    {
        "country": "Papua New Guinea",
        "iso": "PG",
        "number": "(+675)"
    },
    {
        "country": "Paraguay",
        "iso": "PY",
        "number": "(+595)"
    },
    {
        "country": "Peru",
        "iso": "PE",
        "number": "(+51)"
    },
    {
        "country": "Philippines",
        "iso": "PH",
        "number": "(+63)"
    },
    {
        "country": "Poland",
        "iso": "PL",
        "number": "(+48)"
    },
    {
        "country": "Portugal",
        "iso": "PT",
        "number": "(+351)"
    },
    {
        "country": "Puerto Rico",
        "iso": "PR",
        "number": "(+1)"
    },
    {
        "country": "Qatar",
        "iso": "QA",
        "number": "(+974)"
    },
    {
        "country": "Republic of the Congo",
        "iso": "CG",
        "number": "(+242)"
    },
    {
        "country": "Reunion",
        "iso": "RE",
        "number": "(+262)"
    },
    {
        "country": "Romania",
        "iso": "RO",
        "number": "(+40)"
    },
    {
        "country": "Russian Federation",
        "iso": "RU",
        "number": "(+7)"
    },
    {
        "country": "Rwanda",
        "iso": "RW",
        "number": "(+250)"
    },
    {
        "country": "Saint Kitts and Nevis",
        "iso": "KN",
        "number": "(+1)"
    },
    {
        "country": "Saint Lucia",
        "iso": "LC",
        "number": "(+1)"
    },
    {
        "country": "Samoa",
        "iso": "WS",
        "number": "(+685)"
    },
    {
        "country": "San Marino",
        "iso": "SM",
        "number": "(+378)"
    },
    {
        "country": "São Tomé e Príncipe",
        "iso": "ST",
        "number": "(+239)"
    },
    {
        "country": "Saudi Arabia",
        "iso": "SA",
        "number": "(+966)"
    },
    {
        "country": "Senegal",
        "iso": "SN",
        "number": "(+221)"
    },
    {
        "country": "Serbia",
        "iso": "RS",
        "number": "(+381)"
    },
    {
        "country": "Seychelles",
        "iso": "SC",
        "number": "(+248)"
    },
    {
        "country": "Sierra Leone",
        "iso": "SL",
        "number": "(+232)"
    },
    {
        "country": "Singapore",
        "iso": "SG",
        "number": "(+65)"
    },
    {
        "country": "Slovakia",
        "iso": "SK",
        "number": "(+421)"
    },
    {
        "country": "Slovenia",
        "iso": "SI",
        "number": "(+386)"
    },
    {
        "country": "Solomon Islands",
        "iso": "SB",
        "number": "(+677)"
    },
    {
        "country": "Somalia",
        "iso": "252",
        "number": "(+27)"
    },
    {
        "country": "South Africa",
        "iso": "ZA",
        "number": "(+27)"
    },
    {
        "country": "South Korea",
        "iso": "KR",
        "number": "(+82)"
    },
    {
        "country": "South Sudan",
        "iso": "SS",
        "number": "(+211)"
    },
    {
        "country": "Spain",
        "iso": "ES",
        "number": "(+34)"
    },
    {
        "country": "Sri Lanka",
        "iso": "LK",
        "number": "(+94)"
    },
    {
        "country": "Suriname",
        "iso": "SR",
        "number": "(+597)"
    },
    {
        "country": "Eswatini",
        "iso": "SZ",
        "number": "(+268)"
    },
    {
        "country": "Sweden",
        "iso": "SE",
        "number": "(+46)"
    },
    {
        "country": "Switzerland",
        "iso": "CH",
        "number": "(+41)"
    },
    {
        "country": "Taiwan",
        "iso": "TW",
        "number": "(+886)"
    },
    {
        "country": "Tajikistan",
        "iso": "TJ",
        "number": "(+992)"
    },
    {
        "country": "Tanzania",
        "iso": "TX",
        "number": "(+255)"
    },
    {
        "country": "Thailand",
        "iso": "TH",
        "number": "(+66)"
    },
    {
        "country": "Timor-Leste",
        "iso": "TL",
        "number": "(+670)"
    },
    {
        "country": "togolaise",
        "iso": "TG",
        "number": "(+228)"
    },
    {
        "country": "Tonga",
        "iso": "TO",
        "number": "(+676)"
    },
    {
        "country": "Trinidad and Tobago",
        "iso": "TT",
        "number": "(+1)"
    },
    {
        "country": "Tunisia",
        "iso": "TN",
        "number": "(+216)"
    },
    {
        "country": "Turkey",
        "iso": "TR",
        "number": "(+90)"
    },
    {
        "country": "Turkmenistan",
        "iso": "TM",
        "number": "(+993)"
    },
    {
        "country": "Turks and Caicos Islands",
        "iso": "TC",
        "number": "(+1)"
    },
    {
        "country": "Tuvalu",
        "iso": "TC",
        "number": "(+688)"
    },
    {
        "country": "Uganda",
        "iso": "UG",
        "number": "(+256)"
    },
    {
        "country": "Ukraine",
        "iso": "UA",
        "number": "(+380)"
    },
    {
        "country": "United Arab Emirates",
        "iso": "AE",
        "number": "(+971)"
    },
    {
        "country": "United Kingdom",
        "iso": "GB",
        "number": "(+44)"
    },
    {
        "country": "United States of America",
        "iso": "US",
        "number": "(+1)"
    },
    {
        "country": "Uruguay",
        "iso": "UY",
        "number": "(+598)"
    },
    {
        "country": "Uzbekistan",
        "iso": "UZ",
        "number": "(+998)"
    },
    {
        "country": "Vanuatu",
        "iso": "VU",
        "number": "(+678)"
    },
    {
        "country": "Venezuela",
        "iso": "VE",
        "number": "(+58)"
    },
    {
        "country": "Vietnam",
        "iso": "VN",
        "number": "(+84)"
    },
    {
        "country": "Virgin Islands",
        "iso": "VG",
        "number": "(+1284)"
    },
    {
        "country": "United States Virgin Islands",
        "iso": "VI",
        "number": "(+1340)"
    },
    {
        "country": "Yemen",
        "iso": "YE",
        "number": "(+967)"
    },
    {
        "country": "Zambia",
        "iso": "ZM",
        "number": "(+260)"
    },
    {
        "country": "Zimbabwe",
        "iso": "ZW",
        "number": "(+263)"
    }
]