import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../hooks/device';

// styled component

// img
import Logo from '../img/Logo.svg';
import serviceOne from '../img/HomeOne.svg';
import serviceTwo from '../img/HomeTwo.svg';
import serviceThree from '../img/HomeThree.svg';
import serviceFour from '../img/HomeFour.svg';
import serviceFive from '../img/HomeFive.svg';
import serviceSix from '../img/HomeSix.svg';
import serviceSeven from '../img/HomeSeven.svg';
import testerPoster from '../img/testerManual.png';
// import playButton from '../img/playButton.svg';
import file from '../img/file.svg';
import TextTwoPart from '../atomic/atom/TextTwoPart';
import { useRecoilValue } from 'recoil';
import { languageIs } from '../atoms';
import Video from '../atomic/atom/Video';

interface Home5Props {
  ref: React.MutableRefObject<undefined>;
}

interface Home5Style extends Home5Props {
  clicked: number;
}

const Home5: React.FC<Home5Props> = (props) => {
  const [count, setCount] = useState(1);
  const language = useRecoilValue(languageIs);

  useEffect(() => {
    if (document.location.href.split('#')[1] === 'how_to_use_examinee') {
      setCount(2);
    }
  }, []);

  return (
    <StBackGround clicked={count} ref={props.ref}>
      <div className="inBox">
        <div className="upBox">
          <div className="line">
            <img className="logo" alt="" src={Logo} />
            <div className="h6">
              <TextTwoPart
                textKor="서비스는 어떻게 이용할 수 있나요?"
                textEng="How To Use"
              />
            </div>
          </div>
          <div className="ft35">
            {language === '/ko' ? (
              <>
                시험 주최자, 응시자 모두
                <br className="mobile" /> 쉽고 간편하게
              </>
            ) : (
              <TextTwoPart
                textEng="User-friendly for all"
                tab_margin="0 auto"
              />
            )}
          </div>
          <div className="subTitle">
            {language === '/ko' ? (
              <>
                부정행위를 자동 포착하여 요약된
                <br className="mobile" /> 결과보고서로 자동 생성!
              </>
            ) : (
              <>
                An AI proctor accessible and convenient&nbsp;
                <br className="mobile" />
                for both administrators and test-takers.
              </>
            )}
          </div>
        </div>
        {/* <Video
          src="https://proctormatic-prod-s3-fe-asset-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/testerManual.mp4"
          width="100%"
          pc_margin="0 0 40px 0"
          poster={testerPoster}
          mute={true}
        /> */}
        <div className="box">
          <div className="line">
            <div
              className="leftBox"
              // clicked={count}
              onClick={() => {
                setCount(1);
              }}
            >
              <TextTwoPart textKor="시험 주최자" textEng="Administrator" />
            </div>
            <div
              className="rightBox"
              // clicked={count}
              onClick={() => {
                setCount(2);
              }}
            >
              <TextTwoPart textKor="시험 응시자" textEng="Test-taker" />
            </div>
          </div>
          {count === 1 ? (
            <div className="leftBack">
              <div className="leftInBox">
                <img src={serviceOne} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>①</span>{' '}
                    <TextTwoPart
                      textKor="시험 정보 입력 후 결제하여 시험 예약하기"
                      textEng="Create the events"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="시험 정보와 응시 인원을 입력하면 서비스 이용료와 결제 금액이
                    자동 산출돼요. 결제를 완료하면 시험이 개설되며 응시 시간
                    기준 3시간 전까지 취소가 가능해요."
                      textEng="Enter event details and the number of test-takers. The service calculates the fee automatically. After payment, you can schedule proctoring sessions and cancel them up to 3 hours before the exam."
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceTwo} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>②</span>
                    <TextTwoPart
                      textKor="응시자 전용 웹링크(URL)과 QR 코드 발급"
                      textEng="Check the URL and QR code"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="설정한 날짜와 시간에만 응시자의 녹화 영상 업로드가 가능한
                    전용 웹링크(URL)와 원활한 시험 진행을 위한 가이드 정보가
                    이메일로 발송돼요."
                      textEng="You will receive a dedicated URL, QR code, and event guidelines via email. 
                      The test-takers can access the event using the Proctormatic app at the scheduled date and time."
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceThree} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>③</span>
                    <TextTwoPart
                      textKor="시험 정보와 응시 안내 전달"
                      textEng="Send event details and instructions to test-takers"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="응시자에게 시험 시작부터 종료까지 계속 응시 영상을
                    녹화하도록 요청해 주세요. 영상에 반드시 얼굴, 양손, PC
                    화면이 나오도록 안내해 주세요."
                      textEng="Provide test-takers with pre-session preparation 
                      and a guide for using the Proctormatic app to 
                      complete the entire proctored process."
                    />
                  </div>
                  <div
                    className="leftButton"
                    onClick={() => {
                      window.open(
                        'https://proctormatic-prod-s3-fe-asset-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/DetailedGuideforTestTakers1_0.pdf'
                      );
                    }}
                  >
                    <img src={file} alt="" className="playButton" />
                    <TextTwoPart
                      textKor="응시자 매뉴얼"
                      textEng="Test-taker Guide"
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceFour} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>④</span>
                    <TextTwoPart
                      textKor="영상 분석 완료 후 보고서 확인"
                      textEng="Review my reports"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="AI의 자동 분석이 완료되면 검증 결과가 담긴 보고서가 이메일로
                    발송돼요. 항목별 근거 데이터를 손쉽게 확인하여 부정행위
                    여부를 판단할 수 있어요."
                      textEng="Once our smart AI proctor's automatic analysis is complete, 
                      you will receive an email with reports that include detailed evidence, 
                      making it easy to assess any cheating."
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceFive} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>⑤</span>
                    <TextTwoPart
                      textKor="언제든 간편하게 내 시험 관리"
                      textEng="Manage the reports"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="로그인 후 ‘내 시험 관리’ 메뉴를 통해 진행한 전체 시험 내역을
                    확인할 수 있어요. 각 시험의 진행 현황, 예상 완료 시간 등
                    상세정보를 언제든 쉽게 확인이 가능해요."
                      textEng="After logging in, you can view your event history
                     and check details for each proctored session through
                      the 'View My Reports' menu, including its progress 
                      status and expected completion time."
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="leftBack">
              <div className="leftInBox">
                <img src={serviceTwo} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>①</span>
                    <TextTwoPart
                      textKor="전용 웹링크(URL) 또는 QR 코드로 시험 접속"
                      textEng="Access the proctored session via URL or QR code"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor=" 당일 주최자에게 전달받은 응시 가이드 내 전용 URL 또는 QR
                    코드와 프록토매틱 모바일 앱을 이용하여 시험에 입장해주세요.
                    시작 30분 전부터 입장이 가능해요."
                      textEng="Connect to AI proctor using the Proctormatic mobile app with the URL or QR code provided by the administrator, beginning 30 minutes prior to the scheduled time."
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceSix} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>②</span>{' '}
                    <TextTwoPart
                      textKor="시험 정보 확인 후 응시를 위한 사전준비 진행"
                      textEng="Review session details"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="시험 제목, 응시 날짜와 시간, 결과보고서 전달을 위한 주최자
                    정보를 꼼꼼히 확인해주세요. 응시한 시험 정보가 맞다면 이름
                    입력과 이메일 인증을 완료 후 AI 자동 분석을 위한 개인정보
                    수집 및 이용 동의를 진행해 주세요."
                      textEng="Carefully check the session details. If the details are correct, 
                      enter your name, verify your email, and agree to the collection 
                      and use of personal information for automated AI analysis."
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceThree} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>③</span>
                    <TextTwoPart
                      textKor="스스로 응시 영상 녹화 및 완료"
                      textEng="Record the exam video"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="시험 시작부터 종료까지 계속 응시 영상을 녹화해 주세요. 얼굴과 양손, PC 화면이 영상에 반드시 나오도록 측면에서 녹화해 주세요(아래 매뉴얼 반드시 숙지)."
                      textEng="Record the entire exam video, making sure your face, both hands, and the PC screen are visible from a side angle (please refer to the manual below)."
                    />
                  </div>
                  <div
                    className="leftButton"
                    onClick={() => {
                      window.open(
                        'https://proctormatic-prod-s3-fe-asset-ap-northeast-2.s3.ap-northeast-2.amazonaws.com/DetailedGuideforTestTakers1_0.pdf'
                      );
                    }}
                  >
                    <img src={file} alt="" className="playButton" />
                    <TextTwoPart
                      textKor="응시자 매뉴얼"
                      textEng="Test-taker Guide"
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceSeven} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>④</span>{' '}
                    <TextTwoPart
                      textKor="녹화한 응시 영상 업로드"
                      textEng="Upload the recorded video"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="영상 검증을 위해 프록토매틱 클라우드(CLOUD)로 녹화 영상 파일
                    업로드를 시험 종료 직후 12시간 안에 진행해 주세요. 녹화 종료
                    직후 ‘영상 업로드’를 통해 바로 이어서 진행하는 것을
                    권장해요."
                      textEng="After the exam ends, upload the recorded video file to the Proctormatic 
                      Cloud within 12 hours. We recommend uploading immediately after recording is completed."
                    />
                  </div>
                </div>
              </div>
              <div className="leftInBox">
                <img src={serviceFour} alt="" className="leftInBoxImg" />
                <div className="leftRight">
                  <div className="leftTitle">
                    <span>⑤</span>
                    <TextTwoPart
                      textKor="영상 검증 후 결과보고서 전달"
                      textEng="You are all set!"
                    />
                  </div>
                  <div className="leftText">
                    <TextTwoPart
                      textKor="응시 영상 업로드가 완료되면 프록토매틱 서비스에서 AI 자동
                    분석을 시작해요. 분석이 완료되면 부정행위 검증 결과보고서가
                    생성되어 주최자 이메일로 전달돼요."
                      textEng="After you upload the exam video, Proctormatic
                       begins an automated AI analysis. Once this is completed,
                        a cheating verification report is generated and emailed to the administrator."
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {count === 2 ? <></> : <></>}
        </div>
      </div>
    </StBackGround>
  );
};

const StBackGround = styled.div<Home5Style>`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f0f1f2;
  @media ${device.tabMob} {
    width: 100%;
    padding: 100px 24px;
    box-sizing: border-box;
    .playButton {
      margin-right: 6px;
      width: 14px;
      height: 14px;
    }
    .leftInBoxImg {
      width: 100px;
    }
    .leftButton {
      background-color: #2276dc;
      width: 121px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
      margin-top: 12px;
      color: #ffffff;
      font-size: 12px;
      cursor: pointer;
    }
    .leftRight {
    }
    .leftBack {
      width: 100%;
      padding: 64px 24px 32px;
      box-sizing: border-box;
      background-color: #ffffff;
    }
    .leftInBox {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 64px;
      .leftTitle {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 6px;
        font-family: 'Bold';
        font-size: 18px;
        margin-top: 20px;
        line-height: 27px;
        word-break: break-all;
        span {
          font-family: 'extralight';
          font-size: 21px;
          margin-bottom: auto;
        }
      }
      .leftText {
        margin-top: 20px;
        font-size: 15px;
        line-height: 22px;
      }
    }
    .subTitle {
      color: #2276dc;
      font-size: 14px;
      line-height: 21px;
      font-family: 'Bold';
      text-align: center;
    }
    .column {
      display: flex;
      flex-direction: column;
    }
    .upBox {
      display: flex;
      flex-direction: column;
      margin-bottom: 50px;
    }
    .line {
      display: flex;
      align-items: center;
    }
    .logo {
      margin-right: 8px;
      width: 28px;
      height: 28px;
    }
    .h6 {
      font-size: 19px;
      font-family: 'Medium';
    }
    .ft35 {
      display: flex;
      font-size: 28px;
      font-family: 'Bold';
      line-height: 41px;
      margin: 50px 0 14px;
      text-align: center;
    }
    .box {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      box-shadow: 10px 10px 38px 10px rgba(0, 0, 0, 0.08);
      .leftBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 42px;
        font-size: 14px;
        border-bottom: ${(props) =>
          props.clicked === 1 ? '0.5px solid #20315B' : '0.5px solid #E9E9E9'};
        background-color: ${(props) =>
          props.clicked === 1 ? '#20315B' : '#F5F5F5'};
        color: ${(props) => (props.clicked === 1 ? 'white' : 'black')};
        cursor: pointer;
      }
      .rightBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 42px;
        font-size: 14px;
        border-bottom: ${(props) =>
          props.clicked === 2 ? '0.5px solid #20315B' : '0.5px solid #E9E9E9'};
        background-color: ${(props) =>
          props.clicked === 2 ? '#20315B' : '#F5F5F5'};
        color: ${(props) => (props.clicked === 2 ? 'white' : 'black')};
        cursor: pointer;
      }
    }
  }
  @media ${device.pc} {
    width: 100%;
    .leftInBoxImg {
      width: 180px;
      height: 180px;
    }
    .mobile {
      display: none;
    }
    .inBox {
      margin: auto;
      width: 1440px;
      padding: 140px 122px;
      box-sizing: border-box;
    }
    .playButton {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
    .leftButton {
      background-color: #2276dc;
      width: 186px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 43px;
      margin-top: 16px;
      font-size: 18px;
      color: #ffffff;
      cursor: pointer;
    }
    .leftRight {
      margin-left: 64px;
    }
    .leftBack {
      width: 100%;
      padding: 140px 120px 0;
      box-sizing: border-box;
      background-color: #ffffff;
    }
    .leftInBox {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 140px;
      .leftTitle {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 12px;
        font-family: 'Bold';
        font-size: 28px;
        line-height: 41px;
        span {
          font-size: 32px;
          font-family: 'extraLight';
        }
      }
      .leftText {
        width: 707px;
        margin-top: 32px;
        font-size: 22px;
        line-height: 33px;
      }
    }
    .subTitle {
      color: #2276dc;
      font-size: 32px;
      font-family: 'Medium';
      line-height: 47px;
    }
    .column {
      display: flex;
      flex-direction: column;
    }
    .upBox {
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;
      .line {
        margin-right: auto;
      }
    }
    .line {
      display: flex;
      align-items: center;
    }
    .logo {
      margin-right: 20px;
      width: 38px;
      height: 38px;
    }
    .h6 {
      font-size: 26px;
      font-family: 'Medium';
    }
    .ft35 {
      font-size: 54px;
      font-family: 'Bold';
      line-height: 80px;
      margin: 90px 0 16px;
    }
    .box {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 1192px;
      margin: 0 auto;
      box-shadow: 10px 10px 38px 10px rgba(0, 0, 0, 0.08);
      .leftBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 596px;
        height: 62px;
        font-size: 24px;
        font-family: ${(props) => (props.clicked === 1 ? 'Medium' : 'Regular')};
        border-bottom: ${(props) =>
          props.clicked === 1 ? '0.5px solid #20315B' : '0.5px solid #E9E9E9'};
        background-color: ${(props) =>
          props.clicked === 1 ? '#20315B' : '#F5F5F5'};
        color: ${(props) => (props.clicked === 1 ? 'white' : 'black')};
        cursor: pointer;
      }
      .rightBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 596px;
        height: 62px;
        font-size: 24px;
        font-family: ${(props) => (props.clicked === 2 ? 'Medium' : 'Regular')};
        border-bottom: ${(props) =>
          props.clicked === 2 ? '0.5px solid #20315B' : '0.5px solid #E9E9E9'};
        background-color: ${(props) =>
          props.clicked === 2 ? '#20315B' : '#F5F5F5'};
        color: ${(props) => (props.clicked === 2 ? 'white' : 'black')};
        cursor: pointer;
      }
    }
  }
`;

export default Home5;
