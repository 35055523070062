import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { device } from "../hooks/device";

import greyX from "../img/greyX.svg";
import arrowLeft from "../img/arrowLeftWhite.svg";
import arrowRight from "../img/arrowRightWhite.svg";
import { useTranslation } from "react-i18next";

const Photo = ({ setPictureModalOn, list, clickedNumber }) => {
  const { t } = useTranslation();
  useScrollLockThree();

  const goBack = () => {
    setPictureModalOn(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useEffect(() => {
    setNowNumber(clickedNumber);
  }, [clickedNumber]);

  const [nowNumber, setNowNumber] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <StPhoto>
      <div className="headerPart">
        <div>{t(`customer.h3_1_1.attach`)}</div>
        <img
          src={greyX}
          alt=""
          onClick={() => {
            window.history.back();
          }}
        />
      </div>
      <div className="bodyPart">
        <img
          className="picture"
          src={list[nowNumber - 1]}
          alt=""
          onClick={toggleVisibility}
        />
        <div className="">
          {list?.length !== 1 && isVisible && (
            <div className="listBox onlyForMobile">
              <div>
                {nowNumber > 1 && (
                  <img
                    src={arrowLeft}
                    onClick={() => setNowNumber(nowNumber - 1)}
                    alt=""
                  />
                )}
              </div>
              {list?.length > 1 && (
                <div>
                  <div>
                    {nowNumber} / {list?.length}
                  </div>
                </div>
              )}
              {list?.length > nowNumber && (
                <div>
                  <img
                    src={arrowRight}
                    onClick={() => setNowNumber(nowNumber + 1)}
                    alt=""
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="listPart onlyForPc">
        {list?.length !== 1 && isVisible && (
          <div className="listBox">
            <div>
              {nowNumber > 1 && (
                <img
                  src={arrowLeft}
                  onClick={() => setNowNumber(nowNumber - 1)}
                  alt=""
                />
              )}
            </div>
            <div>
              {nowNumber} / {list?.length}
            </div>
            {list?.length > nowNumber && (
              <div>
                <img
                  src={arrowRight}
                  onClick={() => setNowNumber(nowNumber + 1)}
                  alt=""
                />
              </div>
            )}
          </div>
        )}
      </div>
    </StPhoto>
  );
};

const StPhoto = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 60;
  width: 100%;
  height: 100%;
  background-color: white;
  @media ${device.pc} {
    .onlyForMobile {
      display: none;
    }
    .headerPart {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 16px 24px;
      box-sizing: border-box;
      font-size: 17px;
      font-family: "bold";
      line-height: 28px;
      img {
        cursor: pointer;
      }
    }
    .bodyPart {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 121px);
      flex-grow: 1;
      background-color: black;
      img {
        height: 100%;
        max-height: 804px;
        max-width: 1341px;
      }
    }
    .listPart {
      background-color: black;
      height: 61px;
      .listBox {
        display: grid;
        grid-template-columns: 24px 50px 24px;
        gap: 95px;
        width: 100%;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #f0f1f2;
        font-size: 18px;
        font-family: "medium";
        padding: 16px;
        box-sizing: border-box;
        div {
          cursor: pointer;
        }
      }
    }
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none;
    }
    .headerPart {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 60px;
      padding: 16px 24px;
      box-sizing: border-box;
      font-size: 19px;
      font-family: "bold";
      line-height: 28px;
      img {
        width: 14px;
      }
    }
    .bodyPart {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      height: calc(100% - 60px);
      .picture {
        max-width: 100%;
        max-height: 100%;
      }
      .listBox {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        display: grid;
        justify-content: center;
        grid-template-columns: 24px 188px 24px;
        gap: 26px;
        color: white;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 16px 0;
        width: 100%;
      }
    }
  }
`;

export default Photo;
