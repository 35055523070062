import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import useToast from "../hooks/useToast";
import spinner from "../img/spinner.svg";
import { useTranslation } from "react-i18next";

const SendingVideo = ({
  setSendingVideoModal,
  videoReady,
  setVideoReady,
  videoLink,
  sendingMail,
}) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d3_11_1";
  const showToast = useToast();

  useScrollLockThree();

  const goBack = () => {
    setSendingVideoModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  /** 영상 play할 수 있는 링크 복사하기 */
  const copy = () => {
    navigator.clipboard.writeText(videoLink);
    window.history.back();
    showToast({ message: t(`errorCode.copyLink`) });
  };

  const modalOff = () => {
    window.history.back();
  };

  useEffect(() => {
    return () => {
      setVideoReady(false);
    };
  }, []);

  return (
    <>
      <ModalBackD modalOff={modalOff} />
      <StSendingVideo>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        <div className="buttonBox">
          <div id="text" onClick={copy}>
            {t(`${baseLang}.button1`)}
          </div>
          <a href={sendingMail}>{t(`${baseLang}.button2`)}</a>
        </div>
        <div className="cancelButton" onClick={() => window.history.back()}>
          {t(`${baseLang}.button3`)}
        </div>
      </StSendingVideo>
    </>
  );
};

const StSendingVideo = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
  @media ${device.pc} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    width: 580px;
    display: grid;
    flex-direction: column;
    gap: 48px;
    .spinBox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 132px;
      .spinner {
        width: 67px;
        height: 67px;
        animation: rotate_image 1.5s linear infinite;
      }
    }
    .buttonBox {
      display: grid;
      flex-direction: column;
      gap: 39px;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      padding: 19.5px 0;
      div {
        width: 100px;
        cursor: pointer;
      }
      a {
        width: 200px;
        text-decoration: none;
        color: black;
        cursor: pointer;
      }
    }
    .cancelButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      border-radius: 43px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    width: 344px;
    height: 207px;
    padding: 24px;
    box-sizing: border-box;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 26px;
    .buttonBox {
      display: grid;
      flex-direction: column;
      gap: 31px;
      padding: 15.5px 0;
      font-size: 17px;
      line-height: 25px;
      div {
        cursor: pointer;
      }
      a {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }
    .spinBox {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      .spinner {
        width: 42px;
        height: 42px;
        animation: rotate_image 1.5s linear infinite;
      }
    }
    .cancelButton {
      display: none;
    }
  }
`;

export default SendingVideo;
