import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetEnterpriseInvite = ({ invited }) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getEnterpriseInvite({ utc });
    return data;
  };
  return useQuery(["EnterpriseInvitation", invited], fetcher);
};

export default useGetEnterpriseInvite;
