import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import useModal from "../hooks/useModal";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import { ModalBackDTwo, ModalHeaderD } from "../components/Style";
import { useTranslation } from "react-i18next";

const FailToChangePlan = ({ close, title, textOne, textTwo }) => {
  // const goBack = () =>{
  //     close(false);
  //   }

  // useEffect(()=>{
  //     window.history.pushState(null,'',window.location.href)
  //     window.addEventListener('popstate',goBack);
  // return(()=>{
  //     window.removeEventListener('popstate',goBack);
  // })
  // },[])

  const { t } = useTranslation();
  const navigate = useNavigate();

  useModal(close);
  useScrollLockThree();

  return (
    <>
      <ModalBackDTwo />
      <StModal textTwo={textTwo}>
        <ModalHeaderD title={title} />
        <div className="titleInfo">{textOne}</div>
        <div className="titleInfoTwo">{textTwo}</div>
        <span
          className="cancelButton"
          onClick={() => {
            navigate(`/myexam`);
          }}
        >
          {t(`mypage.f1_2.button`)}
        </span>
      </StModal>
    </>
  );
};

const StModal = styled.div`
  position: fixed;
  z-index: 70;
  background-color: white;
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .titleInfo,
    .titleInfoTwo {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin-top: 48px;
    }
    .titleInfoTwo {
      margin: 0px 0 48px;
      /* margin-bottom: 48px; */
    }
    .cancelButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px auto;
      width: 204px;
      height: 52px;
      background: #20315b;
      border-radius: 43px;
      font-family: "medium";
      color: white;
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    padding: 24px;
    box-sizing: border-box;
    border-radius: 24px;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 53;
    width: 344px;
    margin: 0 auto;
    .titleInfo,
    .titleInfoTwo {
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
    }
    .cancelButton {
      text-align: center;
      color: #0072de;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin-top: 28.5px;
      cursor: pointer;
    }
  }
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  .titlePart {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
`;

export default FailToChangePlan;
