import React, { useEffect, useState } from "react";
import styled from "styled-components";
import questionMark from "../../img/Q.svg";
import arrow from "../../img/arrow.svg";
import spread from "../../img/spread.svg";
import { Outlet, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageIs, userInfo } from "../../atoms";
import { baseURL } from "../../api/handler";
import blueCheck from "../../img/blueCheck.svg";
import PointInfo from "../../modal/PointInfo";
import { device } from "../../hooks/device";
import customAxios from "../../api/handler";
import useToast from "../../hooks/useToast";
import Img from "../../atomic/atom/Img";
import chargeIcon from "../../img/chargeIcon.svg";
import useGetTradeDetailInd from "../../hooks/api/useGetTradeDetailInd";
import DivTwoPartImprove from "../../atomic/atom/DivTwoPartImprove";
import { useTranslation } from "react-i18next";

const MyPoint = ({ setModalOn }) => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g4";
  const country = useRecoilValue(languageIs);
  const navigate = useNavigate();
  const forFilter = ["all", "charge", "use", "extinct", "cancel"];
  const [value, setValue] = useState(forFilter[0]);
  const [point, setPoint] = useState();
  const [pointUse, setPointUse] = useState();
  const [filterOn, setFilterOn] = useState(false);
  const [filterChoose, setFilterChoose] = useState(0);
  const filters = [
    t(`${baseLang}.all`),
    t(`${baseLang}.charge`),
    t(`${baseLang}.used`),
    t(`${baseLang}.expired`),
    t(`${baseLang}.canceled`),
  ];
  const user = useRecoilValue(userInfo);
  const [lang, setLang] = useState("kr");
  const showToast = useToast();
  const [tradeType, setTradeType] = useState();
  const [tradeId, setTradeId] = useState();

  const { data } = useGetTradeDetailInd({ tradeId, type: tradeType });

  // modalState
  const [pointInfoModal, setPointInfoModal] = useState(false);
  const [pointChargeModal, setPointChargeModal] = useState(false);

  useEffect(() => {
    if (country === "/ko") {
      setLang("kr");
    } else {
      setLang("en");
    }
  }, [country]);

  /** 포인트 요약 데이터 받아오기 */
  useEffect(() => {
    if (user?.plan === "managerPlan") {
      // 모계정만 기업포인트 조회가 가능함
      customAxios
        .get(`${baseURL}/api/v1/payments/enterprise/points`)
        .then(({ data }) => {
          setPoint(data);
        })
        .catch(({ response }) => {
          if (response.data?.errorCode === "ENTERPRISE-009") {
            showToast({
              message:
                "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
            });
          }
        });
    } else if (user?.plan === "memberPlan" || user?.plan === "individualPlan") {
      customAxios
        .get(`${baseURL}/api/v1/payments/individual/credits`)
        .then(({ data }) => {
          setPoint(data);
        })
        .catch(({ response }) => {
          if (response.data?.errorCode === "HOST-ROLE-003") {
            showToast({
              message:
                "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
            });
          }
        });
    }
  }, [user?.plan]);

  /** 포인트 사용 내역 받아오기 */
  useEffect(() => {
    customAxios
      .get(`${baseURL}/api/v1/payments/individual/credits/list?type=${value}`)
      .then(({ data }) => {
        setPointUse(data);
      })
      .catch(({ response }) => {
        if (response.data?.errorCode === "HOST-ROLE-003") {
          showToast({
            message:
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          });
        }
      });
  }, [value, lang, user]);

  /** 포인트 사용내역 필터링 */
  const filtering = (num) => {
    setFilterOn(false);
    setFilterChoose(num);
    setValue(forFilter[num]);
  };

  return (
    <>
      {pointInfoModal && <PointInfo setPointInfoModal={setPointInfoModal} />}
      {/* {pointChargeModal && (
        <PointCharge setPointChargeModal={setPointChargeModal} />
      )} */}
      <StMyPoint filterOn={filterOn} filterChoose={filterChoose}>
        <div className="wrapper">
          <div className="headerBox">
            <div className="headerLeft">
              <img
                style={{ cursor: "pointer" }}
                src={arrow}
                alt=""
                onClick={() => {
                  navigate(`/myPage`);
                }}
              />
              <div>{t(`${baseLang}.title`)}</div>
            </div>
            <img
              src={questionMark}
              style={{ cursor: "pointer" }}
              alt=""
              onClick={() => {
                setPointInfoModal(true);
              }}
            />
          </div>
          <div className="pcDownBox">
            <div className="availablePointBox">
              <div className="pcAvailableBox">
                <div className="pcAvailableLeft">
                  <div className="availableTitle">
                    {t(`${baseLang}.availableCredit`)}
                  </div>
                  <div className="availablePoints">
                    {Number(
                      point?.hostCreditAndExpired?.hostCredits
                    )?.toLocaleString()}
                    <span>C</span>
                  </div>
                </div>
                <div className="columnLine onlyForPc" />
                <div className="availableVanish">
                  <div className="vanishUp">{t(`${baseLang}.expireInd`)}</div>
                  <div className="vanishDown">
                    {Number(
                      point?.hostCreditAndExpired?.paymentsCreditsExpiredAt
                    )?.toLocaleString()}
                    <span>c</span>
                  </div>
                </div>
              </div>
              <div className="pcMiddleBox">
                <div className="availableExplain">{t(`${baseLang}.info1`)}</div>
                <div className="rowGrayBar onlyForMobile" />
                <div
                  className="availableSave"
                  onClick={() => {
                    // setPointChargeModal(true);
                    navigate("couponEnter");
                  }}
                >
                  <Img
                    src={chargeIcon}
                    pc_display="none"
                    height={15}
                    margin="auto 6px auto 0"
                  />
                  &nbsp;{t(`${baseLang}.coupon`)}
                </div>
              </div>
            </div>
            <div className="pointSpendListBox">
              <div className="listHeader">
                <span>{t(`${baseLang}.history`)}</span>
                <div className="headRight">
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setFilterOn(true);
                    }}
                  >
                    {filters[filterChoose]}
                    <img src={spread} alt="" />
                  </div>
                  <div className="filterModal">
                    <div
                      className="modalItemOne"
                      onClick={() => {
                        filtering(0);
                      }}
                    >
                      <div>{t(`${baseLang}.all`)}</div>{" "}
                      {filterChoose === 0 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemTwo"
                      onClick={() => {
                        filtering(1);
                      }}
                    >
                      {t(`${baseLang}.charge`)}{" "}
                      {filterChoose === 1 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemThree"
                      onClick={() => {
                        filtering(2);
                      }}
                    >
                      {t(`${baseLang}.used`)}{" "}
                      {filterChoose === 2 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemFour"
                      onClick={() => {
                        filtering(3);
                      }}
                    >
                      {t(`${baseLang}.expired`)}{" "}
                      {filterChoose === 3 && <img src={blueCheck} alt="" />}
                    </div>
                    <div
                      className="modalItemFive"
                      onClick={() => {
                        filtering(4);
                      }}
                    >
                      {t(`${baseLang}.canceled`)}{" "}
                      {filterChoose === 4 && <img src={blueCheck} alt="" />}
                    </div>
                  </div>
                  <div
                    className="filterBack"
                    onClick={() => setFilterOn(false)}
                  />
                </div>
              </div>
              <div className="onlyForPc listTag">
                <div className="thickLine" />
                <div>
                  <span>{t(`${baseLang}.type`)}</span>
                  <span>{t(`${baseLang}.detail`)}</span>
                  <span>{t(`${baseLang}.credits`)}</span>
                </div>
                <div className="blackLine" />
              </div>
              <div className="listBody">
                {pointUse?.creditTradeList?.map((value, index) => {
                  let temp = t(`${baseLang}.availableCredit`);
                  switch (value.paymentsCreditsType) {
                    case "charge":
                      temp = t(`${baseLang}.charge`);
                      break;
                    case "use":
                      temp = t(`${baseLang}.used`);
                      break;
                    case "cancel":
                      temp = t(`${baseLang}.canceled`);
                      break;
                    case "extinct":
                      temp = t(`${baseLang}.expired`);
                      break;
                    default:
                      break;
                  }
                  return (
                    <div key={index}>
                      <div
                        className="listItem"
                        key={index}
                        onClick={() => {
                          setTradeId(value.paymentsCreditTradeId);
                          setTradeType(value.paymentsCreditsType);
                          navigate(`tradeDetail`);
                        }}
                      >
                        <DivTwoPartImprove
                          display="grid"
                          pc_grid_template_column="160px auto"
                        >
                          <div className="itemFor">{temp}</div>
                          <DivTwoPartImprove>
                            <div className="itemMiddle">
                              <div className="middleLeft">
                                {value.paymentsCreditsTypeDetail}
                              </div>
                            </div>
                            <div className="dateInfo">
                              {value.paymentsCreditsTradeAt}
                            </div>
                          </DivTwoPartImprove>
                        </DivTwoPartImprove>
                        <div
                          className="middleRight"
                          style={
                            value.paymentsCredits < 0
                              ? { color: "#C83B38" }
                              : {}
                          }
                        >
                          {Number(value.paymentsCredits).toLocaleString()}
                          <span>C</span>
                        </div>
                      </div>
                      {index + 1 !== pointUse?.creditTradeList?.length && (
                        <div className="greyBar" />
                      )}
                    </div>
                  );
                })}
              </div>
              {!pointUse?.creditTradeList ||
              pointUse?.creditTradeList.length === 0 ? (
                <div
                  style={{
                    width: "100%",
                    height: "151px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#BDBDBD",
                    fontSize: "16px",
                    background: "",
                  }}
                >
                  {t(`${baseLang}.none`)}
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </StMyPoint>
      <Outlet
        context={{ tradeId: tradeId, tradeType: tradeType, tradeData: data }}
      />
    </>
  );
};

const StMyPoint = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto auto;
  background-color: #f0f1f2;
  .rowGrayBar {
    width: 100%;
    height: 0.5px;
    background-color: #bdbdbd;
    margin: 24px 0;
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    font-size: 19px;
    font-family: "bold";
    .headerLeft {
      display: flex;
      align-items: center;
    }
  }
  .availablePointBox {
    display: flex;
    flex-direction: column;
    .availableTitle {
      font-size: 13px;
      font-family: "semibold";
      margin-bottom: 16px;
    }
    .availablePoints {
      font-family: "bold";
      font-size: 24px;
      color: #2276dc;
      span {
        font-size: 16px;
        font-family: "regular";
        line-height: 28px;
        color: #2276dc;
        margin-left: 3px;
      }
    }
    .availableVanish {
      display: flex;
      flex-direction: column;
      align-items: center;
      .vanishUp {
        font-size: 12px;
        font-family: "medium";
        color: #818181;
      }
      .vanishDown {
        font-size: "19px";
        font-family: "bold";
        span {
          margin-left: 3px;
          font-family: "regular";
        }
      }
    }
    .availableExplain {
      font-size: 11px;
      font-family: "regular";
      color: #444444;
    }
    .availableSave {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }
  .pointSpendListBox {
    .listHeader {
      display: flex;
      justify-content: space-between;
      font-family: "medium";
      font-size: 14px;
      line-height: 21px;
      color: #909090;
      .headRight {
        position: relative;
        font-size: 14px;
        font-family: "regular";
        color: black;
        img {
          margin-left: 10px;
        }
        .filterBack {
          position: fixed;
          display: ${(props) => (props.filterOn ? "" : "none")};
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
        }
        .filterModal {
          position: absolute;
          top: 0;
          right: 0;
          z-index: 2;
          display: ${(props) => (props.filterOn ? "flex" : "none")};
          flex-direction: column;
          width: 168px;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          background-color: white;
          font-size: 16px;
          line-height: 19px;
          cursor: pointer;
          img {
            margin-left: auto;
            width: 19px;
            height: 19px;
          }
          .modalItemOne,
          .modalItemTwo,
          .modalItemThree,
          .modalItemFour,
          .modalItemFive {
            display: flex;
            padding: 15px 24px;
            box-sizing: border-box;
            font-family: "medium";
          }
          .modalItemOne {
            color: ${(props) =>
              props.filterChoose === 0 ? "#0381FE" : "black"};
          }
          .modalItemTwo {
            color: ${(props) =>
              props.filterChoose === 1 ? "#0381FE" : "black"};
          }
          .modalItemThree {
            color: ${(props) =>
              props.filterChoose === 2 ? "#0381FE" : "black"};
          }
          .modalItemFour {
            color: ${(props) =>
              props.filterChoose === 3 ? "#0381FE" : "black"};
          }
          .modalItemFive {
            color: ${(props) =>
              props.filterChoose === 4 ? "#0381FE" : "black"};
          }
        }
      }
    }
    .listBody {
      border-radius: 24px;
      background-color: #ffffff;
      .listItem {
        display: flex;
        justify-content: space-between;
        padding: 16px 24px;
        box-sizing: border-box;
        cursor: pointer;
        .itemFor {
          font-size: 12px;
          line-height: 18px;
          color: #55595f;
        }
        .itemMiddle {
          display: flex;
          justify-content: space-between;
          margin: 6px 0 2px;
          .middleLeft {
            font-family: "medium";
            font-size: 16px;
            line-height: 24px;
          }
        }
        .middleRight {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #2276dc;
          font-size: 16px;
          font-family: "bold";
          line-height: 24px;
          span {
            font-family: "regular";
            margin-left: 2px;
          }
        }
        .dateInfo {
          font-size: 12px;
          font-family: "regular";
          color: #55595f;
        }
      }
      .greyBar {
        width: 100%;
        height: 1px;
        background-color: #d9d9d9;
      }
    }
  }
  @media ${device.pc} {
    padding: 80px 0px;
    min-height: calc(100vh - 353px);
    box-sizing: border-box;
    .onlyForMobile {
      display: none;
    }
    .wrapper {
      width: 1228px;
      margin: 0 auto;
    }
    .headerBox {
      .headerLeft {
        display: grid;
        grid-template-columns: auto auto;
        gap: 19px;
        font-size: 28px;
        font-family: "bold";
        line-height: 41px;
        img {
          width: 28px;
          height: 28px;
        }
      }
      img {
        width: 29px;
        height: 29px;
      }
    }
    .pcDownBox {
      max-width: 865px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 60px 64px;
      box-sizing: border-box;
      margin: 60px auto 0;
      .availablePointBox {
        margin-bottom: 48px;
        .pcAvailableBox {
          display: grid;
          grid-template-columns: auto auto auto 1fr;
          align-items: center;
          gap: 60px;
          .columnLine {
            width: 0.5px;
            height: 68px;
            background-color: #bdbdbd;
          }
          .pcAvailableLeft {
            display: flex;
            flex-direction: column;
            width: 184px;
            .availableTitle {
              font-size: 18px;
              font-family: "semibold";
              line-height: 27px;
            }
            .availablePoints {
              font-size: 28px;
              font-family: "bold";
              line-height: 41px;
              span {
                font-family: "regular";
                font-size: 22px;
                line-height: 36px;
              }
            }
          }
          .availableVanish {
            .vanishUp {
              font-size: 18px;
              font-family: "semibold";
              line-height: 27px;
              color: black;
              margin-bottom: 16px;
            }
            .vanishDown {
              font-size: 28px;
              font-family: "semibold";
              line-height: 41px;
              margin-right: auto;
              span {
                font-size: 22px;
                font-family: "regular";
                line-height: 36px;
              }
            }
          }
        }
        .pcMiddleBox {
          display: flex;

          /* flex-direction: column; */
          /* justify-content: end; */
          justify-content: space-between;
          align-items: flex-end;
          height: 39px;
          margin-top: 3px;
          .availableSave {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 13px;
            font-family: "medium";
            line-height: 19px;
            width: 179px;
            height: 39px;
            color: white;
            background: #20315b;
            border-radius: 20px;
          }
        }
      }
      .pointSpendListBox {
        .listHeader {
          margin-bottom: 16px;
          span {
            font-size: 18px;
            font-family: "semibold";
            line-height: 27px;
            color: black;
          }
          .headRight {
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
            img {
              width: 12.56px;
              height: 6.58px;
            }
          }
        }
        .listTag {
          .thickLine {
            width: 100%;
            height: 4px;
            background-color: black;
          }
          div {
            display: flex;
            justify-content: space-between;
            span {
              width: 160px;
              padding: 16.5px 0;
              text-align: center;
              font-size: 16px;
              font-family: "medium";
              line-height: 19px;
            }
          }
          .blackLine {
            width: 100%;
            height: 1px;
            background-color: black;
          }
        }
        .listBody {
          .listItem {
            padding: 16px 0;
            display: grid;
            grid-template-columns: auto 160px;
            .itemFor {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 16px;
              color: "#55595F";
            }
            .itemMiddle {
              margin: 0 0 4px;
            }
            .dateInfo {
              font-size: 14px;
              font-family: "regular";
            }
            .middleRight {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #2276dc;
              font-size: 16px;
              font-family: "bold";
              line-height: 24px;
              span {
                font-family: "regular";
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none;
    }
    .wrapper {
      max-width: 752px;
      width: 100%;
      margin: 0 auto;
    }
    .headerBox {
      padding: 24px;
      .headerLeft {
        display: grid;
        grid-template-columns: auto auto;
        gap: 12px;
        line-height: 28px;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    .availablePointBox {
      margin: 12px auto 24px;
      width: calc(100% - 48px);
      background-color: white;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 24px;
      box-sizing: border-box;
      .pcAvailableBox {
        .pcAvailableLeft {
          .availablePoints {
            margin-bottom: 24px;
          }
        }
        .availableVanish {
          border: 0.6px solid #bdbdbd;
          border-radius: 8px;
          padding: 16px 0;
          box-sizing: border-box;
          margin-bottom: 12px;
          .vanishDown {
            margin-top: 7px;
          }
        }
      }
      .pcMiddleBox {
        .availableSave {
          font-size: 15px;
          font-family: "medium";
          span {
            font-size: 20px;
          }
        }
      }
    }
    .pointSpendListBox {
      padding-bottom: 38px;
      .listHeader {
        padding: 5px 24px;
        span {
        }
        .headRight {
          img {
          }
        }
      }
      .listBody {
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      }
    }
  }
`;

export default MyPoint;
