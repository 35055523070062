import { useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getCookie } from './Cookie';
import { StyleSheetManager } from 'styled-components';
// import styled from 'styled-components';

//recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  bannerOn,
  languageIs,
  modalPadding,
  toastMessage,
  toastOn,
  toastUpdate,
  userInfo,
  userlang,
} from './atoms';

// pages
import Landing from './pages/Landing';
import MyExam from './pages/MyExam';
import Report from './pages/Report';
import ReportSpecific from './pages/ReportSpecific';

//components
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';
import ToastPopup from './components/ToastPopup';
import axios from 'axios';
import Test from './pages/Test';
import QnA from './pages/QnA';
import Notice from './pages/Notice';
import Support from './pages/Support';
import customAxios, { baseURL } from './api/handler';
import NoticeDetail from './pages/NoticeDetail';
import CreatingExam from './pages/CreateExamFirstStep';
import ServicePlan from './pages/ServicePlan';
import TestPage from './pages/TestPage';

import MemberManage from './pages/MemberManage';
import InviteFinish from './modal/InviteFinish';
import Invited from './pages/Invited';
import RefundList from './pages/RefundList';
import MyPage from './pages/MyPage';
import Footer from './components/Footer';
import QnAEdit from './pages/QnAEdit';
import MemberAccount from './pages/MemberAccount';
import { AxiosInterceptor } from './api/handler';
import styled from 'styled-components';
import { device } from './hooks/device';
import NotFound from './pages/NotFound';
import VideoPage from './pages/VideoPage';
import BroswerPopUp from './modal/BroswerPopUp';
import ExtraTestConfirm from './modal/ExtraTestConfirm';
import { OutletDataProvider } from './components/OutletDataContext';
import MyCredit from './pages/MyCredit';
import CreateExamSecondStep from './pages/CreateExamSecondStep';
import Request from './pages/ConsultRequest/index';
import Complete from './pages/ConsultRequest/modal/Complete';
import CreditChargeRequest from './pages/CreateExamSecondStep/modal/CreditChargeRequest';
import ChargeConfirm from './pages/CreateExamSecondStep/modal/ChargeConfirm';
import CreditUseSpecific from './pages/MyCredit/modal/TradeDetail';
import QnADetail from './pages/QnADetail';
import RefundTemp from './pages/MyCredit/modal/RefundTemp';
import CreditChargeRequestConfirm from './pages/CreateExamSecondStep/modal/CreditChargeRequestConfirm';
import Policy from './modal/Policy';
import EditNotAllowed from './pages/ExamEdit/modal/EditNotAllowed';
import EditExam from './pages/ExamEdit/index';
import CommentEdit from './pages/QnADetail/modal/CommentEdit';
import CreditInfo from './pages/MyCredit/modal/CreditInfo';
import MyPoint from './pages/MyPoint/MyPoint';
import PointCharge from './pages/MyPoint/modal/PointCharge';
import CouponApplied from './pages/MyPoint/modal/CouponApplied';
import TestUrlPage from './pages/TestUrlPage';
import usePageScrollReset from './hooks/usePageScrollReset';
// import Test from './Test';

import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import cryptoJS from "crypto-js";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [nav, setNav] = useState([0, true]); // landingPage scrolling
  const [myPageScroll, setMyPageScroll] = useState('');
  // const browser = window.navigator.userAgent;
  let token = getCookie('token');

  const [modalOn, setModalOn] = useRecoilState(modalPadding);
  const [browserModal, setBrowserModal] = useState(false);
  // Noah, 채널톡
  // const setUserData = useSetRecoilState(userInfo);
  const [userData, setUserData] = useRecoilState(userInfo);
  const toastON = useRecoilValue(toastOn);
  const toastMESSAGE = useRecoilValue(toastMessage);
  const toastUPDATE = useRecoilValue(toastUpdate);
  const [banneris, setBannerIs] = useRecoilState(bannerOn);

  // Noah, 채널톡
  // 개발서버 조건 추가 -> 상용서버 채널톡 추가 시 조건 제거
  if(process.env.REACT_APP_BUILD_MODE === 'develope') {
    ChannelService.loadScript();
  }

  /**
   * Noah, 페이지네이션 뒤로가기 버튼 페이지 저장 (뒤로가기 시 페이지네이션 이동, 버튼 클릭 시 이전 페이지)
   */
  const [reportLocation, setReportLocation] = useState("");
  const [reSpLocation, setReSpLocation] = useState("");

  useEffect(() => {
    if (location.pathname === "/myexam") {
      setReportLocation(location.pathname + location.search);
    }

    if (location.pathname.startsWith("/report/")) {
      setReSpLocation(location.pathname + location.search);
    }
  }, [location]);

  /** 브라우저를 체크한다. */
  const browserCheck = () => {
    const agent = window.navigator.userAgent.toLowerCase();
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (
      agent.indexOf('edg') > -1 ||
      agent.indexOf('chrome') > -1 ||
      agent.indexOf('whale') > -1 ||
      isSafari
    ) {
      setBrowserModal(false);
    } else {
      if (getCookie('browserPopup')) setBrowserModal(false);
      else {
        setBrowserModal(true);
      }
    }
  };

  useLayoutEffect(() => {
    browserCheck();
  }, []);

  // // GA 관련 토큰 저장
  // useEffect(() => {
  //   if ((!window.location.href || "").includes("local")) {
  //     ReactGA.initialize("G-Q1ME11RPRZ");
  //   }
  // }, []);

  const [userLang, setUserLang] = useRecoilState(userlang); // 서버에 저장되어 있는 언어정보 (KR,EN)
  const [language, setLanguage] = useRecoilState(languageIs); // 사이트 주소로 보여질 언어정보 (ko,en)
  /** 페이지 진입시 브라우저 언어 정보를 기반으로 사이트의 주소를 설정함 */
  // const languageBeforeLogin = (finalAddress) => {
  //   if (navigator.language === "ko") {
  //     if (!token) {
  //       // 브라우저 언어가 한국어 일때 한국어로 사이트를 보여준다.
  //       setLanguage("/ko");
  //     } else {
  //       // 브라우저 언어가 한국어가 아닐 때 영어로 사이트를 보여준다.
  //       setLanguage("/en");
  //     }
  //     navigate(finalAddress);
  //   }
  // };

  /** 로그인 이후 유저정보를 기반으로 사이트의 주소를 설정한다. */
  // const languageAfterLogin = (finalAddress) => {
  //   if (token) {
  //     if (userLang === "KR") {
  //       setLanguage("/ko");
  //     } else if (userLang === "EN") {
  //       setLanguage("/en");
  //     }
  //     navigate(finalAddress, {
  //       state,
  //     });
  //   }
  // };

  /** 헤더 또는 푸터의 언어변경을 통해 사이트 주소 변경 */
  // const languageChangeThroughHeader = () => {};

  // const tempAddress = location.pathname.split("/");
  // tempAddress.splice(0, 2, language);
  // let finalAddress = tempAddress.join("/") + location.search;

  /** 로그인이 되어있지 않은 경우 유저 브라우저 언어를 기반으로 페이지를 보여준다. */
  // useEffect(() => {
  //   if (!token) {
  //     languageBeforeLogin(finalAddress);
  //   }
  // }, []);

  // useEffect(() => {
  //   languageChangeThroughHeader();
  // }, [language]);

  /** 로그인이 되어있을 경우 유저 정보에 저장되어있는 언어정보로 페이지를 보여준다. */
  // useEffect(() => {
  //   if (token) {
  //     languageAfterLogin(finalAddress);
  //   }
  // }, [token, userLang, language]);

  // userLang 은 로그인 이후에만 영향을 미친다.
  // token 은 로그인 전 후 둘다 영향을 미친다.
  // language 또한 로그인 전 후 둘다 영향을 미친다.

  // 로그아웃 시에 사이트 언어 유지

  // Noah, 채널톡
  useEffect(() => {
    // 개발서버 조건 추가 -> 상용서버 채널톡 추가 시 조건 제거
    if(process.env.REACT_APP_BUILD_MODE === 'develope') {
      if(userData.hostEmail != null) {
        const memberId = userData.hostEmail;
        const secretKey = process.env.REACT_APP_CHANNELTALK_SECRETKEY;
        const hash = cryptoJS.HmacSHA256(memberId,
                                          cryptoJS.enc.Hex.parse(secretKey))
                              .toString();

        ChannelService.boot({
          "pluginKey": process.env.REACT_APP_CHANNELTALK_PLUGINKEY,
          "memberId": memberId,
          "memberHash": hash,
          "profile": {
            "name": userData.hostName,
            "email": userData.hostEmail,
          },
        });
      } else {
        ChannelService.boot({
          "pluginKey": process.env.REACT_APP_CHANNELTALK_PLUGINKEY,
        });
      }
      ChannelIO('updateUser', {"language" : "en"});
      ChannelIO("setPage", "Proctormatic");
    }
  }, [userData]);

  // 로그인 시 유저 정보 저장 함수를 불러온다.
  useEffect(() => {
    if (token) {
      getUserData(token);
      // 랜딩페이지면 myexam으로 이동
      if(location.pathname === '/') {
        navigate(`/myexam`);
      }
    }
  }, [token]);

  /** 토큰이 있다면 유저 데이터를 불러와서 저장하기 */
  const getUserData = async (token) => {
    const { data } = await customAxios.get(`${baseURL}/api/v1/hosts`);
    setUserLang(data?.getHost?.hostLanguage);
    setUserData(data?.getHost);
    return data;
  };

  /** 100vh 를 브라우저마다 고정시켜주자 */
  const setVh = () => {
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight}px`
    );
  };
  window.addEventListener('resize', setVh);
  setVh();

  /** 배너를 하루 이내에 지웠다면 다시 띄워주지 않기 */
  useEffect(() => {
    if (getCookie('banner')) {
      setBannerIs(false);
    } else {
      setBannerIs(true);
    }
  }, []);

  // useEffect(() => {
  //   if (language === "/ko") {
  //     i18n.changeLanguage("ko");
  //   } else {
  //     i18n.changeLanguage("en");
  //   }
  // }, [language]);

  /** 진입 시점에서 buildMode 에 따라 콘솔 여부 체크 */
  if (process.env.REACT_APP_BUILD_MODE === 'production') {
    // production에서만 사용할 수 없도록
    console = window.console || {};
    console.log = function no_console() {}; // console log 막기
    console.warn = function no_console() {}; // console warning 막기
    console.error = function () {}; // console error 막기
  }

  usePageScrollReset();

  return (
    <>
      <StyleSheetManager shouldForwardProp={(prop) => prop !== 'clicked'}>
        {browserModal && <BroswerPopUp setBrowserModal={setBrowserModal} />}
        <Header
          modalon={modalOn}
          banneris={banneris}
          setBannerIs={setBannerIs}
          setModalOn={setModalOn}
          setNav={setNav}
          setMyPageScroll={setMyPageScroll}
        />
        <AxiosInterceptor>
          <StBody modalon={modalOn} $banneris={banneris}>
            <div className="block" />
            <div className="bodyBlock">
              <OutletDataProvider>
                <Routes>
                  <Route
                    path={`/`}
                    restricted={false}
                    element={
                      <Landing
                        nav={nav}
                        setNav={setNav}
                        browserModal={browserModal}
                        banneris={banneris}
                        setMyPageScroll={setMyPageScroll}
                      />
                    }
                  />
                  {/* <Route
                    path={`/${language}`}
                    restricted={false}
                    element={
                      <Landing
                        nav={nav}
                        setNav={setNav}
                        browserModal={browserModal}
                        banneris={banneris}
                        setMyPageScroll={setMyPageScroll}
                      />
                    }
                  /> */}
                  <Route
                    path={`/createExamFinal`}
                    element={
                      <CreateExamSecondStep setMyPageScroll={setMyPageScroll} />
                    }
                    // element={<CreateExamFinal setMyPageScroll={setMyPageScroll} />}
                  >
                    <Route
                      path="createConfirm"
                      element={
                        <ChargeConfirm setMyPageScroll={setMyPageScroll} />
                      }
                    />
                    <Route
                      path="chargeRequest"
                      element={<CreditChargeRequest />}
                    />
                    <Route
                      path="chargeRequestConfirm"
                      element={<CreditChargeRequestConfirm />}
                    />
                  </Route>
                  <Route
                    path={`/creatingExam`}
                    element={<CreatingExam setMyPageScroll={setMyPageScroll} />}
                  />
                  <Route
                    path={`/editExam/:uid`}
                    element={<EditExam setMyPageScroll={setMyPageScroll} />}
                  >
                    <Route path="notAllowed" element={<EditNotAllowed />} />
                  </Route>
                  <Route path={`/test`} element={<Test />} />
                  <Route
                    path={`/myexam`}
                    element={
                      <PrivateRoute>
                        <MyExam
                          setModalOn={setModalOn}
                          setMyPageScroll={setMyPageScroll}
                        />
                      </PrivateRoute>
                    }
                  >
                    <Route
                      path=":preuid"
                      element={
                        <ExtraTestConfirm setMyPageScroll={setMyPageScroll} />
                      }
                    />
                  </Route>
                  <Route
                    path={`/report/:testId`}
                    element={
                      <PrivateRoute>
                        <Report 
                          setModalOn={setModalOn}
                          reportLocation={reportLocation}
                        />
                      </PrivateRoute>
                    }
                  ></Route>
                  <Route
                    path={`/reportSpecific/:testerId/:testId`}
                    element={
                      <PrivateRoute>
                        <ReportSpecific 
                          setModalOn={setModalOn}
                          reSpLocation={reSpLocation}
                        />
                      </PrivateRoute>
                    }
                  ></Route>
                  <Route
                    path={`/myPage`}
                    element={
                      <MyPage
                        setModalOn={setModalOn}
                        myPageScroll={myPageScroll}
                        setMyPageScroll={setMyPageScroll}
                      />
                    }
                  />
                  <Route
                    path={`/qna/:page`}
                    element={
                      <PrivateRoute>
                        <QnA setMyPageScroll={setMyPageScroll} />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={`/qnaDetail/:uid`}
                    element={
                      <PrivateRoute>
                        <QnADetail setModalOn={setModalOn} />
                      </PrivateRoute>
                    }
                  >
                    <Route path="commentEdit" element={<CommentEdit />} />
                  </Route>
                  <Route
                    path={`/notice/:page`}
                    element={<Notice setMyPageScroll={setMyPageScroll} />}
                  />
                  <Route
                    path={`/noticeDetail/:id`}
                    element={<NoticeDetail setModalOn={setModalOn} />}
                  />
                  <Route
                    path={`/support`}
                    element={
                      <PrivateRoute>
                        <Support />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path={`/servicePlan`}
                    element={<ServicePlan setModalOn={setModalOn} />}
                  />
                  <Route path={`/testPage`} element={<TestPage />} />
                  <Route
                    path={`/myPoint`}
                    element={<MyPoint setModalOn={setModalOn} />}
                  >
                    <Route
                      path={`tradeDetail`}
                      element={<CreditUseSpecific />}
                    />
                    <Route path={`couponEnter`} element={<PointCharge />} />
                    <Route path={`couponApplied`} element={<CouponApplied />} />
                  </Route>
                  <Route
                    path={`/myCredit`}
                    element={
                      <MyCredit
                        setModalOn={setModalOn}
                        setMyPageScroll={setMyPageScroll}
                      />
                    }
                  >
                    <Route path="policy" element={<Policy />} />
                    <Route path="creditInfo" element={<CreditInfo />} />
                    <Route
                      path="chargeRequest"
                      element={<CreditChargeRequest />}
                    />
                    <Route
                      path="chargeRequestConfirm"
                      element={<CreditChargeRequestConfirm />}
                    />
                    <Route
                      path="refund"
                      element={<RefundTemp setMyPageScroll={setMyPageScroll} />}
                    />
                    <Route
                      path={`tradeDetail`}
                      element={<CreditUseSpecific />}
                    />
                  </Route>
                  <Route
                    path={`/memberManage`}
                    element={
                      <MemberManage
                        setMyPageScroll={setMyPageScroll}
                        setModalOn={setModalOn}
                      />
                    }
                  >
                    <Route
                      path={`/memberManage/invite`}
                      element={<InviteFinish setModalOn={setModalOn} />}
                    />
                  </Route>
                  <Route
                    path={`/memberAccount/:uid`}
                    element={<MemberAccount />}
                  />
                  <Route path={`/invited`} element={<Invited />} />
                  <Route
                    path={`/refundList`}
                    element={<RefundList setMyPageScroll={setMyPageScroll} />}
                  />
                  <Route path={`/qnaEdit/:uid`} element={<QnAEdit />} />

                  <Route path={`/video`} element={<VideoPage />} />
                  <Route path="/*" element={<NotFound />} />
                  <Route path={'/api/v1/tests'} element={<TestUrlPage />} />
                  <Route path={`/request`} element={<Request />} />
                  <Route path={`complete`} element={<Complete />} />
                </Routes>
              </OutletDataProvider>
            </div>
            <Footer />
          </StBody>
          <ToastPopup
            toast={toastON}
            toastText={toastMESSAGE}
            update={toastUPDATE}
          />
        </AxiosInterceptor>
      </StyleSheetManager>
    </>
  );
}

const StBody = styled.div`
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 1440px;
    min-height: 100vh;
    padding-right: ${(props) => (props.modalOn ? '10px' : '')};
    box-sizing: border-box;
    .block {
      width: 100%;
      height: ${(props) => (props.$banneris ? '116px' : '76px')};
      /* height: 76px; */
    }
  }

  @media ${device.tabMob} {
    :root {
      --vh: 100%;
    }
    body {
      min-height: 675px;
      height: 100vh;
      height: var(--vh);
    }
    .block {
      width: 100%;
      height: ${(props) => (props.$banneris ? '108px' : '60px')};
    }
  }
`;

export default App;
