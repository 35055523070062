import React from "react";
import { styled } from "styled-components";
import { device } from "../../hooks/device";

interface TextProps {
  text?: string | number | null;
  pc_fontsize?: number;
  pc_fontcolor?: string;
  pc_fontFamily?: string;
  pc_lineheight?: number;
  pc_textAlign?: string;
  pc_margin?: string;
  pc_padding?: string;
  pc_width?: string;
  pc_height?: number;
  pc_border?: string;
  pc_border_radius?: number;
  fontsize?: number;
  fontcolor?: string;
  fontFamily?: string;
  lineheight?: number;
  textAlign?: string;
  margin?: string;
  padding?: string;
  width?: string;
  height?: number;
  border?: string;
  border_radius?: number;
  backgroundcolor?: string;
  textdeco?: string;
  cursor?: boolean;
  onclick?: () => void;
}

const TextLine: React.FC<TextProps> = (props) => {
  return (
    <StText
      fontsize={props.fontsize}
      fontFamily={props.fontFamily}
      fontcolor={props.fontcolor}
      textAlign={props.textAlign}
      margin={props.margin}
      padding={props.padding}
      lineheight={props.lineheight}
      width={props.width}
      height={props.height}
      border={props.border}
      border_radius={props.border_radius}
      pc_fontsize={props.pc_fontsize}
      pc_fontFamily={props.pc_fontFamily}
      pc_fontcolor={props.pc_fontcolor}
      pc_textAlign={props.pc_textAlign}
      pc_margin={props.pc_margin}
      pc_padding={props.pc_padding}
      pc_lineheight={props.pc_lineheight}
      pc_width={props.pc_width}
      pc_height={props.pc_height}
      pc_border={props.pc_border}
      pc_border_radius={props.pc_border_radius}
      backgroundcolor={props.backgroundcolor}
      textdeco={props.textdeco}
      cursor={props.cursor}
      onClick={props.onclick}
    >
      {props.text}
    </StText>
  );
};

const StText = styled.span<TextProps>`
  /* display: flex; */
  font-size: ${(props) => props.fontsize}px;
  font-family: ${(props) => props.fontFamily};
  color: ${(props) => props.fontcolor};
  line-height: ${(props) => props.lineheight}px;
  text-align: ${(props) => props.textAlign};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  border: ${(props) => props.border};
  border-radius: ${(props) => props.border_radius}px;
  background-color: ${(props) => props.backgroundcolor};
  box-sizing: border-box;
  text-decoration: ${(props) => props.textdeco};
  cursor: ${(props) => (props.cursor ? "pointer" : "default")};
  @media ${device.pc} {
    font-size: ${(props) => props.pc_fontsize}px;
    font-family: ${(props) => props.pc_fontFamily};
    color: ${(props) => props.pc_fontcolor};
    line-height: ${(props) => props.pc_lineheight}px;
    text-align: ${(props) => props.pc_textAlign};
    margin: ${(props) => props.pc_margin};
    padding: ${(props) => props.pc_padding};
    width: ${(props) => props.pc_width};
    height: ${(props) => props.pc_height}px;
    border: ${(props) => props.pc_border};
    border-radius: ${(props) => props.pc_border_radius}px;
  }
`;

export default TextLine;
