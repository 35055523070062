import React, { useState } from "react";
import ReportDown from "../components/ReportDown";
import { Outlet, useLocation, useParams } from "react-router-dom";

// styled components

// components
import ReportUp from "../components/ReportUp";
import styled from "styled-components";
import { device } from "../hooks/device";
import useGetReportAIEvent from '../hooks/useGetReportAIEvent';
// pages

// modals

// img

const Report = ({ setModalOn, reportLocation }) => {
  const [lang, setLang] = useState('en');
  const testId = useParams().testId;
  const { data: events } = useGetReportAIEvent({ testId, lang });

  return (
    <>
      <StReport>
        <div className="wrapper">
          <ReportUp 
            setModalOn={setModalOn} 
            events={events} 
            setLang={setLang} 
            testId={testId} 
            reportLocation={reportLocation} 
          />
          <ReportDown events={events} />
        </div>
      </StReport>
      <Outlet />
    </>
  );
};

const StReport = styled.div`
  @media ${device.tabMob} {
    width: 100%;
    margin: 0 auto;
    background-color: #f0f1f2;
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
    }
  }
  @media ${device.pc} {
    width: 100%;
  }
`;

export default Report;
