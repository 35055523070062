import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import TextLine from "../atomic/atom/TextLine";

const QnaTag = ({ category, title, date, isAnswered }) => {
  const { t } = useTranslation();
  const baseLang = "customer.h2_1_1";

  return (
    <DivTwoPartImprove
      display="grid"
      padding="16px 24px"
      pc_grid_template_column="160px auto 160px"
      pc_padding="0px"
      cursor="pointer"
    >
      <DivTwoPartImprove
        font_size={12}
        lineheight={18}
        pc_padding="14px 24px"
        font_color="#55595F"
        pc_font_size={16}
        pc_lineheight={24}
        pc_display="flex"
        justify="center"
        align_items="center"
      >
        {category}
      </DivTwoPartImprove>
      <DivTwoPartImprove
        display="flex"
        align_items="center"
        justify="space-between"
        margin="6px 0 2px"
        pc_margin="0px"
        pc_padding="14px 24px"
      >
        <TextLine
          fontsize={16}
          lineheight={24}
          fontcolor="#1e2222"
          text={title}
        />
        {isAnswered && (
          <DivTwoPartImprove
            display="flex"
            justify="center"
            align_items="center"
            border_radius={4}
            background_color="#444"
            font_color="white"
            font_size={10}
            lineheight={15}
            padding="3px 8px"
            pc_height={24}
          >
            {t(`${baseLang}.answered`)}
          </DivTwoPartImprove>
        )}
      </DivTwoPartImprove>
      <DivTwoPartImprove
        font_size={12}
        lineheight={20}
        font_color="#55595f"
        pc_padding="14px 24px"
        pc_font_size={16}
        pc_lineheight={24}
        pc_display="flex"
        justify="center"
        align_items="center"
      >
        {dayjs(date).format("M/D/YYYY")}
      </DivTwoPartImprove>
    </DivTwoPartImprove>
  );
};

export default QnaTag;
