import React from "react";
import styled from "styled-components";
import blueCheck from "../img/arrow2.svg";
import LandingFour from "../img/LandingFour.png";
import LandingMobileFour from "../img/LandingMobileFour.png";
import { device } from "../hooks/device";
import { useRecoilValue } from "recoil";
import { bannerOn } from "../atoms";
import { HomeOneStyleProps } from "../interface/styleInterface";
import TextLine from "../atomic/atom/TextLine";
import { useTranslation } from "react-i18next";

const HomeFour = () => {
  const banneris = useRecoilValue(bannerOn);
  const { t } = useTranslation();
  const baseLang: string = "home.a1.firstPage.fourth";

  return (
    <StPage $banneris={banneris}>
      <div className="wrapper">
        <div className="leftBox">
          <div className="firstPart">
            <TextLine
              text={t(`${baseLang}.upTitleOne`)}
              lineheight={21}
              pc_lineheight={37}
              fontFamily="medium"
            />
            <TextLine
              text={t(`${baseLang}.upTitleTwo`)}
              lineheight={21}
              pc_lineheight={37}
              fontFamily="medium"
            />
          </div>
          <div className="middlePart">
            <div className="secondBlock">
              <TextLine text={t(`${baseLang}.titleOne`)} fontFamily="bold" />
              <TextLine text={t(`${baseLang}.titleTwo`)} fontFamily="bold" />
            </div>

            <div className="thirdBlock">
              <div className="lineForPlan">
                <img src={blueCheck} />
                <TextLine text={t(`${baseLang}.list.one`)} />
              </div>
              <div className="lineForPlan">
                <img src={blueCheck} />
                <TextLine text={t(`${baseLang}.list.two`)} />
              </div>
              <div className="lineForPlan">
                <img src={blueCheck} />
                <TextLine text={t(`${baseLang}.list.three`)} />
              </div>
            </div>
          </div>
        </div>
        <img src={LandingFour} className="rightImgBox" />
        <div className="mobileImgBox">
          <img src={LandingMobileFour} className="rightMobileImgBox" />
        </div>
      </div>
    </StPage>
  );
};

const StPage = styled.div<HomeOneStyleProps>`
  width: 100%;
  /* background: radial-gradient(189.33% 88.48% at 17.27% 20.89%, #0D2256 0%, #070F21 100%); */
  display: flex;
  align-items: center;
  .wrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
    width: 100%;
    overflow: hidden;
    .leftBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      grid-row: 1;
      grid-column: 2/8;
      .firstPart {
        display: flex;
        flex-direction: column;
        font-size: 25px;
        font-family: "medium";
        line-height: 37px;
        color: #55a2ff;
      }
      .secondBlock {
        display: grid;
        font-family: "bold";
        line-height: 130%;
        color: white;
      }
      .thirdBlock {
        display: grid;
        flex-direction: column;
        gap: 10px;
        color: white;
        .lineForPlan {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 8px;
          font-family: "medium";
          line-height: 36px;
          font-size: 24px;
          img {
            width: 38px;
            height: 38px;
          }
        }
      }
    }
    .rightImgBox {
      grid-row: 1;
      grid-column: 6/11;
      height: ${(props) =>
        props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
      padding-left: 100px;
    }
  }
  @media ${device.pc} {
    overflow: hidden;
    .wrapper {
      height: ${(props) =>
        props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
      .leftBox {
        .secondBlock {
          margin: 22px 0 64px;
          font-size: 62px;
        }
      }
      .rightMobileImgBox {
        display: none;
      }
    }
    .onlyForMobile {
      display: none !important;
    }
  }
  @media ${device.tabMob} {
    height: ${(props) =>
      props.$banneris ? "calc(100vh - 108px)" : "calc(100vh - 60px)"};
    min-height: 675px;
    .wrapper {
      display: flex;
      flex-direction: column;
      height: ${(props) =>
        props.$banneris ? "calc(100vh - 108px)" : "calc(100vh - 60px)"};
      min-height: 675px;
      .leftBox {
        justify-content: center;
        .firstPart {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-family: "regular";
          line-height: 18px;
          font-size: 14px;
          margin-top: 48px;
        }
        .middlePart {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: ${(props) =>
            props.$banneris ? "calc(100vh - 388px)" : "calc(100vh - 320px)"};
          min-height: 415px;
          .secondBlock {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-family: "medium";
            line-height: 130%;
            margin-bottom: 38px;
          }
          .thirdBlock {
            width: 331px;
            margin: 0 auto;
            .lineForPlan {
              font-size: 15px;
              font-family: "medium";
              line-height: 22px;
              gap: 4.3px;
              img {
                width: 21.5px;
                height: 21.5px;
              }
            }
          }
        }
      }
      .mobileImgBox {
        height: 194px;
        .rightMobileImgBox {
          width: 172.5px;
          height: 131px;
          margin-top: 19px;
        }
      }
      .rightImgBox {
        display: none;
      }
    }
  }
  @media ${device.tablet} {
    .onlyForMobile {
      display: none !important;
    }
    .secondBlock {
      font-size: 36px;
    }
  }
  @media ${device.mobile} {
    .secondBlock {
      font-size: 30px;
    }
  }
`;

export default HomeFour;
