import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import arrow from "../img/arrow.svg";
import trash from "../img/trashOn.svg";
import downArrow from "../img/downArrow.svg";
import useGetMemberUse from "../hooks/useGetMemberUse";
import { useNavigate, useParams } from "react-router-dom";
import useGetMemberCreditUseList from "../hooks/useGetMemberCreditUseList";
import dayjs from "dayjs";
import AbandonMember from "../modal/MemberAccountAbandonMember";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import customAxios from "../api/handler";
import useToast from "../hooks/useToast";
import InfoModal from "../modal/InfoModal";
import getApi from "../api/getApi";
import patchApi from "../api/patchApi";
import { useTranslation } from "react-i18next";
import postApi from "../api/postApi";

const MemberAccount = () => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g7";
  const baseLang2 = "servicePlan.g7_3";
  const [lang, setLang] = useState("kr");
  const [type, setType] = useState("all");
  const [showType, setShowType] = useState("전체");
  const showToast = useToast();
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  // modal
  const [abandonModal, setAbandonModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [onGoingTestLeftModal, setOnGoingTestLeftModal] = useState(false);

  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const uid = useParams().uid;
  const { data } = useGetMemberUse({ uid });
  const { data: useList } = useGetMemberCreditUseList({ uid, lang, type });

  useEffect(() => {
    setName(data?.childInformation?.hostName);
    setEmail(data?.childInformation?.hostEmail);
  }, [data]);

  useEffect(() => {
    if (type === "all") {
      setShowType(t(`${baseLang}.all`));
    } else if (type === "charge") {
      setShowType(t(`${baseLang}.charge`));
    } else if (type === "use") {
      setShowType(t(`${baseLang}.use`));
    } else if (type === "cancel") {
      setShowType(t(`${baseLang}.cancel`));
    }
    // else if (type === "refund") {
    //   setShowType("환불");
    // } else {
    //   setShowType("환불불가");
    // }
  }, [type]);

  const disconnect = () => {
    postApi
      .postDisconnectProgressing({
        hostEmail: data?.childInformation?.hostEmail,
      })
      .then(() => {
        patchApi
          .disconnectMemeber({ hostEmail: data?.childInformation?.hostEmail })
          // customAxios
          //   .patch(`/api/v1/enterprises/manager/disconnectMember`, {
          //     hostEmail: data?.childInformation?.hostEmail,
          //   })
          .then(({ data }) => {
            navigate(`/memberManage`);
            showToast({
              message: `${t(`errorCode.disconnectMember1`)} ${name} ${t(
                `errorCode.disconnectMember2`
              )} (${email})`,
            });
          })
          .catch(({ response }) => {
            if (response.data.errorCode === "ENTERPRISE-008") {
              showToast({
                message: t(`errorCode.unknown_server_error`),
              });
            }
          });
      })
      .catch(({ response }) => {
        const errorCode = response.data.errorCode;
        if (errorCode === "ENTERPRISE-010" || errorCode === "TIMEZONE-007") {
          setOnGoingTestLeftModal(true);
        } else if (
          errorCode === "HOST-ROLE-001" ||
          errorCode === "HOST-ROLE-005"
        ) {
          showToast({ message: "관리자만 이용할 수 있어요." });
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  const closeModalOnGoing = () => {
    setOnGoingTestLeftModal(false);
  };

  return (
    <>
      {abandonModal && (
        <AbandonMember
          disconnect={disconnect}
          setAbandonModal={setAbandonModal}
        />
      )}
      {onGoingTestLeftModal && (
        <InfoModal
          close={closeModalOnGoing}
          title={t(`${baseLang2}.title`)}
          textOne={t(`${baseLang2}.body1`)}
          textTwo={t(`${baseLang2}.body2`)}
        />
      )}
      <StMemberAccount>
        <div className="bodyPart">
          <div className="headerBox">
            <div className="headerLeft">
              <img
                src={arrow}
                onClick={() => navigate(`/memberManage`)}
                alt=""
              />
              <div>{t(`${baseLang}.title`)}</div>
            </div>
            <div
              className="abandonButton"
              onClick={() => {
                setAbandonModal(true);
              }}
            >
              <img src={trash} alt="" />
              <span className="onlyForPc">{t(`${baseLang}.disconnect`)}</span>
            </div>
          </div>
          <div className="memberInfoBox">
            <div className="memberInfoEachItem">
              <div className="infoKey">{t(`${baseLang}.name`)}</div>
              <div className="infoValue">
                {data?.childInformation?.hostName}
              </div>
            </div>
            <div className="memberInfoEachItem">
              <div className="infoKey">{t(`${baseLang}.email`)}</div>
              <div className="infoEmail">
                {data?.childInformation?.hostEmail}
              </div>
            </div>
            <div className="memberInfoEachItem">
              <div className="infoKey">{t(`${baseLang}.company`)}</div>
              <div className="infoValue">
                {data?.childInformation?.hostGroupName}
              </div>
            </div>
            <div className="memberInfoTable">
              <div className="tableEachItem">
                <div className="tableItemKey">{t(`${baseLang}.total`)}</div>
                <div className="tableItemValue">
                  {data?.childInformation?.hostUsageAmounts}
                  <span>C</span>
                </div>
              </div>
              <div className="greyRow onlyForMobile" />
              <div className="greyColumn onlyForPc" />
              <div className="downItems">
                <div className="tableEachItem">
                  <div className="tableItemKey">
                    {t(`${baseLang}.complete`)}
                  </div>
                  <div className="tableItemValue">
                    {data?.childInformation?.success}
                  </div>
                </div>
                <div className="greyColumn" />
                <div className="tableEachItem">
                  <div className="tableItemKey">
                    {t(`${baseLang}.scheduled`)}
                  </div>
                  <div className="tableItemValue">
                    {data?.childInformation?.pending}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="memberUseBox">
            <div className="memberUseHeader">
              <div className="headerTitle">{t(`${baseLang}.history`)}</div>
              <div className="headerFilter">
                <div
                  className="filterShow"
                  onClick={() => setFilterModal(true)}
                >
                  {showType}
                  <img src={downArrow} alt="" />
                </div>
                {filterModal && (
                  <>
                    <div
                      className="filterBack"
                      onClick={() => {
                        setFilterModal(false);
                      }}
                    />
                    <div className="filterList">
                      <div
                        className="filterEachItem"
                        onClick={() => {
                          setType("all");
                          setFilterModal(false);
                        }}
                      >
                        {t(`${baseLang}.all`)}
                      </div>
                      <div
                        className="filterEachItem"
                        onClick={() => {
                          setType("charge");
                          setFilterModal(false);
                        }}
                      >
                        {t(`${baseLang}.charge`)}
                      </div>
                      <div
                        className="filterEachItem"
                        onClick={() => {
                          setType("use");
                          setFilterModal(false);
                        }}
                      >
                        {t(`${baseLang}.use`)}
                      </div>
                      <div
                        className="filterEachItem"
                        onClick={() => {
                          setType("cancel");
                          setFilterModal(false);
                        }}
                      >
                        {t(`${baseLang}.cancel`)}
                      </div>
                      {/* <div
                        className="filterEachItem"
                        onClick={() => {
                          setType("refund");
                          setFilterModal(false);
                        }}
                      >
                        환불
                      </div>
                      <div
                        className="filterEachItem"
                        onClick={() => {
                          setType("extinct");
                          setFilterModal(false);
                        }}
                      >
                        환불불가
                      </div> */}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="onlyForPc listTag">
              <div className="thickLine" />
              <div>
                <span>{t(`${baseLang}.type`)}</span>
                <span>{t(`${baseLang}.detail`)}</span>
                <span>{t(`${baseLang}.credit`)}</span>
              </div>
              <div className="blackLine" />
            </div>
            {useList?.childTradeList?.length > 0 ? (
              <div className="creditUseList">
                {useList?.childTradeList?.map((value, index) => {
                  let temp = t(`${baseLang}.use`);
                  switch (value.paymentsCreditsType) {
                    case "charge":
                      temp = t(`${baseLang}.charge`);
                      break;
                    case "use":
                      temp = t(`${baseLang}.use`);
                      break;
                    case "cancel":
                      temp = t(`${baseLang}.cancel`);
                      break;
                    // case "refund":
                    //   temp = "환불";
                    //   break;
                    // case "extinct":
                    //   temp = "환불불가";
                    //   break;
                    default:
                      break;
                  }
                  return (
                    <div key={index}>
                      <div className="creditUseEachItem" key={index}>
                        <div className="creditRow">
                          <div className="creditType">{temp}</div>
                          <div className="creditDown">
                            <div className="creditDetail">
                              {value?.paymentsCreditsTypeDetail}
                            </div>
                            <div className="creditDate">
                              {value?.paymentsCreditsTradeAt}{" "}
                              {value?.hostChildName}
                            </div>
                          </div>
                        </div>
                        <div
                          className="creditAmount"
                          style={
                            value.paymentsCreditsType === "use"
                              ? { color: "#c83b38" }
                              : { color: "#2276DC" }
                          }
                        >
                          {value?.paymentsCreditsAmount}
                          <span>C</span>
                        </div>
                      </div>
                      {index + 1 !== useList?.childTradeList?.length && (
                        <div
                          className="border onlyForMobile"
                          style={{ borderBottom: "1px solid #d9d9d9" }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="nothing onlyForPc">No History Found</div>
            )}
          </div>
          {(useList?.childTradeList.length === 0 ||
            !useList?.childTradeList.length) && (
            <div className="nothing onlyForMobile">No History Found</div>
          )}
        </div>
      </StMemberAccount>
    </>
  );
};

const StMemberAccount = styled.div`
  width: 100%;
  background-color: #f0f1f2;
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerLeft {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 12px;
      font-size: 19px;
      font-family: "bold";
      line-height: 28px;
      img {
        width: 18px;
        cursor: pointer;
      }
    }
    img {
      cursor: pointer;
    }
  }
  .memberInfoBox {
    display: flex;
    flex-direction: column;
    width: calc(100% - 48px);
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 10px 24px 24px;
    box-sizing: border-box;
    .memberInfoEachItem {
      display: flex;
      .infoKey {
        font-size: 13px;
        line-height: 19px;
        color: #55595f;
      }
      .infoValue {
        font-family: "medium";
        line-height: 24px;
      }
      .infoEmail {
        color: #2276dc;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .memberInfoTable {
      display: flex;
      width: 100%;
      padding: 16px 12px;
      border: 0.6px solid #bdbdbd;
      border-radius: 8px;
      margin: 0 auto;
      box-sizing: border-box;
      .greyRow {
        width: 100%;
        height: 1px;
        background-color: #d6d9dd;
      }
      .greyColumn {
        width: 1px;
        height: 53px;
        background-color: #d6d9dd;
      }
      .downItems {
        display: flex;
        justify-content: center;
      }
      .tableEachItem {
        display: grid;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        align-items: center;
        gap: 7px;
        .tableItemKey {
          font-size: 12px;
          font-family: "medium";
          line-height: 18px;
          color: #818181;
        }
        .tableItemValue {
          font-size: 19px;
          font-family: "bold";
          line-height: 28px;
          span {
            margin-left: 2px;
            font-size: 14px;
            font-family: "regular";
            line-height: 24px;
          }
        }
      }
    }
  }
  .memberUseBox {
    display: flex;
    flex-direction: column;
    .memberUseHeader {
      display: flex;
      justify-content: space-between;
      padding: 12px 24px 5px;
      margin-top: 24px;
      .headerTitle {
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        color: #909090;
      }
      .headerFilter {
        position: relative;
        .filterShow {
          display: grid;
          grid-template-columns: auto auto;
          align-items: center;
          gap: 9.86px;
          font-size: 14px;
          line-height: 21px;
          cursor: pointer;
        }
        .filterBack {
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 51;
        }
        .filterList {
          position: absolute;
          z-index: 52;
          top: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          background: #ffffff;
          box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          .filterEachItem {
            width: 168px;
            height: 49px;
            box-sizing: border-box;
            padding: 15px 24px;
            font-family: "regular";
            font-size: 16px;
            line-height: 19px;
            cursor: pointer;
          }
        }
      }
    }
    .creditUseList {
      .creditUseEachItem {
        .creditType {
          font-size: 12px;
          line-height: 18px;
          color: #55595f;
        }
        .creditDown {
          display: flex;
          flex-direction: column;
          .creditDetail {
            font-size: 16px;
            font-family: "medium";
            line-height: 24px;
          }
          .creditDate {
            font-size: 12px;
            font-family: "light";
            line-height: 20px;
          }
        }
        .creditAmount {
          font-size: 16px;
          font-family: "semibold";
          line-height: 19px;
          color: #c83b38;
          span {
            font-size: 13px;
            font-family: "regular";
            line-height: 19px;
            margin-left: 2px;
          }
        }
      }
      .nothing {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 151px;
        font-size: 16px;
        font-family: "regular";
        line-height: 19px;
        color: #bdbdbd;
      }
    }
  }
  .nothing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 151px;
    font-size: 16px;
    font-family: "regular";
    line-height: 19px;
    color: #bdbdbd;
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none !important;
    }
    .bodyPart {
      padding-bottom: 38px;
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
      .headerBox {
        margin-bottom: 20px;
        padding: 24px 24px 16px;
      }
      .memberInfoBox {
        .memberInfoEachItem {
          display: grid;
          flex-direction: column;
          gap: 5px;
          padding: 14px 0;
          .infoValue {
            padding: 5px 0;
          }
          .infoEmail {
            padding: 5px 0;
          }
        }
      }
      .memberInfoTable {
        display: grid;
        flex-direction: column;
        gap: 16px;
        .greyRow {
        }
        .greyColumn {
        }
        .downItems {
          display: grid;
          justify-content: center;
          grid-template-columns: 1fr 1px 1fr;
        }
        .tableEachItem {
          .tableItemKey {
          }
          .tableItemValue {
          }
        }
      }
      .memberUseBox {
        .creditUseList {
          display: flex;
          flex-direction: column;
          filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.08));
          border-radius: 24px;
          background-color: #ffffff;
          .creditUseEachItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px 24px;
            box-sizing: border-box;
            .creditRow {
              display: grid;
              flex-direction: column;
              gap: 6px;
            }
            .creditDown {
              display: grid;
              flex-direction: column;
              gap: 2px;
            }
          }
        }
      }
    }
  }
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    width: 100%;
    .onlyForMobile {
      display: none !important;
    }
    .bodyPart {
      width: 1228px;
      margin: 80px auto;
      .headerBox {
        margin-bottom: 60px;
        .headerLeft {
          display: grid;
          grid-template-columns: auto auto;
          gap: 19px;
          img {
            width: 28px;
            height: 28px;
          }
          div {
            font-size: 28px;
            font-family: "bold";
            line-height: 41px;
          }
        }
        .abandonButton {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 163px;
          height: 41px;
          padding: 8.5px 22px;
          box-sizing: border-box;
          background: #ffffff;
          border-radius: 24px;
          cursor: pointer;
        }
      }
      .memberInfoBox {
        display: grid;
        width: 865px;
        padding: 60px;
        gap: 12px;
        .memberInfoEachItem {
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          gap: 100px;
          .infoKey {
            width: 100px;
            font-size: 18px;
            line-height: 27px;
            color: black;
          }
          .infoValue {
            font-family: "medium";
            font-size: 18px;
            line-height: 27px;
          }
          .infoEmail {
            font-family: "medium";
            font-size: 18px;
            line-height: 27px;
          }
        }
        .memberInfoTable {
          display: grid;
          grid-template-columns: 248px 1px 497px;
          padding: 28px 0;
          margin-top: 24px;
          .greyRow {
          }
          .greyColumn {
            height: 59px;
          }
          .downItems {
            display: grid;
            grid-template-columns: 248px 0.5px 248px;
          }
          .tableEachItem {
            .tableItemKey {
              font-size: 13px;
              font-family: "medium";
              line-height: 19px;
            }
            .tableItemValue {
              font-size: 22px;
              font-family: "bold";
              line-height: 33px;
              span {
                font-size: 15px;
                line-height: 28px;
                margin-left: 3px;
              }
            }
          }
        }
      }
      .memberUseBox {
        width: 865px;
        background: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        box-sizing: border-box;
        border-radius: 12px;
        padding: 60px;
        margin: 60px auto 80px;
        .memberUseHeader {
          margin: 0 0 16px;
          padding: 0;
          .headerTitle {
            font-size: 18px;
            font-family: "semibold";
            line-height: 27px;
            color: black;
          }
          .headerFilter {
            .filterShow {
              font-family: "medium";
              font-size: 16px;
            }
          }
        }
        .listTag {
          .thickLine {
            width: 100%;
            height: 4px;
            background-color: black;
          }
          div {
            display: flex;
            justify-content: space-between;
            span {
              width: 160px;
              padding: 16.5px 0;
              text-align: center;
              font-size: 16px;
              font-family: "medium";
              line-height: 19px;
            }
          }
          .blackLine {
            width: 100%;
            height: 1px;
            background-color: black;
          }
        }
        .creditUseList {
          display: flex;
          flex-direction: column;
          .creditUseEachItem {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #d9d9d9;
            box-sizing: border-box;
            .creditRow {
              display: flex;
              padding: 14.5px 0;
              .creditType {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 160px;
                height: 47px;
                font-size: 16px;
                line-height: 19px;
              }
              .creditDown {
                display: grid;
                flex-direction: column;
                gap: 4px;
                .creditDetail {
                  font-size: 16px;
                  font-family: "medium";
                  line-height: 24px;
                }
                .creditDate {
                  font-size: 14px;
                  font-family: "regular";
                  line-height: 20px;
                  color: #55595f;
                }
              }
            }
            .creditAmount {
              line-height: 24px;
              padding: 0 25px;
              span {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
`;

export default MemberAccount;
