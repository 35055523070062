import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import { useTranslation } from "react-i18next";

const InviteFinish = ({ setFinished, setInviteOn }) => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g8_4";
  const navigate = useNavigate();

  const goBack = () => {
    // setFinished(false);
  };

  // console.log(setFinished);

  useEffect(() => {
    window.addEventListener("popstate", goBack);
  }, []);

  return (
    <>
      <ModalBackD />
      <StInviteFinish>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        <div className="inviteBody">{t(`${baseLang}.body`)}</div>
        <div
          className="inviteButton"
          onClick={() => {
            navigate(`/memberManage`);
            setFinished(false);
            setInviteOn(false);
          }}
        >
          {t(`${baseLang}.button`)}
        </div>
      </StInviteFinish>
    </>
  );
};

const StInviteFinish = styled.div`
  position: fixed;
  bottom: 16px;
  z-index: 53;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  @media ${device.pc} {
    bottom: 50%;
    left: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, 50%);
    .inviteBody {
      margin: 48px 0;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .inviteButton {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #000000;
      border-radius: 43px;
      width: 204px;
      height: 52px;
      margin: 0 auto;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    left: 50%;
    width: 344px;
    /* height: 254px; */
    border-radius: 24px;
    padding: 24px;
    transform: translate(-50%, 0px);
    .inviteButton {
      color: #0072de;
      margin: 28.5px auto 0;
      cursor: pointer;
    }
    .inviteBody {
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
    }
  }
  .inviteTitle {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

const StBack = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
`;

export default InviteFinish;
