import ModalPageTemplate from "../../../atomic/template/ModalPageTemplate";
import { ModalHeader } from "../../../components/Style";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { GetTradeDetail } from "../../../interface/apiInterface";
import { useTranslation } from "react-i18next";

interface OutletData {
  tradeId: number;
  tradeType: string;
  tradeData: GetTradeDetail["detail"];
}

const CreditUseSpecific = () => {
  // const testId = useParams().testId;
  const { t } = useTranslation();
  const baseLang = "servicePlan.g5_1_7";
  const outletData = useOutletContext<OutletData>();

  // const { data } = useGetTradeDetail({
  //   tradeId: outletData.tradeId,
  //   type: outletData.tradeType,
  // });

  const [creditText, setCreditText] = useState<string>(t(`${baseLang}.list1`));
  const [creditColor, setCreditColor] = useState<string>("#000");

  /** 타입에 따라 나타내줄 문구 정하기 */
  const creditTextChoose = () => {
    switch (outletData.tradeType) {
      case "use":
        setCreditText(t(`${baseLang}.list1`));
        break;
      case "charge":
        setCreditText(t(`${baseLang}.list2`));
        break;
      case "extinct":
        setCreditText(t(`${baseLang}.list3`));
        break;
      case "cancel":
        setCreditText(t(`${baseLang}.list4`));
        break;
      case "refund":
        setCreditText(t(`${baseLang}.list5`));
        break;
      case "withdraw":
        setCreditText(t(`${baseLang}.list6`));
        break;
    }
  };

  /** 타입에 따라 크레딧 색상 정하기 */
  const creditColorChoose = () => {
    switch (outletData.tradeType) {
      case "withdraw":
      case "refund":
      case "use":
      case "recovery":
        setCreditColor("#C83B38");
        break;
      case "charge":
      case "cancel":
        setCreditColor("#2276DC");
        break;
      case "extinct":
        setCreditColor("#000");
        break;
    }
  };

  useEffect(() => {
    creditTextChoose();
    creditColorChoose();
    if (!outletData.tradeId) {
      // navigate(`/myCredit`);
      window.history.back();
    }
  }, []);

  return (
    <ModalPageTemplate modalBackground="#F0F1F2">
      <ModalHeader
        title={outletData.tradeData?.paymentsCreditsTypeText}
        cancelIs={false}
        off={false}
      />
      <DivTwoPartImprove
        display="grid"
        margin="20px 0 36px 0"
        pc_margin="48px 0 40px 0"
      >
        <DivTwoPartImprove
          display="grid"
          justify="center"
          font_color="#818181"
          font_size={12}
          pc_font_size={13}
          lineheight={18}
          pc_lineheight={24}
          text_align="center"
        >
          <TextLine
            text={dayjs(outletData.tradeData?.paymentsTradeAt).format(
              "M/D/YYYY HH:mm"
            )}
          />
          {outletData.tradeData?.paymentsCreditsExpiredAt && (
            <TextLine
              text={
                "(Will be expired on " +
                dayjs(outletData.tradeData?.paymentsCreditsExpiredAt).format(
                  "M/D/YYYY HH:mm)"
                )
              }
            />
          )}
          {outletData.tradeData?.hostName && (
            <TextLine text={`${outletData.tradeData?.hostName}`} />
          )}
        </DivTwoPartImprove>
        <DivTwoPartImprove
          display="flex"
          justify="center"
          margin="10px 0 4px"
          pc_margin="16px 0 6px"
        >
          <TextLine
            text={outletData.tradeData?.totalChargeCredits}
            fontFamily="extrabold"
            fontcolor={creditColor}
            fontsize={26}
            pc_fontsize={28}
            lineheight={38}
          />{" "}
          <TextLine
            lineheight={32}
            fontsize={16}
            pc_fontsize={22}
            fontFamily="bold"
            pc_fontFamily="regular"
            pc_lineheight={36}
            text="C"
            margin="auto 0 0 4px"
          />
        </DivTwoPartImprove>
        <TextLine
          text={creditText}
          fontsize={14}
          lineheight={21}
          margin="0 auto"
        />
      </DivTwoPartImprove>
      <DivTwoPartImprove
        display="grid"
        grid_template_column="1fr 1px 1fr"
        pc_border="0.6px solid #BDBDBD"
        border_radius={8}
        background_color="white"
        box_shadow="0px 5px 6px 0px rgba(0, 0, 0, 0.08);"
        pc_box_shadow="none"
        padding="16px 0"
        pc_padding="28px 0"
      >
        <DivTwoPartImprove
          display="grid"
          gap={7}
          justify="center"
          align_items="center"
        >
          <TextLine
            text={t(`${baseLang}.refundable`)}
            fontsize={12}
            pc_fontsize={13}
            lineheight={18}
            fontFamily="medium"
            fontcolor="#818181"
          />
          <DivTwoPartImprove display="flex" justify="center">
            <TextLine
              fontsize={19}
              fontFamily="bold"
              pc_fontsize={22}
              lineheight={28}
              text={outletData.tradeData?.refundableCredits}
            />
            <TextLine
              fontsize={14}
              pc_fontsize={15}
              lineheight={24}
              text="C"
              margin="auto 0 0 2px"
              pc_margin="auto 0 0 3px"
            />
          </DivTwoPartImprove>
        </DivTwoPartImprove>
        <DivTwoPartImprove width="1px" height={53} background_color="#D6D9DD" />
        <DivTwoPartImprove
          display="grid"
          gap={7}
          justify="center"
          align_items="center"
        >
          <TextLine
            text={t(`${baseLang}.nonRefundable`)}
            fontsize={12}
            pc_fontsize={13}
            lineheight={18}
            fontFamily="medium"
            fontcolor="#818181"
          />
          <DivTwoPartImprove display="flex" justify="center">
            <TextLine
              fontsize={19}
              fontFamily="bold"
              pc_fontsize={22}
              lineheight={28}
              text={outletData.tradeData?.notRefundableCredits}
            />
            <TextLine
              fontsize={14}
              pc_fontsize={15}
              lineheight={24}
              text="C"
              margin="auto 0 0 2px"
              pc_margin="auto 0 0 3px"
            />
          </DivTwoPartImprove>
        </DivTwoPartImprove>
      </DivTwoPartImprove>
      <DivTwoPartImprove
        display="grid"
        gap={6}
        font_size={11}
        pc_font_size={12}
        font_color="#444"
        lineheight={16}
        margin="10px 0 48px"
      >
        <TextLine text={t(`${baseLang}.info1`)} />
        <TextLine text={t(`${baseLang}.info2`)} />
      </DivTwoPartImprove>
      {outletData.tradeData?.testName && (
        <DivTwoPartImprove
          display="grid"
          margin="36px 0 0"
          pc_margin="48px 0 0"
        >
          <TextLine
            text={t(`${baseLang}.paid`)}
            fontsize={14}
            fontFamily="medium"
            fontcolor="#909090"
            lineheight={21}
            pc_fontsize={18}
            pc_lineheight={27}
            pc_fontcolor="#000"
            pc_padding="0"
            padding="12px 0 5px"
          />
          <DivTwoPartImprove
            display="none"
            pc_display="flex"
            width="100%"
            height={0.5}
            background_color="#BDBDBD"
            margin="6px 0 28px 0"
          />

          <DivTwoPartImprove
            display="grid"
            box_shadow=" 0px 5px 6px 0px rgba(0, 0, 0, 0.08)"
            background_color="#fff"
            border_radius={24}
            pc_border_radius={12}
            padding="16px 24px"
            width="calc(100% + 48px)"
            margin="0 0 0 -24px"
            pc_width="100%"
            pc_margin="0"
            pc_box_shadow="none"
            pc_border="0.6px solid #BDBDBD"
            // onclick={() => {
            //   navigate(`/myexam/${outletData.tradeData.testUuid}`);
            // }}
          >
            <TextLine
              text={outletData.tradeData?.testName}
              fontFamily="medium"
              lineheight={24}
            />
            <TextLine
              text={outletData.tradeData?.testTime}
              fontsize={12}
              fontFamily="light"
              lineheight={20}
            />
          </DivTwoPartImprove>
        </DivTwoPartImprove>
      )}
    </ModalPageTemplate>
  );
};

export default CreditUseSpecific;
