import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const NoticeTag = ({ number, importance, title, date, category }) => {
  const { t } = useTranslation();
  const baseLang = "customer.h2_2_2";
  const [backGroundColor, setBackGroundColor] = useState();
  const [color, setColor] = useState();

  useEffect(() => {
    if (category === "General") {
      setBackGroundColor("#F1F1F1");
      setColor("#888888");
    } else if (category === "Administrator") {
      setBackGroundColor("#FFEDDC");
      setColor("#ED893E");
    } else if (category === "Test-taker") {
      setBackGroundColor("#E2EEFF");
      setColor("#1A75FF");
    }
  }, [category]);

  return (
    <StTag
      color={color}
      backGroundColor={backGroundColor}
      importance={importance}
      category={category}
    >
      <div className="firstTag">{number}</div>
      <div>
        {importance ? (
          <div className="subjectEmergency">{t(`${baseLang}.important`)}</div>
        ) : (
          <div className="subjectM">{category}</div>
        )}
      </div>
      <div className="secondTagS">
        <div className="noticeTitle">{title}</div>
        {importance ? (
          <div className="categoryPart">
            <div className="importantNotice">{t(`${baseLang}.important`)}</div>
          </div>
        ) : (
          <div className="categoryPart">
            <div className="subject">{category}</div>
          </div>
        )}
      </div>
      <div className="thirdTag">{dayjs(date).format("M/D/YYYY")}</div>
    </StTag>
  );
};

const StTag = styled.div`
  cursor: pointer;
  @media ${device.pc} {
    display: flex;
    justify-content: space-between;
    background-color: ${(props) => (props.importance ? "#FFF1F1" : "")};
    .subjectM,
    .subjectEmergency {
      display: none;
    }
    .firstTag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      min-height: 52px;
      padding: 14px 24px;
      color: #55595f;
      box-sizing: border-box;
    }
    .secondTagS {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 780px;
      min-height: 52px;
      padding: 14px 0;
      box-sizing: border-box;
      .noticeTitle {
        width: 717px;
        line-height: 24px;
      }
      .categoryPart {
        display: flex;
        justify-content: center;
        width: 51px;
      }
      .importantNotice {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        font-size: 12px;
        line-height: 18px;
        border-radius: 4px;
        background-color: #fee0e0;
        color: #ef4444;
      }
      .subject {
        padding: 4px 8px;
        font-size: 12px;
        line-height: 18px;
        border-radius: 4px;
        color: ${(props) => props.color};
        background-color: ${(props) => props.backGroundColor};
      }
    }
    .secondTag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 780px;
      height: 52px;
      padding: 14px 24px;
      box-sizing: border-box;
    }
    .thirdTag {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      min-height: 52px;
      font-family: "light";
      padding: 14px 24px;
      box-sizing: border-box;
      color: #55595f;
    }
  }

  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    min-height: 102px;
    background-color: ${(props) => props.importance && "#FFF1F1"};
    .subject,
    .importantNotice {
      display: none;
    }
    .subjectEmergency {
      display: inline-flex;
      justify-items: center;
      align-items: center;
      padding: 3px 8px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: #fee0e0;
      color: #ef4444;
      font-size: 10px;
      line-height: 15px;
    }
    .subjectM {
      display: inline-flex;
      justify-items: center;
      align-items: center;
      padding: 3px 8px;
      box-sizing: border-box;
      border-radius: 4px;
      background-color: ${(props) => props.backGroundColor};
      color: ${(props) => props.color};
      font-size: 10px;
      line-height: 15px;
    }
    .firstTag {
      display: none;
    }
    .secondTagS {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      .noticeTitle {
        margin: 6px 0 2px;
      }
    }
    .thirdTag {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      font-size: 12px;
      font-family: "light";
      line-height: 20px;
      color: #888888;
    }
  }
`;

export default NoticeTag;
