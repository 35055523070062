import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetMessageBox = ({ answerInvitation }) => {
  const fetcher = async () => {
    const response = await apis.getMessageBox();
    return response.data;
  };
  return useQuery(["message", answerInvitation], fetcher);
};

export default useGetMessageBox;
