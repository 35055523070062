import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBack, ModalHeader } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";

// img
import questionMark from "../img/Q.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { bannerOn, languageIs } from "../atoms";
import { device } from "../hooks/device";
import useGetRefundSpecific from "../hooks/useGetRefundSpecific";

const RefundSpecific = ({
  refundId,
  refundSpecificModal,
  setRefundSpecificModal,
  setMyPageScroll,
}) => {
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const { data } = useGetRefundSpecific({ refundId });
  const banneris = useRecoilValue(bannerOn);

  const goBack = () => {
    setRefundSpecificModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    return () => {
      if (!refundSpecificModal)
        // 버튼이 눌리지 않았을 때
        window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBack />
      <StRefund banneris={banneris} state={data?.cancelData?.cancelStatus}>
        <div className="wrapper">
          <ModalHeader title="보유 적립금 환불" cancelIs={true} />
          <div className="modalBody">
            <div className="mainBox">
              <div className="mainWhole">
                총 적립금 :{" "}
                {data &&
                  Number(data?.cancelData?.totalCredits).toLocaleString()}{" "}
                원
              </div>
              <div className="mainAvailable">
                {data &&
                  Number(data?.cancelData?.targetCredits).toLocaleString()}
                <span>원</span>
              </div>
              <div className="mainInfo">{data?.cancelData?.cancelStatus}</div>
              <div className="mainDate">
                {data?.cancelData?.cancelCreditsTradeAt}
              </div>
            </div>
            <div className="amountBox">
              {data?.cancelData?.cancelStatus === "환불 오류" && (
                <div style={{ width: "100%" }}>
                  <div className="errorCase">
                    <div className="errorItem">
                      <div className="amountInfo">환불 신청을 완료한 금액</div>
                      <div className="amountAmount">
                        {Number(
                          data?.cancelData?.successRefundCredits
                        ).toLocaleString()}
                        <span>원</span>
                      </div>
                    </div>
                    <div className="lineColumn" />
                    <div className="errorItem">
                      <div className="amountInfo">환불 신청을 실패한 금액</div>
                      <div
                        className="amountAmount"
                        style={{ color: "#C83B38" }}
                      >
                        {Number(
                          data?.cancelData?.failedRefundCredits
                        ).toLocaleString()}
                        <span>원</span>
                      </div>
                    </div>
                  </div>
                  <div className="lineRow" />
                </div>
              )}
              <div className="amountInfo">환불이 불가한 총 금액</div>
              <div className="amountAmount">
                {Number(
                  data?.cancelData?.notRefundableCredits
                ).toLocaleString()}
                <span>원</span>
              </div>
            </div>
            {data?.cancelData?.cancelStatus === "환불 오류" && (
              <div className="errorInfo">
                환불 신청이 정상적으로 처리되지 않았어요. 고객센터를 통해
                연락주시면 &nbsp;
                <br className="onlyForPc" />
                신속하게 도와드릴게요.
              </div>
            )}
            <div className="infoBox">
              <div>
                * 결제 시 지급된 추가 적립금은 환불과 함께 자동으로 소멸돼요.
              </div>
              <div>* 환불은 결제한 카드사 기준으로 3~4일 가량 소요돼요.</div>
              <div>
                * 자세한 문의는 고객센터로 연락주시면 신속하게 도와드릴게요.
              </div>
            </div>
            <div className="listBox">
              <div className="listTitle">환불 불가 금액 내역</div>
              <div className="rowGreyBar" />
              <div className="linePart">
                <div className="listLine">
                  <div className="lineLeft">환불 유효기간 만료</div>
                  <div className="lineRight">
                    총{" "}
                    {Number(
                      data?.cancelData?.notRefundableCreditsDetail
                        ?.expiredCredits
                    ).toLocaleString()}{" "}
                    원
                  </div>
                </div>
                <div className="listLine">
                  <div className="lineLeft">이벤트 적립금</div>
                  <div className="lineRight">
                    총{" "}
                    {Number(
                      data?.cancelData?.notRefundableCreditsDetail?.eventCredits
                    ).toLocaleString()}{" "}
                    원
                  </div>
                </div>
                <div className="listLine">
                  <div className="lineLeft">
                    추가 적립금(20%)
                    <img src={questionMark} />
                  </div>
                  <div className="lineRight">
                    총{" "}
                    {Number(
                      data?.cancelData?.notRefundableCreditsDetail
                        ?.rewardCredits
                    ).toLocaleString()}{" "}
                    원
                  </div>
                </div>
                {/* <div className='listLine'>
                  <div className='lineLeft'>내역항목</div>
                  <div className='lineRight'>총 3,000 원</div>
                </div> */}
              </div>
            </div>
            <div className="buttonBox">
              <div
                className="confirmButton"
                onClick={() => {
                  setRefundSpecificModal(false);
                }}
                style={{ cursor: "pointer" }}
              >
                확인
              </div>
              {data?.cancelData?.cancelStatus === "환불 오류" && (
                <div
                  className="customerButton"
                  onClick={() => {
                    setRefundSpecificModal(false);
                    navigate(`/myPage`);
                    setMyPageScroll("customerCenter");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  고객센터
                </div>
              )}
            </div>
          </div>
        </div>
      </StRefund>
    </>
  );
};

const StRefund = styled.div`
  position: fixed;
  background-color: white;
  .modalBody {
    display: flex;
    flex-direction: column;
    .mainBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      .mainWhole {
        font-size: 12px;
        font-family: "semibold";
        line-height: 18px;
        color: #818181;
      }
      .mainAvailable {
        font-size: 26px;
        font-family: "extrabold";
        line-height: 38px;
        color: #2276dc;
        margin: 10px 0 4px;
        span {
          font-family: "bold";
          font-size: 16px;
          line-height: 32px;
          margin-left: 4px;
          color: black;
        }
      }
      .mainInfo {
        font-size: 14px;
        font-family: "regular";
        line-height: 21px;
        color: ${(props) => (props.state === "환불 오류" ? "#C83B38" : "")};
      }
      .mainDate {
        font-size: 14px;
        font-family: "regular";
        line-height: 21px;
        color: #818181;
        margin-top: 4px;
      }
    }
    .amountBox {
      display: grid;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      align-items: center;
      border: ${(props) =>
        props.state === "환불 오류"
          ? "1px solid #C83B38"
          : "0.6px solid #BDBDBD"};
      border-radius: 8px;
      gap: 7px;
      .amountInfo {
        color: #818181;
        font-size: 12px;
        font-family: "medium";
        line-height: 18px;
      }
      .amountAmount {
        font-size: 19px;
        font-family: "bold";
        line-height: 28px;
        span {
          margin-left: 2px;
          font-size: 14px;
          line-height: 24px;
          font-family: "regular";
        }
      }
    }
    .infoBox {
      display: flex;
      flex-direction: column;
      gap: 6px;
      div {
        font-size: 11px;
        font-family: "regular";
        line-height: 16px;
      }
    }
    .listBox {
      margin-top: 36px;
      .listTitle {
        font-size: 13px;
        font-family: "semibold";
        line-height: 19px;
      }
      .linePart {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        gap: 6px;
        .listLine {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .lineLeft {
            display: flex;
            align-items: center;
            color: #55595f;
            font-size: 14px;
            font-family: "regular";
            line-height: 21px;
            img {
              width: 16px;
              height: 16px;
              margin: auto 0 auto 4px;
            }
          }
          .lineRight {
            font-size: 14px;
            font-family: "medium";
            line-height: 21px;
          }
        }
      }
    }
    .rowGreyBar {
      width: 100%;
      height: 0.5px;
      background-color: #bdbdbd;
      margin-top: 4px;
    }
  }
  @media ${device.pc} {
    z-index: 70;
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .modalBody {
      margin-top: 48px;
      .errorInfo {
        color: #c83b38;
        text-align: center;
        font-family: "regular";
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 14px;
      }
    }
    .amountBox {
      width: 400px;
      box-sizing: border-box;
      margin: 40px auto 5px;
      padding: 16px;
      .errorCase {
        display: flex;
        align-items: center;
        width: 100%;
        .errorItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 199.75px;
        }
        .lineColumn {
          width: 0.5px;
          height: 59px;
          background-color: #d6d9dd;
        }
      }
      .lineRow {
        width: 376px;
        height: 0.5px;
        background-color: #d6d9dd;
        margin: 16px auto;
      }
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 21px;
      font-size: 17px;
      font-family: "medium";
      line-height: 21px;
      margin: 54.5px auto 0;
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 42px;
      }
      .customerButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        background-color: #20315b;
        color: white;
        border-radius: 42px;
        box-sizing: border-box;
      }
    }
  }
  @media ${device.tabMob} {
    z-index: 49;
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
      margin-top: 100px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #c6c6c6;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 1rem;
    }
    padding: 0 24px;
    box-sizing: border-box;
    bottom: 0;
    left: 0;
    .onlyForPc {
      display: none;
    }
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
      height: -webkit-fill-available;
    }
    .modalBody {
      .amountBox {
        width: 312px;
        box-sizing: border-box;
        margin: 24px auto 10px;
        padding: 16px;
        .errorCase {
          display: flex;
          align-items: center;
          width: 100%;
          .errorItem {
            display: flex;
            flex-direction: column;
            font-size: 12px;
            align-items: center;
            width: 155.75px;
          }
          .lineColumn {
            width: 0.5px;
            height: 59px;
            background-color: #d6d9dd;
          }
        }
        .lineRow {
          width: calc(100% - 24px);
          height: 0.5px;
          background-color: #d6d9dd;
          margin: 16px auto;
          box-sizing: border-box;
        }
      }
      .errorInfo {
        color: #c83b38;
        text-align: center;
        font-family: "regular";
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 14px;
      }
      .buttonBox {
        display: flex;
        justify-content: center;
        gap: 10px;
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        margin: 24px auto 15px;
        .confirmButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 42px;
          border: 1px solid #000000;
          border-radius: 42px;
        }
        .customerButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 42px;
          background-color: #20315b;
          color: white;
          border-radius: 42px;
          box-sizing: border-box;
        }
      }
      .mainBox {
        padding: 12px 0;
        .mainInfo {
          font-family: "medium";
        }
      }
    }
  }
`;

export default RefundSpecific;
