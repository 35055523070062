import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { examUpdate, userInfo } from "../atoms";
import getApi from "../api/getApi";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import customAxios from "../api/handler";
import { GetInvitedList } from "../interface/apiInterface";
import LandingInvited from "../modal/LandingInvited";
import LeftTest from "../modal/ServicePlanLeftTest";
import PreTest from "../modal/LandingPreTest";

const InviteModal = () => {
  const { t } = useTranslation();
  const showToast = useToast();

  const [user, setUser] = useRecoilState(userInfo);
  const [inviteInfo, setInviteInfo] = useState<GetInvitedList>();

  const [leftTestModal, setLeftTestModal] = useState<boolean>(false);
  const [preTestModal, setPreTestModal] = useState<boolean>(false);
  const [inviteModal, setInviteModal] = useState<boolean>(false);

  useEffect(() => {
    if (user?.hasInvite) {
      setInviteModal(true);
      getApi
        .getInvitedList()
        .then(({ data }) => {
          setInviteInfo(data);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
  }, [user?.hasInvite]);

  /** 수락 전 미결제 금액과 현재 진행중인 시험 유무 확인 및 환불 금액 체크 */
  const beforeAccept = (props: string) => {
    getApi
      .getProgressingTest()
      .then(() => {
        getApi
          .getPendingTest()
          .then(() => {
            inviteAnswer(props);
            setInviteModal(false);
          })
          .catch(({ response }) => {
            if (response.data.errorCode === "ENTERPRISE-011") {
              setPreTestModal(true);
              setInviteModal(false);
            } else {
              showToast({
                message: t(`errorCode.unknown_server_error`),
              });
            }
          });
      })
      .catch(({ response }) => {
        console.log(response);
        if (
          response?.data.errorCode === "ENTERPRISE-010" ||
          response?.data.errorCode === "TIMEZONE-007"
        ) {
          setLeftTestModal(true);
          setInviteModal(false);
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  const inviteAnswer = (props: string) => {
    customAxios
      .patch(`/api/v1/users/individual/approval`, {
        isVerifiedHostEmailInvite: props,
        hostEmail: inviteInfo?.invitedList.hostEmail,
        hostGroupName: inviteInfo?.invitedList.hostGroupName,
      })
      .then(() => {
        setPreTestModal(false);
        customAxios.get(`/api/v1/hosts`).then(({ data }) => {
          setUser(data?.getHost);
        });
        if (props === "approval") {
          showToast({
            message: `${inviteInfo?.invitedList.hostGroupName} ${t(
              `errorCode.transToMember`,
            )}`,
          });
        }
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-ROLE-003" ||
          response.data.errorCode === "HOST-ROLE-005" ||
          response.data.errorCode === "ENTERPRISE-006"
        ) {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  return (
    <>
      {inviteModal && (
        <LandingInvited
          inviteInfo={inviteInfo}
          setInviteModal={setInviteModal}
          inviteAnswer={inviteAnswer}
          beforeAccept={beforeAccept}
        />
      )}
      {leftTestModal && (
        <LeftTest
          close={setLeftTestModal}
          title={t(`mypage.f1_2.title`)}
          textOne={t(`mypage.f1_2.body1`)}
          textTwo={t(`mypage.f1_2.body2`)}
        />
      )}
      {preTestModal && (
        <PreTest
          setPreTestModal={setPreTestModal}
          inviteAnswer={inviteAnswer}
        />
      )}
    </>
  );
};

export default InviteModal;