import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { 
  HoverToolTip, 
  HoverToolTipModal, 
  SelectedToolTip, 
  SelectedToolTipModal, 
  clickInfo, 
  modalPadding 
} from "../atoms";
import styled from "styled-components";

const EventBar = ({
  start,
  end,
  level,
  videoLength,
  eventIndex,
  setClickedEvent,
  setPauTime,
  clearTimeOut,
  setCurTime,
  setStartTime,
  setDuration
})=>{

    const [backColor, setBackColor] = useState();

    const [clickIndex, setClickIndex] = useRecoilState(clickInfo);

    /** 티켓ID 77 관련 추가 */
    const modalOn = useRecoilValue(modalPadding);

    // 티켓ID 83 관련 추가
    let setHoverToolTip;
    let setSelectedToolTip;
    /** 티켓ID 77 관련 추가 */
    if(modalOn) {
      setHoverToolTip = useSetRecoilState(HoverToolTipModal);
      setSelectedToolTip = useSetRecoilState(SelectedToolTipModal);
    } else {
      setHoverToolTip = useSetRecoilState(HoverToolTip);
      setSelectedToolTip = useSetRecoilState(SelectedToolTip);
    }

    useEffect(()=>{
        if(level==='cheat')
            setBackColor('#C83B38');
        else
            setBackColor('#FFBC00');
    },[level])

    return (
        <StEventBar 
          start={start} 
          end={end} 
          videoLength={videoLength} 
          backColor={backColor}
          // 티켓ID 83 관련 추가
          onMouseEnter={() => {
            setHoverToolTip
            ({
              isHover: true, 
              start: start,
              end: end,
              level: level,
              index: eventIndex
            });
          }}
          // 티켓ID 83 관련 추가
          onMouseLeave={() => {
            setHoverToolTip({isHover: false});
          }}
          // 티켓ID 83 관련 추가
          onClick={() => {
            setSelectedToolTip
            ({
              isSelected: true,
              start: start,
              end: end,
              level: level,
              index: eventIndex
            });

            setClickIndex({ eventIndex });
            setClickedEvent(eventIndex + 1);
            setCurTime(start);
            setStartTime(start);
            setDuration(end - start);
            clearTimeOut();
            setPauTime((end - start) * 1000);
          }}
        >
            <div className='event'/>
        </StEventBar>
    )
}

const StEventBar = styled.div`
    position : absolute;
    left : calc( ${props=>props.start} * 100% / ${props=>props.videoLength} );
    background-color: ${props=>props.backColor};
    width : calc( 100% / ${props=>props.videoLength} * ( ${props=>props.end} - ${props=>props.start} ) );
    height : 15px;
`;

export default EventBar