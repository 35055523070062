import React, { Children, ReactNode } from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface FlexDivProp {
  children: ReactNode;
  onclick?: () => void;
  font_color?: string;
  font_family?: string;
  font_size?: number;
  z_index?: number;
  position?: string;
  top?: number;
  left?: number;
  width?: number;
  height?: number;
  padding?: string;
  pc_display?: string;
  pc_justify?: string;
  pc_align_items?: string;
  pc_font_size?: number;
  pc_font_family?: string;
  pc_lineheight?: number;
  pc_margin?: string;
  pc_gap?: number;
  pc_text_align?: string;
  tab_display?: string;
  tab_justify?: string;
  tab_align_items?: string;
  tab_padding?: string;
  tab_font_size?: number;
  tab_font_family?: string;
  tab_lineheight?: number;
  tab_margin?: string;
  tab_gap?: number;
  tab_text_align?: string;
}

const DivTwoPart: React.FC<FlexDivProp> = (props) => {
  return (
    <StDiv
      onClick={props.onclick}
      width={props.width}
      height={props.height}
      font_family={props.font_family}
      font_color={props.font_color}
      font_size={props.font_size}
      position={props.position}
      top={props.top}
      left={props.left}
      padding={props.padding}
      tab_padding={props.tab_padding}
      pc_display={props.pc_display}
      pc_align_items={props.pc_align_items}
      pc_font_size={props.pc_font_size}
      pc_lineheight={props.pc_lineheight}
      pc_margin={props.pc_margin}
      pc_gap={props.pc_gap}
      pc_text_align={props.pc_text_align}
      pc_justify={props.pc_justify}
      tab_display={props.tab_display}
      tab_align_items={props.tab_align_items}
      tab_font_size={props.tab_font_size}
      tab_font_family={props.tab_font_family}
      tab_lineheight={props.tab_lineheight}
      tab_margin={props.tab_margin}
      tab_gap={props.tab_gap}
      tab_text_align={props.tab_text_align}
      tab_justify={props.tab_justify}
    >
      {props.children}
    </StDiv>
  );
};

const StDiv = styled.div<FlexDivProp>`
  font-family: ${(props) => props.font_family};
  color: ${(props) => props.font_color};
  font-size: ${(props) => props.font_size}px;
  position: ${(props) => props.position};
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  padding: ${(props) => props.padding};
  @media ${device.pc} {
    display: ${(props) => props.pc_display};
    justify-content: ${(props) => props.pc_justify};
    align-items: ${(props) => props.pc_align_items};
    gap: ${(props) => props.pc_gap}px;
    font-size: ${(props) => props.pc_font_size}px;
    font-family: ${(props) => props.pc_font_family};
    line-height: ${(props) => props.pc_lineheight}px;
    margin: ${(props) => props.pc_margin};
    text-align: ${(props) => props.pc_text_align};
  }
  @media ${device.tabMob} {
    display: ${(props) => props.tab_display};
    justify-content: ${(props) => props.tab_justify};
    align-items: ${(props) => props.tab_align_items};
    gap: ${(props) => props.tab_gap}px;
    font-size: ${(props) => props.tab_font_size}px;
    font-family: ${(props) => props.tab_font_family};
    line-height: ${(props) => props.tab_lineheight}px;
    padding: ${(props) => props.tab_padding};
    margin: ${(props) => props.tab_margin};
    text-align: ${(props) => props.tab_text_align};
  }
`;

export default DivTwoPart;
