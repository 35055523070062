import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalBack } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

import greyX from "../img/greyX.svg";
import arrowLeft from "../img/arrowLeftWhite.svg";
import arrowRight from "../img/arrowRightWhite.svg";

const Photo = ({ photoModalList, setPhotoModal, title, subTitle }) => {
  const goBack = () => {
    setPhotoModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);
  useScrollLockThree();

  // state
  const [nowPhotoNumber, setNowPhotoNumber] = useState(1);

  return (
    <>
      <ModalBack />
      <StPhoto>
        <div className="mainTitle">
          {title}
          <img
            src={greyX}
            style={{ cursor: "pointer" }}
            alt=""
            onClick={() => window.history.back()}
          />
        </div>
        <div className="bodyPart">
          <div className="titleBox">
            {photoModalList?.length > 1 && (
              <div>
                {photoModalList?.length - nowPhotoNumber + 1}. {subTitle}
              </div>
            )}
          </div>
          <div className="imgBox">
            <img src={photoModalList?.[nowPhotoNumber - 1]} alt="" />
          </div>
          {photoModalList?.length > 1 && (
            <div className="pageNationBox">
              <div>
                {nowPhotoNumber > 1 && (
                  <img
                    style={{ cursor: "pointer" }}
                    src={arrowLeft}
                    alt=""
                    onClick={() => setNowPhotoNumber(nowPhotoNumber - 1)}
                  />
                )}
              </div>
              <div>
                {nowPhotoNumber}/{photoModalList?.length}
              </div>
              <div>
                {nowPhotoNumber < photoModalList?.length && (
                  <img
                    style={{ cursor: "pointer" }}
                    src={arrowRight}
                    alt=""
                    onClick={() => setNowPhotoNumber(nowPhotoNumber + 1)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </StPhoto>
    </>
  );
};

const StPhoto = styled.div`
  position: fixed;
  @media ${device.pc} {
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 825px;
    padding: 60px;
    box-sizing: border-box;
    background: rgba(34, 34, 34, 0.9);
    box-shadow: 0px 30px 80px 10px rgba(0, 0, 0, 0.2);
    color: white;
    .mainTitle {
      display: flex;
      justify-content: space-between;
      font-size: 28px;
      font-family: "bold";
      line-height: 41px;
      margin-bottom: img {
        cursor: pointer;
      }
    }
    .bodyPart {
      color: white;
      display: flex;
      flex-direction: column;
      height: calc(100% - 60px);
      .titleBox {
        font-size: 18px;
        font-family: "medium";
        line-height: 24px;
        padding: 16px 0;
      }
      .imgBox {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 383.52px;
        align-items: center;
        background-color: #222222;
        img {
          width: 100%;
        }
      }
      .pageNationBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        margin-top: auto;
        font-size: 18px;
        font-family: "medium";
        line-height: 24px;
        div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  @media ${device.tabMob} {
    z-index: 53;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    .mainTitle {
      display: flex;
      justify-content: space-between;
      padding: 16px 24px;
      font-size: 19px;
      font-family: "bold";
      line-height: 28px;
    }
    .bodyPart {
      color: white;
      background-color: #222222;
      display: flex;
      flex-direction: column;
      height: calc(100% - 60px);
      .titleBox {
        font-size: 18px;
        font-family: "medium";
        line-height: 24px;
        padding: 16px 24px;
      }
      .imgBox {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        img {
          width: 100%;
        }
      }
      .pageNationBox {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        background-color: #00000066;
        margin-top: auto;
        font-size: 18px;
        font-family: "medium";
        line-height: 24px;
        div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;

export default Photo;
