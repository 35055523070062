import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeader, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import spinner from "../img/spinner.svg";
import useGetEnterpriseChargeConfirm from "../hooks/useGetEnterpriseChargeConfirm";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { languageIs } from "../atoms";
import { useOutletData } from "../components/OutletDataContext";

const ExtraTestConfirm = ({ setTestConfirmModalOn }) => {
  const goBack = () => {
    setTestConfirmModalOn(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const country = useRecoilValue(languageIs);
  const navigate = useNavigate();
  const preUid = useParams().preuid;
  const [loading, setLoading] = useState("pending");
  const { search } = useLocation();
  const { data: outletData } = useOutletData();

  /** 쿼리스트링 값으로 들어오는 정보를 객체로 변경 */
  function parseQueryString(queryString) {
    const params = new URLSearchParams(queryString);
    const keyValuePairs = {};

    for (const [key, value] of params.entries()) {
      keyValuePairs[key] = value;
    }

    return keyValuePairs;
  }
  const pair = parseQueryString(search);

  useLayoutEffect(() => {
    if (pair.imp_success === "false") {
      window.history.go(-2);
    }
  }, [pair]);

  const { data } = useGetEnterpriseChargeConfirm({
    preUid,
  });

  /** pulling 되는 상태 값에 따라 보여주는 api 변경 */
  useEffect(() => {
    if (data?.status === "pending") {
      setLoading("pending");
    } else if (data?.status === "success") {
      setLoading("success");
    } else if (data?.status === "fail") {
      setLoading("fail");
    }
  }, [data]);

  // fail, success, pending

  /** loading값이 fail일 경우 뒤로 보내주고 토스트 띄워주기 */
  const purchaseFail = () => {
    if (loading === "fail") {
      window.history.go(-2);
    }
  };

  useEffect(() => {
    purchaseFail();
  }, [loading]);

  useScrollLockThree();

  return pair.imp_success === "true" ? (
    <>
      <ModalBackD />

      {loading === "pending" && (
        <StTestConfirm>
          <ModalHeaderD title="결제 확인 중..." />
          <img className="spinner" src={spinner} />
        </StTestConfirm>
      )}
      {loading === "success" && (
        <StTestConfirm>
          <ModalHeaderD title="추가 비용 결제 완료" />
          <div className="bodyBox">결제가 정상적으로 완료되었어요!</div>
          <div
            className="buttonBox"
            onClick={() => {
              // setTestConfirmModalOn(false);
              navigate(`/report/${outletData.testUid}`);
            }}
          >
            확인
          </div>
        </StTestConfirm>
      )}
    </>
  ) : (
    window.history.go(-2)
  );
};

const StTestConfirm = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .spinner {
    animation: rotate_image 1.5s linear infinite;
  }
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    .spinner {
      width: 67px;
      height: 67px;
      margin: 70px auto 37px;
    }
    .bodyBox {
      margin: 48px 0;
      line-height: 27px;
      font-size: 18px;
      font-family: "medium";
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      border-radius: 43px;
      box-sizing: border-box;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyBox {
      margin: 20px 0 24px;
      line-height: 24px;
    }
    .buttonBox {
      color: #0072de;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      text-align: center;
      cursor: pointer;
    }
  }
`;

export default ExtraTestConfirm;
