import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { languageIs, userlang } from "../../atoms";

import langDown from "../../img/downArrow.png";
import blueCheck from "../../img/blueCheck.svg";

interface LanguageProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

const LanguageChangeButton: React.FC<LanguageProps> = (props) => {
  const navigate = useNavigate();
  const [langClick, setLangClick] = useState<boolean>(false);
  const [nowLang, setNowLang] = useRecoilState<string>(userlang);
  const [lang, setLang] = useState<string>("KOR");
  const [country, setCountry] = useRecoilState<string>(languageIs);

  useEffect(() => {
    if (country === "/ko") {
      setLang("KOR");
    } else {
      setLang("ENG");
    }
  }, [country]);

  return (
    <StWrapper
      top={props.top}
      left={props.left}
      right={props.right}
      bottom={props.bottom}
    >
      <div className="langButton">
        <div className="langShow" onClick={() => setLangClick(true)}>
          <div>{lang}</div>
          <img src={langDown} alt="" />
        </div>
        {langClick && (
          <>
            <div className="langBack" onClick={() => setLangClick(false)} />
            <div className="optionBox">
              <div
                className="optionOne"
                onClick={() => {
                  setNowLang("EN");
                  setLangClick(false);
                  setLang("ENG");
                  setCountry("/en");
                  navigate(
                    "/en/" +
                      window.location.pathname
                        .split("/")
                        .splice(2, 2, country)
                        .join("/") +
                      window.location.search
                  );
                }}
              >
                ENG (English)
                {nowLang === "EN" && <img src={blueCheck} alt="" />}
              </div>
              <div
                className="optionTwo"
                onClick={() => {
                  setNowLang("KR");
                  setLangClick(false);
                  setLang("KOR");
                  setCountry("/ko");
                  navigate(
                    "/ko/" +
                      window.location.pathname
                        .split("/")
                        .splice(2, 2, country)
                        .join("/") +
                      window.location.search
                  );
                }}
              >
                KOR (한국어)
                {nowLang === "KR" && <img src={blueCheck} alt="" />}
              </div>
            </div>
          </>
        )}
      </div>
    </StWrapper>
  );
};

const StWrapper = styled.div<LanguageProps>`
  .langButton {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    box-sizing: border-box;
    width: 88px;
    height: 30px;
    font-size: 14px;
    font-family: "regular";
    line-height: 21px;
    border: 1px solid #bdbdbd;
    .langShow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
    .langBack {
      position: fixed;
      z-index: 50;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .optionBox {
      position: absolute;
      z-index: 50;
      right: ${(props) => props.right};
      top: ${(props) => props.top};
      bottom: ${(props) => props.bottom};
      left: ${(props) => props.left};
      display: flex;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      width: 188px;
      height: 108px;
      .optionOne {
        width: 100%;
        height: 54px;
        padding: 15px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: "medium";
        line-height: 24px;
        color: ${(props) => (props.lang === "ENG" ? "#0381FE" : "black")};
        cursor: pointer;
      }
      .optionTwo {
        width: 100%;
        height: 54px;
        padding: 15px 24px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: "medium";
        line-height: 24px;
        color: ${(props) => (props.lang === "KOR" ? "#0381FE" : "black")};
        cursor: pointer;
      }
    }
  }
`;

export default LanguageChangeButton;
