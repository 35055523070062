import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import ModalHeader from "../../../atomic/molecule/ModalHeader";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "../../../atomic/atom/ConfirmButton";
import { useTranslation } from "react-i18next";

const Complete = () => {
  const { t } = useTranslation();
  const baseLang = "requestConsult";
  const navigate = useNavigate();
  return (
    <>
      <ModalOneButtonTemplate gap={48} tab_gap={24}>
        <ModalHeader title={t(`${baseLang}.successTitle`)} />
        <DivTwoPartImprove
          display="grid"
          pc_gap={30}
          gap={24}
          pc_font_size={18}
          font_size={16}
        >
          <DivTwoPartImprove display="grid" lineheight={24} pc_lineheight={27}>
            <TextLine text={t(`${baseLang}.successBody`)} />
          </DivTwoPartImprove>
          <TextLine text={t(`${baseLang}.successInfo`)} />
        </DivTwoPartImprove>
        <ConfirmButton
          font_size={17}
          font_family="medium"
          margin="0 auto"
          text={t(`${baseLang}.successButton`)}
          tab_width="158px"
          tab_height={35}
          tab_fontsize={18}
          tab_fontcolor="#0072DE"
          cursor="pointer"
          onclick={() => navigate("/")}
        />
      </ModalOneButtonTemplate>
    </>
  );
};

export default Complete;
