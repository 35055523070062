import React, { ReactNode } from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import { ModalBackD } from "../../components/Style";
import styled from "styled-components";
import { device } from "../../hooks/device";
import { useRecoilValue } from "recoil";
import { bannerOn } from "../../atoms";
import useScrollLockThree from "../../hooks/useScrollLockThree";
import ModalPageBack from "../molecule/ModalPageBack";

interface ModalPageProps {
  children: ReactNode;
  width?: string;
  pc_height?: number;
  gap?: number;
  tab_width?: string;
  tab_gap?: number;
  overflow?: string;
  pc_padding?: string;
  modalBackground?: string;
  banneris?: boolean;
}

const ModalPageTemplate: React.FC<ModalPageProps> = (props) => {
  useScrollLockThree();
  const banneris = useRecoilValue(bannerOn);

  return (
    <>
      <ModalPageBack />
      <StModalPage
        modalBackground={props.modalBackground}
        pc_padding={props.pc_padding}
        banneris={banneris}
      >
        {props.children}
      </StModalPage>
    </>
  );
};

const StModalPage = styled.div<ModalPageProps>`
  position: fixed;
  background-color: white;
  z-index: 49;
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    width: ${(props) => (props.width ? props.width : "580px")};
    gap: ${(props) => props.gap}px;
    box-sizing: border-box;
    height: ${(props) => props.pc_height}px;
    overflow-x: ${(props) => props.overflow};
    transform: translate(-50%, -50%);
    top: 50%;
    padding: ${(props) => (props.pc_padding ? props.pc_padding : "60px")};
    box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
    z-index: 54;
  }
  @media ${device.tabMob} {
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    gap: ${(props) => props.tab_gap}px;
    padding: 0 24px 24px;
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: ${(props) => props.modalBackground};
  }
`;

export default ModalPageTemplate;
