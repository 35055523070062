import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { device } from "../hooks/device";

const ChargingInfo = ({ setChargingInfoModal }) => {
  useScrollLockThree();

  const goBack = () => {
    setChargingInfoModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  return (
    <>
      <ModalBackD />
      <StPaymentInfo>
        <ModalHeaderD title="구매 금액 정보" />
        <div className="bodyPart">
          적립금의 환불 유효기간은 구매일로부터 최대 6개월까지이며, 1원 이상부터
          결제금액 제한 없이 자유롭게 사용하실 수 있어요. 그리고 예정된 시험을
          취소할 경우 사용했던 적립금은 모두 자동 환불돼요. <br />
          <br />
          먼저 구매한 적립금부터 순서대로 사용되며, 환불 유효기간 내 사용하지
          않은 적립금의 경우 환불이 불가한 적립금으로 자동 전환돼요.
        </div>
        <div className="warningPart">
          <span>※ 주의 사항</span>
          <ul>
            <li>환불 유효기간 : 구매일로부터 최대 6개월</li>
            <li>
              환불 유효기간 만료일의 시간은 당일 자정(00:00)을 기준으로 해요.
            </li>
            <li>
              환불 불가 적립금은 기존 적립금과 동일하게 사용이 가능하나 환불은
              가능하지 않아요.
            </li>
          </ul>
        </div>
        <div className="confirmButton" onClick={() => window.history.back()}>
          확인
        </div>
      </StPaymentInfo>
    </>
  );
};

const StPaymentInfo = styled.div`
  position: fixed;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  z-index: 53;
  @media ${device.pc} {
    width: 580px;
    padding: 60px;
    top: 50%;
    transform: translate(-50%, -50%);
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 48px auto 0;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      border: 1px solid #000000;
      border-radius: 43px;
    }
    .bodyPart {
      margin: 48px 0;
      font-family: "medium";
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    bottom: 16px;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyPart {
      margin-bottom: 20px;
    }
    .confirmButton {
      color: #0072de;
    }
  }
  .bodyPart {
    line-height: 24px;
  }
  .warningPart {
    line-height: 24px;
    span {
      font-family: "semibold";
    }
    ul {
      display: grid;
      flex-direction: column;
      gap: 4px;
      padding-left: 20px;
      margin-top: 4px;
      color: #444444;
    }
  }
  .confirmButton {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    text-align: center;
    cursor: pointer;
  }
`;

export default ChargingInfo;
