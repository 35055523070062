import { useEffect } from "react";
import styled from "styled-components";
import { ModalHeaderB } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import denied from "../img/denied.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalPageTemplate from "../atomic/template/ModalPageTemplate";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import Img from "../atomic/atom/Img";
import Button from "../atomic/atom/Button";
import Br from "../atomic/atom/Br";

const DeleteDenied = ({ setDeleteDeniedModal, setMyPageScroll }) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d2_1_3_1";
  const navigate = useNavigate();

  const goBack = () => {
    setDeleteDeniedModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalPageTemplate>
        <StBucket>
          <ModalHeaderB />
          <DivTwoPartImprove
            display="flex"
            pc_height={411}
            justify="center"
            align_items="center"
          >
            <DivTwoPartImprove
              display="grid"
              justify="center"
              margin="auto 0"
              pc_height={191}
            >
              <Img src={denied} margin="0 auto" />
              <TextLine
                text={t(`${baseLang}.title`)}
                fontsize={18}
                pc_fontsize={22}
                lineheight={27}
                pc_lineheight={34}
                fontFamily="medium"
                margin="10px 0 14px"
                pc_margin="24px 0"
              />
              <DivTwoPartImprove
                margin="14px 0 0"
                pc_margin="0"
                font_size={16}
                font_color="#6e6e6e"
                lineheight={24}
                text_align="center"
              >
                <TextLine
                  text={t(`${baseLang}.middleTitle1`)}
                  pc_fontcolor="#242424"
                />
                <Br mobile={true} />
                <TextLine
                  text={t(`${baseLang}.middleTitle2`)}
                  pc_fontcolor="#242424"
                />
                <Br />
                <TextLine
                  text={t(`${baseLang}.middleTitle3`)}
                  pc_fontcolor="#242424"
                />
              </DivTwoPartImprove>
            </DivTwoPartImprove>
          </DivTwoPartImprove>
          <DivTwoPartImprove
            display="grid"
            gap={22}
            pc_gap={40}
            margin="auto 0 0"
          >
            <DivTwoPartImprove
              font_size={11}
              lineheight={16}
              pc_font_size={12}
              pc_lineheight={18}
              text_align="center"
            >
              <TextLine text={t(`${baseLang}.info1`)} />
              <Br />
              <TextLine
                text={t(`${baseLang}.support`)}
                textdeco="underline"
                fontcolor="#2276dc"
                cursor="pointer"
              />
              .&nbsp;
              <TextLine text={t(`${baseLang}.info2`)} />
            </DivTwoPartImprove>
            <DivTwoPartImprove
              display="grid"
              grid_template_column="151px 151px"
              pc_grid_template_column="204px 204px"
              justify="center"
              gap={10}
              pc_gap={21}
              padding="15px 0"
            >
              <Button
                text={t(`${baseLang}.button1`)}
                width="151px"
                height={42}
                pc_width="204px"
                pc_height={52}
                border_radius={42}
                border="1px solid #000"
                backgroundcolor="white"
                font_color="black"
                font_family="medium"
                font_size={14}
                pc_fontsize={17}
                onclick={() => window.history.back()}
              />
              <Button
                text={t(`${baseLang}.button2`)}
                width="151px"
                height={42}
                pc_width="204px"
                pc_height={52}
                border_radius={42}
                border="none"
                backgroundcolor="#20315b"
                font_color="white"
                font_family="medium"
                font_size={14}
                pc_fontsize={17}
                onclick={() => {
                  navigate(`/myPage`);
                  setMyPageScroll("customerCenter");
                }}
              />
            </DivTwoPartImprove>
          </DivTwoPartImprove>
        </StBucket>
      </ModalPageTemplate>
    </>
  );
};

const StBucket = styled.div`
  display: grid;
  height: 100%;
`;

export default DeleteDenied;
