import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetMemberUse = ({ uid }) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getMemberUse({ uid, utc });
    return data;
  };
  return useQuery(["memberUse", uid], fetcher);
};

export default useGetMemberUse;
