import React from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
// styled components

// img
import question from "../img/question mark.svg";
import answer from "../img/answer.svg";
import notebook from "../img/notebook.png";
import vector from "../img/vector.svg";
import computer from "../img/computer.png";
import proctor from "../img/proctor.svg";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";
import TextTwoPart from "../atomic/atom/TextTwoPart";
import DivTwoPart from "../atomic/atom/DivTwoPart";
import { useTranslation } from "react-i18next";
import TextLine from "../atomic/atom/TextLine";
import Img from "../atomic/atom/Img";

interface Home2StyleProps {
  language: string;
}

const Home2 = () => {
  const language = useRecoilValue(languageIs);
  const { t } = useTranslation();
  const baseLang = "home.a1.secondPage";

  return (
    <StHomePage language={language}>
      <div className="group">
        <div className="left2">
          <img className="question" alt="" src={question} />
          <DivTwoPart
            tab_display="grid"
            tab_gap={16}
            pc_lineheight={33}
            font_family="medium"
            pc_font_size={24}
          >
            <DivTwoPart
              pc_display="flex"
              pc_font_size={24}
              pc_margin="15px 0 18px 0"
              tab_display="grid"
              tab_lineheight={33}
              tab_font_size={18}
              font_family="medium"
              tab_margin="0 auto"
            >
              <DivTwoPart pc_display="flex" tab_display="flex">
                <TextLine text={t(`${baseLang}.firstQuestion.one`)} />
                &nbsp;
                <TextLine
                  text={t(`${baseLang}.firstQuestion.two`)}
                  fontcolor="#C83B38"
                  fontFamily="bold"
                />
                &nbsp;
                <TextLine
                  text={t(`${baseLang}.firstQuestion.three`)}
                  pc_margin="0 5px 0 0"
                />
              </DivTwoPart>
              <TextLine
                text={t(`${baseLang}.firstQuestion.four`)}
                margin="0 auto"
                pc_margin="0"
              />
            </DivTwoPart>
            <DivTwoPart
              tab_display="grid"
              tab_lineheight={33}
              tab_font_size={18}
              font_family="medium"
              tab_margin="0 auto"
            >
              <DivTwoPart pc_display="flex" tab_display="grid">
                <TextLine
                  text={t(`${baseLang}.firstQuestion.five`)}
                  pc_margin="0 5px 0 0"
                  margin="0 auto"
                />
                <DivTwoPart pc_display="flex" tab_display="flex">
                  <TextLine
                    text={t(`${baseLang}.firstQuestion.six`)}
                    fontcolor="#C83B38"
                    fontFamily="bold"
                  />
                  &nbsp;
                  <TextLine text={t(`${baseLang}.firstQuestion.seven`)} />
                </DivTwoPart>
              </DivTwoPart>
              <TextLine
                text={t(`${baseLang}.firstQuestion.eight`)}
                margin="0 auto"
                pc_margin="0"
              />
            </DivTwoPart>
          </DivTwoPart>
        </div>
        <img className="note" alt="" src={notebook} />
      </div>
      <div className="groupColumn">
        <DivTwoPart
          pc_display="flex"
          pc_margin="0 auto 0 0"
          tab_display="grid"
          tab_align_items="center"
          tab_margin="0 0 6px 0"
        >
          <img className="question" alt="" src={answer} />
          <DivTwoPart tab_display="grid" tab_gap={16}>
            <DivTwoPart
              pc_display="flex"
              pc_font_size={26}
              font_family="bold"
              pc_lineheight={38}
              pc_margin="15px 0 0 0"
              tab_display="grid"
              tab_justify="center"
              tab_align_items="center"
              tab_font_size={22}
              tab_lineheight={33}
            >
              <TextTwoPart
                tab_display="none"
                textEng="Start Automated Proctoring with&nbsp;"
              />
              <TextTwoPart
                pc_display="none"
                textEng="Start Automated Proctoring&nbsp;"
              />
              <DivTwoPart tab_display="flex" tab_justify="center">
                <TextTwoPart pc_display="none" textEng="with&nbsp;" />
                <TextTwoPart
                  textEng="Proctormatic"
                  pc_font_size_en={32}
                  pc_margin="0 0 5px 0"
                  font_color="#2276DC"
                />
                <TextTwoPart pc_display="none" textEng="!" />
              </DivTwoPart>
              <TextTwoPart tab_display="none" textEng="!" />
            </DivTwoPart>
            <DivTwoPart
              font_family="semibold"
              pc_font_size={24}
              pc_lineheight={32}
              font_color="#4882BD"
              pc_margin="20px 0 0 0"
              tab_font_size={15}
              tab_lineheight={22}
              tab_font_family="medium"
            >
              <TextTwoPart textEng="Meet our AI proctor that detects cheating with exceptional accuracy! " />
              <TextTwoPart textEng="Discover our AI video analysis technology, easily accessible to both administrators and test-takers. " />
            </DivTwoPart>
          </DivTwoPart>
        </DivTwoPart>
        <div className="title2d">
          <img className="computer" alt="" src={computer} />
          <div className="right2">
            {language === "/ko" && (
              <div className="grey">Online Exam AI Inspector</div>
            )}
            {language === "/ko" && (
              <img className="proctor" src={proctor} alt="" />
            )}
            <DivTwoPart
              pc_display="grid"
              pc_gap={24}
              font_family="medium"
              pc_font_size={24}
              pc_lineheight={36}
              tab_font_size={15}
              tab_display="grid"
              tab_justify="center"
              tab_gap={3.6}
            >
              <DivTwoPart
                pc_display="flex"
                pc_align_items="center"
                tab_display="flex"
              >
                <Img
                  src={vector}
                  width="16px"
                  margin="0 7px 0 0"
                  pcWidth="27px"
                  pcMargin="0 13px 0 0"
                />
                <TextTwoPart
                  textKor="쉽고 편리한 온라인 시험 진행"
                  textEng="Analyzes Behavioral Pattern"
                  pc_font_size_en={26}
                />
              </DivTwoPart>
              <DivTwoPart
                pc_display="flex"
                pc_align_items="center"
                tab_display="flex"
              >
                <Img
                  src={vector}
                  width="16px"
                  margin="0 7px 0 0"
                  pcWidth="27px"
                  pcMargin="0 13px 0 0"
                />
                <TextTwoPart
                  textKor="AI 기반의 공정한 부정행위 자동 검증"
                  textEng="Detects Surrounding Objects/Sound "
                  pc_font_size_en={26}
                />
              </DivTwoPart>
              <DivTwoPart
                pc_display="flex"
                pc_align_items="center"
                tab_display="flex"
              >
                <Img
                  src={vector}
                  width="16px"
                  pcWidth="27px"
                  margin="0 7px 0 0"
                  pcMargin="0 13px 0 0"
                />
                <TextTwoPart
                  textKor="검증 결과를 일목요연하게 정리한 보고서"
                  pc_font_size_en={26}
                  textEng="Automatically Creates Reports"
                />
              </DivTwoPart>
            </DivTwoPart>
          </div>
        </div>
      </div>
    </StHomePage>
  );
};

const StHomePage = styled.div<Home2StyleProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  margin: 0 auto;
  padding: 160px 122px;
  box-sizing: border-box;
  @media ${device.tabMob} {
    width: 100%;
    padding: 0 24px 60px;
    box-sizing: border-box;
    .letter {
      position: relative;
      .redDot {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: -1px;
        width: 4px;
        height: 4px;
      }
      .blueDot {
        position: absolute;
        left: 50%;
        top: -3px;
        transform: translate(-50%, 0);
        width: 4px;
        height: 4px;
      }
    }
    .proctor {
      display: flex;
      margin: 10px auto 28px;
      width: 262px;
      height: 24px;
    }
    .computer {
      width: 290px;
      height: 186.37px;
      margin: 0 auto;
    }
    .H4pc {
      display: none;
    }
    .H4Blue {
      display: flex;
      font-family: "Bold";
      font-size: 26px;
      color: #2276dc;
    }
    .line3 {
      display: flex;
      align-items: baseline;
      margin-bottom: 30px;
    }
    .H4mobile {
      font-size: 19px;
      font-family: "Medium";
    }
    .grey {
      margin: 0 auto;
      width: 262px;
      color: #4882bd;
      font-family: "Medium";
      font-size: 15px;
      line-height: 22px;
    }
    .groupColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .group {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .vector {
      width: 8.61px;
      height: 8.61px;
      margin: auto 10px auto 0;
    }
    .brain {
      display: none;
    }
    .title2d {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .title2u {
      display: flex;
      flex-direction: column;
    }
    .prosList {
      display: grid;
      flex-direction: column;
      gap: 3.59px;
      font-size: 15px;
      font-family: "medium";
      line-height: 22px;
    }
    .line2 {
      display: flex;
      margin: 0 auto 3.6px;
      width: 267px;
      font-size: 15px;
      font-family: "Medium";
    }
    .note {
      width: 245px;
      height: 160px;
      margin: 30px auto 0px;
    }
    .question {
      width: 48px;
      height: 48px;
      margin: 180px auto 40px;
    }
    .left2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .right2 {
      width: 100%;
      padding: 40px 0px;
      box-sizing: border-box;
      margin: 0 auto;
    }
    .H5 {
      font-family: "Bold";
      font-size: 22px;
    }
    .H4Red {
      display: flex;
      font-family: "Bold";
      font-size: 19px;
      color: #c83b38;
    }
    .H4 {
      font-size: 19px;
      font-family: "Medium";
    }
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .write {
      display: grid;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      gap: 16px;
      text-align: center;
      align-items: center;
      font-size: 19px;
      font-family: "medium";
      line-height: 28px;
    }
  }
  @media ${device.pc} {
    width: 1440px;
    .letter {
      position: relative;
      .redDot {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: -1px;
        width: 4px;
        height: 4px;
      }
      .blueDot {
        position: absolute;
        left: 50%;
        top: -10px;
        transform: translate(-50%, 0);
        width: 4px;
        height: 4px;
      }
    }
    .line3 {
      display: flex;
      align-items: baseline;
    }
    .dot {
      line-height: -1px;
      font-size: 40px;
    }
    .proctor {
      width: 463px;
      height: 44px;
      margin-bottom: 50px;
    }
    .computer {
      width: 534px;
      /* height: 344px; */
      margin-left: 32px;
      margin-top: 15px;
    }
    .H4mobile {
      display: none;
    }
    .H4pc {
      font-size: 22px;
      font-family: "Medium";
    }
    .mobile {
      display: none;
    }
    .grey {
      color: #4882bd;
      font-family: "Medium";
      font-size: 24px;
      margin-bottom: 16px;
    }
    .groupColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 50px;
    }
    .group {
      display: flex;
      width: 100%;
    }
    .vector {
      width: 14.27px;
      height: 14.27px;
      margin: 0 20px 0 0;
    }
    .brain {
      width: 620px;
      height: 350px;
    }
    .title2d {
      display: flex;
      width: 100%;
      margin-top: 20px;
    }
    .title2u {
      display: flex;
      align-items: center;
      margin-right: auto;
      margin-left: 52px;
    }
    .prosList {
      display: grid;
      flex-direction: column;
      gap: 10px;
      .line2 {
        font-family: "Medium";
        font-size: 24px;
        line-height: 36px;
      }
    }
    .note {
      width: 366px;
      margin-left: auto;
      margin-right: 20px;
    }
    .question {
      width: 48px;
      height: 48px;
      margin: 10px 16px 0 0;
    }
    .left2 {
      display: flex;
      /* margin: 0px 0 0 52px; */
    }
    .right2 {
      display: flex;
      flex-direction: column;
      justify-content: ${(props) => (props.language === "/ko" ? "" : "center")};
      width: 598px;
      height: 374px;
      padding: ${(props) =>
        props.language === "/ko" ? "48px 62px" : "48px 40px"};
      box-sizing: border-box;
      margin-left: auto;
    }
    .H5 {
      font-family: "Bold";
      line-height: 38.36px;
      font-size: 25px;
    }
    .H4Blue {
      /* text-emphasis-style: dot;
        text-emphasis-position: unset; */
      display: flex;
      font-family: "Bold";
      font-size: 32px;
      line-height: 38.36px;
      color: #2276dc;
    }
    .H4Red {
      display: flex;
      /* text-emphasis: dot; */
      font-family: "Bold";
      font-size: 22px;
      color: #c83b38;
    }
    .H4 {
      font-family: "Medium";
      line-height: 33px;
      font-size: 22px;
    }
    .line {
      display: flex;
      align-items: baseline;
    }
    .write {
      display: grid;
      grid-template-rows: auto auto 1fr;
      margin-top: 15px;
      font-size: 22px;
      font-family: "medium";
      line-height: 33px;
      gap: 18px;
    }
  }
`;

export default Home2;
