import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

/** 페이지 이동시 스크롤이 초기화됨, 모달은 초기화 되지않게 되어있음. 스크롤 초기화 부분 문제시 이 훅을 수정할 것 */
const usePageScrollReset = () => {
  const location = useLocation();
  const [previousPage, setPreviousPage] = useState<string>(
    location.pathname.split("/")[1]
  );

  useEffect(() => {
    if (location.pathname.split("/")[1] !== previousPage.split("/")[1]) {
      window.scrollTo(0, 0);
    }
    setTimeout(() => {
      setPreviousPage(location.pathname);
    }, 0);
  }, [location.pathname]);
};

export default usePageScrollReset;
