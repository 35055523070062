import React, { useState } from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import TextLine from "../atom/TextLine";
import Img from "../atom/Img";
import styled from "styled-components";
import { device } from "../../hooks/device";

import imgInput from "../../img/imgInput.svg";
import imgAdd from "../../img/imageAdd.svg";
import postApi from "../../api/postApi";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../atoms";
import patchApi from "../../api/patchApi";
import cancel from "../../img/imgCancel.svg";
import { useTranslation } from "react-i18next";
import useToast from "../../hooks/useToast";

export interface ImageAttachProps {
  questionUuid: string;
  questionComponentUuid: string;
  s3KeyList: string[];
  setS3KeyList: React.Dispatch<React.SetStateAction<string[]>>;
  imageData: string[];
  setImageData: React.Dispatch<React.SetStateAction<string[]>>;
}

const ImageAttach: React.FC<ImageAttachProps> = (props) => {
  const { t } = useTranslation();
  const baseLang = "customer.h5";
  const showToast = useToast();

  const userData = useRecoilValue(userInfo);
  /** 이미지 업로드시 실행 및 파일 읽어오기 */
  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (files?.length + props.imageData.length >= 6) {
        showToast({ message: t(`errorCode.imgCount`) });
      }
      for (
        let i = 0;
        i < (files.length < 6 ? files.length : 6 - props.imageData.length);
        i++
      ) {
        let reader = new FileReader();
        reader.onload = () => {
          props.setImageData((data: string[]) => [
            ...data,
            reader.result as string,
          ]);
          getS3Key({ imageData: files[i] });
        };
        reader.readAsDataURL(files[i]);
      }
    }
  };

  /** 드래그 앤 드롭시 페이지 전체에 사진이 퍼지는 것을 방지 */
  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files) {
    }
  };

  /** 드래그해온 사진을 읽어 저장 */
  const dragImage = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    let reader = new FileReader();
    reader.onload = () => {
      props.setImageData((data: string[]) => [
        ...data,
        reader.result as string,
      ]);
      getS3Key({ imageData: files[0] });
    };
    reader.readAsDataURL(files[0]);
  };

  /** s3에 업로드 완료 */
  const uploadToS3 = ({
    imageData,
    uploadUrl,
  }: {
    imageData: File;
    uploadUrl: string;
  }) => {
    patchApi
      .uploadToS3({ imageUploadUrl: uploadUrl, image: imageData })
      .then(() => {
        console.log("완성");
      })
      .catch(() => {
        console.log("실패");
      });
  };

  /** 서버에서 사진한장에 해당하는 s3 key값과 upload Url 받아오기 */
  const getS3Key = ({ imageData }: { imageData: File }) => {
    if (userData.hostUuid && userData.hostEmail) {
      postApi
        .getS3Key({
          hostUuid: userData.hostUuid,
          hostEmail: userData.hostEmail,
          questionComponentUuid: props.questionComponentUuid,
          questionUuid: props.questionUuid,
        })
        .then(({ data }) => {
          props.setS3KeyList((s3KeyList) => [
            ...s3KeyList,
            data.preSignedUrl.imageS3Key,
          ]);
          uploadToS3({
            imageData,
            uploadUrl: data.preSignedUrl.imageUploadUrl,
          });
        })
        .catch(() => {});
    }
  };

  /** imageData에서 사진을 제거해주기 */
  const removeImg = (index: number) => {
    let tempImageData: string[] = props.imageData;
    tempImageData.splice(index, 1);
    let temp = Array.from(tempImageData);
    props.setImageData(temp);
  };

  /** s3keylist에서 해당 key 제거해주기 */
  const removeS3Key = (index: number) => {
    let tempS3KeyList: string[] = props.s3KeyList;
    tempS3KeyList.splice(index, 1);
    let temp = Array.from(tempS3KeyList);
    props.setS3KeyList(temp);
  };

  return (
    <StImageAttach>
      <TextLine
        text={t(`${baseLang}.attach`)}
        fontFamily="medium"
        fontsize={13}
        lineheight={18}
      />
      <DivTwoPartImprove
        display={props.imageData.length > 0 ? "grid" : "flex"}
        grid_template_column="1fr 1fr 1fr"
        pc_grid_template_column={
          props.imageData.length > 0 ? "repeat(auto-fill,82px)" : "flex"
        }
        gap={15}
        width="100%"
        height={props.imageData.length === 0 ? 114 : undefined}
        background_color="#F3F3F3"
        margin="10px 0 12px"
        pc_margin="12px 0 15px"
        padding="16px 18px"
        onDrop={dragImage}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
      >
        <input
          id="imageInput"
          type="file"
          onChange={uploadImage}
          multiple
          accept="image/jpeg, image/jpg, image/png, image/heic"
        />
        {props.imageData.length === 0 && (
          <label className="labelInitial" htmlFor="imageInput">
            <Img src={imgInput} width="18px" />
            <TextLine
              text={t(`${baseLang}.select`)}
              fontsize={14}
              fontFamily="medium"
              margin="0 0 0 7px"
            />
          </label>
        )}
        {props.imageData.length > 0 && props.imageData.length < 6 ? (
          <label htmlFor="imageInput">
            <DivTwoPartImprove
              display="flex"
              justify="center"
              align_items="center"
              border="1px solid #bdbdbd"
              border_radius={12}
              width="82px"
              height={82}
              cursor="pointer"
            >
              <Img src={imgAdd} />
            </DivTwoPartImprove>
          </label>
        ) : (
          ""
        )}
        {props.imageData.map((value: string, index: number) => {
          return (
            <DivTwoPartImprove
              width="82px"
              height={82}
              position="relative"
              overflow="hidden"
              border_radius={12}
            >
              <Img
                src={cancel}
                position="absolute"
                top="5px"
                right="5px"
                cursor="pointer"
                onclick={() => {
                  removeImg(index);
                  removeS3Key(index);
                }}
              />
              <Img src={value} width="82px" height={82} objectFit="cover" />
            </DivTwoPartImprove>
          );
        })}
      </DivTwoPartImprove>
      <DivTwoPartImprove
        display="grid"
        font_size={11}
        pc_font_size={12}
        pc_lineheight={18}
        lineheight={16}
        font_family=""
        text_align="center"
      >
        <DivTwoPartImprove
          display="grid"
          pc_display="flex"
          pc_justify="center"
          pc_margin="6px 0 0"
        >
          <TextLine text={t(`${baseLang}.info`)} />
        </DivTwoPartImprove>
      </DivTwoPartImprove>
    </StImageAttach>
  );
};

const StImageAttach = styled.div`
  padding: 14px 0;
  input {
    display: none;
  }
  .labelInitial {
    display: flex;
    /* grid-column-start: 2; */
    align-items: center;
    width: 151px;
    height: 44px;
    border-radius: 43px;
    background-color: white;
    padding: 11px 18px;
    box-sizing: border-box;
    margin: auto;
    cursor: pointer;
  }
  @media ${device.tabMob} {
    .labelInitial {
    }
  }
`;

export default ImageAttach;
