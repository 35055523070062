import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBack, ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

const RefundError = ({ errorCode, setRefundErrorModal }) => {
  const goBack = () => {
    setRefundErrorModal(false);
  };

  useEffect(() => {
    window.history.pushState("", null, window.location.href);
    window.addEventListener("popstate", goBack);
    return () => window.removeEventListener("popstate", goBack);
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StRefundError>
        <div className="wrapper">
          <ModalHeaderD title="적립금 환불 오류" />
          <div className="info">
            보유 적립금을 환불 신청하는 중에 오류가 발생하였어요. 자세한 내용은
            ‘환불 신청 내역’에서 확인이 가능해요.
          </div>
          <div className="errorCode">※ 오류 코드 : {errorCode}</div>
          <div
            className="confirmButton"
            onClick={() => window.history.back()}
            style={{ cursor: "pointer" }}
          >
            확인
          </div>
        </div>
      </StRefundError>
    </>
  );
};

const StRefundError = styled.div`
  position: fixed;
  z-index: 70;
  background-color: white;
  .info {
    font-size: 16px;
    line-height: 24px;
  }
  .errorCode {
    font-size: 16px;
    font-family: "semibold";
    line-height: 24px;
    margin-top: 20px;
  }
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .info {
      margin: 48px 0;
      font-size: 18px;
      font-family: "medium";
    }
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      border-radius: 43px;
      margin: 48px auto 0;
    }
  }
  @media ${device.tabMob} {
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 24px;
    box-sizing: border-box;
    width: 344px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
    }
    .confirmButton {
      text-align: center;
      margin-top: 24.5px;
      color: #0072de;
    }
    .info {
      font-family: "regular";
    }
  }
`;

export default RefundError;
