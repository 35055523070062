import React from "react";
import styled from "styled-components";

// styled component

// img
// import Back from '../img/ProctormaticBG2.png';
import Back from "../img/HomeBG.jpg";
import LandingThree from "../img/LandingThree.png";
import LandingMobileThree from "../img/LandingMobileThree.png";
import { device } from "../hooks/device";
import apple from "../img/apple.svg";
import playStore from "../img/playStore.svg";
import proctorLogo from "../img/proctorLogo.svg";
import { useRecoilValue } from "recoil";
import { bannerOn } from "../atoms";
import TextLine from "../atomic/atom/TextLine";
import { useTranslation } from "react-i18next";
import { HomeOneStyleProps } from "../interface/styleInterface";

const HomeThree = () => {
  const banneris = useRecoilValue(bannerOn);
  const { t } = useTranslation();
  const baseLang: string = "home.a1.firstPage.third";

  return (
    <>
      <StBackGround $banneris={banneris}>
        <div className="wrapper">
          <div className="leftBox">
            <div className="firstPart">
              <img src={proctorLogo} />
              <TextLine text={t(`${baseLang}.upTitle`)} />
            </div>
            <div className="middlePart">
              <div className="secondBlock">
                <div className="blueText">
                  <TextLine text={t(`${baseLang}.middleTitleOne`)} />
                  <TextLine text={t(`${baseLang}.middleTitleTwo`)} />
                </div>
                <div className="mainTitle">
                  <TextLine text={t(`${baseLang}.title`)} />
                </div>
              </div>
              <div className="buttonBlock">
                <div
                  className="startButton"
                  onClick={() => {
                    window.open(
                      `https://apps.apple.com/us/app/%ED%94%84%EB%A1%9D%ED%86%A0%EB%A7%A4%ED%8B%B1/id6473776826`
                    );
                  }}
                >
                  <img src={apple} />
                  App store
                </div>
                <div
                  className="startButton"
                  onClick={() => {
                    window.open(
                      `https://play.google.com/store/apps/details?id=com.proctormatic`
                    );
                  }}
                >
                  <img src={playStore} />
                  Google Play
                </div>
              </div>
            </div>
          </div>
          <img className="rightImgBox" src={LandingThree} />
          <img className="rightMobileImgBox" src={LandingMobileThree} />
        </div>
      </StBackGround>
    </>
  );
};

const StBackGround = styled.div<HomeOneStyleProps>`
  width: 100%;
  display: flex;
  align-items: center;
  .wrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
    width: 100%;
    overflow: hidden;
    .leftBox {
      grid-row: 1;
      grid-column: 2/7;
      display: flex;
      flex-direction: column;
      /* margin-left : auto; */
      .firstPart {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 10px;
        font-family: "regular";
        color: white;
      }
      .secondBlock {
        display: grid;
        flex-direction: column;
        gap: 22px;
        font-size: 25px;
        font-family: "medium";
        line-height: 37px;
        color: #55a2ff;
        .mainTitle {
          font-family: "bold";
          line-height: 130%;
          color: white;
        }
      }
      .buttonBlock {
        display: grid;
        grid-template-columns: 1fr;
        margin: 0 auto;
        gap: 18px;
        .startButton {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          align-items: center;
          gap: 8px;
          /* padding: 15px 11px; */
          box-sizing: border-box;
          width: 204px;
          height: 52px;
          color: white;
          border-radius: 43px;
          font-family: "medium";
          font-size: 17px;
          line-height: 25px;
          border: 1px solid #fff;
          cursor: pointer;
        }
      }
    }
  }
  @media ${device.pc} {
    width: 100%;
    height: ${(props) =>
      props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
    overflow: hidden;
    .wrapper {
      height: ${(props) =>
        props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
      .leftBox {
        .firstPart {
          font-size: 20px;
          line-height: normal;
        }
        .middlePart {
          font-size: 62px;
          .secondBlock {
            margin: 82px 0 48px;
            .mainTitle {
              font-size: 62px;
            }
            .blueText {
              display: flex;
              flex-direction: column;
            }
          }
        }
        .buttonBlock {
          grid-template-columns: auto auto 1fr;
        }
      }
      .rightImgBox {
        grid-row: 1;
        grid-column: 5/11;
        height: ${(props) =>
          props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
        margin: 0 0 0 auto;
        padding-left: 50px;
      }
      .rightMobileImgBox {
        display: none;
      }
    }
  }
  @media ${device.tabMob} {
    height: ${(props) =>
      props.$banneris ? "calc(100vh - 108px)" : "calc(100vh - 60px)"};
    min-height: 675px;
    .wrapper {
      display: flex;
      flex-direction: column;
      height: ${(props) =>
        props.$banneris ? "calc(100vh - 108px)" : "calc(100vh - 60px)"};
      min-height: 675px;
      .leftBox {
        .firstPart {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "regular";
          line-height: 18px;
          font-size: 14px;
          margin-top: 48px;
          img {
            width: 15px;
            height: 13px;
          }
        }
        .middlePart {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: ${(props) =>
            props.$banneris ? "calc(100vh - 368px)" : "calc(100vh - 320px)"};
          min-height: 415px;
          .secondBlock {
            text-align: center;
            gap: 12px;
            font-size: 14px;
            font-family: "medium";
            line-height: 21px;
            margin-bottom: 38px;
            .blueText {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .mainTitle {
            }
          }
          .buttonBlock {
            justify-content: center;
            gap: 14px;
            .startButton {
              height: 42px;
              width: 151px;
              font-size: 14px;
              font-family: "medium";
              line-height: 25px;
            }
          }
        }
      }
      .rightMobileImgBox {
        height: 194px;
        margin-top: auto;
      }
      .rightImgBox {
        display: none;
      }
    }
  }
  @media ${device.tablet} {
    .mainTitle {
      font-size: 36px;
    }
  }
  @media ${device.mobile} {
    .wrapper {
      .leftBox {
        .middlePart {
          .secondBlock {
            .mainTitle {
              font-size: 30px;
            }
          }
          .buttonBlock {
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
`;

export default HomeThree;
