import React from "react";
import styled from "styled-components";
import circleFill from "../../img/circleFilled.svg";
import circleEmpty from "../../img/circleEmpty.svg";
import Img from "../atom/Img";
import { device } from "../../hooks/device";

interface circleStyleProps {
  gap?: number;
  width?: string;
  pc_item_width?: string;
  fontfamily?: string;
  fontsize?: number;
  pc_fontsize?: number;
  justify?: string;
}

interface CheckCircleProps extends circleStyleProps {
  text: string;
  choosed: number;
  count: number;
  //   setChoosed: React.Dispatch<React.SetStateAction<boolean>>;
  onclick: () => void;
}

const CheckCircleWithText: React.FC<CheckCircleProps> = (props) => {
  return (
    <StCheckCircle
      gap={props.gap}
      width={props.width}
      fontsize={props.fontsize}
      fontfamily={props.fontfamily}
      pc_item_width={props.pc_item_width}
      pc_fontsize={props.pc_fontsize}
      justify={props.justify}
      onClick={props.onclick}
    >
      {props.choosed === props.count ? (
        <Img margin={`0 ${props.gap}px 0 0`} src={circleFill} />
      ) : (
        <Img margin={`0 ${props.gap}px 0 0`} src={circleEmpty} />
      )}
      {props.text}
    </StCheckCircle>
  );
};

const StCheckCircle = styled.div<circleStyleProps>`
  display: flex;
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  align-items: center;
  width: ${(props) => props.width};
  font-size: ${(props) => props.fontsize}px;
  font-family: ${(props) => props.fontfamily};
  cursor: pointer;
  @media ${device.pc} {
    width: ${(props) => props.pc_item_width};
    font-size: ${(props) => props.pc_fontsize}px;
  }
`;

export default CheckCircleWithText;
