import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Pagination from "../components/Pagination";
import arrow from "../img/arrow.svg";
import arrowLeft from "../img/ArrowLeft.png";
import arrowRight from "../img/ArrowRight.png";
import { device } from "../hooks/device";
import QnaTag from "../components/QnaTag";
import useGetQnAList from "../hooks/useGetQnAList";
import useGetQnACount from "../hooks/useGetQnACount";
import { useTranslation } from "react-i18next";

const QnA = ({ setMyPageScroll }) => {
  const { t } = useTranslation();
  const baseLang = "customer.h2_1_1";
  const navigate = useNavigate();
  const pageCount = Number(useParams().page);
  const { data: count } = useGetQnACount();
  const [pageCounting, setPageCount] = useState(); //

  const [pageShowing, setPageShowing] = useState([]);

  const { data: listing } = useGetQnAList({ listPage: pageCount });

  const questionList = listing?.questionList;
  // 총 페이지 수 세기
  useEffect(() => {
    setPageCount(Math.ceil(count?.questionCount / 15));
  }, [count?.questionCount]);

  // 페이지네이션
  useEffect(() => {
    let temp = [];
    if ((pageCount <= 3) & (pageCounting > 4)) {
      setPageShowing([1, 2, 3, 4, 5]);
    } else if (pageCounting <= 4) {
      for (let i = 1; i <= pageCounting; i++) {
        temp.push(i);
      }
      setPageShowing(temp);
    } else {
      if (pageCounting <= 5) {
        for (let i = 1; i <= pageCounting; i++) {
          temp.push(i);
        }
        setPageShowing(temp);
      } else if (pageCounting > 5) {
        if (pageCount <= pageCounting - 2) {
          for (let i = pageCount - 2; i <= pageCount + 2; i++) {
            temp.push(i);
          }
          setPageShowing(temp);
        } else {
          for (let i = pageCounting - 4; i <= pageCounting; i++) {
            temp.push(i);
          }
          setPageShowing(temp);
        }
      }
    }
  }, [pageCounting, pageCount]);

  return (
    <>
      <StNotice>
        <div className="container">
          <div className="title">
            <img
              className="arrow"
              onClick={() => {
                navigate(`/myPage`);
                setMyPageScroll("customerCenter");
              }}
              src={arrow}
              alt=""
            />
            {t(`${baseLang}.customer`)}
          </div>
          <div className="middleTitleBoxBox">
            <div className="middleTitleBox">
              <img
                className="arrow"
                onClick={() => {
                  navigate(`/myPage`);
                  setMyPageScroll("customerCenter");
                }}
                src={arrow}
                alt=""
              />
              <div className="middleTitle">{t(`${baseLang}.title`)}</div>
            </div>
            <div
              className="askButtonM"
              onClick={() => {
                navigate(`/support`);
              }}
            >
              {t(`${baseLang}.submit`)}
            </div>
          </div>
          <div className="inbox">
            <div className="smallTitle">{t(`${baseLang}.title`)}</div>
            <div className="middleBox">
              <div className="middleInfo">
                <div className="middleInfoTitle">
                  {t(`${baseLang}.operation`)}
                </div>
                <div className="middleInfoTimeLine">
                  <div className="middleInfoTimeLineUp">
                    {t(`${baseLang}.time`)}
                  </div>
                  <div className="middleInfoTimeLineDown">
                    * {t(`${baseLang}.info`)}
                  </div>
                </div>
              </div>
              <div
                className="askButton"
                onClick={() => {
                  navigate(`/support`);
                }}
              >
                {t(`${baseLang}.submit`)}
              </div>
            </div>
            <div className="thickBar" />
            {questionList?.length > 0 ? (
              <div className="bottomBucket">
                <div className="bottomBox">
                  <div className="lastTag">
                    <div className="firstTag">{t(`${baseLang}.inquiry`)}</div>
                    <div className="secondTag">{t(`${baseLang}.qnaTitle`)}</div>
                    <div className="thirdTag">{t(`${baseLang}.date`)}</div>
                  </div>
                  <div className="thinBar" />
                  {questionList?.map((value, index) => (
                    <div
                      onClick={() => {
                        navigate(`/qnaDetail/${value.questionUuid}`);
                      }}
                      key={index}
                    >
                      <QnaTag
                        value={value}
                        category={value.category}
                        date={value.createdAt}
                        uid={value.questionUuid}
                        title={value.title}
                        isAnswered={value.isAnswered}
                      />
                      <div className="thinGreyBar" />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="nothing">{t(`${baseLang}.noQuestion`)}</div>
            )}
            {pageShowing?.length > 1 && (
              <div className="pagination">
                {pageShowing?.map((value, index) => (
                  <div key={index}>
                    <Pagination nowPage={pageCount} type="qna" page={value} />
                  </div>
                ))}
                {pageCount !== 1 && (
                  <img
                    className="leftArrow"
                    onClick={() => {
                      navigate(`/qna/${pageCount - 1}`);
                    }}
                    src={arrowLeft}
                    alt=""
                  />
                )}
                {parseInt(count.questionCount / 15) + 1 !== pageCount && (
                  <img
                    className="rightArrow"
                    onClick={() => {
                      navigate(`/qna/${pageCount + 1}`);
                    }}
                    src={arrowRight}
                    alt=""
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </StNotice>
    </>
  );
};

const StNotice = styled.div`
  @media ${device.pc} {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 353px);
    box-sizing: border-box;
    padding: 80px 0px;
    background-color: #f0f1f2;
    .middleBox {
      line-height: 24px;
      display: flex;
      .middleInfo {
        display: flex;
        .middleInfoTitle {
          font-family: "bold";
        }
        .middleInfoTimeLine {
          margin-left: 68px;
          .middleInfoTimeLineUp {
            line-height: 24px;
            margin-bottom: 8px;
          }
          .middleInfoTimeLineDown {
            font-size: 14px;
            line-height: 24px;
            color: #55595f;
          }
        }
      }
      .askButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 110px;
        height: 39px;
        background-color: #20315b;
        color: white;
        border-radius: 20px;
        font-size: 13px;
        font-family: "medium";
        margin-left: auto;
        cursor: pointer;
      }
    }
    .smallTitle {
      font-family: "medium";
      font-size: 28px;
      padding: 0 0 36px;
    }
    .pagination {
      position: relative;
      display: inline-flex;
      justify-content: space-between;
      margin: 60px auto 0;
      .rightArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        right: -50px;
        cursor: pointer;
      }
      .leftArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        left: -50px;
        cursor: pointer;
      }
    }
    .container {
      width: 1228px;
      margin: 0 auto;
    }
    .inbox {
      display: flex;
      flex-direction: column;
      margin: 60px auto 0;
      padding: 60px 64px;
      box-sizing: border-box;
      background-color: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
    }
    .title {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      font-size: 28px;
      font-family: "Bold";
      line-height: 41px;
      gap: 19px;
      .arrow {
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
    }
    .thickBar {
      width: 100%;
      height: 3px;
      background-color: black;
      margin-top: 24px;
    }
    .bottomBucket {
      .bottomBox {
        min-height: 239px;
        .lastTag {
          display: flex;
          justify-content: space-between;
          .firstTag {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 52px;
            padding: 14px 24px;
            box-sizing: border-box;
          }
          .secondTagS {
            display: flex;
            /* justify-content: center; */
            align-items: center;
            width: 780px;
            height: 52px;
            padding: 14px 24px;
            box-sizing: border-box;
          }
          .secondTag {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 780px;
            height: 52px;
            padding: 14px 24px;
            box-sizing: border-box;
          }
          .thirdTag {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 160px;
            height: 52px;
            padding: 14px 24px;
            box-sizing: border-box;
          }
        }
        .thinBar {
          width: 100%;
          height: 1px;
          background-color: black;
        }
        .thinGreyBar {
          width: 100%;
          height: 1px;
          background-color: rgba(217, 217, 217, 0.5);
        }
      }
    }
    .nothing {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 239px;
      color: #bdbdbd;
      font-size: 16px;
      font-family: "regular";
      line-height: 19px;
    }
    .middleTitleBox,
    .askButtonM {
      display: none;
    }
  }
  @media ${device.tabMob} {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: #f0f1f2;
    .middleBox {
      margin-right: auto;
      padding: 0px 24px;
      .middleInfo {
        .middleInfoTitle {
          font-family: "bold";
          padding: 6px 0px;
        }
        .middleInfoTimeLine {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          /* height: 85px; */
          padding: 8px 0 24px;
          box-sizing: border-box;
          .middleInfoTimeLineUp {
          }
          .middleInfoTimeLineDown {
            margin-top: 8px;
            font-size: 14px;
            color: #55595f;
          }
        }
      }
    }
    .middleTitleBoxBox {
      padding: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .askButtonM {
        font-size: 18px;
        font-family: "medium";
        cursor: pointer;
      }
    }
    .pagination {
      position: relative;
      display: inline-flex;
      justify-content: space-between;
      margin: 60px auto 48px;
      .rightArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        right: -50px;
        cursor: pointer;
      }
      .leftArrow {
        position: absolute;
        transform: translate(0%, -50%);
        top: 50%;
        left: -50px;
        cursor: pointer;
      }
    }
    .bottomBucket {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-height: 471px;
    }
    .bottomBox {
      filter: drop-shadow(0px 5px 6px rgba(0, 0, 0, 0.08));
      border-radius: 24px;
      background-color: white;
      width: 100%;
      max-width: 731px;
    }
    .nothing {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 400px;
      color: #bdbdbd;
      line-height: 19px;
      font-size: 16px;
      font-family: "regular";
    }
    .container {
      width: 100%;
      max-width: 731px;
    }
    .middleTitleBox {
      display: grid;
      grid-template-columns: auto auto 1fr;
      align-items: center;
      gap: 12px;
      .middleTitle {
        font-size: 19px;
        font-family: "bold";
        line-height: 28px;
      }
      .arrow {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
    }
    .inbox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
    }
    .title,
    .smallTitle,
    .lastTag,
    .askButton {
      display: none;
    }
    /* .thinBar{
                width: 100%;
                height: 1px;
                background-color: black;
            } */
    .thinGreyBar {
      width: 100%;
      height: 1px;
      background-color: rgba(217, 217, 217, 0.5);
    }
  }
`;

export default QnA;
