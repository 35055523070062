import { useQuery } from "react-query";
import { useRecoilValue } from "recoil";
import apis from "../api/getApi";
import { examUpdate } from "../atoms";

const useGetAwait = ({ uid }) => {
  const utc = new Date().getTimezoneOffset();
  const update = useRecoilValue(examUpdate);
  const fetcher = async () => {
    const { data } = await apis.getAwaitExam({ utc, uid });
    return data;
  };
  return useQuery(["await", update], fetcher);
};

export default useGetAwait;
