import { useRecoilState, useSetRecoilState } from "recoil";
import { toastMessage, toastOn, toastUpdate } from "../atoms";

const useToast = () => {
  const setToast = useSetRecoilState(toastOn);
  const setToastMessage = useSetRecoilState(toastMessage);
  const [update, setUpdate] = useRecoilState(toastUpdate);

  const showToast = ({ message }) => {
    const toastOut = () =>
      setTimeout(() => {
        setToast(false);
      }, 4000);

    setToast(true);
    setToastMessage(`${message}`);
    setUpdate(!update);
    toastOut();
  };
  return showToast;
};

export default useToast;
