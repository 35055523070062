import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import styled from "styled-components";

// img

import { useRecoilValue } from "recoil";
import { bannerOn, userInfo } from "../atoms";
import { device } from "../hooks/device";
import deleting from "../img/delete.svg";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { baseURL } from "../api/handler";
import { ModalBack, ModalHeader } from "../components/Style";
import customAxios from "../api/handler";
import useModal from "../hooks/useModal";
import { useTranslation } from "react-i18next";
import TextLine from "../atomic/atom/TextLine";

const Bye = ({ setConfirmModalOn, setByeModalOn }) => {
  const { t } = useTranslation();
  const baseLang = "mypage.f5";
  const height = window.innerHeight - 433;
  const banneris = useRecoilValue(bannerOn);

  const [one, setOne] = useState(false);
  const [oneFocus, setOneFocus] = useState(false);
  const [oneValue, setOneValue] = useState(false);

  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [error, setError] = useState(false);

  const user = useRecoilValue(userInfo);
  const focus = useRef();

  // 모달 필수 함수
  useModal(setByeModalOn);

  // 회원 탈퇴 버튼 클릭 시 비밀번호 확인
  const PasswordCheck = () => {
    customAxios
      .post(`${baseURL}/api/v1/hosts/delete?hostPlan=${user?.hostPlan}`, {
        hostPassword: password.target.value,
      })
      .then(() => {
        setConfirmModalOn(true);
        setByeModalOn(false);
      })
      .catch(({ response }) => {
        if (response.data.errorCode === "INCORECT-DB-PWD") {
          setError(true);
        }
      });
  };

  useEffect(() => {
    if (oneValue && oneFocus) {
      setOne(true);
    } else {
      setOne(false);
    }
  }, [oneValue, oneFocus, password]);

  useEffect(() => {
    function handleOutside(e) {
      if (focus.current && !focus.current.contains(e.target)) {
        setOneFocus(false);
      }
    }
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, [focus]);

  useEffect(() => {
    if (password?.target?.value) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [password]);

  const Password_check = (element) => {
    // console.log(element)
    const legPass = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    if (!legPass.test(element)) {
      setPassErr(true);
    } else {
      setPassErr(false);
    }
  };

  useEffect(() => {
    Password_check(password?.target?.value);
  }, [password?.target?.value]);

  useScrollLockThree();

  return (
    <>
      <ModalBack className="backGround" />
      <StBack
        banneris={banneris}
        innerHeight={height}
        errorr={error}
        className="back"
        one={one}
        validation={passErr}
        active={active}
      >
        <div className="contents">
          <ModalHeader title={t(`${baseLang}.title`)} />
          <div className="what">
            <div className="mid">{t(`${baseLang}.body`)}</div>
            <div className="inputs">
              <div className="email">{user?.hostEmail}</div>
              <div className="line" />
              <div className="inputBox">
                <input
                  ref={focus}
                  onFocus={() => {
                    setOneFocus(true);
                  }}
                  onBlur={() => {
                    setError(false);
                  }}
                  onChange={(e) => {
                    setPassword(e);
                    setError(false);
                    if (e.target.value) {
                      setOneValue(true);
                    } else if (e.target.value === "") {
                      setOneValue(false);
                    }
                  }}
                  type="password"
                  className="input"
                  placeholder={t(`${baseLang}.placeholder`)}
                />
                <img
                  src={deleting}
                  className="deleteOne"
                  alt=""
                  onClick={() => {
                    focus.current.value = "";
                    setOneFocus(false);
                    setOneValue(false);
                    setPassword("");
                  }}
                />
              </div>
              {error && (
                <div className="errorBox">
                  <div className="line" />
                  <div className="wrongPassword">
                    {t(`errorMessage.missMatch`)}
                  </div>
                </div>
              )}
            </div>
            <div className="info">
              <TextLine text="*" margin="0 4px 0 0" /> {t(`${baseLang}.info1`)}
            </div>
            <div className="info2">
              <TextLine text="*" margin="0 4px 0 0" /> {t(`${baseLang}.info2`)}
            </div>
          </div>
          <div className="mobiles">
            <button
              disabled={!password?.target?.value}
              onClick={() => {
                PasswordCheck();
              }}
              className="button"
            >
              {t(`${baseLang}.button`)}
            </button>
          </div>
          <div className="pc">
            <button
              disabled={!password?.target?.value}
              onClick={() => {
                PasswordCheck();
              }}
              className="button"
            >
              {t(`${baseLang}.button`)}
            </button>
          </div>
        </div>
      </StBack>
    </>
  );
};

const StBack = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${device.pc} {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 580px;
    padding: 60px;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    z-index: 53;
    .pc {
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 204px;
        height: 52px;
        color: white;
        font-size: 17px;
        margin: 67px auto 0;
        font-family: "Medium";
        border: none;
        background: ${(props) => (props.active ? "#ED893E;" : "#D9D9D9")};
        border-radius: 43px;
        /* margin-top : 67px;  */
        cursor: ${(props) => (props.active ? "pointer" : "")};
      }
    }
    .mobiles {
      display: none;
    }
    .what {
      padding: 0 50px;
      margin-top: 68px;
      box-sizing: border-box;
    }
    .contents {
      width: 100%;
    }
    .wrongPassword {
      font-size: 12px;
      color: red;
      margin-top: 5px;
    }
    .arrow {
      display: none;
    }
    .mid {
      font-size: 15px;
      font-family: "Medium";
      margin-bottom: 20px;
    }
    .email {
      font-size: 16px;
      color: #2276dc;
    }
    .inputBox {
      position: relative;
      font-size: 15px;
      height: 50px;
      padding: 14px 0 0;
      box-sizing: border-box;
      input {
        width: 100%;
      }
      .deleteOne {
        display: ${(props) => (props.one ? "" : "none")};
        position: absolute;
        right: 0px;
        top: 50%;
        cursor: pointer;
      }
    }
    .input {
      border: none;
      width: 360px;
      height: 35px;
      font-size: 16px;
      padding: 1px 0px;
      border-bottom: ${(props) =>
        props.errorr ? "1px solid red" : "1px solid #DFDFDF"};
      /* margin : 16px 0; */
      &:focus {
        outline: none;
        border-bottom: ${(props) =>
          props.errorr ? "1px solid red" : "1px solid #2276DC"};
      }
    }
    .info,
    .info2 {
      display: flex;
      font-size: 12px;
      font-family: "Regular";
      color: #444444;
      margin-top: 16px;
    }
    .info2 {
      margin-top: 8px;
    }
    .title {
      font-size: 26px;
      width: 360px;
      height: 38px;
      text-align: center;
      font-family: "Bold";
      margin: 44px 0 90px 0;
    }
    .headerBox {
      display: flex;
      align-items: center;
      width: 360px;
      justify-content: space-between;
      .logo {
      }
      .x {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    bottom: 0;
    left: 50%;
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    /* min-height: 680px; */
    box-sizing: border-box;
    transform: translate(-50%, 0);
    background-color: #f0f1f2;
    z-index: 49;
    .pc {
      display: none;
    }
    .errorBox {
      width: 100%;
      padding: 0px 0 5px;
      .line {
        background-color: red;
      }
      .wrongPassword {
        font-size: 12px;
        color: red;
        margin-top: 5px;
      }
    }
    .mobiles {
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding: 293px 0 22px;
      box-sizing: border-box;
      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 204px;
        height: 42px;
        color: white;
        font-size: 17px;
        font-family: "Medium";
        background: ${(props) => (props.active ? "#ED893E;" : "#D9D9D9")};
        border: none;
        border-radius: 43px;
        cursor: ${(props) => (props.active ? "pointer" : "")};
      }
    }
    .contents {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 24px;
      box-sizing: border-box;
      max-width: 731px;
      overflow-x: hidden;
      ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        margin-top: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
    .what {
      width: 100%;
    }
    .line {
      width: 100%;
      height: 1px;
      background-color: #d9d9d9;
    }
    .inputs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% + 48px);
      margin: 0 48px 16px -24px;
      padding: 0 24px;
      box-sizing: border-box;
      min-height: 105px;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 24px;
    }
    .for {
      margin-left: 18.6px;
    }
    .arrow {
      width: 11px;
      height: 18px;
      cursor: pointer;
    }
    .mid {
      font-size: 15px;
      color: #909090;
      font-family: "Regular";
      padding: 12px 0 5px;
    }
    .email {
      width: 100%;
      height: 52px;
      font-size: 15px;
      color: #2276dc;
      padding: 14px 0;
      box-sizing: border-box;
    }
    .inputBox {
      display: flex;
      align-items: center;
      width: 100%;
      height: 52px;
      font-size: 15px;
      padding: 14px 0;
      box-sizing: border-box;
      position: relative;
      .deleteOne {
        display: ${(props) => (props.one ? "" : "none")};
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translate(0%, -50%);
        cursor: pointer;
      }
    }
    .input {
      border: none;
      width: 100%;
      height: 17px;
      /* font-size : 50px; */
      margin: 0 auto;
      &::placeholder {
        color: #bdbdbd;
        font-size: 16px;
        font-family: "light";
      }
      &:focus {
        outline: none;
        /* border-bottom: 1px solid #2276DC; */
      }
    }
    .info {
      font-size: 11px;
      font-family: "Regular";
      color: #6e6e6e;
      margin-bottom: 10px;
    }
    .info2 {
      font-size: 11px;
      font-family: "Regular";
      color: #6e6e6e;
      margin-bottom: 10px;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 19px;
      width: 100%;
      text-align: center;
      padding: 16px 24px;
      box-sizing: border-box;
      font-family: "Bold";
    }
    .headerBox {
      display: flex;
      align-items: center;

      justify-content: space-between;
      .logo {
        display: none;
      }
      .x {
        display: none;
      }
    }
  }
`;

export default Bye;
