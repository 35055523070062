import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ModalBack, ModalHeaderD } from "../components/Style";

// img
import check from "../img/confirm.svg";
import { device } from "../hooks/device";

const RefundInfoModal = ({ setRefundInfoModal, refundInfoModal }) => {
  const [checking, setChecking] = useState(false);

  const goBack = () => {
    setRefundInfoModal(false);
  };

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    return () => {
      if (!refundInfoModal)
        // 버튼이 눌리지 않았을 때
        window.removeEventListener("popstate", goBack);
    };
  }, []);

  return (
    <>
      <StBack />
      <StInnerModal checking={checking}>
        <ModalHeaderD title="추가 적립금(20%)에 따른 환불 불가 금액 정보" />
        <div className="infoPart">
          환불 신청 시, 적립금 구매 건별 추가적으로 지급받은 금액(20% 추가 적립
          혜택)은 아래와 같은 산정 방식을 통해 환불 가능 금액에서 차감되며
          동시에 자동으로 소멸처리 돼요.
        </div>
        <div className="middleTitle">※ 구매 건별 차감/소멸 금액 산정 방식</div>
        <div className="calculation">( a / b ) x c = d</div>
        <div className="calculationInfo">
          예시. 100만원 구매 후 총 120만원 적립받아 40만원 사용 시 (20만원 /
          120만원) x 80만원 = 13.3만원 차감/소멸
        </div>
        <div className="calculationList">
          <div>a. 혜택 받은 적립금액(20% 추가 적립) </div>
          <div>b. 구매 금액 포함 지급된 총 적립금액</div>
          <div>c. 사용 후 잔여 적립금액</div>
          <div>d. 최종 차감/소멸 금액</div>
        </div>
        <div
          className="confirmButton"
          onClick={() => {
            setRefundInfoModal(false);
          }}
          style={{ cursor: "pointer" }}
        >
          확인
        </div>
      </StInnerModal>
    </>
  );
};

const StInnerModal = styled.div`
  position: fixed;
  z-index: 72;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  margin: 0 auto;
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .infoPart {
      font-family: "medium";
    }
    .calculationList {
      font-family: "medium";
    }
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      box-sizing: border-box;
      border-radius: 43px;
      font-size: 17px;
      font-family: "medium";
      margin: 48px auto 0;
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    padding: 24px;
    box-sizing: border-box;
    border-radius: 26px;
    .infoPart {
      font-family: "regular";
    }
    .calculationList {
      font-family: "regular";
    }
    .confirmButton {
      color: #2276dc;
      text-align: center;
      border: none;
      margin-top: 20px;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      cursor: ${(props) => (props.checking ? "pointer" : "default")};
    }
  }
  .titlePart {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .infoPart {
    font-size: 16px;
    line-height: 24px;
    margin: 20px 0;
  }
  .middleTitle {
    font-size: 16px;
    font-family: "semibold";
    line-height: 24px;
  }
  .calculation {
    font-size: 18px;
    font-family: "bold";
    line-height: 27px;
    margin: 12px 0 5px;
  }
  .calculationInfo {
    font-size: 12px;
    font-family: "regular";
    line-height: 18px;
    color: #818181;
    margin-bottom: 12px;
  }
  .calculationList {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    line-height: 24px;
  }
  .clickPart {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: "regular";
    line-height: 19px;
    padding: 5px;
    .bigCircle {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-right: 13px;
      border: 1px solid #8c8c8c;
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }
    img {
      margin-right: 13px;
      width: 20px;
      height: 20px;
    }
  }
`;

const StBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 71;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;

export default RefundInfoModal;
