import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import spinner from "../img/spinner.svg";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { useTranslation } from "react-i18next";

const SendingConfirm = ({
  selectedVideoSend,
  setSendingConfirmModal,
  setVideoReady,
  videoReady,
}) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d3_11_3";
  const modalOff = () => {
    window.history.back();
  };

  const goBack = () => {
    setSendingConfirmModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      setVideoReady(false);
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD modalOff={modalOff} />
      <StSendingConfirm>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        {!videoReady ? (
          <div className="wrapper">
            <div className="textBox">{t(`${baseLang}.body`)}</div>
            <div className="warningBox">
              <div className="warningHeader">
                ※ &nbsp;&nbsp;<span>{t(`${baseLang}.listHeader`)}</span>
              </div>
              <div className="warningList">
                <li>{t(`${baseLang}.list1`)}</li>
                <li>{t(`${baseLang}.list2`)}</li>
              </div>
            </div>
            <div className="buttonBox">
              <div
                className="cancelButton"
                onClick={() => {
                  setVideoReady(false);
                  window.history.back();
                }}
              >
                {t(`${baseLang}.button1`)}
              </div>
              <div className="greyBar" />
              <div
                className="confirmButton"
                onClick={() => {
                  selectedVideoSend();
                  setVideoReady(true);
                }}
              >
                {t(`${baseLang}.button2`)}
              </div>
            </div>
          </div>
        ) : (
          <div className="spinBox">
            <img className="spinner" src={spinner} />
          </div>
        )}
      </StSendingConfirm>
    </>
  );
};

const StSendingConfirm = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
  .spinBox {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 284px;
    .spinner {
      width: 67px;
      height: 67px;
      animation: rotate_image 1.5s linear infinite;
    }
  }
  .textBox {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
  }
  .warningBox {
    display: grid;
    gap: 4px;
    .warningHeader {
      span {
        font-family: "semibold";
      }
      line-height: 24px;
    }
    .warningList {
      display: grid;
      gap: 4px;
      line-height: 24px;
      color: #444;
      margin-left: 3px;
    }
  }
  @media ${device.pc} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    display: grid;
    gap: 48px;
    .wrapper {
      display: grid;
      flex-direction: column;
      gap: 48px;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 21px;
      font-size: 17px;
      font-family: "medium";
      line-height: 27px;
      .cancelButton,
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        margin: 0 auto;
        cursor: pointer;
      }
      .confirmButton {
        background-color: #20315b;
        border: none;
        color: white;
      }
      .greyBar {
        display: none;
      }
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    width: 344px;
    /* height: 207px; */
    padding: 24px;
    box-sizing: border-box;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 26px;
    .textBox {
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
      margin-bottom: 20px;
    }
    .warningBox {
      .warningList {
        color: black;
      }
      .warningHeader {
        font-size: 16px;
      }
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto 1px auto;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      padding: 4.5px 0;
      box-sizing: border-box;
      margin-top: 24px;
      .cancelButton,
      .confirmButton {
        width: 147.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        color: #0072de;
        cursor: pointer;
      }
      .confirmButton {
        border: none;
      }
      .greyBar {
        width: 1px;
        height: 16px;
        background-color: #e6e6e6;
      }
    }
    .spinBox {
      display: flex;
      /* flex: 1; */
      justify-content: center;
      align-items: center;
      height: 232px;
      .spinner {
        width: 42px;
        height: 42px;
        animation: rotate_image 1.5s linear infinite;
      }
    }
  }
`;

export default SendingConfirm;
