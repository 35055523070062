import { useEffect } from "react";
import useScrollLockThree from "./useScrollLockThree";

const useModalMust = ({ modal }) => {
  const goBack = () => {
    modal(false);
  };

  useEffect(() => {
    window.history.pushState("", null, window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();
};

export default useModalMust;
