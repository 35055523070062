import React from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface buttonProps {
  margin?: string;
  width?: string;
  height?: number;
  font_size?: number;
  font_color?: string;
  font_family?: string;
  border_radius?: number;
  border?: string;
  pc_width?: string;
  pc_height?: number;
  pc_fontsize?: number;
  pc_fontcolor?: string;
  pc_fontfamily?: string;
  pc_border_radius?: number;
  pc_border?: string;
  text?: string;
  placeholdercolor?: string;
  padding?: string;
  backgroundcolor?: string;
  pc_backgroundcolor?: string;
  cursor?: string;
  disabled?: boolean;
  onclick?: () => void;
}

const Button: React.FC<buttonProps> = (props) => {
  return (
    <StButton
      type="submit"
      margin={props.margin}
      onClick={props.onclick}
      width={props.width}
      height={props.height}
      font_size={props.font_size}
      font_color={props.font_color}
      font_family={props.font_family}
      border={props.border}
      border_radius={props.border_radius}
      pc_width={props.pc_width}
      pc_height={props.pc_height}
      pc_fontsize={props.pc_fontsize}
      pc_fontcolor={props.pc_fontcolor}
      pc_fontfamily={props.pc_fontfamily}
      pc_border={props.pc_border}
      pc_border_radius={props.pc_border_radius}
      padding={props.padding}
      backgroundcolor={props.backgroundcolor}
      pc_backgroundcolor={props.pc_backgroundcolor}
      cursor={props.cursor}
      disabled={props.disabled}
    >
      {props.text}
    </StButton>
  );
};

const StButton = styled.button<buttonProps>`
  margin: ${(props) => props.margin};
  width: ${(props) => (props.width ? props.width : "204px")};
  height: ${(props) => (props.height ? props.height : 52)}px;
  font-size: ${(props) => props.font_size}px;
  font-family: ${(props) => props.font_family};
  color: ${(props) => props.font_color};
  border-radius: ${(props) => props.border_radius}px;
  border: ${(props) => props.border};
  padding: ${(props) => props.padding};
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundcolor};
  cursor: ${(props) => (props.cursor ? props.cursor : "pointer")};
  @media ${device.pc} {
    width: ${(props) => props.pc_width};
    height: ${(props) => props.pc_height}px;
    font-size: ${(props) => props.pc_fontsize}px;
    font-family: ${(props) => props.pc_fontfamily};
    color: ${(props) => props.pc_fontcolor};
    border-radius: ${(props) => props.pc_border_radius}px;
    border: ${(props) => props.pc_border};
    background-color: ${(props) => props.pc_backgroundcolor};
  }
`;

export default Button;
