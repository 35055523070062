import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalBack, ModalHeader } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";

// img
import questionMark from "../img/Q.svg";
import check from "../img/blueConfirm.svg";
import { device } from "../hooks/device";
import refunderror from "../img/refundError.svg";
import useGetCreditForRefund from "../hooks/useGetCreditForRefund";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { bannerOn, languageIs } from "../atoms";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import Button from "../atomic/atom/Button";

const Refund = ({
  refundModal,
  refundError,
  setRefundError,
  refundErrorCode,
  setMyPageScroll,
  refunded,
  setRefunded,
  setRefundInfoModal,
  setRefundModal,
  setRefundInModal,
}) => {
  const { data } = useGetCreditForRefund();

  const [refundAmount, setRefundAmount] = useState();
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const banneris = useRecoilValue(bannerOn);

  useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    return () => {
      setRefundError(false);
    };
  }, []);

  useEffect(() => {
    setRefundAmount(Number(data?.cancelData?.targetCredits));
  }, [data]);

  const goBack = () => {
    setRefundModal(false);
  };

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    return () => {
      setRefunded(false);
      if (!refundModal)
        // 버튼이 눌리지 않았을 때
        window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBack />
      <StRefund banneris={banneris} refundAmount={refundAmount}>
        <div className="wrapper">
          {!refundError & !refunded ? <ModalHeader title="환불하기" /> : <></>}
          {/* {refundError ? ( // 환불 에러케이스
            <div className="modalBody">
              <div className="confirmBox">
                <img src={refunderror} />
                <div style={{ color: "black" }}>
                  {refundAmount}
                  <span>원</span>
                </div>
                <span>환불 신청 중에 오류가 발생하였어요!</span>
                <div className="errorCode">(오류 코드 : {refundErrorCode})</div>
              </div>
              <div className="confirmInfoBox">
                환불은 결제한 카드사 기준으로 3~4일 가량 소요돼요. <br />
                자세한 문의는{" "}
                <span
                  onClick={() => {
                    navigate(`/mypage`);
                    setMyPageScroll("customerCenter");
                  }}
                >
                  고객센터
                </span>
                로 연락주시면 신속하게 도와드릴게요.
              </div>
              <div className="buttonBox">
                <div
                  className="button"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setRefundModal(false);
                  }}
                >
                  확인
                </div>
                <div
                  className="customerButton"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/myPage`);
                    setMyPageScroll("customerCenter");
                  }}
                >
                  고객센터
                </div>
              </div>
            </div>
          ) : (
            <div>
              {refunded ? ( //  환불 신청 완료
                <div className="modalBodyComplete">
                  <div className="confirmBox">
                    <img src={check} />
                    <div>
                      {refundAmount}
                      <span>원</span>
                    </div>
                    <span>환불 신청이 완료되었어요!</span>
                  </div>
                  <div>
                    <div className="confirmInfoBox">
                      환불은 결제한 카드사 기준으로 3~4일 가량 소요돼요. <br />
                      자세한 문의는{" "}
                      <span
                        onClick={() => {
                          navigate(`/mypage`);
                          setMyPageScroll("customerCenter");
                        }}
                      >
                        고객센터
                      </span>
                      로 연락주시면 신속하게 도와드릴게요.
                    </div>
                    <div
                      className="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setRefundModal(false);
                      }}
                    >
                      확인
                    </div>
                  </div>
                </div> // 환불 신청 전
              ) : (
                <div className="modalBody">
                  <div className="mainBox">
                    <div className="mainWhole">
                      총 적립금 :{" "}
                      {data &&
                        Number(data?.cancelData?.totalCredits).toLocaleString()}
                    </div>
                    <div className="mainAvailable">
                      {data &&
                        Number(
                          data?.cancelData?.targetCredits
                        ).toLocaleString()}
                      <span>원</span>
                    </div>
                    <div className="mainInfo">환불 가능 금액</div>
                  </div>
                  <div className="amountBox">
                    <div className="amountInfo">환불이 불가한 총 금액</div>
                    <div className="amountAmount">
                      {data &&
                        Number(
                          data?.cancelData?.notRefundableCredits
                        ).toLocaleString()}
                      <span>원</span>
                    </div>
                  </div>
                  <div className="infoBox">
                    <div>
                      * 결제 시 지급된 추가 적립금은 환불과 함께 자동으로
                      소멸돼요.
                    </div>
                    <div>
                      * 환불은 결제한 카드사 기준으로 3~4일 가량 소요돼요.
                    </div>
                    <div>
                      * 자세한 문의는 고객센터로 연락주시면 신속하게
                      도와드릴게요.
                    </div>
                  </div>
                  <div className="listBox">
                    <div className="listTitle">환불 불가 금액 내역</div>
                    <div className="rowGreyBar" />
                    {data?.cancelData?.notRefundableCredits === 0 ? (
                      <div className="notExist">환불 불가 금액이 없음</div>
                    ) : (
                      <div className="linePart">
                        {data?.cancelData?.notRefundableCreditsDetail
                          ?.expiredCredits !== 0 && (
                          <div className="listLine">
                            <div className="lineLeft">환불 유효기간 만료</div>
                            <div className="lineRight">
                              총{" "}
                              {data &&
                                Number(
                                  data?.cancelData?.notRefundableCreditsDetail
                                    ?.expiredCredits
                                ).toLocaleString()}{" "}
                              원
                            </div>
                          </div>
                        )}
                        {data?.cancelData?.notRefundableCreditsDetail
                          ?.eventCredits !== 0 && (
                          <div className="listLine">
                            <div className="lineLeft">이벤트 적립금</div>
                            <div className="lineRight">
                              총{" "}
                              {data &&
                                Number(
                                  data?.cancelData?.notRefundableCreditsDetail
                                    ?.eventCredits
                                ).toLocaleString()}{" "}
                              원
                            </div>
                          </div>
                        )}
                        {data?.cancelData?.notRefundableCreditsDetail
                          ?.rewardCredits !== 0 && (
                          <div className="listLine">
                            <div className="lineLeft">
                              추가 적립금(20%)
                              <img
                                src={questionMark}
                                onClick={() => {
                                  setRefundInfoModal(true);
                                  window.history.pushState(
                                    { page: "seokwoo" },
                                    "",
                                    window.location.pathname
                                  );
                                }}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <div className="lineRight">
                              총{" "}
                              {data &&
                                Number(
                                  data?.cancelData?.notRefundableCreditsDetail
                                    ?.rewardCredits
                                ).toLocaleString()}{" "}
                              원
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <button
                    disabled={refundAmount === 0}
                    onClick={() => {
                      setRefundInModal(true);
                    }}
                  >
                    환불 신청하기
                  </button>
                </div>
              )}
            </div>
          )} */}
          <DivTwoPartImprove display="grid" gap={24} margin="20px 0 24px 0">
            <TextLine text="현재 환불하기 기능은 준비 중에 있어요." />
            <DivTwoPartImprove lineheight={24}>
              <TextLine text="보유하신 적립금의 환불 관련 문의는" />
              <TextLine text="고객센터" />
              <TextLine text="를 통해 연락주시면 신속하게 도와드릴게요." />
            </DivTwoPartImprove>
          </DivTwoPartImprove>
          <DivTwoPartImprove
            display="grid"
            grid_template_column="1fr 1px 1fr"
            pc_grid_template_column="1fr 1fr"
            margin="0 auto"
          >
            <Button
              text="취소"
              onclick={() => window.history.back()}
              cursor="pointer"
            />
            <DivTwoPartImprove
              width="1px"
              height={36}
              background_color="#E6E6E6"
              pc_display="none"
            />
            <Button
              text="고객센터"
              onclick={() => navigate(``)}
              cursor="pointer"
            />
          </DivTwoPartImprove>
        </div>
      </StRefund>
    </>
  );
};

const StRefund = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  .modalBody,
  .modalBodyComplete {
    display: flex;
    flex-direction: column;
    .mainBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      .mainWhole {
        font-family: "semibold";
        line-height: 18px;
        color: #818181;
      }
      .mainAvailable {
        font-size: 26px;
        font-family: "extrabold";
        line-height: 38px;
        color: #2276dc;
        margin: 10px 0 4px;
        span {
          margin-left: 4px;
          color: black;
        }
      }
    }
    .amountBox {
      display: grid;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      border: 0.6px solid #bdbdbd;
      border-radius: 8px;
      padding: 16px 0;
      gap: 7px;
      .amountInfo {
        color: #818181;
        font-size: 12px;
        font-family: "medium";
        line-height: 18px;
      }
      .amountAmount {
        font-size: 19px;
        font-family: "bold";
        line-height: 28px;
        span {
          margin-left: 2px;
          font-size: 14px;
          line-height: 24px;
          font-family: "regular";
        }
      }
    }
    .infoBox {
      display: flex;
      flex-direction: column;
      div {
        font-size: 11px;
        font-family: "regular";
        line-height: 16px;
      }
    }
    .listBox {
      margin-top: 36px;
      .linePart {
        display: flex;
        flex-direction: column;
        .listLine {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .lineLeft {
            display: flex;
            align-items: center;
            color: #55595f;
            img {
              width: 16px;
              height: 16px;
              margin: auto 0 auto 4px;
            }
          }
        }
      }
    }
    button {
      border: none;
      width: 204px;
      background-color: ${(props) =>
        props.refundAmount === 0 ? "#D9D9D9" : "#ED893E"};
      color: white;
      border-radius: 42px;
      margin: 54.5px auto 15px;
      cursor: ${(props) => (props.refundAmount === 0 ? "" : "pointer")};
    }
    .rowGreyBar {
      width: 100%;
      height: 0.5px;
      background-color: #bdbdbd;
    }
    .confirmBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 42px;
        height: 42px;
      }
      div {
        font-size: 26px;
        font-family: "extrabold";
        line-height: 38px;
        color: #2276dc;
        margin-top: 14px;
        span {
          font-size: 16px;
          font-family: "bold";
          line-height: 32px;
          color: black;
          margin-left: 4px;
        }
      }
      span {
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
      }
    }
    .confirmInfoBox {
      font-size: 11px;
      font-family: "regular";
      line-height: 16px;
      text-align: center;
      span {
        color: #2276dc;
        text-decoration: underline;
      }
    }
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 42px;
      border: 1px solid black;
      border-radius: 42px;
      margin: 0 auto;
    }
  }
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    left: 50%;
    bottom: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, 50%);
    z-index: 70;
    .modalBody,
    .modalBodyComplete {
      .confirmBox {
        margin: 129px 0;
        .errorCode {
          font-size: 16px;
          font-family: "regular";
          line-height: 24px;
          color: #242424;
        }
      }
      .confirmInfoBox {
        margin-bottom: 40px;
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        gap: 21px;
        .customerButton {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #20315b;
          width: 204px;
          height: 52px;
          border-radius: 43px;
          color: white;
          font-size: 17px;
          font-family: "medium";
        }
        .button {
          height: 52px;
          font-size: 17px;
          font-family: "medium";
          box-sizing: border-box;
          margin: 0 auto;
        }
      }
      .mainBox {
        margin-top: 48px;
        .mainWhole {
          font-size: 13px;
        }
        .mainAvailable {
          font-size: 28px;
          margin: 16px 0 6px;
          span {
            font-size: 22px;
            font-family: "regular";
            line-height: 36px;
          }
        }
        .mainInfo {
          font-size: 16px;
          font-family: "regular";
          line-height: 24px;
        }
      }
      .amountBox {
        margin: 40px 0 14px;
        box-sizing: border-box;
        .amountInfo {
          font-size: 13px;
        }
        .amountAmount {
          font-size: 22px;
          line-height: 33px;
          span {
            font-size: 15px;
            line-height: 28px;
          }
        }
      }
      .infoBox {
        display: grid;
        gap: 8px;
        div {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .listBox {
        .listTitle {
          font-size: 18px;
          font-family: "medium";
          line-height: 27px;
          margin-bottom: 6px;
        }
        .notExist {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 109px;
          font-size: 16px;
          font-family: "regular";
          line-height: 24px;
          color: #bdbdbd;
        }
        .linePart {
          display: grid;
          gap: 6px;
          margin-top: 28px;
          .listLine {
            .lineLeft {
              font-size: 18px;
              line-height: 27px;
              img {
                width: 20px;
                height: 20px;
                margin-left: 6px;
              }
            }
            .lineRight {
              font-size: 18px;
              font-family: "medium";
              line-height: 27px;
            }
          }
        }
        .linePart {
          display: grid;
          gap: 14px;
        }
      }
      button {
        height: 52px;
        font-size: 17px;
        font-family: "regular";
      }
    }
  }
  @media ${device.tabMob} {
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    padding: 0 24px;
    box-sizing: border-box;
    z-index: 49;
    .wrapper {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
      height: -webkit-fill-available;
    }
    .modalBodyComplete {
      display: grid;
      grid-template-rows: 1fr 98px;
      align-items: center;
      gap: 174px;
      .confirmBox {
      }
      .confirmInfoBox {
      }
    }
    .modalBody,
    .modalBodyComplete {
      .confirmBox {
        .errorCode {
          font-size: 16px;
          font-family: "regular";
          line-height: 24px;
          color: black;
          text-align: center;
          margin-bottom: 150px;
        }
      }
      .buttonBox {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 42px;
          border: 1px solid #000000;
          border-radius: 42px;
          font-size: 14px;
          font-family: "medium";
          line-height: 21px;
        }
        .customerButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 151px;
          height: 42px;
          border-radius: 42px;
          background-color: #20315b;
          color: white;
          font-size: 14px;
          font-family: "medium";
          line-height: 21px;
        }
      }
      .mainBox {
        .mainWhole {
          font-size: 12px;
        }
        .mainAvailable {
          font-size: 28px;
          span {
            font-family: "bold";
            font-size: 16px;
            line-height: 32px;
          }
        }
        .mainInfo {
          font-size: 14px;
          font-family: "regular";
          line-height: 21px;
        }
      }
      .amountBox {
        margin: 16px 0 10px;
      }
      .infoBox {
        display: grid;
        gap: 6px;
      }
      .listBox {
        .listTitle {
          font-size: 13px;
          font-family: "semibold";
          line-height: 19px;
        }
        .notExist {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 104px;
          color: #bdbdbd;
          font-size: 14px;
          line-height: 21px;
        }
        .linePart {
          display: grid;
          gap: 6px;
          margin-top: 24px;
          .listLine {
            .lineLeft {
              font-size: 14px;
              font-family: "regular";
              line-height: 21px;
            }
            .lineRight {
              font-size: 14px;
              font-family: "medium";
              line-height: 21px;
            }
          }
        }
      }
      .confirmInfoBox {
        margin-bottom: 22px;
      }
      button {
        height: 42px;
        margin: 20px auto 20px;
      }
    }
  }
`;

export default Refund;
