import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import editPen from "../../img/editPen.svg";
import createExamFinal from "../../img/createExamFinal.svg";
import { useRecoilValue } from "recoil";
import { languageIs, userInfo } from "../../atoms";
import dayjs from "dayjs";
import "dayjs/locale/en";

// img
import update from "../../img/update.svg";
import request from "../../img/request.svg";
import { PageHeader } from "../../components/Style";
import { device } from "../../hooks/device";
import ChargingInfo from "../../modal/CreateFinalTestChargingInfo";
import customAxios from "../../api/handler";
import useToast from "../../hooks/useToast";
import TextTwoPart from "../../atomic/atom/TextTwoPart";
import useGetCreditInfo from "../../hooks/api/useGetCreditInfo";
import Button from "../../atomic/atom/Button";
import postApi from "../../api/postApi";
import TextLine from "../../atomic/atom/TextLine";
import DivTwoPartImprove from "../../atomic/atom/DivTwoPartImprove";
import Img from "../../atomic/atom/Img";
import { useTranslation } from "react-i18next";

const CreateExamFinal = ({ setMyPageScroll }) => {
  const { t } = useTranslation();
  const utcInfo = new Date().getTimezoneOffset();
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const state = useLocation().state;
  const user = useRecoilValue(userInfo);
  const [confirmButton, setConfirmButton] = useState(true);
  const [servicePrice, setServicePrice] = useState(0); // 서비스 이용료
  const [usingEnterPoint, setUsingEnterPoint] = useState(0);

  const tenMinuteCredit = 10; // 10분당 사용크레딧 수
  const startTime = dayjs(state?.tempStartTime);
  const endTime = dayjs(state?.tempEndTime);
  const testTime = endTime.diff(startTime, "m");
  const [testPrice, setTestPrice] = useState(0); // 서비스 가격
  const [testTesterCount, setTestTesterCount] = useState(0); // 응시자 인원수
  const [creditRefresh, setCreditRefresh] = useState(false);

  const { data: credit } = useGetCreditInfo({ creditRefresh }); // 유저 크레딧 정보 받아오기

  /** 사용 크레딧 계산하기 */
  const usingCreditCalculate = () => {
    setTestPrice(testTesterCount * Math.ceil(testTime / 10) * tenMinuteCredit);
  };

  useEffect(() => {
    usingCreditCalculate();
  }, [testTesterCount]);

  useEffect(() => {
    if (!state) {
      navigate(`/creatingExam`);
    }
  }, []);

  /** 개인 기업 분리 후 시험 생성해주기 */
  const createTest = () => {
    setConfirmButton(false);
    if (user.hostPlan === "individualPlan") {
      postApi
        .postCreateTestInd({
          testName: state?.testTitle,
          testStartTime: startTime.format("YYYY-MM-DD HH:mm:ss"),
          testEndTime: endTime.format("YYYY-MM-DD HH:mm:ss"),
          testPlannedTester: testTesterCount,
          testComment: state?.courageMessage,
          testUtcOffset: -9,
          isReactivateAroundSetting: state?.reConfirm,
          isTesterSelfExit: state?.leaving,
          paymentsUnitPrice: Math.ceil(testTime / 10) * 10,
          paymentsAmount: testPrice,
          paymentsAt: dayjs().format("YYYY-MM-DD hh:mm:ss"),
          paymentsUsedCredits: testPrice,
        })
        .then(() => {
          navigate(`/createExamFinal/createConfirm`);
          setConfirmButton(true);
        })
        .catch(({ response }) => {
          setConfirmButton(true);
          const errorCode = response.data.errorCode;
          if (errorCode === "TIMEZONE-003") {
            showToast({
              message: t(`errorCode.timezone_003`),
            });
          } else if (errorCode === "TIMEZONE-005") {
            showToast({
              message: t(`errorCode.timezone_005`),
            });
          } else if (errorCode === "TIMEZONE-006") {
            showToast({
              message: t(`errorCode.timezone_006`),
            });
          } else if (errorCode === "TIMEZONE-010") {
            showToast({
              message: t(`errorCode.timezone_010`),
            });
          } else if (errorCode === "TIMEZONE-011") {
            showToast({
              message: t(`errorCode.timezone_011`),
            });
          } else if (errorCode === "PAYMENT-IND-002") {
            showToast({
              message: t(`errorCode.payment_002_manager`),
            });
          } else {
            showToast({
              message: t(`errorCode.unknown_server_error`),
            });
          }
        });
    } else {
      postApi
        .postCreateTestEnt({
          testName: state?.testTitle,
          testStartTime: startTime.format("YYYY-MM-DD HH:mm:ss"),
          testEndTime: endTime.format("YYYY-MM-DD HH:mm:ss"),
          testPlannedTester: testTesterCount,
          testComment: state?.courageMessage,
          testUtcOffset: -9,
          isReactivateAroundSetting: state?.reConfirm,
          isTesterSelfExit: state?.leaving,
          paymentsUnitPrice: Math.ceil(testTime / 10) * 10,
          paymentsAmount: testPrice,
          paymentsAt: dayjs().format("YYYY-MM-DD hh:mm:ss"),
          paymentsUsedCredits: testPrice,
        })
        .then(() => {
          navigate(`/createExamFinal/createConfirm`);
          setConfirmButton(true);
        })
        .catch(({ response }) => {
          setConfirmButton(true);
          const errorCode = response.data.errorCode;
          if (errorCode === "TIMEZONE-003") {
            showToast({
              message: t(`errorCode.timezone_003`),
            });
          } else if (errorCode === "TIMEZONE-005") {
            showToast({
              message: t(`errorCode.timezone_005`),
            });
          } else if (errorCode === "TIMEZONE-006") {
            showToast({
              message: t(`errorCode.timezone_006`),
            });
          } else if (errorCode === "TIMEZONE-010") {
            showToast({
              message: t(`errorCode.timezone_010`),
            });
          } else if (errorCode === "TIMEZONE-011") {
            showToast({
              message: t(`errorCode.timezone_011`),
            });
          } else if (errorCode === "PAYMENT-ENT-002") {
            if (user.hostPlan === "memberPlan") {
              showToast({
                message: t(`errorCode.payment_002_member`),
              });
            } else if (user.hostPlan === "managerPlan") {
              showToast({
                message: t(`errorCode.payment_002_manager`),
              });
            }
          } else {
            showToast({
              message: t(`errorCode.unknown_server_error`),
            });
          }
        });
    }
  };

  // modalState
  const [chargingInfoModal, setChargingInfoModal] = useState(false);

  useEffect(() => {
    setServicePrice(Math.ceil(testTime / 10) * 1500 * testTesterCount);
  }, [testTime, testTesterCount]);

  // 서버로 보내기 위한 시간 변경
  const [realStartHour, setRealStartHour] = useState();
  const [realEndHour, setRealEndHour] = useState();

  const showToast = useToast();
  const timeErrorRef = useRef();

  useEffect(() => {
    if (state?.endAM === false) {
      setRealEndHour(Number(state?.endHour) + 12);
    } else {
      setRealEndHour(state?.endHour);
    }
    if (state?.startAM === false) {
      setRealStartHour(Number(state?.startHour) + 12);
    } else {
      setRealStartHour(state?.startHour);
    }
  }, [realEndHour, realStartHour]);

  // 인원수 인풋 값 입력 시 0명 이하 999명 이상 안되게 하기
  useEffect(() => {
    if (testTesterCount > 999) {
      setTestTesterCount(999);
    } else if (testTesterCount < 0) {
      setTestTesterCount(0);
    }
  }, [testTesterCount]);

  /** 포인트 전부 사용 이후 인원수를 줄였을 때 서비스 이용료보다 포인트가 더 많이 나오지 않게 하기 */
  // useEffect(() => {
  //   if (state?.type === "individualPlan") {
  //     if (usingPoint > servicePrice) {
  //       setUsingPoint(servicePrice);
  //     } else if (!servicePrice) {
  //       setUsingPoint(0);
  //     }
  //     if (servicePrice === 0) {
  //       setUsingPoint(0);
  //     }
  //   }
  // }, [usingPoint, servicePrice]);

  useEffect(() => {
    if (state?.type !== "individualPlan") {
      if (usingEnterPoint > servicePrice / 2) {
        setUsingEnterPoint(servicePrice / 2);
      } else if (!servicePrice) {
        setUsingEnterPoint(0);
      }
      if (servicePrice === 0) {
        setUsingEnterPoint(0);
      }
    }
  }, [usingEnterPoint, servicePrice]);

  // useEffect(() => {
  //   if (enterPoint?.hostPointAndExpired?.hostPoints > servicePrice / 2) {
  //     setUsingEnterPoint(servicePrice / 2);
  //   } else if (enterPoint?.hostPointAndExpired?.hostPoints < servicePrice / 2) {
  //     setUsingEnterPoint(enterPoint?.hostPointAndExpired?.hostPoints);
  //   }
  // }, [servicePrice]);

  /** 포인트가 0 이하로 안가게 하기 */
  // useEffect(() => {
  //   if (state?.type === "individualPlan") {
  //     if (usingPoint < 0) {
  //       setUsingPoint(0);
  //     }
  //   } else {
  //     if (usingEnterPoint < 0) {
  //       setUsingEnterPoint(0);
  //     }
  //   }
  // }, [usingPoint, usingEnterPoint]);

  /** 기업 포인트를 현재 갖고 있는 금액보다 더 많이 사용하려고 할 경우 갖고 있는 금액으로 맞춰준다.*/
  // const enterprisePointLimit = () => {
  //   if (usingEnterPoint > enterPoint?.hostPointAndExpired?.hostPoints) {
  //     setUsingEnterPoint(enterPoint?.hostPointAndExpired?.hostPoints);
  //   }
  // };

  // 서비스 이용료가 변경 될 경우 포인트 자동으로 채워지기
  // useEffect(() => {
  //   if (state?.type === "individualPlan") {
  //     if (data?.point > servicePrice) {
  //       setUsingPoint(servicePrice);
  //     } else if (data?.point <= servicePrice) {
  //       setUsingPoint(data?.point);
  //     }
  //   } else {
  //     if (enterPoint?.hostPointAndExpired?.hostPoints > servicePrice / 2) {
  //       setUsingEnterPoint(servicePrice / 2);
  //     } else if (
  //       enterPoint?.hostPointAndExpired?.hostPoints <=
  //       servicePrice / 2
  //     ) {
  //       setUsingEnterPoint(enterPoint?.hostPointAndExpired?.hostPoints);
  //     }
  //   }
  // }, [servicePrice]);

  // utc 보여주기

  const [loading, setLoading] = useState(false);

  const [chargePrice, setChargePrice] = useState(0);

  // 기업 충전하기
  const [chargingCredit, setChargingCredit] = useState(0);

  /** 기업 자계정 적립금 충전 요청하기 */
  const requestForCredit = () => {
    customAxios
      .post(`/api/v1/payments/enterprise/member/credits/charge-request`)
      .then(() => {
        showToast({
          message: t(`errorCode.chargeRequest`),
        });
      })
      .catch((data) => {
        console.log(data);
      });
  };

  /** 응시인원 및 input 을 위해 현재페이지에서 사용할 수 없는 문자 + - . spacebar */
  useEffect(() => {
    const handlePress = (e) => {
      if (
        e.keyCode === "187" ||
        e.keyCode === 189 ||
        e.keyCode === 190 ||
        e.keyCode === "32"
      ) {
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", handlePress);
    return () => document.removeEventListener("keydown", handlePress);
  }, []);

  /** 금액 설정 전 499만원 이상 일 경우 499만원으로 맞춰주기 */
  const chargingCreditCheck = (amount) => {
    if (chargingCredit + amount > 4990000) {
      setChargingCredit(4990000);
    } else {
      setChargingCredit(chargingCredit + amount);
    }
  };

  const [paymentButton, setPaymentButton] = useState(false);

  const buttonAvailable = () => {
    if (credit >= testPrice && testTesterCount > 0) {
      setPaymentButton(true); // 시험생성 가능
    } else {
      setPaymentButton(false); // 시험생성 불가능
    }
  };

  useEffect(() => {
    buttonAvailable();
  }, [servicePrice]);

  return (
    <>
      {chargingInfoModal && (
        <ChargingInfo setChargingInfoModal={setChargingInfoModal} />
      )}
      <StCreateExamFinal
        $studentCount={testTesterCount}
        $chargePrice={chargePrice}
        $usingEnterPoint={usingEnterPoint}
        // $usingPoint={usingPoint}
        $chargingCredit={chargingCredit}
        country={country}
      >
        <div className="mainFrame">
          <PageHeader title="Create Event" page="creatingExam" />
          <div className="bucket">
            <div className="testInfoBox">
              <div className="title">
                <TextTwoPart textEng="Event Details" />
              </div>
              <div className="inBox" ref={timeErrorRef}>
                <div className="bodyPart">
                  <div className="inTitle">{state?.testTitle}</div>
                  <div className="timePart">
                    <div className="timeTitle">
                      <TextTwoPart textEng="Date & Time" />
                    </div>
                    <div className="timing">
                      {/* {state?.startAM ? 'AM' : 'PM'} {state?.startHour}:{state?.startMin}&nbsp;~&nbsp;{state?.endAM ? 'AM' : 'PM'} {state?.endHour}:{state?.endMin} */}
                      {startTime.format("M/D/YYYY (ddd)")},&nbsp;{" "}
                      <br className="onlyForMobile" />
                      {startTime.locale("en").format("hh:mm A")} -
                      {endTime.locale("en").format("hh:mm A")} (UTC{" "}
                      {utcInfo <= 0 ? "+" : ""}
                      {-utcInfo / 60})
                    </div>
                  </div>
                </div>
                <div className="infoPart">
                  <div className="infoForm">
                    <div className="infoLeft">
                      <TextTwoPart
                        textKor="총 시험시간"
                        textEng="Exam Duration"
                      />
                    </div>
                    <div className="infoRight">
                      {endTime.diff(startTime, "m")}
                      {country === "/ko" ? "분" : " Minutes"}
                    </div>
                  </div>
                  <div className="infoForm">
                    <div className="infoLeft">
                      <TextTwoPart
                        textKor="응시자 자율 퇴실"
                        textEng="Voluntary Withdrawal"
                      />
                    </div>
                    <div className="infoRight">
                      {state?.leaving ? (
                        <TextTwoPart textKor="허용" textEng="Allow" />
                      ) : (
                        <TextTwoPart
                          textKor="허용 안함"
                          textEng="Don't Allow"
                        />
                      )}
                    </div>
                  </div>
                  <div className="infoForm">
                    <div className="infoLeft">
                      <TextTwoPart
                        textKor="주변환경 재검검 진행"
                        textEng="Rescan Environment"
                      />
                    </div>
                    <div className="infoRight">
                      {state?.reConfirm ? (
                        <TextTwoPart textKor="진행" textEng="Always Proceed" />
                      ) : (
                        <TextTwoPart
                          textKor="진행 안함"
                          textEng="Don't Proceed"
                        />
                      )}
                    </div>
                  </div>
                  <div className="infoForm">
                    <div className="infoLeft">
                      <TextTwoPart
                        textKor="응원 메세지"
                        textEng="Support Message (Optional)"
                      />
                    </div>
                    <div className="infoRight">{state?.courageMessage}</div>
                  </div>
                </div>
                <div className="rowGrayBar" />
                <div
                  className="editPart"
                  onClick={() => {
                    navigate(`/creatingExam`, {
                      state: {
                        title: state?.testTitle,
                        startTime: state?.tempStartTime,
                        endTime: state?.tempEndTime,
                        testComment: state?.courageMessage,
                        leaving: state?.leaving,
                        reConfirm: state?.reConfirm,
                      },
                    });
                  }}
                >
                  <img src={editPen} alt="" />
                  <TextTwoPart textKor="정보 수정하기" textEng="Edit" />
                </div>
              </div>
            </div>
            <div className="servicePriceBox">
              <div className="title">Use Credits to Create Sessions</div>
              <div className="inBox">
                <div className="middleRow">
                  <div className="middleRowLeft">
                    <div className="inTitle">Service Plan / Price</div>
                    <div className="planPart">
                      <div className="plan">
                        <TextLine
                          text={
                            state?.type === "individualPlan"
                              ? "Free Trial "
                              : "Corporate Plan "
                          }
                        />

                        {state?.type === "managerPlan" && (
                          <TextLine text="(Administrator)" />
                        )}
                        {state?.type === "memberPlan" && (
                          <TextLine text="(Member)" />
                        )}
                      </div>
                      <div className="howMuch">
                        <div className="topLine">
                          60<span>C</span>
                        </div>
                        <div className="bottomLine">(per 60 minutes)</div>
                      </div>
                    </div>
                  </div>
                  <img src={createExamFinal} alt="" />
                </div>
                <div className="pricePart">
                  <div className="chart">
                    <div className="leftPart">
                      <div className="wholeTime">Duration</div>
                      <div className="times">
                        {endTime.diff(startTime, "m")}
                        <span>minutes</span>
                      </div>
                    </div>
                    <div className="columnGrayBar" />
                    <div className="leftPart">
                      <div className="wholeTime">Credits/10 minutes</div>
                      <div className="times">
                        10<span>C</span>
                      </div>
                    </div>
                    <div className="columnGrayBar onlyForPc" />
                  </div>
                  {/* <div
                    className="rowGrayBar onlyForMobile"
                  /> */}
                  <DivTwoPartImprove
                    width="calc(100% - 24px)"
                    height={0.5}
                    margin="0 auto"
                    background_color="#d9d6dd"
                    pc_display="none"
                  />
                  <div className="leftPart">
                    <div className="wholeTime">Price</div>
                    <div className="times">
                      {(Math.ceil(testTime / 10) * 10)?.toLocaleString()}
                      <span>C / Test-taker</span>
                    </div>
                  </div>
                </div>
                <div className="rowGrayBar" />
                <div className="howManyPart">
                  <div className="howTitle">
                    <div>Number of Test-takers & Total Price</div>
                  </div>
                  <div className="howBody">
                    <div className="howLeft">
                      <input
                        id="counting"
                        className="howCountIn"
                        type="number"
                        max="999"
                        min="0"
                        onWheel={(e) => e.target.blur()}
                        inputMode="numeric"
                        pattern="[0-9]*"
                        placeholder="0"
                        value={testTesterCount == 0 ? "" : testTesterCount}
                        onChange={(e) => {
                          // setStudentCount(e.target.value.substring(0, 3));
                          // handlePeopleCount();
                          setTestTesterCount(e.target.value);
                        }}
                        onBlur={() => {
                          setTestTesterCount(Math.floor(testTesterCount));
                        }}
                      />
                      <div className="howCountUnit">Test-takers</div>
                    </div>
                    <div className="howRight">
                      <div className="howDownLine">
                        {testTesterCount ? testPrice?.toLocaleString() : "0"}
                        <span>C</span>
                      </div>
                      <div className="howUpLine">(Service fee)</div>
                    </div>
                  </div>
                </div>
                <div className="rowGrayBar" />
                <div className="finalPricePart">
                  <div className="finalLeft">Use Credits</div>
                  <div className="finalRight">
                    {user?.hostPlan === "individualPlan" ? (
                      <div className="finalUpLine">
                        {testTesterCount ? testPrice?.toLocaleString() : "0"}
                        <span>C</span>
                      </div>
                    ) : (
                      <div className="finalUpLine">
                        {testTesterCount ? testPrice?.toLocaleString() : "0"}
                        <span>C</span>
                      </div>
                    )}
                    {credit - testPrice >= 0 ? (
                      <div className="finalDownLine">(Automatic Payment)</div>
                    ) : (
                      <div
                        className="finalDownLine"
                        style={{ color: "#C83B38" }}
                      >
                        (Additional Credits Needed)
                      </div>
                    )}
                  </div>
                </div>
                <div className="enterpriseCenter">
                  <div className="enterpriseMiddle">
                    <div className="middleBucket">
                      <div className="middleTitle">
                        {user.hostPlan === "individualPlan"
                          ? "My Credits"
                          : "Corporate Credits"}
                      </div>
                      <div
                        className="enterpriseMiddleDown"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {Number(credit)?.toLocaleString()}
                        <TextLine
                          text="C"
                          fontFamily="regular"
                          fontsize={14}
                          lineheight={19}
                          margin="auto 0 0 2px"
                        />
                        <Img
                          src={update}
                          margin="auto 0 auto 6px"
                          pcMargin="auto 0 auto 8px"
                          cursor="pointer"
                          onclick={() => {
                            setCreditRefresh(!creditRefresh);
                            showToast({ message: t(`errorCode.refresh`) });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="enterpriseDown">
                    <div className="downTitle">Credits after use</div>
                    {credit - testPrice >= 0 ? (
                      <div className="enterpriseAfter">
                        {(credit - testPrice)?.toLocaleString()}
                        <span>C</span>
                      </div>
                    ) : (
                      <div className="enterpriseAfterWarn">
                        {(credit - testPrice)?.toLocaleString()}
                        <span>C</span>
                      </div>
                    )}
                  </div>
                </div>
                <DivTwoPartImprove
                  width="100%"
                  height={0.5}
                  pc_margin="24px 0 0"
                  background_color="#BDBDBD"
                  display={user.hostPlan === "individualPlan" ? "none" : ""}
                />
                <DivTwoPartImprove
                  display={user.hostPlan === "individualPlan" ? "none" : "flex"}
                  justify="center"
                  align_items="center"
                  font_size={15}
                  pc_font_size={18}
                  lineheight={22}
                  font_family="medium"
                  cursor="pointer"
                  onclick={() => {
                    if (user.hostPlan === "managerPlan") {
                      navigate(`chargeRequest`);
                    } else if (user.hostPlan === "memberPlan") {
                      requestForCredit();
                    }
                  }}
                >
                  <Img src={request} margin="0 6px 0 0" />
                  {user.hostPlan === "managerPlan" && "Charge"}
                  {user.hostPlan === "memberPlan" && "Request for credits"}
                </DivTwoPartImprove>
              </div>
            </div>
            <div className="lastBox">
              If you need assistance, feel free to reach out to our&nbsp;{" "}
              <span
                onClick={() => {
                  navigate(`/mypage`);
                  setMyPageScroll("customerCenter");
                }}
              >
                Customer Support
              </span>
              . We're here to assist you promptly.
            </div>
            <Button
              text="Confirm"
              font_size={14}
              font_family="medium"
              backgroundcolor={paymentButton ? "#ed893e" : "#d9d9d9"}
              width="204px"
              height={42}
              font_color="white"
              border="none"
              border_radius={42}
              margin="0 auto"
              cursor={paymentButton ? "pointer" : ""}
              disabled={!paymentButton}
              onclick={() => {
                if (confirmButton) {
                  createTest();
                }
              }}
            />
          </div>
        </div>
      </StCreateExamFinal>
      <Outlet />
    </>
  );
};

const StCreateExamFinal = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f0f1f2;
  image-rendering: -webkit-optimize-contrast;
  .columnGrayBar {
    width: 0.5px;
    height: 100%;
    background-color: #d6d9dd;
  }
  .rowGrayBar {
    width: 100%;
    height: 0.5px;
    background-color: #d9d6dd;
  }
  .testInfoBox {
    width: 100%;
    .title {
      color: #55595f;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .inBox {
      display: grid;
      width: 100%;
      padding: 24px;
      box-sizing: border-box;
      flex-direction: column;
      background-color: white;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      gap: 24px;
      .bodyPart {
        display: grid;
        flex-direction: column;
        gap: 24px;
        .inTitle {
          font-family: "bold";
          font-size: 22px;
          line-height: 30px;
        }
        .timePart {
          display: grid;
          flex-direction: column;
          gap: 5px;
          .timeTitle {
            font-size: 13px;
            font-family: "medium";
            line-height: 22px;
          }
          .timing {
            color: #2276dc;
            font-family: "semibold";
            line-height: 22px;
          }
        }
      }
      .infoPart {
        display: grid;
        flex-direction: column;
        width: 100%;
        gap: 6px;
        .infoForm {
          display: grid;
          grid-template-columns: auto 1fr;
          width: 100%;
          gap: 16px;
          .infoLeft {
            width: 136px;
            font-size: 13px;
            font-family: "medium";
            line-height: 20px;
          }
          .infoRight {
            word-break: break-all;
            flex-grow: 1;
            font-size: 13px;
            font-family: "medium";
            color: #2276dc;
            line-height: 20px;
            word-wrap: break-word;
          }
        }
      }
      .editPart {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 9px;
        font-size: 15px;
        font-family: "medium";
        line-height: 21px;
        margin: 0 auto;
        cursor: pointer;
      }
    }
  }
  .servicePriceBox {
    .title {
      color: #55595f;
      font-size: 13px;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .inBox {
      display: grid;
      flex-direction: column;
      padding: 24px;
      box-sizing: border-box;
      flex-direction: column;
      background-color: white;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      gap: 24px;
      .enterpriseUp {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        font-family: "bold";
        line-height: 18px;
        .enterpriseLeft {
          line-height: 31px;
        }
        .enterpriseRight {
          display: flex;
          flex-direction: column;
          font-size: 26px;
          font-family: "extraBold";
          color: #2276dc;
          line-height: 31px;
          div {
            margin-left: auto;
          }
          span {
            margin-left: 4px;
            font-size: 16px;
            font-family: "bold";
            line-height: 23px;
            color: black;
          }
          .enterpriseAuto {
            font-size: 14px;
            font-family: "regular";
            line-height: 17px;
            color: black;
          }
          .enterpriseWarn {
            font-size: 14px;
            font-family: "regular";
            line-height: 17px;
            color: #c83b38;
          }
        }
      }
      .enterpriseCenter {
        display: grid;
        flex-direction: column;
        gap: 10px;
        .enterpriseMiddle {
          justify-content: center;
          box-sizing: border-box;
          border: 0.6px solid #bdbdbd;
          border-radius: 8px;
          .enterpriseMiddleDown {
            font-size: 19px;
            font-family: "bold";
            line-height: 23px;
            color: black;
            margin-top: 7px;
            /* span {
              margin-left: 2px;
              font-size: 14px;
              font-family: "regular";
              line-height: 19px;
            } */
          }
        }
        .enterpriseDown {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          font-family: "medium";
          line-height: 17px;
          color: #818181;
          padding: 0 12px;
          box-sizing: border-box;
          .enterpriseAfter {
            font-family: "bold";
            line-height: 20px;
            color: black;
            span {
              margin-left: 2px;
              font-family: "regular";
            }
          }
          .enterpriseAfterWarn {
            font-family: "bold";
            line-height: 20px;
            color: #c83b38;
            span {
              margin-left: 2px;
              font-family: "regular";
              color: black;
            }
          }
        }
      }
      .planPart {
        display: flex;
        justify-content: space-between;
        .plan {
          font-size: 20px;
          color: #2276dc;
          font-family: "semibold";
          line-height: 33px;
        }
        .howMuch {
          display: flex;
          .topLine {
            font-family: "bold";
            font-size: 24px;
            line-height: 33px;
            span {
              font-family: "regular";
              font-size: 16px;
              line-height: 28px;
              margin-left: 3px;
            }
          }
          .bottomLine {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
      .pricePart {
        display: grid;
        flex-direction: column;
        gap: 16px;
        border: 0.6px solid #bdbdbd;
        box-shadow: 0px 0.4px 0px 0px #bdbdbd inset;
        border-radius: 8px;
        padding: 16px 0px;
        box-sizing: border-box;
        .chart {
          display: flex;
          height: 44px;
        }
        .leftPart {
          display: grid;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          text-align: center;
          gap: 7px;
          .wholeTime {
            font-size: 12px;
            font-family: "medium";
            line-height: 14px;
            color: #818181;
          }
          .times {
            font-family: "bold";
            font-size: 19px;
            line-height: 23px;
            span {
              font-family: "regular";
              font-size: 14px;
              line-height: 19px;
              margin-left: 2px;
            }
          }
        }
      }
      .howManyPart {
        display: flex;
        flex-direction: column;
        .howTitle {
          display: flex;
          align-items: center;
          font-size: 13px;
          font-family: "semibold";
          line-height: 19px;
          img {
            width: 16px;
            height: 16px;
            margin: auto 0 auto 6px;
            cursor: pointer;
          }
        }
        .howBody {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          .howLeft {
            display: grid;
            grid-template-columns: auto auto 1fr;
            align-content: center;
            gap: 6px;
            .howCountIn {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 44px;
              height: 42px;
              padding: 9px 6px;
              box-sizing: border-box;
              text-align: center;
              margin: 0;
              border: 0.6px solid #818181;
              border-radius: 6px;
              font-size: 16px;
              /* ::-webkit-inner-spin-button,
              ::-webkit-outer-spin-button {
                -webkit-appearance: none;
                margin: 0;
              } */
              &::placeholder {
                color: #bdbdbd;
                font-family: "light";
              }
              &:focus {
                outline: none;
              }
            }
            .howCountUnit {
              font-size: 14px;
              font-family: "medium";
              margin: auto 0;
              color: #818181;
            }
          }
          .howRight {
            display: grid;
            flex-direction: column;
            gap: 3px;
            .howUpLine {
              font-size: 13px;
              line-height: 16px;
              margin-left: auto;
            }
            .howDownLine {
              font-family: "bold";
              font-size: 19px;
              line-height: 23px;
              margin-left: auto;
              span {
                margin-left: 2px;
                font-size: 14px;
                font-family: "regular";
                line-height: 19px;
              }
            }
          }
        }
      }
      .pointPart {
        display: grid;
        flex-direction: column;
        gap: 10px;
        .pointTitle {
          align-items: center;
          font-family: "semibold";
          font-size: 13px;
          line-height: 19px;
          display: grid;
          grid-template-columns: auto auto 1fr;
          gap: 6px;
          img {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
        .pointBody {
          display: grid;
          grid-template-columns: auto auto 1fr;
          align-items: center;
          gap: 10px;
          .pointToBeUsed {
            width: 184px;
            height: 42px;
            font-size: 16px;
            border: 0.6px solid #818181;
            border-radius: 6px;
            padding: 9px 12px;
            box-sizing: border-box;
            &::placeholder {
              font-family: "light";
            }
            &:focus {
              outline: none;
            }
          }
        }
        .myPoint {
          display: flex;
          font-size: 14px;
          font-family: "medium";
          line-height: 20px;
          color: #818181;
          margin-left: 12px;
          span {
            font-family: "bold";
            font-size: 14px;
            line-height: 20px;
            color: #2276dc;
            margin-left: 12px;
          }
          div {
            color: #2276dc;
            font-family: "regular";
            line-height: 20px;
            margin-left: 3px;
          }
        }
        .enterpriseInfo {
          font-size: 11px;
          font-family: "regular";
          color: #444444;
          line-height: 16px;
        }
      }
      .finalPricePart {
        display: flex;
        justify-content: space-between;
        .finalLeft {
          font-family: "bold";
          font-size: 15px;
          line-height: 18px;
          margin-top: 8.5px;
        }
        .finalRight {
          display: grid;
          flex-direction: column;
          gap: 3px;
          .finalUpLine {
            font-size: 26px;
            font-family: "extrabold";
            margin-left: auto;
            line-height: 31px;
            color: #2276dc;
            span {
              font-size: 16px;
              font-family: "bold";
              line-height: 23px;
              margin-left: 4px;
              color: black;
            }
          }
          .finalDownLine {
            font-size: 12px;
            line-height: 17px;
            margin-left: auto;
          }
        }
      }
      .linePart {
        display: grid;
        flex-direction: column;
        gap: 3px;
        .priceLine {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .priceLineUp {
            color: #818181;
            font-size: 14px;
            line-height: 22px;
          }
          .priceLineDownOne {
            font-size: 13px;
            line-height: 20px;
            font-family: "semibold";
            span {
              font-family: "regular";
            }
          }
          .priceLineDownTwo {
            font-size: 13px;
            line-height: 20px;
            color: #2276dc;
            font-family: "semibold";
            span {
              font-family: "regular";
            }
          }
        }
      }
    }
  }
  .middleImg {
    max-width: 206px;
    margin: 46px auto 0;
  }
  .lastBox {
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    span {
      color: #2276dc;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media ${device.pc} {
    .onlyForMobile {
      display: none;
    }
    .columnGrayBar {
      height: 59px;
    }
    width: 100%;
    min-width: 1440px;
    .mainFrame {
      width: 1228px;
      padding: 80px 0px;
      box-sizing: border-box;
      margin: 0 auto;
      .bucket {
        display: grid;
        flex-direction: column;
        width: 865px;
        margin: 60px auto 0;
        gap: 60px;
        box-sizing: border-box;
        border-radius: 12px;
        .testInfoBox {
          .title {
            font-size: 18px;
            font-family: "medium";
            line-height: 27px;
            margin-bottom: 15px;
          }
          .inBox {
            padding: 60px 60px 32px;
            .bodyPart {
              .timePart {
                .timeTitle {
                  font-size: 16px;
                  font-family: "medium";
                  line-height: 24px;
                }
              }
            }
            .infoPart {
              margin-bottom: 36px;
              .infoForm {
                display: grid;
                gap: 40px;
                .infoLeft {
                  width: 170px;
                  font-size: 16px;
                  font-family: "medium";
                  line-height: 24px;
                }
                .infoRight {
                  /* width : 500px; */
                  flex-grow: 1;
                  font-size: 16px;
                  font-family: "medium";
                  line-height: 24px;
                }
              }
            }
            .editPart {
              margin-top: 8px;
            }
          }
        }
        .servicePriceBox {
          .title {
            font-size: 18px;
            font-family: "medium";
            line-height: 27px;
            margin-bottom: 15px;
          }
          .inBox {
            padding: 60px;
            gap: 36px;
            .enterpriseCenter {
              gap: 18px;
              .enterpriseMiddle {
                display: flex;
                padding: 28px 0;
                box-sizing: border-box;
                .middleBucket {
                  width: 372.25px;
                  text-align: center;
                  .middleTitle {
                    font-size: 13px;
                    font-family: "medium";
                    left: 19px;
                  }
                  .enterpriseMiddleDown {
                    margin-top: 7px;
                    font-size: 22px;
                    font-family: "bold";
                    line-height: 33px;
                    span {
                      font-size: 15px;
                      font-family: "regular";
                      line-height: 28px;
                      margin-left: 3px;
                    }
                  }
                }
              }
              .enterpriseDown {
                .downTitle {
                  font-size: 18px;
                  font-family: "regular";
                  line-height: 27px;
                }
                .enterpriseAfter {
                  font-size: 18px;
                  font-family: "semibold";
                  line-height: 27px;
                }
                .enterpriseAfterWarn {
                  font-size: 18px;
                  font-family: "semibold";
                  line-height: 27px;
                  color: #c83b38;
                }
              }
            }
            .middleRow {
              display: flex;
              justify-content: space-between;
              .middleRowLeft {
                .inTitle {
                  font-size: 18px;
                  font-family: "medium";
                  line-height: 27px;
                }
                .planPart {
                  display: flex;
                  flex-direction: column;
                  margin-top: 33px;
                  .plan {
                    font-size: 22px;
                    font-family: "semibold";
                    line-height: 33px;
                  }
                  .howMuch {
                    display: flex;
                    align-items: baseline;
                    margin-top: 6px;
                    .topLine {
                      font-size: 32px;
                      line-height: 47px;
                      span {
                        font-size: 20px;
                        font-family: "regular";
                        line-height: 40px;
                      }
                    }
                    .bottomLine {
                      font-size: 20px;
                      font-family: "regular";
                      line-height: 40px;
                      margin-left: 8px;
                    }
                  }
                }
              }
              img {
                width: 191px;
                height: 146px;
              }
            }
            .pricePart {
              display: grid;
              grid-template-columns: 2fr 1fr;
              padding: 28px 12px;
              .chart {
                .leftPart {
                  .wholeTime {
                    font-size: 13px;
                    line-height: 19px;
                  }
                  .times {
                    font-size: 22px;
                    line-height: 33px;
                    span {
                      font-size: 15px;
                    }
                  }
                }
              }
              .leftPart {
                .wholeTime {
                  font-size: 13px;
                  line-height: 19px;
                }
                .times {
                  font-size: 22px;
                  line-height: 33px;
                  span {
                    font-size: 15px;
                  }
                }
              }
            }
            .howManyPart {
              .howTitle {
                font-size: 18px;
                font-family: "medium";
                line-height: 27px;
              }
              .howBody {
                margin-top: 18px;
                align-items: center;
                .howRight {
                  .howDownLine {
                    font-size: 32px;
                    line-height: 47px;
                    span {
                      font-size: 20px;
                      line-height: 40px;
                    }
                  }
                  .howUpLine {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }
              }
            }
            .pointPart {
              gap: 18px;
              .pointTitle {
                font-size: 18px;
                font-family: "medium";
                line-height: 27px;
              }
              .pointBody {
                .pointToBeUsed {
                  width: 280px;
                  &::placeholder {
                    font-family: "light";
                  }
                }
              }
              .myPoint {
                display: flex;
                font-size: 18px;
                line-height: 27px;
                /* margin-left: 12px; */
                span {
                  font-size: 18px;
                  line-height: 27px;
                }
                div {
                  font-family: "regular";
                  color: #2276dc;
                  line-height: 27px;
                  margin-left: 3px;
                }
              }
            }
            .finalPricePart {
              .finalLeft {
                font-size: 22px;
                line-height: 33px;
              }
              .finalRight {
                .finalUpLine {
                  font-family: "extrabold";
                  font-size: 38px;
                  line-height: 56px;
                  color: #2276dc;
                  span {
                    font-size: 20px;
                    line-height: 46px;
                    color: black;
                  }
                }
                .finalDownLine {
                  font-size: 16px;
                  font-family: "regular";
                  line-height: 24px;
                }
              }
            }
            .linePart {
              .priceLineUp {
                font-size: 18px;
                line-height: 27px;
              }
              .priceLineDownOne {
                font-size: 18px;
                font-family: "semibold";
                line-height: 27px;
                span {
                  font-size: 18px;
                  font-family: "regular";
                  line-height: 27px;
                }
              }
              .priceLineDownTwo {
                font-size: 18px;
                font-family: "semibold";
                line-height: 27px;
                span {
                  font-size: 18px;
                  font-family: "regular";
                  line-height: 27px;
                }
              }
            }
          }
        }
        .payButton {
          height: 52px;
          font-size: 17px;
        }
      }
    }
  }
  @media ${device.tabMob} {
    padding: 0px 24px 15px;
    box-sizing: border-box;
    .mainFrame {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
    }
    .bucket {
      display: grid;
      flex-direction: column;
      width: 100%;
      gap: 36px;
      margin-top: 8px;
    }
    .onlyForPc {
      display: none !important;
    }
    .servicePriceBox {
      .inBox {
        .middleRow {
          display: flex;
          flex-direction: column-reverse;
          .middleRowLeft {
            .inTitle {
              font-size: 13px;
              font-family: "semibold";
              line-height: 19px;
              margin-bottom: 16px;
            }
            .planPart {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .plan {
                display: grid;
                font-size: 20px;
                color: #2276dc;
                font-family: "semibold";
                line-height: 33px;
                margin-bottom: auto;
              }
              .howMuch {
                display: flex;
                flex-direction: column;
                .topLine {
                  display: flex;
                  justify-content: end;
                  align-items: end;
                  font-family: "bold";
                  font-size: 24px;
                  line-height: 33px;
                  span {
                    font-family: "regular";
                    font-size: 16px;
                    line-height: 28px;
                    margin-left: 3px;
                  }
                }
                .bottomLine {
                  font-size: 14px;
                  line-height: 17px;
                  margin-left: auto;
                }
              }
            }
          }
          img {
            width: 191px;
            height: 146px;
            margin: 0 auto 20px;
          }
        }
        .enterpriseCenter {
          .enterpriseMiddle {
            display: grid;
            grid-template-columns: 1fr;
            flex-direction: column;
            width: 100%;
            padding: 16px 12px;
            justify-content: center;
            align-items: center;
            gap: 16px;
            color: #818181;
            font-size: 12px;
            font-family: "medium";
            line-height: 12px;
            div {
              text-align: center;
            }
          }
        }
      }
    }
    .payButton {
      margin-bottom: 22px;
      font-family: 14px;
    }
  }
`;

export default CreateExamFinal;
