import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { MobileModalBack, ModalBack, ModalHeaderD } from "../components/Style";
import { useTranslation } from "react-i18next";

const CreditQuestion = ({ setQuestionModal }) => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g1_1";
  const goBack = () => {
    setQuestionModal(false);
  };

  useEffect(() => {
    window.history.pushState("", null, window.location.pathname);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <MobileModalBack />
      <StModal>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        <div className="titleInfo">{t(`${baseLang}.body`)}</div>
        <div className="middlePart">{t(`${baseLang}.notice`)} </div>
        <ul>
          <li>{t(`${baseLang}.list1`)}</li>
          <li>{t(`${baseLang}.list2`)}</li>
          <li>{t(`${baseLang}.list3`)}</li>
          <li>{t(`${baseLang}.list4`)}</li>
          <li>{t(`${baseLang}.list5`)}</li>
        </ul>
        <div
          className="confirmPart"
          onClick={() => {
            window.history.back();
          }}
        >
          {t(`${baseLang}.button`)}
        </div>
      </StModal>
    </>
  );
};

const StModal = styled.div`
  position: fixed;
  z-index: 70;
  background: #ffffff;
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    box-shadow: 0px 30px 80px 10px rgba(0, 0, 0, 0.2);
    .titleInfo {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin-top: 48px;
    }
    .confirmPart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      border-radius: 43px;
      color: black;
      margin: 48px auto 0;
    }
    .middlePart {
      margin-top: 48px;
    }
  }
  @media ${device.tabMob} {
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 344px;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 24px;
    box-sizing: border-box;
    .confirmPart {
      color: #0072de;
      margin: 0 auto;
    }
    .middlePart {
      margin-top: 20px;
    }
  }
  .titleInfo {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
  }
  .middlePart {
    font-size: 16px;
    font-family: "semiBold";
    line-height: 24px;
  }
  .confirmPart {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    cursor: pointer;
  }
  ul {
    padding-left: 20px;
    margin-top: 4px;
    line-height: 24px;
  }
`;

export default CreditQuestion;
