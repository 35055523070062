import { useState } from "react";
import styled from "styled-components";
import { ModalBack, ModalHeaderB } from "../../../components/Style";
import { device } from "../../../hooks/device";
import useScrollLockThree from "../../../hooks/useScrollLockThree";
import spinner from "../../../img/spinner.svg";
import sentMail from "../../../img/sentMail.svg";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { bannerOn, userInfo } from "../../../atoms";
import { useTranslation } from "react-i18next";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import greyX from "../../../img/greyX.svg";
import Img from "../../../atomic/atom/Img";

const ChargeConfirm = ({ setMyPageScroll, setResendTestEmail }) => {
  const { t } = useTranslation();
  const baseLang = "examiner.b_3_1";
  const navigate = useNavigate();
  const user = useRecoilValue(userInfo);
  const banneris = useRecoilValue(bannerOn);

  useScrollLockThree();

  // useEffect(() => {
  //   window.addEventListener("popstate", () => navigate(`/myexam`));
  //   return () => {
  //     window.removeEventListener("popstate", () => navigate(`/myexam`));
  //   };
  // }, []);

  // useLayoutEffect(() => {
  //   if (pair.imp_success === "false") {
  //     window.history.go(-2);
  //   }
  // }, [pair]);

  // fail, success, pending

  return (
    <>
      <ModalBack />
      <StAlertModal banneris={banneris}>
        <div className="wrapper">
          {/* <ModalHeaderB /> */}
          <DivTwoPartImprove display="none" pc_display="flex" cursor="pointer">
            <Img
              src={greyX}
              onclick={() => {
                if(setResendTestEmail) {
                  setResendTestEmail(false);
                } else {
                  navigate(`/myexam`);
                }
              }}
              margin="0 0 0 auto"
            />
          </DivTwoPartImprove>
          <div className="titleBox">
            {t(`${baseLang}.title1`)} <br className="onlyForMobile" />
            {t(`${baseLang}.title2`)}
          </div>
          {/* {loading === "pending" && (
            <div className="bodyBox">
              <div className="infoPart">
                입력한 정보로 시험을 만들고 있는 중이에요.
                <br />
                잠시만 기다려주세요...
              </div>
              <div className="statePart">
                <img className="spinner" src={spinner} alt="" />
                <div>시험 생성 중...</div>
              </div>
            </div>
          )} */}
          <div className="bodyBox">
            <div className="infoPart">
              <span>{user?.hostName},</span>
              <br />
              {t(`${baseLang}.midTitle1`)}
              <br className="onlyForMobile" />
              {t(`${baseLang}.midTitle2`)}
            </div>
            <div className="statePart">
              <img className="sentMail" src={sentMail} alt="" />
              <div>{t(`${baseLang}.ready`)}</div>
            </div>
          </div>
          <div className="helpBox">
            {t(`${baseLang}.info1`)}
            <br className="onlyForMobile" />{" "}
            <span
              style={{cursor: "pointer"}}
              onClick={() => {
                navigate(`/mypage`);
                setMyPageScroll("customerCenter");
              }}
            >
              {t(`${baseLang}.info2`)}
            </span>
            <br className="onlyForPc" />
            {t(`${baseLang}.info3`)}
          </div>
          <div 
            className="buttonBox" 
            onClick={() => {
              if(setResendTestEmail) {
                setResendTestEmail(false);
              } else {
                navigate(`/myexam`);
              }
            }}
          >
            {t(`${baseLang}.button`)}
          </div>
        </div>
      </StAlertModal>
    </>
  );
};

const StAlertModal = styled.div`
  position: fixed;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  @keyframes rotate_image {
    100% {
      transform: rotate(360deg);
    }
  }
  @media ${device.pc} {
    .onlyForMobile {
      display: none;
    }
    z-index: 51;
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .titleBox {
      font-size: 28px;
      font-family: "bold";
      line-height: 41px;
      margin: 27.5px auto 67px;
      text-align: center;
    }
    .bodyBox {
      .infoPart {
        font-size: 18px;
        font-family: "medium";
        line-height: 27px;
        text-align: center;
        span {
          color: #2276dc;
        }
      }
      .statePart {
        display: grid;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        margin: 80px auto 0;
        .sentMail {
          width: 109px;
          height: 85px;
          margin: 0 auto;
        }
        .spinner {
          width: 42px;
          height: 42px;
          animation: rotate_image 1.5s linear infinite;
          transform-origin: 50% 50%;
          margin: 0 auto;
        }
        div {
          line-height: 27px;
          font-size: 23px;
          font-family: "medium";
        }
      }
    }
    .helpBox {
      font-size: 11px;
      font-family: "regular";
      line-height: 16px;
      text-align: center;
      margin-top: 80px;
      span {
        color: #2276dc;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 42px;
      border: 1px solid #000000;
      border-radius: 42px;
      font-size: 14px;
      font-family: "medium";
      line-height: 25px;
      margin: 40px auto 0;
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    z-index: 49;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    box-sizing: border-box;
    bottom: 0px;
    left: 0;
    .onlyForPc {
      display: none;
    }
    .wrapper {
      /* padding: 24px; */
      padding: 24px;
      padding-bottom: 24px;
      overflow-x: hidden;
      height: 100%;
      ::-webkit-scrollbar {
        width: 5px;
        height: 10px;
        margin-top: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
    .titleBox {
      font-size: 22px;
      font-family: "bold";
      line-height: 33px;
      margin: 30px auto 64px;
      text-align: center;
    }
    .bodyBox {
      .infoPart {
        font-size: 15px;
        font-family: "regular";
        line-height: 22px;
        text-align: center;
        span {
          color: #2276dc;
        }
      }
      .statePart {
        display: grid;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 14px;
        margin: 75px auto 0;
        .sentMail {
          width: 59px;
          height: 46px;
          margin: 0 auto;
        }
        .spinner {
          width: 42px;
          height: 42px;
          margin: 0 auto;
          animation: rotate_image 1.5s linear infinite;
          transform-origin: 50% 50%;
        }
        div {
          line-height: 27px;
          font-size: 18px;
          font-family: "medium";
        }
      }
    }
    .helpBox {
      font-size: 11px;
      font-family: "regular";
      line-height: 16px;
      text-align: center;
      margin-top: 102px;
      padding: 14px 0px;
      box-sizing: border-box;
      span {
        color: #2276dc;
        text-decoration: underline;
      }
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 42px;
      border: 1px solid #000000;
      border-radius: 42px;
      font-size: 14px;
      font-family: "medium";
      line-height: 25px;
      margin: 45.5px auto 0;
      cursor: pointer;
    }
  }
`;

export default ChargeConfirm;
