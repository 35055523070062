import dayjs from "dayjs";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Calendar from "../components/Calendar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

const TestPage = () => {
  return (
    <>
      <StNormal placeholder="normal" />
      <StFont placeholder="regular" />
      <StBold placeholder="bold" />
    </>
  );
};

const StNormal = styled.input``;

const StFont = styled.input`
  font-family: "regular";
`;

const StBold = styled.input`
  font-family: "bold";
`;

export default TestPage;
