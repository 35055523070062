import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetEnterpriseTestOnGoing = () => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getEnterpriseTestOnGoing({ utc });
    return data;
  };
  return useQuery(["EnterpriseTestOnGoing"], fetcher);
};

export default useGetEnterpriseTestOnGoing;
