import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
// import reportWebVitals from "./reportWebVitals.js";
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from './GlobalStyle';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import './i18n/i18n.js';

// components
import ScrollRestoration from './components/ScrollRestoration';
const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <GlobalStyle />
    {/* <ScrollRestoration /> */}
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      {/* Noah, 모달 구분을 위해 RecoilRoot 안으로 이동 */}
      <ScrollRestoration />
    </RecoilRoot>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
