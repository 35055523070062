import React from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { languageIs } from "../../atoms";
import { device } from "../../hooks/device";
import {
  BaseTextStyleProps,
  MobileTextStyleProps,
  PCTextStyleProps,
  TabletTextStyleProps,
} from "../../interfaces";

interface TextProps
  extends PCTextStyleProps,
    TabletTextStyleProps,
    MobileTextStyleProps,
    BaseTextStyleProps {
  height?: number;
  textKor?: string;
  textEng?: string;
  cursor?: string;
  textalign?: string;
  onclick?: () => void;
}

interface TextStyle extends TextProps {
  pc_font_size?: number;
  pc_lineheight?: number;
  tab_font_size?: number;
  tab_lineheight?: number;
  mobile_font_size?: number;
  mobile_lineheight?: number;
}

const TextThreePart: React.FC<TextProps> = (props) => {
  const language = useRecoilValue<string>(languageIs);

  return (
    <StText
      display={props.display}
      height={props.height}
      font_color={props.font_color}
      font_family={props.font_family}
      font_size={props.font_size}
      lineheight={props.lineheight}
      margin={props.margin}
      pc_display={props.pc_display}
      pc_font_size={
        language === "/ko" ? props.pc_font_size_ko : props.pc_font_size_en
      }
      pc_font_color={props.pc_font_color}
      pc_font_family_ko={props.pc_font_family_ko}
      pc_lineheight={
        language === "/ko" ? props.pc_lineheight_ko : props.pc_lineheight_en
      }
      pc_margin={props.pc_margin}
      tab_display={props.tab_display}
      tab_font_size={
        language === "/ko" ? props.tab_font_size_ko : props.tab_font_size_en
      }
      tab_font_color={props.tab_font_color}
      tab_font_family_ko={props.tab_font_family_ko}
      tab_lineheight={
        language === "/ko" ? props.tab_lineheight_ko : props.tab_lineheight_en
      }
      tab_margin={props.tab_margin}
      mobile_display={props.mobile_display}
      mobile_font_color={props.mobile_font_color}
      mobile_font_family_ko={props.mobile_font_family_ko}
      mobile_font_size={
        language === "/ko"
          ? props.mobile_font_size_ko
          : props.mobile_font_size_en
      }
      mobile_lineheight={
        language === "/ko"
          ? props.mobile_lineheight_ko
          : props.mobile_lineheight_en
      }
      mobile_margin={props.mobile_margin}
      onClick={props.onclick}
      textalign={props.textalign}
      cursor={props.cursor}
    >
      {language === "/ko" ? props.textKor : props.textEng}
    </StText>
  );
};

const StText = styled.div<TextStyle>`
  font-family: ${(props) => props.font_family};
  @media ${device.pc} {
    display: ${(props) => props.pc_display};
    font-size: ${(props) => props.pc_font_size}px;
    font-family: ${(props) => props.pc_font_family_ko};
    color: ${(props) => props.pc_font_color};
    line-height: ${(props) => props.pc_lineheight}px;
    margin: ${(props) => props.pc_margin};
  }
  @media ${device.tablet} {
    display: ${(props) => props.tab_display};
    font-size: ${(props) => props.tab_font_size}px;
    font-family: ${(props) => props.tab_font_family_ko};
    color: ${(props) => props.tab_font_color};
    line-height: ${(props) => props.tab_lineheight}px;
    margin: ${(props) => props.tab_margin};
  }
  @media ${device.mobile} {
    display: ${(props) => props.mobile_display};
    font-size: ${(props) => props.mobile_font_size}px;
    font-family: ${(props) => props.mobile_font_family_ko};
    color: ${(props) => props.mobile_font_color};
    line-height: ${(props) => props.mobile_lineheight}px;
    margin: ${(props) => props.mobile_margin};
  }
  cursor: ${(props) => props.cursor};
`;

export default TextThreePart;
