import React from "react";

export const Trash2 = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.34505 5.07964H3.13422C2.79005 5.07964 2.51172 4.80047 2.51172 4.45714C2.51172 4.1138 2.79005 3.83464 3.13422 3.83464H7.51172V3.0013C7.51172 2.55547 7.90672 2.16797 8.34505 2.16797H11.6784C12.1167 2.16797 12.5117 2.55547 12.5117 3.0013V3.83464H16.8901C17.2334 3.83464 17.5117 4.1138 17.5117 4.45714C17.5117 4.80047 17.2334 5.07964 16.8901 5.07964H16.6784V17.9421C16.6784 18.4346 16.3051 18.8346 15.8451 18.8346C13.4509 18.8346 6.57255 18.8346 4.17839 18.8346C3.71839 18.8346 3.34505 18.4346 3.34505 17.9421V5.07964ZM15.4284 5.07964H4.59505V17.5846H15.4284V5.07964ZM11.8867 7.16797C11.5417 7.16797 11.2617 7.44797 11.2617 7.79297V14.8763C11.2617 15.2213 11.5417 15.5013 11.8867 15.5013C12.2317 15.5013 12.5117 15.2213 12.5117 14.8763V7.79297C12.5117 7.44797 12.2317 7.16797 11.8867 7.16797ZM8.13672 7.16797C7.79172 7.16797 7.51172 7.44797 7.51172 7.79297V14.8763C7.51172 15.2213 7.79172 15.5013 8.13672 15.5013C8.48172 15.5013 8.76172 15.2213 8.76172 14.8763V7.79297C8.76172 7.44797 8.48172 7.16797 8.13672 7.16797ZM11.2617 3.83464V3.41797H8.76172V3.83464H11.2617Z"
        fill={color}
      />
    </svg>
  );
};

export default Trash2;
