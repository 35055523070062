import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { userInfo } from "../atoms";
import arrow from "../img/arrowAnswer.svg";
import { device } from "../hooks/device";
import customAxios from "../api/handler";
import dayjs from "dayjs";
import QnADelete from "../modal/QnADelete";
import Photo from "../modal/Photo";
import useToast from "../hooks/useToast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AnswerQnA = ({
  type,
  context,
  imgList,
  setCommentEditModal,
  setCommentUpdate,
  setEditCommentUid,
  setQuestionComponentUuid,
  commentUpdate,
  setReAnswerModal,
  createdAt,
  uid,
  componentUid,
}) => {
  const { t } = useTranslation();
  const baseLang = "customer.h3_1_1";
  const baseLang2 = "customer.deleteComment";
  const navigate = useNavigate();
  const user = useRecoilValue(userInfo);
  const [pictureModalOn, setPictureModalOn] = useState(false);
  const showToast = useToast();
  const [clickedNumber, setClickedNumber] = useState();

  //modal
  const [commentDeleteModal, setCommentDeleteModal] = useState(false);

  const deleteComponent = () => {
    customAxios
      .delete(
        `/api/v1/questions/components?questionUuid=${uid}&questionComponentUuid=${componentUid}`
      )
      .then(() => {
        setCommentUpdate(!commentUpdate);
        showToast({ message: t(`errorCode.deleteReply`) });
      })
      .catch(({ response }) => {
        if (response.data.errorCode === "HOST-001") {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  return (
    <>
      {pictureModalOn && (
        <Photo
          list={imgList}
          clickedNumber={clickedNumber}
          setPictureModalOn={setPictureModalOn}
        />
      )}
      {commentDeleteModal && (
        <QnADelete
          close={setCommentDeleteModal}
          deleting={deleteComponent}
          title={t(`${baseLang2}.title`)}
          textOne={t(`${baseLang2}.body`)}
          // textTwo="작성하신 문의글을 정말 삭제하시겠어요?"
        />
      )}
      <StAnswerQnA type={type}>
        <div className="titleBox">
          <img className="arrow" src={arrow} alt="" />
          {type === "admin" ? (
            <div>Proctormatic Team</div>
          ) : (
            <div>{user?.hostName}</div>
          )}
          <div className="date">
            {dayjs(createdAt).format("hh:mm AㆍM/D/YYYY")}
          </div>
        </div>
        <div className="contentBox">{context}</div>
        <div className="imgBox">
          {imgList?.map((value, index) => (
            <img
              onClick={() => {
                setClickedNumber(index + 1);
                setPictureModalOn(true);
              }}
              className="image"
              src={value}
              key={index}
              alt=""
            />
          ))}
        </div>
        <div className="bottomBox">
          <div className="date">
            {dayjs(createdAt).format("hh:mm AㆍM/D/YYYY")}
          </div>
          <div className="buttonBox">
            {type === "host" && (
              <div
                className="button"
                onClick={() => {
                  setCommentDeleteModal(true);
                }}
              >
                {t(`${baseLang}.delete`)}
              </div>
            )}
            {type === "host" && "ㆍ"}
            {type === "host" && (
              <div
                className="button"
                onClick={() => {
                  // setEditCommentUid(componentUid);
                  // setCommentEditModal(true);
                  navigate("commentEdit");
                  setQuestionComponentUuid(componentUid);
                }}
              >
                {t(`${baseLang}.edit`)}
              </div>
            )}
            {type === "admin" && (
              <div
                className="button"
                onClick={() => {
                  setReAnswerModal(true);
                }}
              >
                {t(`${baseLang}.leaveComment`)}
              </div>
            )}
          </div>
        </div>
      </StAnswerQnA>
    </>
  );
};

const StAnswerQnA = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => (props.type === "admin" ? "#F1F3F5" : "")};
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
  .titleBox {
    font-family: "bold";
    display: flex;
    align-items: center;
  }
  .imgBox {
    .image {
      width: 96px;
      height: 96px;
      border-radius: 10px;
      object-fit: cover;
    }
  }
  .bottomBox {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 160%;
    .date {
      color: #888888;
    }
    .button {
      color: #2276dc;
      line-height: 160%;
      cursor: pointer;
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto 12px auto;
      gap: 3px;
    }
  }
  @media ${device.pc} {
    margin-bottom: 8px;
    .titleBox {
      display: grid;
      grid-template-columns: auto auto auto 1fr;
      gap: 12px;
      line-height: 26px;
      .date {
        font-size: 16px;
        font-family: "regular";
        color: #888888;
      }
    }
    .contentBox {
      margin: 24px 0 32px;
      line-height: 160%;
    }
    .imgBox {
      display: grid;
      grid-template-columns: repeat(6, auto) 1fr;
      gap: 12px;
    }
    .bottomBox {
      margin-top: 24px;
      .date {
        display: none;
      }
    }
  }
  @media ${device.tabMob} {
    .titleBox {
      display: grid;
      grid-template-columns: auto auto 1fr;
      gap: 8px;
      .date {
        display: none;
      }
    }
    .contentBox {
      margin: 12px 0;
      line-height: 26px;
    }
    .imgBox {
      display: grid;
      grid-template-columns: repeat(auto-fill, 80px);
      grid-auto-rows: 80px;
      /* flex-wrap: wrap; */
      gap: 12px;
      .image {
        width: 80px;
        height: 80px;
      }
    }
    .bottomBox {
      margin-top: 12px;
    }
  }
`;

export default AnswerQnA;
