import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import check from "../img/dropdown_check.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { clickInfo, languageIs } from "../atoms";
import { device } from "../hooks/device";

const UserList = ({
  testId,
  nowId,
  testerId,
  uploaderName,
  totalEventCount,
  check,
  close,
}) => {
  const [clickIndex, setClickIndex] = useRecoilState(clickInfo);
  const [now, setNow] = useState("");
  const navigation = useNavigate();
  const [color, setColor] = useState();
  // normal" | "cheat" | "abnormal" | "incompleteTest" | "uploadNotCompleted"
  useEffect(() => {
    if (check === "normal") {
      setColor("#3C9C9C");
    } else if (check === "cheat") {
      setColor("#C83B38");
    } else if (check === "abnormal") {
      setColor("#FFBC00");
    } else if (check === "incompleteTest") {
      setColor("#BDBDBD");
    } else {
      setColor("#BDBDBD");
    }
  }, [testerId]);

  useEffect(() => {
    if (Number(nowId) === testerId) {
      setNow("#2276DC");
    }
  }, [nowId, testerId]);

  return (
    <>
      {/* <StBackGround onClick={()=>{close();}} clickIndex={clickIndex}/> */}
      {nowId !== testerId && (
        <StBody
          num={Number(nowId)}
          testerId={testerId}
          color={color}
          onClick={() => {
            navigation(`/reportSpecific/${testerId}/${testId}`);

            setClickIndex("");
          }}
        >
          {color && (
            <div
              className="informationContainer"
              now={now}
              color={color}
              onClick={close}
            >
              <div className="statingg" />
              <span className="name">{uploaderName}</span>
              <span className="counting">
                {check === "incompleteTest" ||
                check === "errorCase" ||
                check === "uploadNotCompleted"
                  ? "-"
                  : totalEventCount}
              </span>
              {Number(nowId) === testerId ? (
                <img src={check} alt="" />
              ) : (
                <div className="space" />
              )}
            </div>
          )}
          {Number(nowId) === testerId && <StGrey className="grey" />}
        </StBody>
      )}
    </>
  );
};

const StBody = styled.div`
  /* position : ${(props) =>
    props.num === props.testerId ? "" : "absolute"}; */
  .informationContainer {
    z-index: 31;
    display: flex;
    align-items: center;
    padding: 14px 24px;
    box-sizing: border-box;
    color: ${(props) => props.now};
    width: 100%;
    .space {
      width: 20px;
      height: 20px;
    }
    @media ${device.pc} {
      .counting {
        margin: 0 11px 0 auto;
      }
    }
    @media ${device.tablet} {
      .counting {
        margin: 0 20px 0 auto;
      }
    }
    @media ${device.mobile} {
      .counting {
        margin: 0 24px 0 auto;
      }
    }
    .name {
      margin-left: 10px;
      line-height: 24px;
    }
    .statingg {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      background-color: ${(props) => props?.color};
    }
  }
`;

const StGrey = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
`;

export default UserList;
