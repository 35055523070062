import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportUserList = ({ testId }) => {
  const fetcher = async () => {
    const { data } = await apis.getReportUserList({ uid: testId });
    return data;
  };
  return useQuery(["reportUserList", testId], fetcher);
};

export default useGetReportUserList;
