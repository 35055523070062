import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetFinishedExam = ({ uid }, update, updating) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getFinishedExam({ utc, uid });
    return data;
  };
  return useQuery(["finished", update, updating], fetcher);
};

export default useGetFinishedExam;
