import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { languageIs } from "../atoms";

// img
import greyX from "../img/greyX.svg";
import arrow from "../img/arrow.svg";
import { device } from "../hooks/device";

export const ModalHeader = ({ title, cancelIs, off }) => {
  return (
    <StModalHeader>
      <img
        className="mobileButton"
        src={arrow}
        onClick={() => {
          window.history.back();
        }}
      />
      <div>{title}</div>
      {!cancelIs && (
        <img
          className="pcButton"
          src={greyX}
          onClick={() => {
            off ? off() : window.history.back();
          }}
        />
      )}
    </StModalHeader>
  );
};

export const ModalHeaderB = () => {
  return (
    <StModalHeaderB>
      <img
        className="pcButton"
        src={greyX}
        onClick={() => {
          window.history.back();
        }}
      />
    </StModalHeaderB>
  );
};

export const ModalHeaderD = ({ title }) => {
  return (
    <StModalHeaderD>
      <div>{title}</div>
    </StModalHeaderD>
  );
};

export const ModalHeaderC = ({ title }) => {
  return (
    <StModalHeader>
      <div>{title}</div>
      <img
        className="cancelButton"
        src={greyX}
        onClick={() => {
          window.history.back();
        }}
      />
    </StModalHeader>
  );
};

export const ModalBack = () => {
  return <StModalBack />;
};

export const ModalBackD = ({ modalOff }) => {
  return (
    <StModalBackD
      onClick={() => {
        modalOff && modalOff();
      }}
    />
  );
};

export const ModalBackDTwo = () => {
  return <StModalBackD />;
};

export const ModalBackTwo = () => {
  return <StModalBackTwo />;
};

export const ModalBackThree = () => {
  return <StModalBackThree />;
};

export const ModalBackFour = () => {
  return <StModalBackFour />;
};

export const MobileModalBack = () => {
  return <StMobileModalBack />;
};

export const PageHeader = ({ title, page, img, nav, text }) => {
  const country = useRecoilValue(languageIs);
  const navigate = useNavigate();
  return (
    <StPageHeader>
      <div className="headerLeft">
        <img src={arrow} onClick={() => navigate(`/${page}`)} />
        <div className="title">{title}</div>
      </div>
      <div className="headerRight">
        <img src={img} onClick={() => navigate(`/${nav}`)} />
        <div className="">{text}</div>
      </div>
    </StPageHeader>
  );
};

export const ModalButLooksLikePage = ({ title }) => {
  return (
    <StModalButLooksLikePage>
      <img
        className="backButton"
        src={arrow}
        onClick={() => {
          window.history.back();
        }}
      />
      <div>{title}</div>
    </StModalButLooksLikePage>
  );
};

// 두번째 모달의 백 ( pc에서는 나오고 mobile 에서는 안나온다.)
const StModalBackTwo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 53;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  @media ${device.tabMob} {
    display: none;
  }
`;

const StModalHeaderB = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  img {
    margin-left: auto;
    cursor: pointer;
  }
  @media ${device.tabMob} {
    img {
      display: none;
    }
  }
  .cancelButton {
    margin-left: auto;
  }
`;

const StModalBackThree = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  @media ${device.tabMob} {
    display: none;
  }
`;

const StModalBackFour = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 57;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
  @media ${device.tabMob} {
    display: none;
  }
`;

const StModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  img {
    cursor: pointer;
  }
  @media ${device.pc} {
    font-size: 28px;
    font-family: "bold";
    line-height: 41px;
    .mobileButton {
      display: none;
    }
    .pcButton {
      margin-left: auto;
    }
  }
  @media ${device.tabMob} {
    padding: 24px 0;
    box-sizing: border-box;
    font-size: 19px;
    font-family: "bold";
    line-height: 28px;
    .mobileButton {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }
    .pcButton {
      display: none;
    }
  }
  .cancelButton {
    margin-left: auto;
  }
`;

const StModalHeaderD = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${device.pc} {
    font-size: 28px;
    font-family: "bold";
    line-height: 41px;
    .mobileButton {
      display: none;
    }
    .pcButton {
      margin-left: auto;
    }
  }
  @media ${device.tabMob} {
    padding: 0px 0 24px;
    box-sizing: border-box;
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
    .mobileButton {
      width: 14px;
      height: 14px;
      margin-right: 15.6px;
    }
    .pcButton {
      display: none;
    }
  }
`;

const StModalButLooksLikePage = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 24px 16px;
  box-sizing: border-box;
  font-size: 19px;
  font-family: "bold";
  line-height: 28px;
  .backButton {
    height: 18px;
    margin-right: 15.6px;
  }
`;

const StPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .headerLeft {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    gap: 12px;
    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
    title {
      line-height: 28px;
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
    }
    div {
      font-size: 16px;
      font-family: "medium";
      line-height: 24px;
    }
  }
  @media ${device.pc} {
    font-size: 28px;
    font-family: "bold";
    line-height: 41px;
    .headerLeft {
      display: grid;
      grid-template-columns: auto auto;
      gap: 19px;
      img {
        width: 28px;
        height: 28px;
      }
    }
  }
  @media ${device.tabMob} {
    padding: 24px 0px 16px;
    font-size: 19px;
    font-family: "bold";
    line-height: 28px;
  }
`;

const StModalBack = styled.div`
  @media ${device.tabMob} {
    display: none;
  }
  position: fixed;
  top: 0;
  left: 0;
  z-index: 51;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;

const StModalBackD = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 53;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;

const StModalBackDTwo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 55;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;

const StMobileModalBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 52;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;

export const StModalForm = styled.div`
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    box-sizing: border-box;
  }
  @media ${device.tabMob} {
  }
  position: fixed;
  z-index: 70;
  width: 500px;
  height: 700px;
  background-color: white;
`;
