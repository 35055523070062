import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetEnterpriseMemberList = ({ sort }) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getEnterpriseMemberList({ utc, sort });
    return data;
  };
  return useQuery(["EnterpriseMemberList", sort], fetcher);
};

export default useGetEnterpriseMemberList;
