import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportAIJudge = ({ testId }) => {
  const fetcher = async () => {
    const { data } = await apis.getReportAIJudge({ uid: testId });
    return data;
  };
  return useQuery("reportListSum", fetcher);
};

export default useGetReportAIJudge;
