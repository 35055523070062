import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ModalHeaderD } from "../components/Style";

// img
import check from "../img/confirm.svg";
import { device } from "../hooks/device";
import { baseURL } from "../api/handler";
import customAxios from "../api/handler";
import useToast from "../hooks/useToast";

const RefundInnerModal = ({
  setRefunded,
  setRefundErrorCode,
  setRefundError,
  setRefundInModal,
  refundInModal,
}) => {
  const utcInfo = new Date().getTimezoneOffset();
  const [checking, setChecking] = useState(false);
  const showToast = useToast();

  const goBack = () => {
    setRefundInModal(false);
  };

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    return () => {
      if (!refundInModal)
        // 버튼이 눌리지 않았을 때
        window.removeEventListener("popstate", goBack);
    };
  }, []);

  const realRefund = () => {
    customAxios
      .patch(`${baseURL}/api/v1/payments/enterprise/manager/credits`)
      .then(() => {
        setRefunded(true);
        setRefundInModal(false);
      })
      .catch(({ response }) => {
        setRefundErrorCode(response.data.errorCode);
        if (response.data.errorCode === "PAYMENT-ENT-002") {
          showToast({
            message: `알 수 없는 오류가 발생하였어요. 결제한 내역에 대해서는 고객센터를 통해 연락주시면 신속하게 도와드릴게요. (오류 코드 : ${response.data.errorCode})`,
          });
        } else {
          showToast({
            message: ` 알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.`,
          });
        }
        setRefundInModal(false);
        setRefundError(true);
      });
  };

  return (
    <>
      <StBack />
      <StInnerModal checking={checking}>
        <ModalHeaderD title="환불 가능 금액 확인 및 동의" />
        <div className="infoPart">
          환불 받으실 금액을 확인하였으며, 정말로 적립금을 환불 하시겠어요?
        </div>
        <div
          className="clickPart"
          onClick={() => {
            setChecking(!checking);
          }}
        >
          {checking ? (
            <img src={check} alt="" />
          ) : (
            <div className="bigCircle" />
          )}
          <div style={{ cursor: "pointer" }}>내용을 확인했어요.</div>
        </div>
        <div className="buttonPart">
          <div
            className="cancelButton"
            onClick={() => {
              setRefundInModal(false);
            }}
          >
            취소
          </div>
          <div className="columnBar onlyForMobile" />
          <div className="confirmButton" onClick={realRefund}>
            환불 신청
          </div>
        </div>
      </StInnerModal>
    </>
  );
};

const StInnerModal = styled.div`
  position: fixed;
  z-index: 71;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  margin: 0 auto;
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .onlyForMobile {
      display: none;
    }
    .infoPart {
      margin: 48px 0;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .clickPart {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .buttonPart {
      display: grid;
      margin-top: 48px;
      gap: 21px;
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        background-color: white;
        border: 1px solid #000000;
        border-radius: 43px;
        color: black;
        cursor: pointer;
      }
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        background: ${(props) => (props.checking ? "#20315B" : "#D9D9D9")};
        border-radius: 43px;
        color: white;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    /* height : 100%; */
    padding: 24px;
    box-sizing: border-box;
    left: 50%;
    border-radius: 26px;
    bottom: 16px;
    transform: translate(-50%, 0);
    .infoPart {
      margin-bottom: 8px;
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
    }
    .clickPart {
      font-size: 16px;
      font-family: "regular";
      line-height: 19px;
    }
    .buttonPart {
      margin-top: 16px;
      .cancelButton {
        text-align: center;
        width: 147.5px;
        cursor: pointer;
      }
      .confirmButton {
        color: ${(props) => (props.checking ? "#2276DC" : "#D9D9D9")};
        text-align: center;
        width: 147.5px;
        border: none;
        cursor: ${(props) => (props.checking ? "pointer" : "default")};
      }
    }
  }
  .titlePart {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .clickPart {
    display: flex;
    align-items: center;
    padding: 5px;
    .bigCircle {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      margin-right: 13px;
      border: 1px solid #8c8c8c;
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }
    img {
      margin-right: 13px;
      width: 20px;
      height: 20px;
    }
  }
  .buttonPart {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-family: "medium";
    .columnBar {
      width: 1px;
      height: 16px;
      background-color: #e6e6e6;
    }
  }
`;

const StBack = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 70;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
`;

export default RefundInnerModal;
