import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBack } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";

const CreditConfirm = ({
  creditData,
  setByeCreditConfirmModal,
  setByeModalOn,
}) => {
  const goBack = () => {
    setByeCreditConfirmModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBack />
      <StConnectionConfirm>
        <div className="title">기업 적립금 확인</div>
        <div className="info">
          현재 보유하신 적립금과 취소 예정인 시험(해당 시에만) 중 환불이 가능한
          적립금이 있어요. 회원 탈퇴 시 해당 적립금은 계정과 함께 전부 삭제되며,
          더 이상 환불 요청은 불가능해요.{" "}
        </div>
        <div className="middleTitle">※ 환불 가능한 적립금</div>
        <ul className="list">
          <li>
            <span>
              {Number(creditData?.refundableCredits)?.toLocaleString()} C
            </span>{" "}
            (전체 {Number(creditData?.totalCredits)?.toLocaleString()} C 중)
          </li>
        </ul>
        <div className="buttonBox">
          <div className="cancelButton" onClick={() => window.history.back()}>
            취소
          </div>
          <div className="columnBar" />
          <div
            className="cancelButton"
            onClick={() => {
              setByeCreditConfirmModal(false);
              setByeModalOn(true);
            }}
          >
            계속하기
          </div>
        </div>
      </StConnectionConfirm>
    </>
  );
};

const StConnectionConfirm = styled.div`
  position: fixed;
  z-index: 53;
  left: 50%;
  bottom: 16px;
  transform: translate(-50%, 0);
  width: calc(100% - 16px);
  padding: 24px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  border-radius: 24px;
  .title {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .info {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
    margin: 20px 0 20px;
  }
  .middleTitle {
    font-size: 16px;
    font-family: "semibold";
    line-height: 24px;
    margin-bottom: 4px;
  }
  .list {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
    padding-left: 20px;
    span {
      color: #2276dc;
      font-family: "bold";
    }
  }
  .buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 41px;
    padding: 4.5px 0;
    box-sizing: border-box;
    margin-top: 24px;
    .columnBar {
      width: 1px;
      height: 16px;
      background-color: #e6e6e6;
    }
    .cancelButton {
      display: flex;
      justify-content: center;
      width: 63px;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      color: #0072de;
      cursor: pointer;
    }
  }
`;

export default CreditConfirm;
