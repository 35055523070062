import React from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface brProps {
  pc?: boolean;
  tablet: boolean;
  mobile: boolean;
}

const Br: React.FC<brProps> = (props) => {
  return <StBr pc={props.pc} tablet={props.tablet} mobile={props.mobile} />;
};

const StBr = styled.div<brProps>`
  @media ${device.pc} {
    display: ${(props) => (props.mobile || props.tablet ? "none" : "")};
  }
  @media ${device.tablet} {
    display: ${(props) => (props.mobile || props.pc ? "none" : "")};
  }
  @media ${device.mobile} {
    display: ${(props) => (props.tablet || props.pc ? "none" : "")};
  }
  @media ${device.tabMob} {
    display: ${(props) => (props.pc ? "none" : "")};
  }
`;

export default Br;
