import React from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import errorMark from "../../img/error.svg";
import TextLine from "../atom/TextLine";

interface AlertProps {
  text: string;
  fontsize?: number;
  pc_fontsize?: number;
}

const AlertMessage: React.FC<AlertProps> = (props) => {
  return (
    <DivTwoPartImprove display="flex" align_items="center" margin="6px 0 0 0">
      <img src={errorMark} />{" "}
      <TextLine
        text={props.text}
        lineheight={30}
        margin="0 0 0 5px"
        fontcolor="#818181"
        fontsize={props.fontsize}
        pc_fontsize={props.pc_fontsize}
      />
    </DivTwoPartImprove>
  );
};

export default AlertMessage;
