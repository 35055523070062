import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalBack, ModalBackD, ModalHeader } from "../components/Style";
import { useMutation } from "react-query";
import { languageAxios } from "../api/axiosTwo";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { languageIs, userlang } from "../atoms";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

const LanguageModal = ({ setLanguageModalOn, setLanguage, language, uid }) => {
  const [lang, setLang] = useState(language);
  const countryChange = useSetRecoilState(userlang);
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);

  const goBack = () => {
    setLanguageModalOn(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const { mutate: mutateLanguage } = useMutation(languageAxios, {
    onSuccess: ({ data }) => {
      console.log("언어정보 변경 성공");
    },
    onError: ({ response }) => {
      console.log(response);
    },
  });

  const languageChange = (language) => {
    mutateLanguage({
      hostLanguage: language,
      uid: uid,
    });
  };

  useScrollLockThree();

  const modalOff = () => {
    window.history.back();
  };

  return (
    <>
      <ModalBackD modalOff={modalOff} />
      <StLanguageModal lang={lang}>
        <div className="titleBox">서비스 언어</div>
        <div className="languageBox">
          <div
            className="lineBox"
            onClick={() => {
              setLanguageModalOn(false);
              countryChange(`EN`);
              languageChange("EN");
              setLanguage("EN");
            }}
          >
            <div className="bigCircleOne" style={{ cursor: "default" }}>
              <div className="smallCircle" style={{ cursor: "default" }} />
            </div>
            <div style={{ color: "#BDBDBD", cursor: "default" }}>
              ENG (English)
            </div>
          </div>
          <div
            className="lineBox"
            onClick={() => {
              setLanguageModalOn(false);
              countryChange(`KR`);
              languageChange("KR");
              setLanguage("KR");
            }}
          >
            <div className="bigCircleTwo">
              <div className="smallCircle" />
            </div>
            <div>KOR (한국어)</div>
          </div>
        </div>
      </StLanguageModal>
    </>
  );
};

const StLanguageModal = styled.div`
  position: fixed;
  z-index: 55;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .lineBox {
    display: flex;
    align-items: center;
    padding: 17px 0;
    div {
      line-height: 27px;
      cursor: pointer;
    }
    .bigCircleOne {
      position: relative;
      width: 19.5px;
      height: 19.5px;
      border-radius: 19.5px;
      border: 1px solid
        ${(props) => (props.lang === "KR" ? "#BDBDBD" : "#0381FE")};
      margin-right: 16.25px;
      box-sizing: border-box;
      cursor: pointer;
      .smallCircle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${(props) => (props.lang === "KR" ? "" : "#0381FE")};
        width: 11px;
        height: 11px;
        border-radius: 11px;
      }
    }
    .bigCircleTwo {
      position: relative;
      width: 19.5px;
      height: 19.5px;
      border-radius: 19.5px;
      border: 1px solid
        ${(props) => (props.lang === "KR" ? "#0381FE" : "#8c8c8c")};
      margin-right: 16.25px;
      box-sizing: border-box;
      cursor: pointer;
      .smallCircle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: ${(props) => (props.lang === "KR" ? "#0381FE" : "")};
        width: 11px;
        height: 11px;
        border-radius: 11px;
      }
    }
  }
  @media ${device.pc} {
    display: flex;
    flex-direction: column;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 580px;
    height: 321px;
    padding: 60px;
    .lineBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .languageBox {
      display: flex;
      flex-direction: column;
      margin-top: auto;
    }
    .titleBox {
      font-size: 28px;
      font-family: "bold";
      line-height: 41px;
    }
  }
  @media ${device.tabMob} {
    width: calc(100% - 16px);
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 24px;
    border-radius: 26px;
    .titleBox {
      font-size: 20px;
      font-family: "bold";
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
`;

export default LanguageModal;
