import { useQuery } from "react-query";
import apis from "../api/getApi";
import useToast from "./useToast";

const useGetRefundSpecific = ({ refundId }) => {
  const showToast = useToast();

  const fetcher = () => {
    const utc = new Date().getTimezoneOffset();
    return new Promise((resolve, reject) => {
      apis
        .getRefundSpecific({ utc, refundId })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (
            response.data.errorCode === "ENTERPRISE-009" ||
            response.data.errorCode === "PAYMENT-ENT-003"
          ) {
            showToast({
              message:
                "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
            });
          }
          reject(response);
        });
    });
  };
  return useQuery(["refundSpecific"], fetcher);
};

export default useGetRefundSpecific;
