import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetReportSettingPhoto = ({ testerId, testId }) => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getReportSettingPhoto({
      utc,
      testId,
      testerId,
    });
    return data;
  };
  return useQuery(["reportPhoto", testerId], fetcher, {
    onError: (data) => {
      console.log(data);
    },
  });
};

export default useGetReportSettingPhoto;
