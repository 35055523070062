import React from "react";
import ModalPageTemplate from "../../../atomic/template/ModalPageTemplate";
import ModalHeader from "../../../atomic/molecule/ModalHeader";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import TextLine from "../../../atomic/atom/TextLine";
import Button from "../../../atomic/atom/Button";
import { useTranslation } from "react-i18next";

const EditNotAllowed = () => {
  const { t } = useTranslation();
  const baseLang = "examiner.b1_2_1";
  return (
    <ModalOneButtonTemplate>
      <ModalHeader title={t(`${baseLang}.title`)} />
      <DivTwoPartImprove
        display="grid"
        font_size={16}
        lineheight={24}
        pc_font_size={18}
        pc_font_family="medium"
        pc_lineheight={27}
        margin="20px 0 24px"
        pc_margin="48px 0"
      >
        <TextLine text={t(`${baseLang}.body1`)} />
        <br />
        <TextLine text={t(`${baseLang}.body2`)} />
      </DivTwoPartImprove>
      <Button
        text={t(`${baseLang}.button`)}
        font_size={18}
        font_color="#0072de"
        border="none"
        backgroundcolor="white"
        width="158px"
        height={35}
        margin="0 auto"
        pc_width="204px"
        pc_height={52}
        pc_border="1px solid black"
        pc_border_radius={43}
        pc_fontcolor="black"
        onclick={() => window.history.back()}
      />
    </ModalOneButtonTemplate>
  );
};

export default EditNotAllowed;
