import React, { useEffect, useRef, useState } from "react";
import InputBoxWithTitle from "../molecule/InputBoxWithTitle";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import TextLine from "../atom/TextLine";
import AlertMessage from "../molecule/AlertMessage";
import { useTranslation } from "react-i18next";

interface TesterCountChargeProps {
  setChargeAmount: React.Dispatch<React.SetStateAction<number>>;
}

const TesterCountCharge: React.FC<TesterCountChargeProps> = (props) => {
  const { t } = useTranslation();
  const baseLang = "examiner.m2_6";
  const testTimeList = [
    "120 Minutes",
    "110 Minutes",
    "100 Minutes",
    "90 Minutes",
    "80 Minutes",
    "70 Minutes",
    "60 Minutes",
    "50 Minutes",
    "40 Minutes",
    "30 Minutes",
  ];
  const [testTime, setTestTime] = useState<string | null>(null);
  const [testerCount, setTesterCount] = useState<number>(0);
  const [chargePrice, setChargePrice] = useState<number>(0);
  const [chargeCredit, setChargeCredit] = useState<number>(0);

  useEffect(() => {
    setChargePrice(
      ((testerCount * Number(testTime?.replace(/ Minutes/g, ""))) / 10) * 1.5
    );
  }, [testerCount]);

  useEffect(() => {
    setChargeCredit(chargePrice * 10);
    props.setChargeAmount(chargePrice);
  }, [chargePrice]);

  useEffect(() => {
    setChargePrice(
      Math.ceil(
        (((testerCount * Number(testTime?.replace(/ Minutes/g, ""))) / 10) *
          1.5) /
          10
      ) * 10
    );
  }, [testTime]);

  return (
    <DivTwoPartImprove>
      <DivTwoPartImprove
        display="grid"
        grid_template_column="1fr"
        pc_grid_template_column="1fr 1fr"
        gap={12}
      >
        <InputBoxWithTitle
          title={t(`${baseLang}.duration`)}
          optional={true}
          option_list={testTimeList}
          optionChoose={testTime}
          setOptionChoose={setTestTime}
          placeholder={t(`${baseLang}.placeholder2`)}
          option_list_border="1px solid #D9D9D9"
          option_list_border_radius={8}
        />
        <InputBoxWithTitle
          title={t(`${baseLang}.takerCount`)}
          placeholder={t(`${baseLang}.placeholder2`)}
          height={56}
          pc_height={60}
          onchange={(e) => setTesterCount(e.target.value)}
          input_border_radius={8}
          input_type="number"
          onblur={() => setChargePrice(Math.ceil(chargePrice / 10) * 10)}
        />
      </DivTwoPartImprove>
      <DivTwoPartImprove display="grid" gap={2} margin="16px 0 0 0">
        <TextLine
          text={t(`${baseLang}.amount`)}
          fontsize={16}
          pc_fontsize={18}
          lineheight={30}
        />
        <TextLine
          border_radius={8}
          lineheight={36}
          height={50}
          pc_height={54}
          padding="10px 16px"
          pc_padding="12px 16px"
          backgroundcolor="#F0F1F2"
          text={chargePrice > 0 ? chargePrice.toLocaleString() : ""}
          // text={chargePrice > 0 ? chargePrice.toLocaleString() + "$" : ""}
        />
        <AlertMessage
          text={t(`${baseLang}.chargeInfo1`)}
          pc_fontsize={14}
          fontsize={14}
        />
      </DivTwoPartImprove>
      <DivTwoPartImprove display="grid" gap={2} margin="16px 0 0 0">
        <TextLine
          text={t(`${baseLang}.credit`)}
          fontsize={16}
          pc_fontsize={18}
          lineheight={30}
        />
        <TextLine
          border_radius={8}
          lineheight={36}
          height={50}
          pc_height={54}
          fontcolor="#2276DC"
          padding="10px 16px"
          pc_padding="12px 16px"
          backgroundcolor="#F0F1F2"
          text={chargeCredit > 0 ? chargeCredit.toLocaleString() : ""}
          // text={chargeCredit > 0 ? chargeCredit.toLocaleString() + "c" : ""}
        />
        <AlertMessage
          text={t(`${baseLang}.chargeInfo2`)}
          pc_fontsize={14}
          fontsize={14}
        />
      </DivTwoPartImprove>
    </DivTwoPartImprove>
  );
};

export default TesterCountCharge;
