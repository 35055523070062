import React, { ReactNode } from "react";
import styled from "styled-components";

interface PageProps {
  children: ReactNode;
  display?: string;
  direction?: string;
  justify?: string;
  align?: string;
  width?: string;
  padding?: string;
}

const PageTemplate: React.FC<PageProps> = (props) => {
  return (
    <StPage
      display={props.display}
      direction={props.direction}
      justify={props.justify}
      align={props.align}
      width={props.width}
      padding={props.padding}
    >
      {props.children}
    </StPage>
  );
};

const StPage = styled.div<PageProps>`
  display: ${(props) => props.display};
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
`;

export default PageTemplate;
