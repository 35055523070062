import { useEffect } from "react";
import { getCookie } from "../Cookie";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { languageIs, loginModal } from "../atoms";
import { useNavigate } from "react-router-dom";

const useLoginCheck = (start) => {
  const setLoginModal = useSetRecoilState(loginModal);
  const navigate = useNavigate();
  let token = getCookie("token");

  useEffect(() => {
    if (start) {
      if (!token) {
        navigate(`/`);
        setLoginModal(true);
      }
    }
  }, [start]);
};

export default useLoginCheck;
