import React from "react";
import styled from "styled-components";

interface ModalBackProps {
  background_color?: string;
  z_index?: number;
  onclick?: () => void;
}

const ModalBack: React.FC<ModalBackProps> = (props) => {
  return (
    <StModalBack
      background_color={props.background_color}
      z_index={props.z_index}
      onClick={props.onclick}
    />
  );
};

const StModalBack = styled.div<ModalBackProps>`
  z-index: ${(props) => props.z_index};
  position: fixed;
  top: -300px;
  left: -700px;
  width: 3000px;
  height: 3000px;
  background-color: ${(props) => props.background_color};
`;

export default ModalBack;
