import {
  ImageAttachRequest,
  PostCreateExamRequest,
  PostS3Key,
  uploadCommentRequest,
} from "../interface/apiInterface";
import customAxios from "./handler";

const postApi = {
  postDisconnectProgressing: (hostEmail: string) => {
    return customAxios.post(
      `/api/v1/users/enterprise/manager/disconn/progressing-test`,
      hostEmail
    );
  },
  postCreateTestInd: (data: PostCreateExamRequest) => {
    return customAxios.post(`/api/v1/tests/individual/create`, data);
  },
  postCreateTestEnt: (data: PostCreateExamRequest) => {
    return customAxios.post(`/api/v1/tests/enterprise/create`, data);
  },
  uploadComment: ({
    context,
    uid,
    questionComponentUid,
    s3KeyList,
  }: uploadCommentRequest) => {
    return customAxios.post(`/api/v1/questions/components`, {
      context: context,
      questionUuid: uid,
      questionComponentUuid: questionComponentUid,
      confirmImageS3KeyList: s3KeyList,
    });
  },
  getS3Key: ({
    hostUuid,
    hostEmail,
    questionUuid,
    questionComponentUuid,
  }: ImageAttachRequest) => {
    return customAxios.post<PostS3Key>(
      `/api/v1/questions/components/pre-signed-url`,
      {
        hostUuid,
        hostEmail,
        questionUuid,
        questionComponentUuid,
      }
    );
  },
  /**
   * 티켓링크 : https://www.notion.so/edint/WEB-QR-1-54e3b2f2184041dc8b3afb1d2d410761?pvs=4
   * 티켓ID : 58
   * 주석작성자 : Noah
   * 주석작성일자 :
   * 티켓내용 : QR 재열람 가능하도록 수정
   * 주석설명 : 시험 안내 메일 재발송용 API 추가
   */
  postResendTestEmail: (testUuid: string) => {
    return customAxios.post(`/api/v1/tests/resend`, testUuid);
  },
};

export default postApi;
