import React, { useEffect } from "react";
import { ModalBack, ModalBackD, ModalHeaderD } from "../components/Style";
import styled from "styled-components";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { useTranslation } from "react-i18next";

const ModalFormD = ({ title, textOne, textTwo, close }) => {
  const { t } = useTranslation();
  useScrollLockThree();

  const goBack = () => {
    close(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  return (
    <>
      <ModalBackD />
      <StUpload>
        <ModalHeaderD title={title} />
        <div className="uploadBody">
          <div>{textOne}</div>
          <div>{textTwo}</div>
        </div>
        <div className="confirmButton" onClick={() => window.history.back()}>
          {t("myexam.d3_11.button")}
        </div>
      </StUpload>
    </>
  );
};

const StUpload = styled.div`
  position: fixed;
  z-index: 53;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    display: grid;
    flex-direction: column;
    gap: 48px;
    .uploadBody {
      display: flex;
      flex-direction: column;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      border: 1px solid #000000;
      border-radius: 43px;
      height: 52px;
      width: 204px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .uploadBody {
      line-height: 24px;
    }
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      color: #0072de;
      margin: 24.5px auto 0;
      padding: 4px 63px;
      cursor: pointer;
    }
  }
`;

export default ModalFormD;
