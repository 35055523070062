import { useQuery } from "react-query";
import apis from "../api/getApi";
import { useSetRecoilState } from "recoil";
import { toastMessage, toastOn } from "../atoms";

interface tradeListProps {
  value: string;
}

const useGetCreditUseMaster = ({ value }: tradeListProps) => {
  const setToastOn = useSetRecoilState(toastOn);
  const setToastText = useSetRecoilState(toastMessage);
  const toastOut = () =>
    setTimeout(() => {
      setToastOn(false);
    }, 2000);

  const fetcher = () => {
    return new Promise((resolve, reject) => {
      apis
        .getCreditUseMaster({ value })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.errorCode === "ENTERPRISE-009") {
            setToastText(
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요."
            );
            setToastOn(true);
            toastOut();
          }
          reject(response);
        });
    });
  };
  return useQuery(["point", value], fetcher);
};

export default useGetCreditUseMaster;
