import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEn from "./translation.en.json";
import i18next from "i18next";

const customMiddleware = {
  type: "3rdParty",

  init(i18next) {
    i18next.on("missingKey", (lng, ns, key, res) => {
      // Set a default value for missing keys
      res[key] = "";
    });
  },
};

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEn,
  },
  ko: {
    translation: {
      welcome: {
        intro: "리액트에 오신것을 환영합니다.",
        header: "모든 것이 잘 돌아가고 있어",
      },
    },
  },
};

i18next
  .on("missingKey", (lng, ns, key, res) => {
    res[key] = "";
  })
  // .use(customMiddleware) // passes i18n down to react-i18next
  .use(initReactI18next)
  .init({
    resources,
    // fallbackLng: "en",
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS: "translation",
  });

export default i18n;
