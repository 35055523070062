import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

// style
import styled from 'styled-components';
import './Landing.css';

// components
import Home1 from '../components/Home1';
import Home2 from '../components/Home2';
import Home3 from '../components/Home3';
import Home4 from '../components/Home4';
import Home5 from '../components/Home5';
import Home6 from '../components/Home6';
import InviteModal from "../components/InviteModal";

// img
import { device } from '../hooks/device';
import { useTranslation } from 'react-i18next';

const Landing = ({ nav, setNav, setMyPageScroll, banneris }) => {
  // ref for scroll
  const top = useRef();
  const service = useRef();
  const technic = useRef();
  const how = useRef();
  const qna = useRef();

  const [yOffset, setYOffset] = useState();

  useEffect(() => {
    if (document.location.href.split('#')[1] === 'how_to_use_examinee') {
      how.current.scrollIntoView({ block: 'start' });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1140) {
      if (banneris) {
        setYOffset(-108);
      } else {
        setYOffset(-60);
      }
    } else {
      if (banneris) {
        setYOffset(-115);
      } else {
        setYOffset(-75);
      }
    }
  }, [window.innerWidth, banneris]);

  const elementOne = document.getElementById('pageOne');
  const elementThree = document.getElementById('pageThree');
  const elementFour = document.getElementById('pageFour');
  const elementFive = document.getElementById('pageFive');
  const elementSix = document.getElementById('pageSix');

  let pageOne =
    elementOne?.getBoundingClientRect().top + window.pageYOffset + yOffset;
  let pageThree =
    elementThree?.getBoundingClientRect().top + window.pageYOffset + yOffset;
  let pageFour =
    elementFour?.getBoundingClientRect().top + window.pageYOffset + yOffset;
  let pageFive =
    elementFive?.getBoundingClientRect().top + window.pageYOffset + yOffset;
  let pageSix =
    elementSix?.getBoundingClientRect().top + window.pageYOffset + yOffset;

  useEffect(() => {
    if (nav[0] === 1) {
      if (nav[1] === '') {
        window.scrollTo({ top: pageOne, behavior: 'smooth' });
      } else {
        top.current.scrollIntoView({ block: 'end' });
      }
    } else if (nav[0] === 3) {
      if (nav[1] === '') {
        window.scrollTo({ top: pageThree, behavior: 'smooth' });
      } else {
        service.current.scrollIntoView({ block: 'start' });
      }
    } else if (nav[0] === 4) {
      if (nav[1] === '') {
        window.scrollTo({ top: pageFour, behavior: 'smooth' });
      } else {
        technic.current.scrollIntoView({ block: 'start' });
      }
    } else if (nav[0] === 5) {
      if (nav[1] === '') {
        window.scrollTo({ top: pageFive, behavior: 'smooth' });
      } else {
        how.current.scrollIntoView({ block: 'start' });
      }
    } else if (nav[0] === 7) {
      if (nav[1] === '') {
        window.scrollTo({ top: pageSix, behavior: 'smooth' });
      } else {
        qna.current.scrollIntoView({ block: 'start' });
      }
    }
    return setNav(1, '');
  }, [nav, setNav]);

  return (
    <>
      {/* Noah, 기업초대 모달 컴포넌트화 (로그인 후 페이지 변경건으로 나의 시험 페이지에서 필요) */}
      {/* <InviteModal /> */}
      <StBody>
        <StBack>
          <div ref={top} id="pageOne" />
          <Home1 id="pageOne" />
          <Home2 />
          <div ref={service} id="pageThree" />
          <Home3 id="pageThree" />
          <div ref={technic} id="pageFour" />
          <Home4 />
          <div ref={how} id="pageFive" />
          <Home5 />
          <div ref={qna} id="pageSix" />
          <Home6 setMyPageScroll={setMyPageScroll} id="pageSix" />
        </StBack>
      </StBody>
      {/* <Outlet /> */}
    </>
  );
};

const StBody = styled.div`
  @media ${device.pc} {
    /* min-width: 1440px; */
    min-width: 1440px;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
`;

const StBack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`;
export default Landing;
