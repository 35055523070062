import React, { useEffect, useState } from "react";
import { ModalBack, ModalHeader } from "../components/Style";
import styled from "styled-components";
import { device } from "../hooks/device";

// img
import upArrow from "../img/upArrow.svg";
import arrow3 from "../img/arrow3.svg";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { useTranslation } from "react-i18next";

const Policy = () => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g5_4_1";

  const [policyOne, setPolicyOne] = useState();
  const [policyTwo, setPolicyTwo] = useState();

  // useEffect(() => {
  //   window.history.pushState(null, "", window.location.pathname);
  // }, []);

  // const goBack = () => {
  //   setPolicyModal(false);
  // };

  // useEffect(() => {
  //   window.addEventListener("popstate", goBack);
  //   return () => {
  //     window.removeEventListener("popstate", goBack);
  //   };
  // }, []);

  // Noah, 중복 주석 처리
  // useScrollLockThree();

  return (
    <>
      <ModalBack />
      <StPolicy>
        <div className="wrapper">
          <ModalHeader title={t(`${baseLang}.title`)} />
          <div className="listPart">
            <div className="listOne">
              <div
                className="listClick"
                onClick={() => setPolicyOne(!policyOne)}
              >
                <div>{t(`${baseLang}.middleTitle1`)} </div>
                {!policyOne ? (
                  <img src={arrow3} alt="" />
                ) : (
                  <img src={upArrow} alt="" />
                )}
              </div>
              {policyOne && (
                <div className="listDown">
                  <span>{t(`${baseLang}.middle1`)}</span>
                  <ul>
                    <li>
                      {t(`${baseLang}.list1_1`)} <br />
                      {t(`${baseLang}.list1_2`)}
                    </li>
                    <li style={{ color: "#C83B38" }}>
                      {t(`${baseLang}.list2`)}
                    </li>
                    <li>
                      {t(`${baseLang}.list3_1`)}
                      <br />
                      {t(`${baseLang}.list3_2`)}
                    </li>
                  </ul>
                  <span style={{ marginTop: "14px" }}>
                    {t(`${baseLang}.middle2`)}
                  </span>
                  <ul>
                    <li>
                      {t(`${baseLang}.list4_1`)}
                      <br />
                      {t(`${baseLang}.list4_2`)}
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {!policyOne ? <div className="rowGrayBar" /> : <></>}
            <div className="listOne">
              <div
                className="listClick"
                onClick={() => setPolicyTwo(!policyTwo)}
              >
                <div>{t(`${baseLang}.middleTitle2`)}</div>
                {!policyTwo ? (
                  <img src={arrow3} alt="" />
                ) : (
                  <img src={upArrow} alt="" />
                )}
              </div>
              {policyTwo && (
                <div className="listDown">
                  <span>{t(`${baseLang}.middle3`)}</span>
                  <ul>
                    <li>
                      {t(`${baseLang}.list5_1`)}
                      <br />
                      {t(`${baseLang}.list5_2`)}
                    </li>
                    <li>{t(`${baseLang}.list6`)}</li>
                  </ul>
                  <span>{t(`${baseLang}.middle4`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.list7`)}</li>
                    <li>
                      {t(`${baseLang}.list8_1`)}
                      <br />
                      {t(`${baseLang}.list8_2`)}
                      <br />
                      {t(`${baseLang}.list8_3`)}
                    </li>
                  </ul>
                  <span>{t(`${baseLang}.middle5`)}</span>
                  <ul>
                    <li>
                      {t(`${baseLang}.list9`)} <br />
                    </li>
                    <li>
                      {t(`${baseLang}.list10_1`)}
                      <br />
                      {t(`${baseLang}.list10_2`)}
                      <br />
                      {t(`${baseLang}.list10_3`)}
                    </li>
                  </ul>
                  <span>{t(`${baseLang}.middle6`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.list11`)}</li>
                    <li>{t(`${baseLang}.list12`)}</li>
                  </ul>
                  <span>{t(`${baseLang}.middle7`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.list13`)}</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </StPolicy>
    </>
  );
};

const StPolicy = styled.div`
  position: fixed;
  background-color: white;
  .listPart {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .listOne {
      display: flex;
      flex-direction: column;
      align-items: center;
      /* padding: 14px 0px; */
      box-sizing: border-box;
      font-size: 16px;
      font-family: "regular";
      line-height: 24px;
      .listClick {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
      }
      .listDown {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        background-color: #fafafa;
        border-top: 1px solid #d9d9d9;
        span {
          font-family: "medium";
          font-size: 15px;
          margin-bottom: 8px;
        }
        ul {
          margin: 0;
          font-family: "medium";
          font-size: 14px;
          padding-left: 17px;
        }
      }
      img {
        margin-left: auto;
        cursor: pointer;
      }
    }
  }
  .rowGrayBar {
    width: 100%;
    height: 0.5px;
    background-color: #bdbdbd;
  }
  @media ${device.pc} {
    z-index: 70;
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .listPart {
      width: 100%;
      margin-top: 48px;
      .listOne {
        .listClick {
          padding: 16px 0;
          cursor: pointer;
        }
        .listDown {
          padding: 0 24px 24px;
          height: 252px;
          overflow-x: hidden;
          ::-webkit-scrollbar {
            width: 5px;
            height: 10px;
            margin-top: 100px;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #c6c6c6;
            border-radius: 100px;
          }
          ::-webkit-scrollbar-track {
            border-radius: 1rem;
          }
          span {
            margin-top: 24px;
          }
        }
      }
      .rowGrayBar {
        height: 1px;
        background-color: #d9d9d9;
      }
    }
  }
  @media ${device.tabMob} {
    z-index: 49;
    width: 100%;
    height: calc(100% - 60px);
    bottom: 0;
    padding: 0 24px;
    box-sizing: border-box;
    .wrapper {
      width: 100%;
      height: calc(100% - 60px);
      overflow-x: hidden;
      max-width: 752px;
      margin: 0 auto;
      ::-webkit-scrollbar {
        width: 2px;
        height: 10px;
        margin-top: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 2px;
        width: 2px;
        height: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
    }
    .listPart {
      width: calc(100% + 48px);
      margin-left: -24px;
      padding: 0 24px;
      background-color: #ffffff;
      .listOne {
        .listClick {
          padding: 14px 0;
        }
        .listDown {
          padding: 0 12px 14px;
          span {
            margin-top: 14px;
          }
        }
      }
    }
  }
`;

export default Policy;
