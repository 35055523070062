import React from "react";
import checkBox from "../../img/checkbox_filled.svg";
import TextLine from "../atom/TextLine";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface CheckBoxProps {
  checked?: boolean;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  text?: string;
  fontsize?: number;
  pc_fontsize?: number;
  gap?: number;
  lineheight?: number;
}

const CheckBoxWithText: React.FC<CheckBoxProps> = (props) => {
  return (
    <StLine
      gap={props.gap}
      lineheight={props.lineheight}
      fontsize={props.fontsize}
      pc_fontsize={props.pc_fontsize}
      onClick={() => {
        if (props.setChecked) {
          props.setChecked(!props.checked);
        }
      }}
    >
      <>
        {props.checked ? <img src={checkBox} /> : <div className="emptyBox" />}
      </>
      <TextLine text={props.text} />
    </StLine>
  );
};

const StLine = styled.div<CheckBoxProps>`
  display: grid;
  grid-template-columns: 22px auto;
  align-items: center;
  gap: ${(props) => props.gap}px;
  line-height: ${(props) => props.lineheight}px;
  font-size: ${(props) => props.fontsize}px;
  cursor: pointer;
  .emptyBox {
    width: 22px;
    height: 22px;
    border: 1px solid #818181;
    border-radius: 4px;
    box-sizing: border-box;
  }
  @media ${device.pc} {
    font-size: ${(props) => props.pc_fontsize}px;
  }
`;

export default CheckBoxWithText;
