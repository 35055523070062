import React from "react";

interface FocusingProps<T> {
  ref: React.RefObject<T>;
}

const focusing = <T extends HTMLElement>({ ref }: FocusingProps<T>) => {
  if (ref.current) {
    ref.current.focus();
    ref.current.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  }
};

export default focusing;
