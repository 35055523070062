import React, { forwardRef } from "react";
import { styled } from "styled-components";
import { device } from "../../hooks/device";

interface InputProps {
  id?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  width?: string;
  height?: number;
  pc_height?: number;
  placeholderText?: string;
  fontsize?: number;
  pc_fontsize?: number;
  pc_lineheight?: number;
  fontcolor?: string;
  lineheight?: number;
  placeholdercolor?: string;
  padding?: string;
  pc_padding?: string;
  border_radius?: number;
  border?: string;
  border_bottom?: string;
  type?: string;
  value?: string;
  max_length?: number;
  onchange?: (e: any) => void;
  onblur?: () => void;
}

const OnChangeInput = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    return (
      <StInput
        id={props.id}
        ref={props.inputRef}
        width={props.width}
        height={props.height}
        pc_height={props.pc_height}
        pc_fontsize={props.pc_fontsize}
        pc_lineheight={props.pc_lineheight}
        pc_padding={props.pc_padding}
        fontsize={props.fontsize}
        lineheight={props.lineheight}
        placeholdercolor={props.placeholdercolor}
        placeholder={props.placeholderText}
        padding={props.padding}
        border_radius={props.border_radius}
        border={props.border}
        border_bottom={props.border_bottom}
        type={props.type}
        onBlur={props.onblur}
        onChange={props.onchange}
        value={props.value}
        maxLength={props.max_length}
      />
    );
  }
);

const StInput = styled.input<InputProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontsize}px;
  font-family: "regular";
  line-height: ${(props) => props.lineheight}px;
  padding: ${(props) => props.padding};
  &::placeholder {
    color: ${(props) => props.placeholdercolor};
    font-family: "light";
  }
  &:focus {
    outline: none;
  }
  box-sizing: border-box;
  border-radius: ${(props) => props.border_radius}px;
  border: ${(props) => props.border};
  border-bottom: ${(props) => props.border_bottom};
  @media ${device.pc} {
    line-height: ${(props) => props.pc_lineheight}px;
    font-size: ${(props) => props.pc_fontsize}px;
    padding: ${(props) => props.pc_padding};
    height: ${(props) => props.pc_height}px;
  }
`;

export default OnChangeInput;
