import React from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";

interface TableProps {
  rowCellCount?: number;
  columnCellCount?: number;
  tableContent: string[][];
  cellWidth?: string;
  cellHeight?: number;
  borderSize?: number;
  borderColor?: string;
  tableHeaderColor?: string[];
  margin?: string;
}

const TableBasedOnRow: React.FC<TableProps> = (props) => {
  return (
    <DivTwoPartImprove
      display="grid"
      grid_template_rows={`repeat(${props.columnCellCount},${props.cellHeight}px)`}
      margin={props.margin}
    >
      {props.tableContent.map((value, index) => (
        /** 세로줄 */
        <DivTwoPartImprove
          display="grid"
          grid_template_column={`repeat(${props.rowCellCount},1fr)`}
          key={index}
        >
          {value.map((value, ind) => (
            /** 가로줄 */
            <DivTwoPartImprove
              key={ind}
              display="flex"
              border_top={
                index === 0
                  ? `${props.borderSize}px solid ${props.borderColor}`
                  : ""
              }
              border_left={
                ind === 0
                  ? `${props.borderSize}px solid ${props.borderColor}`
                  : ""
              }
              border_right={`${props.borderSize}px solid ${props.borderColor}`}
              border_bottom={`${props.borderSize}px solid ${props.borderColor}`}
              justify="center"
              align_items="center"
              font_color={index === 0 ? props.tableHeaderColor?.[ind] : ""}
              width={props.cellWidth}
              height={props.cellHeight}
              font_size={14}
              font_family="medium"
            >
              {value}
            </DivTwoPartImprove>
          ))}
        </DivTwoPartImprove>
      ))}
    </DivTwoPartImprove>
  );
};

export default TableBasedOnRow;
