import { useQuery } from "react-query";
import apis from "../api/getApi";
import { examUpdate } from "../atoms";
import { useRecoilValue } from "recoil";

const useGetMyExam = ({ uid }) => {
  const update = useRecoilValue(examUpdate);
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getMyExam({ utc, uid });
    return data;
  };
  return useQuery(["exams", uid, update], fetcher);
};

export default useGetMyExam;
