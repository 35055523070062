import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { device } from "../hooks/device";

const VideoPage = () => {
  const [link, setLink] = useState();
  const { search } = useLocation();
  const videoRef = useRef();

  useEffect(() => {
    setLink(search);
    const handleContextMenu = (event) => {
      event.preventDefault(); // Prevent the default right-click behavior
    };

    const videoElement = videoRef.current;
    videoElement.addEventListener("contextmenu", handleContextMenu);

    return () => {
      // Clean up the event listener when the component unmounts
      videoElement.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <StVideoPage>
      <video
        ref={videoRef}
        controls
        controlsList="nodownload"
        src={link?.substr(1)}
      >
        {/* <source src={link?.substr(1)} type="video/mp4" /> */}
      </video>
    </StVideoPage>
  );
};

const StVideoPage = styled.div`
  position: fixed;
  top: 0;
  z-index: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 360px;
  height: 100%;
  background-color: black;
  -webkit-touch-callout: none;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  @media ${device.pc} {
  }
  @media ${device.tabMob} {
    video {
      width: 100%;
    }
  }
`;

export default VideoPage;
