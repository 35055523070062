import React from "react";
import MyExamDown from "../components/MyExamDown";
import MyExamUp from "../components/MyExamUp";
import styled from "styled-components";

// styled components

// components
import InviteModal from "../components/InviteModal";

// modals

//img

const MyExam = ({ setMyPageScroll, setModalOn }) => {
  return (
    <>
      <InviteModal />
      <StMyExam>
        <MyExamUp />
        <MyExamDown setModalOn={setModalOn} setMyPageScroll={setMyPageScroll} />
      </StMyExam>
    </>
  );
};

const StMyExam = styled.div`
  min-height: calc(100vh - 353px);
`;

export default MyExam;
