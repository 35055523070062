import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { device } from "../hooks/device";

const ToastPopup = ({ toast, toastText, update }) => {
  const [realOff, setRealOff] = useState(false);
  const toastRealOutRef = useRef(null);

  useEffect(() => {
    clearTimeout(toastRealOutRef.current);
    if (toast) {
      setRealOff(true);
      toastRealOutRef.current = setTimeout(() => setRealOff(false), 5000);
    } else {
      // toastRealOut();
    }
    return () => {
      clearTimeout(toastRealOutRef.current);
    };
  }, [update]);

  return (
    <>
      {realOff && (
        <ToastContainer toast={toast}>
          <StToast dangerouslySetInnerHTML={{ __html: toastText }} />
        </ToastContainer>
      )}
    </>
  );
};
const ToastContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  z-index: 100;
  /* bottom: ${(props) => (props.toast ? "64px" : "-80px")}; */
  opacity: ${(props) => (props.toast ? "1" : "0")};
  transition: all ${(props) => (props.toast ? "0.3s" : "1s")};
  box-sizing: border-box;
  @media ${device.pc} {
    bottom: 88px;
    padding: 0 170px;
  }
  @media ${device.tablet} {
    bottom: 64px;
    padding: 0 68px;
  }
  @media ${device.mobile} {
    bottom: 64px;
    padding: 0 34px;
  }
`;
const StToast = styled.div`
  /* width: 100%; */
  /* min-height: 38px; */
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "regular";
  @media ${device.pc} {
    padding: 16px 26px;
    min-width: 180px;
    max-width: 800px;
    font-size: 17px;
    line-height: 25px;
    border-radius: 28px;
  }
  @media ${device.tablet} {
    min-width: 146px;
    max-width: 799px;
  }
  @media ${device.mobile} {
    min-width: 146px;
    max-width: 503px;
  }
  @media ${device.tabMob} {
    padding: 12.5px 22px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 24px;
  }
`;
export default ToastPopup;
