import React from 'react'
import styled from 'styled-components'
import imgCancel from '../img/imgCancel.png'

const ImgBox = ({url,onClick}) => {

  return (
    <StImgBox>    
        <img className='cancelImg' src={imgCancel} onClick={onClick}/>
        <img className='previewImg' src={url}/>
    </StImgBox>
  )
}

const StImgBox = styled.div`
    position : relative;
    width: 82px;
    height : 82px;
    .cancelImg{
        position : absolute;
        top : 0;
        right : 0;
        width : 24px;
        height : 24px;
        margin : 5px 5px 0 0;
        cursor: pointer;
    }
    .previewImg{
        width : 82px;
        height : 82px;
        object-fit: cover;
        border-radius : 12px;
        background-color: #59749D;
    }
`;

export default ImgBox