import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getCookie } from "../Cookie.jsx";
import { useRecoilValue, useSetRecoilState } from "recoil";

// styled component

// img
// import Back from '../img/ProctormaticBG2.png';
import { device } from "../hooks/device.jsx";
import landingLogo from "../img/landingLogo.png";
import LandingOne from "../img/LandingOne.png";
import { bannerOn, loginModal } from "../atoms.ts";
import LandingCes from "../img/LandingCes.png";
import { useTranslation } from "react-i18next";
import TextLine from "../atomic/atom/TextLine.tsx";
import { HomeOneStyleProps } from "../interface/styleInterface.ts";

/**
 * 티켓링크 : https://www.notion.so/edint/web-header-if-b9003b6e4419425d860b3227506ef825?pvs=4
 * 주석작성자 : Noah
 * 주석작성일자 : 2024.05.21
 * 티켓내용 : header에 if디자인어워드 로고 삽입
 * 주석설명 : 랜딩페이지 디자인어워드 로고 삽입 및 css변경
 */
import LandingIFAward from "../img/LandingIFAward.png";

const HomeOne = () => {
  const navigate = useNavigate();
  const token = getCookie("token");
  const loginModalOn = useSetRecoilState(loginModal);
  const banneris = useRecoilValue(bannerOn);
  const { t } = useTranslation();
  const baseLang: string = "home.a1.firstPage.first";

  return (
    <>
      <StBackGround $banneris={banneris}>
        <div className="wrapper">
          <div className="leftBox">
            <div>
              <div className="firstPart"></div>
              <div className="middlePart">
                <div className="secondBlock">
                  <TextLine
                    text={t(`${baseLang}.middleTitle`)}
                    fontsize={13}
                    pc_fontsize={25}
                  />
                  <img src={landingLogo} />
                </div>
                <div className="buttonBlock">
                  <div
                    className="startButton"
                    onClick={() => {
                      if (token) {
                        navigate(`/creatingExam`);
                      } else {
                        loginModalOn(true);
                      }
                    }}
                  >
                    <TextLine text={t(`${baseLang}.startButton`)} />
                  </div>
                  <div
                    className="myExamButton"
                    onClick={() => {
                      if (token) {
                        navigate(`/myexam`);
                      } else {
                        loginModalOn(true);
                      }
                    }}
                  >
                    <TextLine text={t(`${baseLang}.reportButton`)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="leftImgBlock onlyForPc">
              <img className="ces" src={LandingCes} />
              <img className="if" src={LandingIFAward} />
            </div> 
          </div>
          <img className="rightImgBox" src={LandingOne} />
          <div className="leftMobileImgBlock onlyForMobile">
            <img className="ces" src={LandingCes} />
            <img className="if" src={LandingIFAward} />
          </div>
        </div>
      </StBackGround>
    </>
  );
};

const StBackGround = styled.div<HomeOneStyleProps>`
  width: 100%;
  /* height: calc(100vh - 116px); */
  height: ${(props) =>
    props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
  min-height: 675px;
  display: flex;
  align-items: center;
  overflow: hidden;
  .wrapper {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 675px;
    overflow: hidden;
    .leftBox {
      grid-column: 2/7;
      grid-row: 1;
      display: flex;
      flex-direction: column;
      .firstPart {
        display: flex;
        font-family: "medium";
        font-size: 25px;
        line-height: 37px;
        color: #55a2ff;
      }
      .secondBlock {
        display: grid;
        flex-direction: column;
        gap: 22px;
        font-size: 25px;
        font-family: "medium";
        line-height: 37px;
        color: #55a2ff;
        img {
          width: 633px;
          height: 81px;
        }
      }
      .buttonBlock {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 18px;
        .startButton,
        .myExamButton {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 15px 11px;
          box-sizing: border-box;
          width: 204px;
          height: 52px;
          color: white;
          border-radius: 43px;
          font-family: "medium";
          font-size: 17px;
          line-height: 25px;
          cursor: pointer;
        }
        .startButton {
          background-color: #e7750c;
        }
        .myExamButton {
          border: 1px solid #fff;
        }
      }
      .leftImgBlock {
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 50px;
        .ces {
          width: 320px;
          height: 125px;
        }
        .if {
          width: 162px; 
          height: 82px;
        }
      }
    }
    .rightImgBox {
      grid-column: 6/11;
      grid-row: 1;
      height: ${(props) =>
        props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
      margin-left: auto;
      padding-left: 100px;
    }
  }
  @media ${device.pc} {
    .leftBox {
      margin: auto 0 auto;
      /* padding : 0 0 0 120px; */
      .secondBlock {
        margin: -55px 0 48px;
      }
      .leftImgBlock {
        margin-top: 104px;
      }
    }
    .rightMobileImgBox {
      display: none;
    }
    .onlyForMobile {
      display: none !important;
    }
  }
  @media ${device.tabMob} {
    height: ${(props) =>
      props.$banneris ? "calc(100vh - 108px)" : "calc(100vh - 60px)"};
    .onlyForPc {
      display: none !important;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      .leftBox {
        justify-content: center;
        .firstPart {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "regular";
          line-height: 18px;
          font-size: 12px;
          margin-top: 48px;
          img {
            width: 15px;
            height: 13px;
          }
        }
        .middlePart {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: calc(100vh - 336px);
          min-height: 446px;
          .secondBlock {
            text-align: center;
            gap: 12px;
            font-size: 14px;
            font-family: "medium";
            line-height: 21px;
            margin-bottom: 38px;
            img {
              margin: 0 auto;
            }
          }
          .buttonBlock {
            display: grid;
            grid-template-columns: 1fr;
            margin: 0 auto;
            .myExamButton,
            .startButton {
              height: 42px;
              width: 151px;
              font-size: 14px;
              font-family: "medium";
              line-height: 25px;
            }
          }
        }
        .ces {
        }
      }
      .rightImgBox {
        display: none;
      }
      .leftMobileImgBlock {
        display: flex;
        height: 194px;
        padding-bottom: 20px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 40px;
        /* .ces {
          width: 260px;
          height: 101px;
        }
        .if {
          width: 162px; 
          height: 82px;
        } */
      }
      /* .rightMobileImgBox {
        width: 260px;
        height: 101px;
        margin: auto 0 56.22px;
      } */
    }
  }
  @media ${device.tablet} {
    .leftBox {
      .middlePart {
        .secondBlock {
          img {
            width: 356px;
            height: 47px;
          }
        }
      }
    }
    .if {
      width: 163px; 
      height: 83px;
    }
  }
  @media ${device.mobile} {
    .leftBox {
      .middlePart {
        .secondBlock {
          img {
            width: 293.44px;
            height: 39px;
          }
        }
        .buttonBlock {
          display: grid;
          align-items: center;
          flex-direction: column;
        }
      }
    }
    .leftMobileImgBlock {
      gap: 35px;
      .ces {
        width: 40.6%;         
      }
      .if {
        width: 25.3%;
      }
    }
  }
`;

export default HomeOne;
