import React, { useState } from "react";
import DivTwoPart from "../atom/DivTwoPart";
import TextLine from "../atom/TextLine";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import OnChangeInput from "../atom/OnchangeInput";

interface InputBoxProps {
  title: string;
  value?: any;
  title_pc_lineheight?: number;
  title_pc_fontsize?: number;
  title_fontsize?: number;
  title_lineheight?: number;
  title_fontfamily?: string;
  placeholder?: string;
  input_border?: string;
  input_border_bottom?: string;
  input_border_radius?: number;
  input_padding?: string;
  input_fontsize?: number;
  input_lineheight?: number;
  input_pc_fontsize?: number;
  input_pc_lineheight?: number;
  input_type?: string;
  width?: string;
  height?: number;
  max_length?: number;
  gap?: number;
  pc_padding?: string;
  padding?: string;
  pc_gap?: number;
  errorText?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  onchange?: (e: any) => void;
  onblur?: () => void;
}

const InputTextWithTitle: React.FC<InputBoxProps> = (props) => {
  return (
    <DivTwoPartImprove
      pc_display="grid"
      // pc_gap={props.pc_gap}
      display="grid"
      // gap={props.gap}
      pc_padding={props.pc_padding}
      padding={props.padding}
    >
      <DivTwoPartImprove
        pc_display="flex"
        pc_align_items="center"
        margin={`0 0 ${props.gap}px`}
        pc_margin={`0 0 ${props.pc_gap}px`}
      >
        <TextLine
          text={props.title}
          pc_fontsize={props.title_pc_fontsize ? props.title_pc_fontsize : 18}
          fontFamily={props.title_fontfamily}
          pc_lineheight={props.title_pc_lineheight}
          lineheight={props.title_lineheight}
          fontsize={props.title_fontsize}
        />
      </DivTwoPartImprove>
      <OnChangeInput
        inputRef={props.inputRef}
        placeholderText={props.placeholder}
        placeholdercolor="#BDBDBD"
        pc_fontsize={props.input_pc_fontsize}
        pc_lineheight={
          props.input_pc_lineheight ? props.input_pc_lineheight : 30
        }
        value={props.value}
        type={props.input_type}
        fontsize={props.input_fontsize}
        lineheight={props.input_lineheight}
        border={props.input_border ? props.input_border : "1px solid #D9D9D9"}
        border_bottom={props.input_border_bottom}
        border_radius={props.input_border_radius}
        width={props.width}
        height={props.height}
        max_length={props.max_length}
        padding={props.input_padding ? props.input_padding : "12px 16px"}
        onchange={props.onchange}
        onblur={props.onblur}
      />
      {props.errorText && (
        <TextLine
          text={props.errorText}
          fontcolor="#c83b38"
          lineheight={18}
          fontsize={12}
          margin="5px 0 0"
        />
      )}
    </DivTwoPartImprove>
  );
};

export default InputTextWithTitle;
