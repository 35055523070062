import React from "react";
import styled from "styled-components";
import { useRecoilValue } from "recoil";
import { languageIs } from "../../atoms";
import { device } from "../../hooks/device";
import {
  BaseTextStyleProps,
  PCTextStyleProps,
  TabMobileTextStyleProps,
} from "../../interfaces";

interface TextProps
  extends PCTextStyleProps,
    TabMobileTextStyleProps,
    BaseTextStyleProps {
  height?: number;
  textKor?: string;
  textEng?: string;
  cursor?: string;
  textalign?: string;
  onclick?: () => void;
}

interface TextStyle extends TextProps {
  pc_font_size?: number;
  pc_lineheight?: number;
  tab_font_size?: number;
  tab_lineheight?: number;
  height?: number;
}

const TextTwoPart: React.FC<TextProps> = (props) => {
  const language = useRecoilValue<string>(languageIs);

  return (
    <StText
      display={props.display}
      height={props.height}
      font_color={props.font_color}
      font_family={props.font_family}
      font_size={props.font_size}
      lineheight={props.lineheight}
      margin={props.margin}
      pc_display={props.pc_display}
      pc_font_size={
        language === "/ko" ? props.pc_font_size_ko : props.pc_font_size_en
      }
      pc_font_color={props.pc_font_color}
      pc_font_family_ko={props.pc_font_family_ko}
      pc_lineheight={
        language === "/ko" ? props.pc_lineheight_ko : props.pc_lineheight_en
      }
      pc_margin={props.pc_margin}
      tab_display={props.tab_display}
      tab_font_size={
        language === "/ko" ? props.tab_font_size_ko : props.tab_font_size_en
      }
      tab_font_color={props.tab_font_color}
      tab_font_family_ko={props.tab_font_family_ko}
      tab_lineheight={
        language === "/ko" ? props.tab_lineheight_ko : props.tab_lineheight_en
      }
      tab_margin={props.tab_margin}
      onClick={props.onclick}
      textalign={props.textalign}
      cursor={props.cursor}
    >
      {language === "/ko" ? props.textKor : props.textEng}
    </StText>
  );
};

const StText = styled.div<TextStyle>`
  display: flex;
  align-items: center;
  height: ${(props) => props.height}px;
  text-align: ${(props) => props.textalign};
  font-family: ${(props) => props.font_family};
  color: ${(props) => props.font_color};
  line-height: ${(props) => props.lineheight}px;
  cursor: ${(props) => props.cursor};
  @media ${device.pc} {
    display: ${(props) => props.pc_display};
    font-size: ${(props) => props.pc_font_size}px;
    font-family: ${(props) => props.pc_font_family_ko};
    color: ${(props) => props.pc_font_color};
    line-height: ${(props) => props.pc_lineheight}px;
    margin: ${(props) => props.pc_margin};
  }
  @media ${device.tabMob} {
    display: ${(props) => props.tab_display};
    font-size: ${(props) => props.tab_font_size}px;
    font-family: ${(props) => props.tab_font_family_ko};
    color: ${(props) => props.tab_font_color};
    line-height: ${(props) => props.tab_lineheight}px;
    margin: ${(props) => props.tab_margin};
  }
`;

export default TextTwoPart;
