import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { languageIs, userInfo } from "../atoms";

// img
import { Trash2 } from "../img/trash2";
import arrow from "../img/arrow.svg";
import whiteCheck from "../img/whiteCheck.svg";
import greyX from "../img/greyX.svg";

// hooks
import useGetMessageBox from "../hooks/useGetMessageBox";

// components
import Invitation from "../components/Invitation";
import { baseURL } from "../api/handler";
import { device } from "../hooks/device";
import customAxios from "../api/handler";
import MessageDelete from "../modal/InvitedMessageDelete";
import LeftTest from "../modal/ServicePlanLeftTest";
import PreTest from "../modal/LandingPreTest";
import RejectModal from "../modal/RejectModal";
import useToast from "../hooks/useToast";
import getApi from "../api/getApi";
import { useTranslation } from "react-i18next";

const Invited = () => {
  const { t } = useTranslation();
  const baseLang = "mypage.f3";
  const navigate = useNavigate();

  // modal
  //   const [otherInvitationRefuse, setOtherInvitationRefuse] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [leftTestModal, setLeftTestModal] = useState(false);
  const [preTestModal, setPreTestModal] = useState(false);
  const [rejectModalOn, setRejectModalOn] = useState(false);

  // state
  const setUser = useSetRecoilState(userInfo);
  const [color, setColor] = useState("#D9d9d9");
  const [removeColor, setRemoveColor] = useState("#D9d9d9");
  const [check, setCheck] = useState(false);
  const [answerInvitation, setAnswerInvitation] = useState(false);
  const [removeMode, setRemoveMode] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [cancelCheckAll, setCancelCheckAll] = useState(false);
  const [removeList, setRemoveList] = useState([]);
  const showToast = useToast();
  const [inviteHost, setInviteHost] = useState();

  // data
  const { data: message } = useGetMessageBox({ answerInvitation });

  // patch 초대 목록을 읽음
  const inviteRead = () => {
    customAxios.patch(`/api/v1/users/has-invite`);
  };

  /** 메세지 수신함 진입 시 초대 메세지 읽음 처리 */
  const messageRead = () => {
    customAxios
      .patch(`${baseURL}/api/v1/hosts/messageBox`)
      .catch(({ response }) => {
        if (response.data.errorCode === "ENTERPRISE-005") {
        }
      });
  };

  useEffect(() => {
    inviteRead();
    messageRead();
  }, []);

  useEffect(() => {
    if (message?.messageBox?.length > 0) setColor("#222222");
    else {
      setColor("#D9d9d9");
    }
  }, [message?.messageBox]);

  //   const messageCount = () => {
  //     if (data?.messageBox?.length > 0) {
  //       setOtherInvitationRefuse(true);
  //     } else {
  //       setOtherInvitationRefuse(false);
  //     }
  //   };

  // 메세지 삭제 전체 선택 취소 시 state변경
  useEffect(() => {
    if (checkAll === false) {
      setCancelCheckAll(false);
    }
  }, [checkAll]);

  /** 삭제할 메세지 선택시 쓰레기통 검은색으로 바뀜 */
  useEffect(() => {
    if (removeList.length > 0) {
      setRemoveColor("#222222");
    } else {
      setRemoveColor("#D9d9d9");
    }
  }, [removeList, check, checkAll, cancelCheckAll]);

  const multipleDelete = () => {
    customAxios
      .post(`${baseURL}/api/v1/hosts/messageBox`, {
        delMessageBox: removeList,
      })
      .then(() => {
        setAnswerInvitation(!answerInvitation);
        setRemoveMode(false);
        setDeleteModal(false);
      })
      .catch(({ response }) => {});
  };

  /** 수락 전 미결제 금액과 현재 진행중인 시험 유무 확인 및 환불 금액 체크 */
  const beforeAccept = (hostInfo) => {
    getApi
      .getProgressingTest()
      .then(() => {
        getApi
          .getPendingTest()
          .then(() => {
            if (message?.pending > 1) {
              setRejectModalOn("approval");
              setInviteHost(hostInfo);
            } else {
              setAnswerInvitation(!answerInvitation);
              inviteAnswer({ type: "approval", hostInfo });
              setRejectModalOn(false);
            }
          })
          .catch((response) => {
            if (response.data.errorCode === "ENTERPRISE-011") {
              /**
               * Noah, 메시지 수신함에서 진행 예정인 시험 있는 상태로
               * 기업초대 메시지 수락 눌렀을 때 에러 발생 수정
               * setInviteHost 추가
               * */
              setInviteHost(hostInfo);
              setPreTestModal(true);
            } else {
              showToast({
                message: t(`errorCode.unknown_server_error`),
              });
            }
          });
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "ENTERPRISE-010" ||
          response.data.errorCode === "TIMEZONE-007"
        ) {
          setLeftTestModal(true);
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  // console.log(hostInfo);

  const inviteAnswer = ({ type, hostInfo }) => {
    customAxios
      .patch(`/api/v1/users/individual/approval`, {
        isVerifiedHostEmailInvite: type,
        hostEmail: hostInfo[0],
        hostGroupName: hostInfo[1],
      })
      .then(() => {
        setAnswerInvitation(!answerInvitation);
        setRejectModalOn(false);
        setPreTestModal(false);
        customAxios.get(`/api/v1/hosts`).then(({ data }) => {
          setUser(data?.getHost);
        });
        if (type === "approval") {
          showToast({
            message: `${t(`errorCode.transToMember1`)}${hostInfo[1]}${t(
              `errorCode.transToMember2`
            )}`,
          });
        }
      })
      .catch(({ response }) => {
        if (
          response.data.errorCode === "HOST-ROLE-003" ||
          response.data.errorCode === "HOST-ROLE-005" ||
          response.data.errorCode === "ENTERPRISE-006"
        ) {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  return (
    <>
      {leftTestModal && (
        <LeftTest
          close={setLeftTestModal}
          title={t(`mypage.f1_2.title`)}
          textOne={t(`mypage.f1_2.body1`)}
          textTwo={t(`mypage.f1_2.body2`)}
        />
      )}
      {preTestModal && (
        <PreTest
          setPreTestModal={setPreTestModal}
          inviteAnswer={setRejectModalOn}
        />
      )}
      {deleteModal && (
        <MessageDelete
          multipleDelete={multipleDelete}
          setDeleteModal={setDeleteModal}
          count={removeList.length}
        />
      )}
      {rejectModalOn === "approval" && (
        <RejectModal
          answer={inviteAnswer}
          setRejectModalOn={setRejectModalOn}
          hostInfo={inviteHost}
        />
      )}
      <StInvited
        checkAll={checkAll}
        removeList={removeList}
        removeMode={removeMode}
        color={color}
        removeColor={removeColor}
      >
        <div className="wrapper">
          {!removeMode ? (
            <div className="headerBox">
              <img
                src={arrow}
                className="arrow"
                alt=""
                onClick={() => {
                  navigate(`/myPage`);
                }}
              />
              {t(`${baseLang}.title`)}
              <div
                className="trash"
                onClick={() => {
                  if (color === "#222222") {
                    setRemoveMode(true);
                  } else {
                    setRemoveMode(false);
                  }
                }}
              >
                <Trash2 color={color} />
                <div className="onlyForPc">{t(`${baseLang}.delete1`)}</div>
              </div>
            </div>
          ) : (
            <div className="headerBox">
              <div className="leftHeader">
                <div
                  className="checkBox"
                  onClick={() => {
                    if (checkAll === true) {
                      setCancelCheckAll(true);
                    }
                    setCheckAll(!checkAll);
                    setCheck(!check);
                  }}
                >
                  {checkAll && <img src={whiteCheck} alt="" />}
                </div>
                <div>{t(`${baseLang}.all`)}</div>
              </div>
              <div className="removeModeRight">
                <div
                  className="removeModeRightLeft"
                  onClick={() => {
                    if (removeList.length > 0) setDeleteModal(true);
                  }}
                >
                  <div className="onlyForPc">
                    <Trash2 color={removeColor} />
                  </div>
                  <div className="rightHeader">{t(`${baseLang}.delete2`)}</div>
                </div>
                <img
                  src={greyX}
                  onClick={() => setRemoveMode(false)}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div>
            </div>
          )}
          <div className="listBox">
            {message?.messageBox?.length > 0 ? (
              <div className="invitation">
                {message?.messageBox?.map((value, index) => (
                  <Invitation
                    props={value}
                    key={index}
                    setAnswerInvitation={setAnswerInvitation}
                    checkAll={checkAll}
                    setCheckAll={setCheckAll}
                    answerInvitation={answerInvitation}
                    removeMode={removeMode}
                    cancelCheckAll={cancelCheckAll}
                    removeList={removeList}
                    setRemoveList={setRemoveList}
                    setCheck={setCheck}
                    check={check}
                    count={message?.pending}
                    beforeAccept={beforeAccept}
                  />
                ))}
              </div>
            ) : (
              <div className="noInvitation">{t(`${baseLang}.empty`)}</div>
            )}
          </div>
        </div>
      </StInvited>
    </>
  );
};

const StInvited = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f0f1f2;
  @media ${device.pc} {
    padding: 80px 0px;
    box-sizing: border-box;
    min-height: calc(100vh - 353px);
    min-width: 1440px;
    .wrapper {
      width: 1228px;
      margin: 0 auto;
    }
    .headerBox {
      font-size: 28px;
      font-family: "bold";
      line-height: 41px;
      .arrow {
        width: 28px;
        height: 28px;
        margin-right: 19px;
        cursor: pointer;
      }
      .trash {
        display: grid;
        grid-template-columns: auto auto;
        justify-content: center;
        align-items: center;
        gap: 12px;
        font-size: 16px;
        font-family: "medium";
        line-height: 24px;
        height: 41px;
        padding: 8.5px 22px;
        box-sizing: border-box;
        color: ${(props) => props.color};
        background-color: white;
        border-radius: 24px;
      }
      .removeModeRight {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 32px;
        .removeModeRightLeft {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          align-items: center;
          gap: 12px;
          font-size: 16px;
          font-family: "medium";
          line-height: 24px;
          width: 132px;
          height: 41px;
          background-color: white;
          border-radius: 24px;
          color: ${(props) =>
            props.removeList.length > 0 ? "#222222" : "#D9D9D9"};
          cursor: ${(props) =>
            props.removeColor === "#222222" ? "pointer" : "default"};
        }
      }
    }
    .listBox {
      margin: 60px auto 0;
      width: 862px;
      .invitation {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 19px;
      }
    }
  }
  @media ${device.tabMob} {
    .onlyForPc {
      display: none;
    }
    .wrapper {
      max-width: 752px;
      width: 100%;
      margin: 0 auto;
    }
    .headerBox {
      margin: 8px 0;
      padding: 16px 24px;
      font-size: 19px;
      font-family: "bold";
      line-height: 28px;
      .onlyForPc {
        display: none;
      }
      .arrow {
        margin-right: 12px;
        width: 18px;
        height: 18px;
        cursor: pointer;
      }
      .removeModeRight {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 12px;
        .removeModeRightLeft {
          .rightHeader {
            font-family: "medium";
            color: #2276dc;
            opacity: ${(props) =>
              props?.removeList?.length === 0 ? "0.5" : ""};
            cursor: pointer;
          }
        }
      }
    }
    .listBox {
      width: 100%;
      .invitation {
        display: flex;
        flex-direction: column;
        /* grid-template-columns: auto; */
        width: 100%;
        /* gap: 12px; */
      }
    }
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    div {
      display: flex;
    }
    .leftHeader {
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 16px;
      .checkBox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border: 0.6px solid #818181;
        border-radius: 4px;
        box-sizing: border-box;
        background-color: ${(props) => (props.checkAll ? "#20315B" : "")};
        img {
          width: 12px;
          height: 10px;
        }
      }
    }
    .rightHeader {
    }
    .trash {
      margin-left: auto;
      cursor: ${(props) => (props.color === "#222222" ? "pointer" : "default")};
    }
  }
  .listBox {
    display: flex;
    justify-content: center;
    min-height: 539px;
    .noInvitation {
      display: flex;
      height: 19px;
      color: #bdbdbd;
      font-size: 16px;
      font-family: "regular";
      line-height: 19px;
      margin: auto 0;
    }
  }
`;

export default Invited;
