import React, { useEffect } from "react";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import styled from "styled-components";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

const RejectModal = ({ setRejectModalOn, answer, hostInfo }) => {
  const goBack = () => {
    setRejectModalOn(false);
  };

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    return () => window.removeEventListener("popstate", goBack);
  }, []);
  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StRejectModal>
        <ModalHeaderD title="다른 초대 자동 거절" />
        <div className="info">
          다른 기업으로부터 초대받은 모든 내역은 자동으로 거절 처리돼요.
        </div>
        <div className="buttonBox">
          <div className="cancelButton" onClick={() => setRejectModalOn(false)}>
            취소
          </div>
          <div className="columnBar" />
          <div
            className="confirmButton"
            onClick={() => answer({ type: "approval", hostInfo })}
          >
            계속하기
          </div>
        </div>
      </StRejectModal>
    </>
  );
};

const StRejectModal = styled.div`
  position: fixed;
  background-color: white;
  z-index: 53;
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .columnBar {
      display: none;
    }
    .info {
      margin: 48px 0;
      font-family: "medium";
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 21px;
      font-family: "medium";
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        cursor: pointer;
      }
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        background: #20315b;
        color: white;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    width: 344px;
    /* height: 206px; */
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto auto;
      justify-content: center;
      align-items: center;
      height: 36px;
      gap: 42.5px;
      margin-top: 24px;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      color: #0072de;
      .columnBar {
        width: 1px;
        height: 16px;
        background-color: #e6e6e6;
      }
      .cancelButton {
        width: 62px;
        text-align: center;
        cursor: pointer;
      }
      .confirmButton {
        width: 63px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
`;

export default RejectModal;
