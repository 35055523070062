import axios from "axios";
import customAxios from "./handler";
import { CommentEditRequest } from "../interface/apiInterface";

const patchApi = {
  memberWithdrawal: (hostEmail: string) => {
    return customAxios.patch(`/api/v1/users/enterprise/member/withdrawal`, {
      hostEmail: hostEmail,
    });
  },
  uploadToS3: ({
    imageUploadUrl,
    image,
  }: {
    imageUploadUrl: string;
    image: File;
  }) => {
    return axios.put(`${imageUploadUrl}`, image, {
      headers: {
        "Content-Type": "image/*",
      },
    });
  },
  disconnectMemeber: (hostEmail: string) => {
    console.log(hostEmail);
    return customAxios.patch(
      `/api/v1/users/enterprise/manager/disconnectMember`,
      hostEmail
    );
  },
  commentEdit: (data: CommentEditRequest) => {
    return customAxios.put(`/api/v1/questions/components`, data);
  },
};

export default patchApi;
