/**
 * 페이지네이션, Noah
 */

import React from "react";
import { SetURLSearchParams, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

// atom
import Img from "../atom/Img";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";

// img
import pageLeftBtn from "../../img/pageLeftBtn.svg";
import pageLeftDisabled from "../../img/pageLeftBtnDisabled.svg";
import pageRightBtn from "../../img/pageRightBtn.svg";
import pageRightDisabled from "../../img/pageRightBtnDisabled.svg";

import { device } from "../../hooks/device";
import TextLine from "../atom/TextLine";

interface PaginationProps extends PaginationStyle {
  lastPage: number;
  pageArr: number[];
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  queryType: string;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
}

interface PaginationStyle {
  pc_padding?: string;
  pc_margin?: string;
  pc_gap?: number;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const path = useLocation().pathname;
  const navigate = useNavigate();

  return (
    <StPage
      pc_padding={props.pc_padding}
      pc_margin={props.pc_margin}
      pc_gap={props.pc_gap}
    >
      {props.currentPage !== 1 && (
        <Img
          src={pageLeftBtn}
          cursor="pointer"
          onclick={() => {
            props.setCurrentPage(props.currentPage - 1);
            if(props.currentPage - 1 === 1) {
              props.searchParams.delete(props.queryType);
            } else {
              props.searchParams.set(props.queryType, String(props.currentPage - 1));
            }
            navigate(path + "?" + props.searchParams.toString());
          }}
        />
      )}
      {props.currentPage === 1 && (
        <Img src={pageLeftDisabled} />
      )}
      <DivTwoPartImprove
        display="flex"
        justify="center"
        align_items="center"
        pc_gap={10}
        pc_min_width="200px"
      >
        {props.pageArr?.map((value, index) => {
          return (
            <TextLine
              key={index}
              pc_fontFamily="Regular"
              pc_fontsize={16}
              pc_lineheight={24}
              cursor={true}
              pc_textAlign="center"
              text={value}
              fontcolor={props.currentPage === value ? "#325EB5" : "#888"}
              onclick={() => {
                if(value !== props.currentPage) {
                  props.setCurrentPage(value);
                  if(value === 1) {
                    props.searchParams.delete(props.queryType);
                  } else {
                    props.searchParams.set(props.queryType, String(value));
                  }
                  navigate(path + "?" + props.searchParams.toString());
                }
              }}
            />  
          )
        })}
      </DivTwoPartImprove>
      {props.currentPage !== props.lastPage && (
        <Img
          src={pageRightBtn}
          cursor="pointer"
          onclick={() => {
            props.searchParams.set(props.queryType, String(props.currentPage + 1));
            navigate(path + "?" + props.searchParams.toString());
          }}
        />
      )}
      {props.currentPage === props.lastPage && (
        <Img src={pageRightDisabled} />
      )}
    </StPage>
  );
};

const StPage = styled.div<PaginationStyle>`
  @media ${device.pc} {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => props.pc_padding};
    margin: ${(props) => props.pc_margin};
    gap: ${(props) => props.pc_gap}px;
    .onlyForMobile {
      display: none !important;
    }
  }
  @media ${device.tabMob} {
    /* 모바일 임시 처리 */
    display: none;
    .onlyForPc {
      display: none !important;
    }
  }
`;

export default Pagination;