import React from "react";
import ModalOneButtonTemplate from "../atomic/template/ModalOneButtonTemplate";
import ModalHeader from "../atomic/molecule/ModalHeader";
import ConfirmButton from "../atomic/atom/ConfirmButton";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import { useTranslation } from "react-i18next";

interface SignupProps {
  modalOn: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignupRequestComplete: React.FC<SignupProps> = (props) => {
  const { t } = useTranslation();
  const baseLang = "login.e3_1";
  return (
    <ModalOneButtonTemplate>
      <ModalHeader title={t(`${baseLang}.title`)} />
      <DivTwoPartImprove
        lineheight={24}
        font_size={16}
        margin="20px 0 24px"
        pc_margin="48px 0"
        pc_font_size={18}
        pc_lineheight={27}
      >
        {t(`${baseLang}.body`)}
      </DivTwoPartImprove>
      <ConfirmButton
        text={t(`${baseLang}.button`)}
        font_size={17}
        font_family="medium"
        margin="0 auto"
        tab_width="158px"
        tab_height={35}
        tab_fontsize={18}
        tab_fontcolor="#0072DE"
        onclick={() => props.modalOn(false)}
        cursor="pointer"
      />
    </ModalOneButtonTemplate>
  );
};

export default SignupRequestComplete;
