import React from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { languageIs } from "../atoms";
import { useNavigate } from "react-router-dom";

const Pagination = ({ page, type, nowPage }) => {
  const country = useRecoilValue(languageIs);
  const navigate = useNavigate();

  return (
    <StPage
      onClick={() => {
        navigate(`/${type}/${page}`);
      }}
      pageCounting={page}
      pageClicked={nowPage}
    >
      {page}
    </StPage>
  );
};

const StPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: ${(props) =>
    props.pageCounting === props.pageClicked ? "#20315B" : ""};
  color: ${(props) =>
    props.pageCounting === props.pageClicked ? "white" : "#BBBBBB"};
  border-radius: 8px;
  margin: 0 10px;
  cursor: pointer;
`;

export default Pagination;
