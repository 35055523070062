import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetBannerInfo = ({ lang, country }) => {
  const fetcher = () => {
    const utc = new Date().getTimezoneOffset();
    return new Promise((resolve, reject) => {
      apis
        .getBannerInfo({ utc, lang })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  };
  return useQuery(["bannerInfo", country], fetcher);
};

export default useGetBannerInfo;
