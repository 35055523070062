import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import x from "../img/x.svg";
import Joi from "joi";
import { invitation, isUserExist } from "../api/axiosTwo";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { bannerOn, languageIs } from "../atoms";
import InviteFinish from "./InviteFinish";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { ModalBack, ModalHeader } from "../components/Style";
import { device } from "../hooks/device";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";

const InviteEnterprise = ({ setInviteOn, invited, setInvited }) => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g8";
  const navigate = useNavigate();
  const country = useRecoilValue(languageIs);
  const email = useRef();
  const [member, setMember] = useState([]);
  const [notMember, setNotMember] = useState([]);
  const [warning, setWarning] = useState();
  const [finished, setFinished] = useState(false);
  const showToast = useToast();
  const banneris = useRecoilValue(bannerOn);

  const goBack = () => {
    setInviteOn(false);
  };

  useEffect(() => {
    window.addEventListener("popstate", goBack);
  }, []);

  const emailSchema = Joi.object({
    email: Joi.string().email({ tlds: { allow: false } }),
  });

  const first = () => {
    const { error } = emailSchema.validate({ email: email.current.value });
    if (error) {
      setWarning(t(`errorCode.emailform_error`));
    } else {
      userCheck();
    }
  };

  const { mutate } = useMutation(isUserExist, {
    onSuccess: (data) => {
      if (data.isProctormatic === "individual") {
        if (!(member || "").includes(email.current.value)) {
          setMember([email.current.value, ...member]);
          email.current.value = "";
          email.current.focus();
        } else {
          setWarning(t(`errorCode.inviteList`));
        }
      } else if (data.isProctormatic === "member") {
        setWarning(t(`errorCode.alreadyCompany`));
      } else if (data.isProctormatic === "enterprise") {
        setWarning(t(`errorCode.alreadyCompany`));
      } else if (data.isProctormatic === "notMember") {
        if (!(notMember || "").includes(email.current.value)) {
          setNotMember([email.current.value, ...notMember]);
          email.current.value = "";
        } else {
          setWarning(t(`errorCode.inviteList`));
        }
      }
    },
    onError: (data) => console.log(data),
  });

  const userCheck = () => {
    mutate({
      hostEmail: email.current.value,
    });
  };

  const removeMember = (index) => {
    const temp = member;
    temp.splice(index, 1);
    let tempTwo = Array.from(temp);
    setMember(tempTwo);
  };

  const removeNotMember = (index) => {
    const temp = notMember;
    temp.splice(index, 1);
    let tempTwo = Array.from(temp);
    setNotMember(tempTwo);
  };

  const { mutate: invite, isLoading } = useMutation(invitation, {
    onSuccess: (data) => {
      if (data?.isSuccess === true) {
        setFinished(true);
        navigate(`/memberManage/invite`);
        setInvited(!invited);
      }
    },
    onError: ({ response }) => {
      const errorCode = response.data.errorCode;
      if (errorCode === "ENTERPRISE-004") {
        showToast({
          message: t(`errorCode.enterprise_004`),
        });
      } else if (
        errorCode === "HOST-ROLE-001" ||
        errorCode === "HOST-ROLE-006"
      ) {
        showToast({ message: "관리자만 이용할 수 있어요." });
      } else {
        showToast({
          message: t(`errorCode.unknown_server_error`),
        });
      }
      //ENTERPRISE-004 메일 500통 에러
    },
  });

  const sendEmail = () => {
    invite({
      isProctormatic: member,
      isntProctormatic: notMember,
    });
  };

  useScrollLockThree();

  return (
    <>
      <ModalBack />
      {finished && (
        <InviteFinish setFinished={setFinished} setInviteOn={setInviteOn} />
      )}
      <StInvite
        member={member.length}
        notMember={notMember.length}
        warning={warning}
        banneris={banneris}
      >
        <div className="wrapper">
          <ModalHeader title={t(`${baseLang}.title`)} />
          <div className="inputBox">
            <div className="inputTitle">{t(`${baseLang}.email`)}</div>
            <div className="sendingPart">
              <input
                onChange={() => setWarning("")}
                ref={email}
                placeholder={t(`${baseLang}.placeholder`)}
              />
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  first();
                }}
              >
                {t(`${baseLang}.add`)}
              </div>
            </div>
            <div className="warningMessage">{warning}</div>
            <div className="info">
              <TextLine text="*" />
              <TextLine margin="0 0 0 5px" text={t(`${baseLang}.info`)} />
            </div>
          </div>
          <div className="emailListBox">
            {(notMember.length === 0) & (member.length === 0) ? (
              <DivTwoPartImprove
                margin="auto"
                font_size={16}
                lineheight={19}
                font_color="#bdbdbd"
              >
                {t(`${baseLang}.none`)}
              </DivTwoPartImprove>
            ) : (
              <div>
                {!isLoading ? (
                  <div>
                    {notMember?.length > 0 && (
                      <div className="emailList">
                        <div className="listHead">
                          {t(`${baseLang}.notMember`)} ({notMember.length})
                          <div className="dotLine" />
                        </div>
                        {/* <div className=''> */}
                        {notMember.map((value, index) => (
                          <div className="notMemberBox" key={index}>
                            {value}
                            <img
                              src={x}
                              onClick={() => removeNotMember(index)}
                              alt=""
                            />
                          </div>
                        ))}
                        {/* </div> */}
                      </div>
                    )}
                    {member?.length > 0 && (
                      <div className="emailList">
                        <div className="listHead">
                          {t(`${baseLang}.member`)} ({member.length})
                          <div className="dotLine" />
                        </div>
                        <div>
                          {member?.map((value, index) => (
                            <div className="memberBox" key={index}>
                              {value}
                              <img
                                src={x}
                                onClick={() => {
                                  removeMember(index);
                                }}
                                alt=""
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}
          </div>
          <div className="buttonBucket">
            <button
              className="sendingButton"
              onClick={() => {
                sendEmail();
              }}
              disabled={member.length === 0 && notMember.length === 0}
            >
              {t(`${baseLang}.button`)}
            </button>
          </div>
        </div>
      </StInvite>
    </>
  );
};

const StInvite = styled.div`
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  background-color: #ffffff;
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    box-sizing: border-box;
    .headTitle {
      font-family: "bold";
      font-size: 19px;
      line-height: 28px;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
  .inputBox {
    box-sizing: border-box;
    .sendingPart {
      display: flex;
      input {
        width: 235px;
        border: none;
        border-bottom: 1px solid
          ${(props) => (props.warning ? "#C83B38" : "#DFDFDF")};
        &::placeholder {
          color: #bdbdbd;
          font-size: 16px;
          font-family: "light";
        }
        &:focus {
          outline: none;
        }
      }
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #20315b;
        color: white;
        font-family: "regular";
        border-radius: 6px;
        margin-left: 7px;
      }
    }
    .warningMessage {
      color: #c83b38;
      font-family: "regular";
      line-height: 18px;
      margin-top: 5px;
      font-size: 12px;
    }
    .info {
      display: flex;
      font-family: "regular";
      line-height: 13px;
      margin-top: 16.5px;
    }
  }
  .emailListBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 326px;
    overflow-x: hidden;
    box-sizing: border-box;
    .emailList {
      /* display : flex;
            flex-direction : column; */
      display: grid;
      gap: 12px;
      .listHead {
        display: flex;
        align-items: center;
        font-size: 13px;
        font-family: "medium";
        line-height: 19px;
        color: #8c8c8c;
        padding: 8.5px 0;
        .dotLine {
          flex-grow: 1;
          height: 1px;
          border: none;
          border-bottom: 2px dotted #c6c6c6;
          margin-left: 24px;
          box-sizing: border-box;
        }
      }
      .memberBox {
        /* display :inline-block; */
        width: max-content;
        align-items: center;
        padding: 6px 16px;
        background-color: #fff4d8;
        border-radius: 16px;
        margin-bottom: 8px;
        img {
          width: 10px;
          height: 10px;
          margin-left: 6px;
          cursor: pointer;
        }
      }
      .notMemberBox {
        /* display :inline-block; */
        width: max-content;
        align-items: center;
        padding: 6px 16px;
        background-color: #e9e9e9;
        border-radius: 16px;
        margin-bottom: 8px;
        img {
          width: 10px;
          height: 10px;
          margin-left: 6px;
          cursor: pointer;
        }
      }
    }
  }
  .sendingButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 204px;
    border-radius: 42px;
    border: none;
    background-color: ${(props) =>
      props.member > 0 || props.notMember > 0 ? "#ED893E" : "#D9D9D9"};
    color: white;
    margin: 0 auto;
    cursor: ${(props) =>
      props.member > 0 || props.notMember > 0 ? "pointer" : "none"};
  }
  @media ${device.pc} {
    display: grid;
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    height: 800px;
    z-index: 52;
    transform: translate(-50%, -50%);
    .wrapper {
      display: flex;
      flex-direction: column;
    }
    .inputBox {
      margin-top: 48px;
      .inputTitle {
        font-size: 15px;
        font-family: "medium";
        line-height: 22px;
      }
      .sendingPart {
        display: flex;
        width: 100%;
        margin-top: 10px;
        input {
          flex-grow: 1;
        }
        div {
          width: 101px;
          height: 37px;
          font-size: 14px;
        }
      }
      .info {
        font-size: 12px;
      }
    }
    .emailListBox {
      margin: 32px 0 36px;
      .emailList {
        margin-bottom: 24px;
        .listHead {
          .dotLine {
          }
        }
        .notMemberBox {
          font-size: 15px;
        }
        .memberBox {
          font-size: 15px;
        }
      }
    }
    .sendingButton {
      height: 52px;
      font-size: 17px;
      font-family: "medium";
    }
    .buttonBucket {
      display: flex;
      margin-top: auto;
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    padding: 0 24px;
    bottom: 0;
    left: 0;
    z-index: 49;
    .wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: ${(props) =>
        props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
      max-width: 752px;
      margin: auto auto 0;
    }
    .inputBox {
      padding: 14px 0px;
      .inputTitle {
        font-size: 13px;
        font-family: "medium";
        line-height: 19px;
      }
      .sendingPart {
        margin-top: 5px;
        div {
          width: 70px;
          height: 31px;
          font-size: 11px;
        }
      }
      .info {
        font-size: 11px;
      }
    }
    .emailListBox {
      padding: 0px 0px;
      margin-bottom: 14px;
      .emailList {
        .notMemberBox {
          font-size: 14px;
        }
        .memberBox {
          font-size: 14px;
        }
      }
    }
    .buttonBucket {
      display: flex;
      padding-bottom: 15px;
      margin-top: auto;
      height: 100%;
      .sendingButton {
        margin: auto auto 0;
        height: 42px;
        font-size: 14px;
      }
    }
  }
`;

export default InviteEnterprise;
