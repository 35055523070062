import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { setCookie } from "../Cookie";
import { device } from "../hooks/device";
import { useRef } from "react";
import customAxios from "../api/handler";

// styled components
import { StLine } from "./styled";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { languageIs, userInfo } from "../atoms";
import dayjs from "dayjs";
import "dayjs/locale/en";
import MyExamTestDelete from "../modal/MyExamTestDelete";
import useToast from "../hooks/useToast";
import { useTranslation } from "react-i18next";
import ReportTestDelete from "../modal/ReportTestDelete";
import Img from "../atomic/atom/Img";
import postApi from "../api/postApi";
import ChargeConfirm from "../pages/CreateExamSecondStep/modal/ChargeConfirm";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";

// img
import xCircle from "../img/x-circle.svg";
import checkCircle from "../img/check-circle.svg";
import threeDot from "../img/3dot.svg";
import ToolTipArrow from "../img/ToolTipArrow.svg";

const TestInfo = ({
  testStartTime,
  hostType,
  setUploadModal,
  testVerifying,
  setVideoAnalysis,
  setDeleteDeniedModal,
  finishTime,
  analysisTime,
  testEndTime,
  testName,
  testStep,
  testUuid,
  setTestUid,
  testUploaded,
  // Noah
  testType,
  currentPage,
  testIndex,
  // setTestListCount,
  setMyPageScroll,
  pageListCount,
}) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d1";
  const navigate = useNavigate();
  const clicked = useRef(null);
  const showToast = useToast();

  const [step, setStep] = useState(); // 진행 현황
  const [report, setReport] = useState(); // 검증 결과 보고서

  // modal state
  const [testDeleteModal, setTestDeleteModal] = useState(false);

  // Noah
  const [deleteButton, setDeleteButton] = useState(false);
  const [reportDeleteModal, setReportDeleteModal] = useState(false);
  const [resendTestEmail, setResendTestEmail] = useState(false);

  // Noah
  const [showNameToolTip, setShowNameToolTip] = useState(false);

  const pageCount = pageListCount === undefined ? 10 : pageListCount;

  // 언어정보 변경
  dayjs.locale("en");

  // upload time
  const [click, setClick] = useState(false);

  useEffect(() => {
    switch (testStep) {
      case "beforeStart": // 진행예정
        setStep(t(`${baseLang}.state1`));
        setReport("-");
        break;
      case "beforeAnalysis":
        // setStep(t(`${baseLang}.state2`) + "(0%)");
        setStep(t(`${baseLang}.state2`));
        setReport(t(`${baseLang}.report2`));
        break;
      case "analyzing":
        setStep(t(`${baseLang}.report2`) + ` (${testVerifying})`);
        setReport(t(`${baseLang}.state2`));
        break;
      case "afterAnalysis":
        setStep(t(`${baseLang}.state3`));
        setReport(t(`${baseLang}.report2`));
        break;
      case "afterSendMail":
        setStep(t(`${baseLang}.state3`));
        setReport(t(`${baseLang}.report3`));
        break;
      case "notProgress":
        setStep(t(`${baseLang}.state4`));
        setReport("-");
        break;
      case "unusualOnly":
        setStep(t(`${baseLang}.state5`));
        setReport("-");
        break;
      default:
        setStep(`-`);
        setReport(`-`);
        break;
    }
  }, [testStep]);

  useEffect(() => {
    function handleOutside(e) {
      if (clicked.current && !clicked.current.contains(e.target)) {
        setClick(false);
      }
    }
    document.addEventListener("click", handleOutside);
    return () => {
      document.removeEventListener("click", handleOutside);
    };
  }, [clicked]);

  // 편집 할수 있는지 체크
  const editPossible = () => {
    customAxios
      .get(`/api/v1/tests/patch/half-hour/${testUuid}`)
      .then(() => {
        navigate(`/editExam/${testUuid}`);
      })
      .catch(({ response }) => {
        if (response.data?.errorCode === "TIMEZONE-012") {
          showToast({ message: t(`errorCode.timezone_012`) });
        } else {
          showToast({
            message: t(`errorCode.unknown_server_error`),
          });
        }
      });
  };

  // 티켓ID 56 관련, 시험 안내 메일 재전송
  const resendingTestEmail = () => {
    postApi
      .postResendTestEmail({
        testUuid: testUuid,
      })
      .then(({ data }) => {
        setResendTestEmail(true);
      })
      .catch(({ response }) => {
        // const errorCode = response.data.errorCode;
        // console.log(errorCode);
        showToast({
          message: t(`errorCode.unknown_server_error`),
        });
      });
  };

  return (
    <>
      {testDeleteModal && (
        <MyExamTestDelete
          testUuid={testUuid}
          hostType={hostType}
          setDeleteDeniedModal={setDeleteDeniedModal}
          setTestDeleteModal={setTestDeleteModal}
        />
      )}
      {reportDeleteModal && (
        <ReportTestDelete
          setTestDeleteModal={setReportDeleteModal}
          testId={testUuid}
          // setTestListCount={setTestListCount}
        />
      )}
      {resendTestEmail && (
        <ChargeConfirm
          setMyPageScroll={setMyPageScroll}
          setResendTestEmail={setResendTestEmail}
        />
      )}
      <StBox
        ref={clicked}
        step={testStep}
        onClick={() => {
          setClick(!click);
          setTestUid(testUuid);
          if (
            testStep === "afterAnalysis" ||
            testStep === "afterSendMail" ||
            testStep === "notProgress" ||
            testStep === "unusualOnly"
          ) {
            navigate(`/report/${testUuid}`);
          } else if (testStep === "beforeAnalysis") {
            setUploadModal(true);
          } else if (testStep === "analyzing") {
            setVideoAnalysis(true);
          }
          setCookie("scroll", window.scrollY, {
            path: "/",
          });
        }}
      >
        <div className="mobileWrapper onlyForMobile">
          {click && testStep === "beforeStart" && (
            <div className="clicked">
              <div className="buttons">
                <div
                  className="inButton"
                  onClick={() => {
                    editPossible();
                  }}
                >
                  {t(`${baseLang}.edit`)}
                </div>
                <div
                  className="inButton"
                  onClick={() => {
                    resendingTestEmail();
                  }}
                >
                  {t(`${baseLang}.resendMail`)}
                </div>
                <div
                  className="inButton"
                  style={{ color: "#C83B38", borderColor: "#C83B38" }}
                  onClick={() => {
                    setTestDeleteModal(true);
                  }}
                >
                  {t(`${baseLang}.delete`)}
                </div>
              </div>
            </div>
          )}
          <div className="test">{testName}</div>
          <div className="font12">{t(`${baseLang}.date`)}</div>
          <div className="grey">
            {dayjs(testStartTime).format("M/D/YYYY(ddd), hh:mm A")} ~{" "}
            {dayjs(testEndTime).format("hh:mm A")}
          </div>
          <div className="write">
            <div className="writeLine">
              <div className="simple12">{t(`${baseLang}.upload`)}</div>
              <div className="blue">{testUploaded}</div>
            </div>
            <div className="writeLine">
              <div className="simple12">{t(`${baseLang}.status`)}</div>
              <div className="progress">{step}</div>
            </div>
            <StLine className="mobile writeLine">
              {testStep === "afterAnalysis" ||
              testStep === "afterSendMail" ||
              testStep === "notProgress" ||
              testStep === "unusualOnly" ? (
                <div className="simple12">{t(`${baseLang}.complete`)}</div>
              ) : (
                <div className="simple12">{t(`${baseLang}.complete`)}</div>
              )}
              {testStep === "afterAnalysis" ||
              testStep === "afterSendMail" ||
              testStep === "notProgress" ||
              testStep === "unusualOnly" ? (
                <div className="blue">
                  {analysisTime === "-"
                    ? "-"
                    : dayjs(analysisTime).format("M/D/YYYY(ddd), hh:mm A")}
                </div>
              ) : (
                <div className="blue">{finishTime}</div>
              )}
            </StLine>
            <StLine className="mobile writeLine">
              <div className="simple12">{t(`${baseLang}.report`)}</div>
              <div className="blue">{report}</div>
            </StLine>
          </div>
        </div>
        {/* PC 리스트 */}
        {Math.floor(testIndex / pageCount) + 1 === currentPage && (
          <DivTwoPartImprove
            display="none"
            pc_display="flex"
            position="relative"
            pc_width="100%"
            pc_height={64}
            pc_padding="16px 0px"
            pc_background_color="#ffffff"
            pc_box_sizing="border-box"
            pc_text_align="center"
            pc_align_items="center"
            border_bottom="1px solid #F0F1F2"
            cursor={testType === "pending" ? "" : "pointer"}
            pc_hover_background_color="rgba(34, 118, 220, 0.05)"
          >
            {testType === "verified" && (
              <>
                {showNameToolTip && (
                  <DivTwoPartImprove
                    width="100%"
                    display="flex"
                    position="absolute"
                    pc_transform="translateY(-65%)"
                  >
                    <DivTwoPartImprove
                      display="flex"
                      flex_direction="column"
                      align_items="center"
                      justify="center"
                      pc_flex="1 0 0px"
                    >
                      <DivTwoPartImprove
                        pc_max_width={420}
                        pc_padding="8px 16px"
                        pc_box_sizing="border-box"
                        pc_border_radius={4}
                        pc_box_shadow="0px 2px 6px 0px rgba(0, 0, 0, 0.25);"
                        background_color="#FFF"
                        font_size={16}
                        font_weight={600}
                        pc_lineheight={24}
                      >
                        {testName}
                      </DivTwoPartImprove>
                      <Img src={ToolTipArrow} />
                    </DivTwoPartImprove>
                    <DivTwoPartImprove
                      pc_flex="0 0 180px"
                      pc_padding="0px 8px"
                    ></DivTwoPartImprove>
                    <DivTwoPartImprove
                      pc_flex="0 0 111px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 98px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 160px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 120px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 51px"
                      pc_padding="0px 8px"
                    />
                  </DivTwoPartImprove>
                )}
                <DivTwoPartImprove
                  pc_font_family="Regular"
                  pc_font_style="normal"
                  lineheight={24}
                  pc_font_weight={600}
                  overflow="hidden"
                  white_space="nowrap"
                  text_overflow="ellipsis"
                  word_break="break-all"
                  pc_flex="1 0 0px"
                  pc_padding="0px 8px"
                  onMouseEnter={(e) => {
                    if (e.target.offsetWidth < e.target.scrollWidth) {
                      setShowNameToolTip(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setShowNameToolTip(false);
                  }}
                >
                  {testName}
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  pc_lineheight={21}
                  pc_flex="0 0 180px"
                  pc_padding="0px 8px"
                >
                  {dayjs(testStartTime).format("YYYY-MM-DD(ddd)")}
                  <br />
                  {dayjs(testStartTime).format("A hh:mm")} ~{" "}
                  {dayjs(testEndTime).format("A hh:mm")}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 111px" pc_padding="0px 8px">
                  {testUploaded}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 98px" pc_padding="0px 8px">
                  <Img
                    src={
                      testStep === "afterAnalysis" ||
                      testStep === "afterSendMail"
                        ? checkCircle
                        : xCircle
                    }
                  />
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  lineheight={21}
                  pc_flex="0 0 160px"
                  pc_padding="0px 8px"
                >
                  {testStep === "afterAnalysis" ||
                  testStep === "afterSendMail" ||
                  testStep === "notProgress" ||
                  testStep === "unusualOnly" ? (
                    analysisTime !== "-" ? (
                      <div>
                        {dayjs(analysisTime).format("YYYY-MM-DD(ddd)")}
                        <br />
                        {dayjs(analysisTime).format("A hh:mm")}
                      </div>
                    ) : (
                      <div>-</div>
                    )
                  ) : finishTime !== "-" ? (
                    <div>
                      {dayjs(finishTime).format("YYYY-MM-DD(ddd)")}
                      <br />
                      {dayjs(finishTime).format("A hh:mm")}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  font_color={
                    testStep === "afterSendMail" ? "#328080" : "#191919"
                  }
                  pc_flex="0 0 120px"
                  pc_padding="0px 8px"
                >
                  {report}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 51px" pc_padding="0px 8px">
                  <Img
                    src={threeDot}
                    alt=""
                    onclick={(e) => {
                      e.stopPropagation();
                      setDeleteButton(true);
                    }}
                  />
                  {deleteButton && (
                    <>
                      <DivTwoPartImprove
                        position="fixed"
                        z_index={1}
                        top={0}
                        left={0}
                        width="100000px"
                        height="500000"
                        onclick={(e) => {
                          e.stopPropagation();
                          setDeleteButton(false);
                        }}
                      />
                      <DivTwoPartImprove
                        position="absolute"
                        top={0}
                        right={0}
                        z_index={10}
                        background_color="#fff"
                      >
                        <DivTwoPartImprove
                          display="flex"
                          align_items="center"
                          width="224px"
                          padding="8px 12px"
                          box_shadow="0px 6px 10px rgba(0, 0, 0, 0.15)"
                          height={56}
                          lineheight={24}
                          font_size={14}
                          font_color="#C83B38"
                          cursor="pointer"
                          onclick={(e) => {
                            e.stopPropagation();
                            setReportDeleteModal(true);
                            setDeleteButton(false);
                          }}
                        >
                          {t(`${baseLang}.delete`)}
                        </DivTwoPartImprove>
                      </DivTwoPartImprove>
                    </>
                  )}
                </DivTwoPartImprove>
              </>
            )}
            {testType === "verifying" && (
              <>
                {showNameToolTip && (
                  <DivTwoPartImprove
                    width="100%"
                    display="flex"
                    position="absolute"
                    pc_transform="translateY(-65%)"
                  >
                    <DivTwoPartImprove
                      display="flex"
                      flex_direction="column"
                      align_items="center"
                      justify="center"
                      pc_flex="1 0 0px"
                    >
                      <DivTwoPartImprove
                        pc_max_width={420}
                        pc_padding="8px 16px"
                        pc_box_sizing="border-box"
                        pc_border_radius={4}
                        pc_box_shadow="0px 2px 6px 0px rgba(0, 0, 0, 0.25);"
                        background_color="#FFF"
                        font_size={16}
                        font_weight={600}
                        pc_lineheight={24}
                      >
                        {testName}
                      </DivTwoPartImprove>
                      <Img src={ToolTipArrow} />
                    </DivTwoPartImprove>
                    <DivTwoPartImprove
                      pc_flex="0 0 180px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 111px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 112px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 160px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 120px"
                      pc_padding="0px 8px"
                    />
                  </DivTwoPartImprove>
                )}
                <DivTwoPartImprove
                  pc_font_family="Regular"
                  pc_font_style="normal"
                  lineheight={24}
                  pc_font_weight={600}
                  overflow="hidden"
                  white_space="nowrap"
                  text_overflow="ellipsis"
                  word_break="break-all"
                  pc_flex="1 0 0px"
                  pc_padding="0px 8px"
                  onMouseEnter={(e) => {
                    if (e.target.offsetWidth < e.target.scrollWidth) {
                      setShowNameToolTip(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setShowNameToolTip(false);
                  }}
                >
                  {testName}
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  pc_lineheight={21}
                  pc_flex="0 0 180px"
                  pc_padding="0px 8px"
                >
                  {dayjs(testStartTime).format("YYYY-MM-DD(ddd)")}
                  <br />
                  {dayjs(testStartTime).format("A hh:mm")} ~{" "}
                  {dayjs(testEndTime).format("A hh:mm")}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 111px" pc_padding="0px 8px">
                  {testUploaded}
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  lineheight={24}
                  pc_flex="0 0 112px"
                  pc_padding="0px 8px"
                  font_color={testStep === "analyzing" ? "#2276DC" : "#BDBDBD"}
                >
                  {step?.split("(")[0].trim()}
                  <br />
                  {step?.split("(").length > 1
                    ? "(" + step?.split("(")[1]
                    : "(0%)"}
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  lineheight={21}
                  pc_flex="0 0 160px"
                  pc_padding="0px 8px"
                >
                  {testStep === "afterAnalysis" ||
                  testStep === "afterSendMail" ||
                  testStep === "notProgress" ||
                  testStep === "unusualOnly" ? (
                    analysisTime !== "-" ? (
                      <div>
                        {dayjs(analysisTime).format("YYYY-MM-DD(ddd)")}
                        <br />
                        {dayjs(analysisTime).format("A hh:mm")}
                      </div>
                    ) : (
                      <div>-</div>
                    )
                  ) : finishTime !== "-" ? (
                    <div>
                      {dayjs(finishTime).format("YYYY-MM-DD(ddd)")}
                      <br />
                      {dayjs(finishTime).format("A hh:mm")}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  font_color={testStep === "analyzing" ? "#2276DC" : "#BDBDBD"}
                  pc_flex="0 0 120px"
                  pc_padding="0px 8px"
                >
                  {report}
                </DivTwoPartImprove>
              </>
            )}
            {testType === "pending" && (
              <>
                {showNameToolTip && (
                  <DivTwoPartImprove
                    width="100%"
                    display="flex"
                    position="absolute"
                    pc_transform="translateY(-65%)"
                  >
                    <DivTwoPartImprove
                      display="flex"
                      flex_direction="column"
                      align_items="center"
                      justify="center"
                      pc_flex="1 0 0px"
                    >
                      <DivTwoPartImprove
                        pc_max_width={420}
                        pc_padding="8px 16px"
                        pc_box_sizing="border-box"
                        pc_border_radius={4}
                        pc_box_shadow="0px 2px 6px 0px rgba(0, 0, 0, 0.25);"
                        background_color="#FFF"
                        font_size={16}
                        font_weight={600}
                        pc_lineheight={24}
                      >
                        {testName}
                      </DivTwoPartImprove>
                      <Img src={ToolTipArrow} />
                    </DivTwoPartImprove>
                    <DivTwoPartImprove
                      pc_flex="0 0 180px"
                      pc_padding="0px 8px"
                    ></DivTwoPartImprove>
                    <DivTwoPartImprove
                      pc_flex="0 0 111px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 98px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 160px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 120px"
                      pc_padding="0px 8px"
                    />
                    <DivTwoPartImprove
                      pc_flex="0 0 51px"
                      pc_padding="0px 8px"
                    />
                  </DivTwoPartImprove>
                )}
                <DivTwoPartImprove
                  position="relative"
                  pc_font_family="Regular"
                  pc_font_style="normal"
                  lineheight={24}
                  pc_font_weight={600}
                  overflow="hidden"
                  white_space="nowrap"
                  text_overflow="ellipsis"
                  word_break="break-all"
                  pc_flex="1 0 0px"
                  pc_padding="0px 8px"
                  onMouseEnter={(e) => {
                    if (e.target.offsetWidth < e.target.scrollWidth) {
                      setShowNameToolTip(true);
                    }
                  }}
                  onMouseLeave={() => {
                    setShowNameToolTip(false);
                  }}
                >
                  {testName}
                </DivTwoPartImprove>
                <DivTwoPartImprove
                  pc_lineheight={21}
                  pc_flex="0 0 180px"
                  pc_padding="0px 8px"
                >
                  {dayjs(testStartTime).format("YYYY-MM-DD(ddd)")}
                  <br />
                  {dayjs(testStartTime).format("A hh:mm")} ~{" "}
                  {dayjs(testEndTime).format("A hh:mm")}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 111px" pc_padding="0px 8px">
                  -
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 98px" pc_padding="0px 8px">
                  {step}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 160px" pc_padding="0px 8px">
                  {finishTime ? finishTime : "-"}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 120px" pc_padding="0px 8px">
                  {report}
                </DivTwoPartImprove>
                <DivTwoPartImprove pc_flex="0 0 51px" pc_padding="0px 8px">
                  <Img
                    src={threeDot}
                    alt=""
                    cursor="pointer"
                    onclick={(e) => {
                      e.stopPropagation();
                      setDeleteButton(true);
                    }}
                  />
                  {deleteButton && (
                    <>
                      <DivTwoPartImprove
                        position="fixed"
                        z_index={1}
                        top={0}
                        left={0}
                        width="100000px"
                        height="500000"
                        onclick={(e) => {
                          e.stopPropagation();
                          setDeleteButton(false);
                        }}
                      />
                      <DivTwoPartImprove
                        position="absolute"
                        top={0}
                        right={0}
                        z_index={10}
                        background_color="#fff"
                        box_shadow="0px 6px 10px rgba(0, 0, 0, 0.15)"
                      >
                        <DivTwoPartImprove
                          display="flex"
                          align_items="center"
                          width="240px"
                          padding="0px 12px"
                          // box_shadow="0px 6px 10px rgba(0, 0, 0, 0.15)"
                          height={40}
                          lineheight={24}
                          font_size={14}
                          cursor="pointer"
                          pc_box_sizing="border-box"
                          onclick={(e) => {
                            e.stopPropagation();
                            editPossible();
                            setDeleteButton(false);
                          }}
                        >
                          {t(`${baseLang}.edit`)}
                        </DivTwoPartImprove>
                        <DivTwoPartImprove
                          display="flex"
                          align_items="center"
                          width="240px"
                          padding="0px 12px"
                          // box_shadow="0px 6px 10px rgba(0, 0, 0, 0.15)"
                          height={40}
                          lineheight={24}
                          font_size={14}
                          cursor="pointer"
                          pc_box_sizing="border-box"
                          onclick={(e) => {
                            e.stopPropagation();
                            resendingTestEmail();
                            setDeleteButton(false);
                          }}
                        >
                          {t(`${baseLang}.resendMail`)}
                        </DivTwoPartImprove>
                        <DivTwoPartImprove
                          display="flex"
                          align_items="center"
                          width="240px"
                          padding="0px 12px"
                          // box_shadow="0px 6px 10px rgba(0, 0, 0, 0.15)"
                          height={40}
                          lineheight={24}
                          font_size={14}
                          font_color="#C83B38"
                          cursor="pointer"
                          pc_box_sizing="border-box"
                          onclick={(e) => {
                            e.stopPropagation();
                            setTestDeleteModal(true);
                            setDeleteButton(false);
                          }}
                        >
                          {t(`${baseLang}.delete`)}
                        </DivTwoPartImprove>
                      </DivTwoPartImprove>
                    </>
                  )}
                </DivTwoPartImprove>
              </>
            )}
          </DivTwoPartImprove>
        )}
      </StBox>
    </>
  );
};

const StBox = styled.div`
  @media ${device.pc} {
    .onlyForMobile {
      display: none !important;
    }
    .buttonss {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100px;
    }
    .clicked {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 12px;
      cursor: default;
      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
      }
      .inButton {
        width: 149px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #000000;
        border-radius: 43px;
        font-family: "Medium";
        font-size: 15px;
        cursor: pointer;
      }
    }
    .testTime {
      font-family: "Medium";
      font-size: 14px;
      line-height: 150%;
    }
    .font12 {
      font-family: "Medium";
      font-size: 14px;
      line-height: 21px;
    }
    .grey {
      font-family: "Regular";
      font-size: 14px;
      line-height: 21px;
      color: #818181;
    }
    .write {
      display: grid;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 25px;
      line-height: 21px;
      gap: 4px;
      .simple12 {
        font-family: "Regular";
        font-size: 14px;
        width: 108px;
      }
      .writeLine {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 12px;
      }
    }
    .blue {
      font-family: "Medium";
      font-size: 13.5px;
      color: #2276dc;
    }
    .progress {
      font-family: "Medium";
      font-size: 13.5px;
      color: ${(props) => (props.step === 5 ? "#C83B38" : "#2276DC")};
    }
  }
  @media ${device.mobile} {
    /* width : 264px; */
    width: 100%;
  }
  @media ${device.tablet} {
    width: 100%;
  }
  @media ${device.tabMob} {
    position: relative;
    height: 212px;
    padding: 24px;
    box-sizing: border-box;
    background: ${(props) =>
      props.step === "beforeAnalysis" || props.step === "analyzing"
        ? "#FFF6A3"
        : "#FFFFFF"};
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    cursor: pointer;
    .onlyForPc {
      display: none !important;
    }
    .clicked {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 12px;
      cursor: default;
      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 156px;
      }
      .buttonss {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100px;
      }
      .inButton {
        width: 151px;
        min-height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ffffff;
        border: 1px solid #000000;
        border-radius: 43px;
        text-align: center;
        cursor: pointer;
      }
    }
    .mobile {
      display: none !important;
    }
    .test {
      font-size: 16px;
      font-family: "Medium";
      line-height: 24px;
      height: 48px;
      margin: 0 0 20px 0;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      word-break: break-all;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .font12 {
      font-family: "Medium";
      font-size: 12px;
      line-height: 18px;
    }
    .grey {
      font-family: "Regular";
      font-size: 11.5px;
      line-height: 18px;
      color: #818181;
    }
    .write {
      display: grid;
      flex-direction: column;
      justify-content: space-between;
      margin: 20px 0 0 0;
      gap: 4px;
      .writeLine {
        display: flex;
        align-items: center;
        line-height: 18px;
      }
    }
    .simple12 {
      font-family: "Regular";
      font-size: 12px;
      width: 92px;
      margin: 0 15px 0 0;
    }
    .blue {
      font-family: "medium";
      font-size: 12px;
      color: #2276dc;
    }
    .progress {
      font-family: "Regular";
      font-size: 12px;
      color: ${(props) => (props.step === 5 ? "#C83B38" : "#2276DC")};
    }
  }
`;

export default TestInfo;
