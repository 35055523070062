import React from "react";
import styled from "styled-components";
import LandingTwo from "../img/LandingTwo.png";
import LandingMobileTwo from "../img/LandingMobileTwo.png";
import { device } from "../hooks/device";
import { useRecoilValue } from "recoil";
import { bannerOn, languageIs } from "../atoms";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import { HomeOneStyleProps } from "../interface/styleInterface";
import { useTranslation } from "react-i18next";

const HomeTwo = () => {
  const banneris = useRecoilValue(bannerOn);
  const baseLang: string = "home.a1.firstPage.second";
  const { t } = useTranslation();

  return (
    <StPage $banneris={banneris}>
      <div className="leftBox">
        {/* <div className="firstPart">
          <TextTwoPart textKor="프록토매틱 출시 기념 런칭 이벤트" />
        </div> */}
        <div className="middlePart">
          <div className="secondBlock">
            {/* <TextLine
              text={t(`${baseLang}.upTitle`)}
              fontsize={14}
              fontFamily="medium"
              fontcolor="#55A2FF"
              pc_fontsize={25}
              pc_lineheight={37}
              lineheight={21}
              margin="0 0 12px 0"
              pc_margin="0 0 22px 0"
            /> */}
            <div className="centerText">
              <TextLine text={t(`${baseLang}.middleTitleOne`)} />
            </div>
            <DivTwoPartImprove display="grid" pc_display="flex">
              <TextLine text={t(`${baseLang}.middleTitleTwo`) + " "} />
              <TextLine
                text={t(`${baseLang}.middleTitleThree`)}
                fontcolor="#ED893E"
                pc_margin="0 0 0 13px"
              />
            </DivTwoPartImprove>
          </div>
        </div>
      </div>
      <img className="rightImgBox" src={LandingTwo} />
      <img className="rightMobileImgBox" src={LandingMobileTwo} />
    </StPage>
  );
};

const StPage = styled.div<HomeOneStyleProps>`
  /* background: radial-gradient(189.33% 88.48% at 17.27% 20.89%, #0D2256 0%, #070F21 100%); */
  .leftBox {
    position: relative;
    z-index: 4;
    grid-column: 2/11;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    .firstPart {
      color: #55a2ff;
      font-size: 25px;
      font-family: "medium";
      line-height: 37px;
    }
    .secondBlock {
      display: flex;
      flex-direction: column;
      font-size: 62px;
      font-family: "bold";
      color: white;
      line-height: 130%;
      margin: 22px 0 64px;
      .centerText {
        display: flex;
      }
    }
    .thirdBlock {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 16px;
      .leftCircle,
      .rightCircle {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 160px;
        border-radius: 80px;
        box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.08);
        color: white;
        .innerCircle {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 140px;
          height: 140px;
          border-radius: 70px;
          border: 1px solid white;
        }
        div {
          font-size: 14px;
          font-family: "medium";
          line-height: 14px;
        }
        .count {
          font-size: 32px;
          font-family: "bold";
          line-height: 32px;
          margin: 10px 0;
        }
      }
      .leftCircle {
        background: var(
          --app-main-gradient,
          radial-gradient(
            881.7% 141.2% at 78.37% -19.02%,
            #4992ff 0%,
            #1a75ff 100%
          )
        );
      }
      .rightCircle {
        background: radial-gradient(
          881.7% 141.2% at 78.37% -19.02%,
          #e19627 0%,
          #ff6c1a 100%
        );
      }
    }
  }
  .rightImgBox {
    height: ${(props) =>
      props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
    grid-column: 2/11;
    grid-row: 1;
    margin-left: auto;
  }
  @media ${device.pc} {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    height: ${(props) =>
      props.$banneris ? "calc(100vh - 116px)" : "calc(100vh - 76px)"};
    overflow: hidden;
    .onlyForMobile {
      display: none;
    }
    .rightMobileImgBox {
      display: none;
    }
    .count {
      font-size: 32px;
      font-family: "bold";
      line-height: 25px;
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    height: ${(props) =>
      props.$banneris ? "calc(100vh - 108px)" : "calc(100vh - 60px)"};
    min-height: 675px;
    .leftBox {
      text-align: center;
      .firstPart {
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        margin-top: 48px;
        display: flex;
        justify-content: center;
      }
      .middlePart {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: ${(props) =>
          props.$banneris ? "calc(100vh - 368px)" : "calc(100vh - 320px)"};
        min-height: 413px;
        .secondBlock {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 36px;
          font-family: "bold";
          line-height: 130%;
          margin: 0 auto 38px;
        }
        .thirdBlock {
          margin: 0 auto;
          .leftCircle,
          .rightCircle {
            width: 85.8px;
            height: 85.8px;
            font-size: 7.5px;
            font-family: "medium";
            line-height: 12px;
            .innerCircle {
              width: 75.1px;
              height: 75.1px;
            }
            div {
              font-size: 8px;
              line-height: 12px;
            }
            .count {
              font-size: 17px;
              font-family: "bold";
              line-height: 25px;
              margin: 1px 0;
            }
          }
          .engImg {
            width: 140px;
          }
        }
      }
    }
    .rightImgBox {
      display: none;
    }
    .rightMobileImgBox {
      width: 264px;
      height: 194px;
      margin: auto auto 0px;
    }
  }
  @media ${device.tablet} {
    .onlyForMobile {
      display: none;
    }
  }
  @media ${device.mobile} {
    .leftBox {
      .middlePart {
        .secondBlock {
          font-size: 30px;
          .centerText {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
`;

export default HomeTwo;
