import styled from "styled-components";
import "../font.css";

export const StBack = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StGroupColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const St28font = styled.div`
  font-size: 28px;
  font-weight: 500;
`;

export const Ft21Medium = styled.div`
  display: flex;
  justify-content: center;
  font-size: 21px;
  font-family: "regular";
`;

export const Ft21Bold = styled.div`
  display: flex;
  justify-content: center;
  color: #2276dc;
  font-size: 21px;
  font-family: "Bold";
`;

export const StGrey = styled.div`
  color: #626168;
  font-weight: 500;
  font-size: 17px;
`;

export const StSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1440px;
  padding: 107px 0 0 0;
  margin: 0 auto;
`;

export const StPageGrey = styled(StPage)`
  margin: auto auto auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1440px;
  height: 92vh;
  padding-top: 0px;
  background-color: #f8f9fa;
`;

export const StPageWhite = styled(StPageGrey)`
  background-color: white;
`;

export const St15font = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

export const StBody = styled.div`
  display: flex;
  width: 1228px;
  margin: 0 auto 0 auto;
  padding: 80px 106px 0 106px;
`;

export const StContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StGreyWord = styled.div`
  color: #818181;
`;

export const StTitles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StTitle = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

export const StLogo = styled.img`
  margin: 0 20px 0 0;
`;

export const Ft22blue = styled.div`
  font-family: "Regular";
  font-size: 22.5px;
  color: #2276dc;
  margin-bottom: 20px;
`;

export const Ft35 = styled.div`
  font-size: 35px;
  font-family: "Medium";
  margin: 51px 0 0 0;
`;

export const Ft22 = styled.div`
  font-family: "Regular";
  font-size: 22.5px;
  margin-bottom: 20px;
`;

export const StHomePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1440px;
  height: 91vh;
  background-color: white;
  margin: 0 auto 0 auto;
`;

export const StLine = styled.div`
  display: flex;
  align-items: center;
`;

export const StWrite = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StH7 = styled.div`
  font-family: "Bold";
  font-size: 31px;
`;

export const StH6 = styled.div`
  font-size: 29px;
  font-family: "Medium";
`;

export const StH5 = styled.div`
  margin: 10px 0 30px 0;
  font-family: "Regular";
  font-size: 40px;
`;

export const StH4Blue = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: #2276dc;
`;

export const StH4Red = styled.div`
  font-weight: 600;
  font-size: 22px;
  color: RED;
`;

export const StH4 = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

export const StH3 = styled.div`
  font-family: "Regular";
  font-size: 18px;
`;

export const StH3Blue = styled.div`
  font-weight: 600;
  font-size: 18px;
  color: #2276dc;
`;

export const StH2 = styled.div`
  font-weight: 500;
  font-size: 25px;
`;

export const StH1 = styled.div`
  font-family: "Medium";
  font-size: 80px;
  letter-spacing: 8px;
`;

export const StUpBox1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 376px;
  height: 63px;
  font-size: 21px;
  font-weight: 500;
  background-color: ${(props) => (props.clicked === 1 ? "#20315B" : " white")};
  color: ${(props) => (props.clicked === 1 ? "white" : "black")};
  cursor: pointer;
`;

export const StUpBox2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 376px;
  height: 63px;
  font-size: 21px;
  font-weight: 500;
  background-color: ${(props) => (props.clicked === 2 ? "#20315B" : " white")};
  color: ${(props) => (props.clicked === 2 ? "white" : "black")};
  cursor: pointer;
`;

export const StUpBox3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 376px;
  height: 63px;
  font-size: 21px;
  font-weight: 500;
  background-color: ${(props) => (props.clicked === 3 ? "#20315B" : " white")};
  color: ${(props) => (props.clicked === 3 ? "white" : "black")};
  cursor: pointer;
`;

export const StDownBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 950px;
  height: 347px;
  padding: 0 89px 0 89px;
  background-color: white;
`;

export const StUpBox = styled.div`
  display: flex;
`;

export const StBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 1128px;
  height: 410px;
  margin: 60px 0 0 0;
`;

export const StProcess = styled.img`
  width: 217px;
  height: 217px;
`;

export const St3Grey = styled.div`
  margin: 45px 0 11px 0;
  color: #686868;
  font-weight: 400;
  font-size: 18px;
`;
