import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
} from "react";

interface scrollFadeProps {
  ref: MutableRefObject<HTMLDivElement | undefined>;
  setScrollHide: Dispatch<SetStateAction<boolean>>;
}

const ScrollFade: React.FunctionComponent<scrollFadeProps> = ({
  ref,
  setScrollHide,
}) => {
  const timeout = useRef<NodeJS.Timeout | null | number>(null);

  const handleScroll = (): void => {
    setScrollHide(false);
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      setScrollHide(true);
    }, 1500);
  };

  useEffect(() => {
    ref.current?.addEventListener("scroll", handleScroll);
    return () => {
      ref.current?.removeEventListener("scroll", handleScroll);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);
  return null;
};

export default ScrollFade;
