import { useQuery } from "react-query";
import apis from "../api/getApi";
import { useSetRecoilState } from "recoil";
import { toastMessage, toastOn } from "../atoms";

const useGetCreditRefundList = () => {
  const setToastOn = useSetRecoilState(toastOn);
  const setToastText = useSetRecoilState(toastMessage);
  const toastOut = () =>
    setTimeout(() => {
      setToastOn(false);
    }, 2000);

  const fetcher = () => {
    const utc = new Date().getTimezoneOffset();
    return new Promise((resolve, reject) => {
      apis
        .getCreditRefundList({ utc })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          if (response.data.errorCode === "ENTERPRISE-009") {
            setToastText(
              "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요."
            );
            setToastOn(true);
            toastOut();
          }
          reject(response);
        });
    });
  };
  return useQuery(["refundList"], fetcher);
};

export default useGetCreditRefundList;
