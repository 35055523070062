import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userInfo } from "../atoms";
import CreditQuestion from "../modal/CreditQuestion";

// img
import vector from "../img/3dots.svg";
import companyMember from "../img/companyMembership.svg";
import indiMember from "../img/individualMembership.svg";
import what from "../img/what.svg";
import arrow from "../img/arrow.svg";
import arrow2 from "../img/vector.svg";
import arrow3 from "../img/arrow3.svg";
import upArrow from "../img/upArrow.svg";
import ConvertIndividual from "../modal/ConvertIndividual";
import LeftTest from "../modal/ServicePlanLeftTest";
import useToast from "../hooks/useToast";
import getApi from "../api/getApi";
import { useTranslation } from "react-i18next";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import styled from "styled-components";
import { device } from "../hooks/device";
import Img from "../atomic/atom/Img";
import FailToChangePlan from "../modal/FailToChangePlan";

const ServicePlan = ({ setModalOn }) => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g2";
  const baseLang2 = "servicePlan.g3_3";
  const errorLang = "errorCode";
  const navigate = useNavigate();
  const user = useRecoilValue(userInfo);
  const [policyOne, setPolicyOne] = useState();
  const [policyTwo, setPolicyTwo] = useState();
  const showToast = useToast();

  // modal state
  const [questionModal, setQuestionModal] = useState(false);
  const [convertModal, setConvertModal] = useState(false);
  const [convertModalAsk, setConvertModalAsk] = useState(false);
  const [leftToPayModal, setLeftToPayModal] = useState(false);
  const [leftTestModal, setLeftTestModal] = useState(false);

  // plan state
  const [plan, setPlan] = useState();

  // list Array
  const manageList = [
    [t(`${baseLang}.list1`), t(`${baseLang}.unlimited`)],
    [t(`${baseLang}.list2`), t(`${baseLang}.unlimited`)],
    [t(`${baseLang}.list3`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list4`), t(`${baseLang}.offered`)],
  ];

  const manageListManager = [
    [
      t(`${baseLang}.list5`),
      t(`${baseLang}.credit1`),
      t(`${baseLang}.credit2`),
    ],
    [t(`${baseLang}.list6`), t(`${baseLang}.included`)],
    [t(`${baseLang}.list7`), t(`${baseLang}.provided`)],
    [
      t(`${baseLang}.list8`),
      t(`${baseLang}.unlimited`),
      t(`${baseLang}.forMember`),
    ],
  ];

  const manageListMember = [
    [t(`${baseLang}.list5`), t(`${baseLang}.option1`)],
    [t(`${baseLang}.list6`), t(`${baseLang}.payment`)],
    [t(`${baseLang}.list7`), t(`${baseLang}.not`)],
    [t(`${baseLang}.list8`), t(`${baseLang}.not`)],
  ];

  const manageListInd = [
    [t(`${baseLang}.list5`), t(`${baseLang}.option1`)],
    [t(`${baseLang}.list6`), t(`${baseLang}.not`)],
    [t(`${baseLang}.list7`), t(`${baseLang}.not`)],
    [t(`${baseLang}.list8`), t(`${baseLang}.not`)],
  ];

  const proctorList = [
    [t(`${baseLang}.list10`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list11`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list12`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list13`), t(`${baseLang}.offered`)],
  ];

  const verificationList = [
    [t(`${baseLang}.list14`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list15`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list16`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list17`), t(`${baseLang}.offered`)],
    [t(`${baseLang}.list18`), t(`${baseLang}.offered`)],
  ];

  /**
   * 티켓링크 : https://www.notion.so/edint/WEB-Flow-1-a649ddc3a7bc4646a65ef9537c7f7624?pvs=4
   * 주석작성자 : Noah
   * 주석작성일자 : 2024.04.18
   * 티켓내용 : 마이 페이지(기업/구성원) → 서비스 플랜 → 개인 회원으로 전환 시나리오 Flow 순서 변경 건
   * 주석설명 : 토스트 메시지 보여주기 위해 추가
   */
  const [convertError, setConverError] = useState(false);

  useEffect(() => {
    if(convertError) {
      showToast({
        message:
          // "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
          t(`${errorLang}.unknown_server_error`)
      });
    }
  }, [convertError]);
  /************************************************************************************************ */ 

  useEffect(() => {
    if (user?.hostPlan === "individualPlan") {
      setPlan(t(`${baseLang}.type2`));
    } else if (user?.hostPlan === "memberPlan") {
      setPlan(t(`${baseLang}.type1`) + " " + t(`${baseLang}.typeDetail2`));
    } else {
      setPlan(t(`${baseLang}.type1`) + " " + t(`${baseLang}.typeDetail1`));
    }
  }, [user?.hostPlan]);

  // const beforeConvert = () => {
  //   getApi
  //     .getProgressingTest()
  //     .then(() => {
  //       setConvertModal(true);
  //     })
  //     .catch(({ response }) => {
  //       if (
  //         response.data.errorCode === "ENTERPRISE-010" ||
  //         response.data.errorCode === "TIMEZONE-007"
  //       ) {
  //         setLeftTestModal(true);
  //       } else {
  //         showToast({
  //           message:
  //             "알 수 없는 오류가 발생하였어요. 계속 오류가 발생한다면 hiya@proctormatic.com으로 연락주세요.",
  //         });
  //       }
  //     });
  // };

  return (
    <>
      {questionModal && <CreditQuestion setQuestionModal={setQuestionModal} />}
      {convertModal && 
        <ConvertIndividual 
          setConvertModal={setConvertModal}
          setLeftTestModal={setLeftTestModal}
          setLeftToPayModal={setLeftToPayModal}
          setConverError={setConverError}
      />}
      {leftTestModal && (
        <FailToChangePlan
          close={setLeftTestModal}
          title={t(`${baseLang2}.title`)}
          textOne={t(`${baseLang2}.body1`)}
          textTwo={t(`${baseLang2}.body2`)}
        />
      )}
      <StServicePlan hostPlan={user?.hostPlan}>
        <div className="layerUp">
          <div className="titleBox">
            <div className="titleLeft">
              <img
                src={arrow}
                alt=""
                onClick={() => {
                  navigate(`/myPage`);
                }}
              />
              <span>{t(`servicePlan.title`)}</span>
            </div>
            {user?.hostPlan === "memberPlan" && (
              <div style={{ position: "relative" }}>
                <img
                  src={vector}
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={() => {
                    setConvertModalAsk(true);
                  }}
                />
                {convertModalAsk && (
                  <>
                    <div
                      onClick={() => setConvertModalAsk(false)}
                      style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        zIndex: "60",
                      }}
                    />
                    <div
                      className="convert"
                      onClick={() => {
                        // beforeConvert();
                        setConvertModalAsk(false);
                        setConvertModal(true);
                        window.history.pushState(
                          null,
                          "",
                          window.location.href
                        );
                      }}
                    >
                      {t(`${baseLang}.button`)}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="inBox">
            <div className="imgPart">
              <div className="imgPLeft">
                <div className="individual">
                  {user?.hostPlan === "individualPlan"
                    ? t(`${baseLang}.title2`)
                    : t(`${baseLang}.title1`)}
                </div>
                <span className="individualInfo">
                  {user?.hostPlan === "individualPlan"
                    ? t(`${baseLang}.midTitle3`)
                    : t(`${baseLang}.midTitle1`)}{" "}
                  <br className="onlyForTabMob" />
                  {user?.hostPlan === "individualPlan"
                    ? t(`${baseLang}.midTitle4`)
                    : t(`${baseLang}.midTitle2`)}
                </span>
              </div>
              {user?.hostPlan === "individualPlan" ? (
                <img src={indiMember} alt="" />
              ) : (
                <img src={companyMember} alt="" />
              )}
            </div>
            <div className="planPart">
              <div className="planPTitle">{t(`${baseLang}.plan`)}</div>
              <div className="pcArrayPlan">
                <div className="planPPrice">
                  <div className="priceLeft">
                    {user.hostPlan === "individualPlan"
                      ? t(`${baseLang}.type2`)
                      : t(`${baseLang}.type1`)}
                    {user.hostPlan === "managerPlan" && (
                      <TextLine
                        text={t(`${baseLang}.typeDetail1`)}
                        fontFamily="regular"
                        fontsize={14}
                        lineheight={17}
                        pc_fontFamily="semibold"
                        pc_fontsize={22}
                        pc_lineheight={33}
                        pc_margin="0 0 0 6px"
                      />
                    )}
                    {user.hostPlan === "memberPlan" && (
                      <TextLine
                        text={t(`${baseLang}.typeDetail2`)}
                        fontFamily="regular"
                        fontsize={14}
                        lineheight={17}
                        pc_fontFamily="semibold"
                        pc_fontsize={22}
                        pc_lineheight={33}
                        pc_margin="0 0 0 6px"
                      />
                    )}
                  </div>
                  <div className="priceRight">
                    <div>
                      60<span>C</span>
                    </div>
                    <span>&nbsp;{t(`${baseLang}.per`)}</span>
                  </div>
                </div>
                <div className="pcArrayPlanInfo">
                  <div className="planPInfo">
                    <div className="infoUnder">
                      <div>{t(`${baseLang}.billingUnit`)}</div>
                      <span>
                        10<div>min.</div>
                      </span>
                    </div>
                    <div className="columnGrayBar" />
                    <div className="infoUnder">
                      <div>{t(`${baseLang}.perUnit`)}</div>
                      <span>
                        10<div>C</div>
                      </span>
                    </div>
                  </div>
                  <div className="planPBottom">
                    <TextLine text="*" margin="0 4px 0 0" />
                    <TextLine text={t(`${baseLang}.info`)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="manageBox">
              <div className="manageRight">
                <div className="managePart">
                  <div className="managePTitle">{t(`${baseLang}.manage`)}</div>
                  <div className="rowGrayBar" />

                  <DivTwoPartImprove
                    display="grid"
                    gap={14}
                    margin="28px 0 0 0"
                    pc_font_size={18}
                    font_size={14}
                    pc_lineheight={27}
                  >
                    {manageList.map((value, index) => {
                      return (
                        <DivTwoPartImprove
                          display="flex"
                          justify="space-between"
                        >
                          <DivTwoPartImprove
                            display="flex"
                            font_color="#55595f"
                          >
                            <Img
                              src={arrow2}
                              width="20px"
                              pc_width="22px"
                              margin="auto 10px auto 0"
                            />
                            <div>{value[0]}</div>
                            {index === 5 && (
                              <Img
                                src={what}
                                width="20px"
                                margin="0 0 0 6px"
                                onclick={() => {
                                  setQuestionModal(true);
                                }}
                                cursor="pointer"
                              />
                            )}
                          </DivTwoPartImprove>
                          <DivTwoPartImprove
                            pc_display="grid"
                            font_color="#2276DC"
                            font_family="medium"
                            pc_text_align="end"
                          >
                            <TextLine text={value[1]} />
                            <TextLine text={value[2]} />
                          </DivTwoPartImprove>
                        </DivTwoPartImprove>
                      );
                    })}
                    {user.hostPlan === "individualPlan" &&
                      manageListInd.map((value, index) => {
                        return (
                          <DivTwoPartImprove
                            display="flex"
                            justify="space-between"
                          >
                            <DivTwoPartImprove
                              display="flex"
                              font_color="#55595f"
                            >
                              {value[1] !== "N/A" ? (
                                <Img
                                  src={arrow2}
                                  width="20px"
                                  pc_width="22px"
                                  margin="auto 10px auto 0"
                                />
                              ) : (
                                <DivTwoPartImprove
                                  width="20px"
                                  pc_width="22px"
                                  margin="auto 10px auto 0"
                                />
                              )}
                              <div>{value[0]}</div>
                              {index === 1 && (
                                <Img
                                  src={what}
                                  width="20px"
                                  margin="0 0 0 6px"
                                  onclick={() => {
                                    setQuestionModal(true);
                                  }}
                                  cursor="pointer"
                                />
                              )}
                            </DivTwoPartImprove>
                            <DivTwoPartImprove
                              pc_display="grid"
                              font_color="#2276DC"
                              font_family="medium"
                              pc_text_align="end"
                            >
                              <TextLine
                                text={value[1]}
                                fontcolor={value[1] === "N/A" ? "#BDBDBD" : ""}
                              />
                              <TextLine text={value[2]} />
                            </DivTwoPartImprove>
                          </DivTwoPartImprove>
                        );
                      })}
                    {user.hostPlan === "managerPlan" &&
                      manageListManager.map((value, index) => {
                        return (
                          <DivTwoPartImprove
                            display="flex"
                            justify="space-between"
                          >
                            <DivTwoPartImprove
                              display="flex"
                              font_color="#55595f"
                            >
                              <Img
                                src={arrow2}
                                width="20px"
                                pc_width="22px"
                                margin="0 10px auto 0"
                              />
                              <div>{value[0]}</div>
                              {index === 1 && (
                                <Img
                                  src={what}
                                  width="20px"
                                  margin="0 0 0 6px"
                                  onclick={() => {
                                    setQuestionModal(true);
                                  }}
                                  cursor="pointer"
                                />
                              )}
                            </DivTwoPartImprove>
                            <DivTwoPartImprove
                              pc_display="grid"
                              font_color="#2276DC"
                              font_family="medium"
                              text_align="end"
                              width="100px"
                              pc_width="auto"
                            >
                              <TextLine text={value[1]} />
                              {value[2] && <TextLine text={" " + value[2]} />}
                            </DivTwoPartImprove>
                          </DivTwoPartImprove>
                        );
                      })}
                    {user.hostPlan === "memberPlan" &&
                      manageListMember.map((value, index) => {
                        return (
                          <DivTwoPartImprove
                            display="flex"
                            justify="space-between"
                          >
                            <DivTwoPartImprove
                              display="flex"
                              font_color="#55595f"
                            >
                              {value[1] !== "N/A" ? (
                                <Img
                                  src={arrow2}
                                  width="20px"
                                  pc_width="22px"
                                  margin="auto 10px auto 0"
                                />
                              ) : (
                                <DivTwoPartImprove
                                  width="20px"
                                  pc_width="22px"
                                  margin="auto 10px auto 0"
                                />
                              )}
                              <div>{value[0]}</div>
                              {index === 1 && (
                                <Img
                                  src={what}
                                  width="20px"
                                  margin="0 0 0 6px"
                                  onclick={() => {
                                    setQuestionModal(true);
                                  }}
                                  cursor="pointer"
                                />
                              )}
                            </DivTwoPartImprove>
                            <DivTwoPartImprove
                              pc_display="grid"
                              font_color="#2276DC"
                              font_family="medium"
                              pc_text_align="end"
                            >
                              <TextLine
                                text={value[1]}
                                fontcolor={value[1] === "N/A" ? "#BDBDBD" : ""}
                              />
                              <TextLine text={value[2]} />
                            </DivTwoPartImprove>
                          </DivTwoPartImprove>
                        );
                      })}
                  </DivTwoPartImprove>
                </div>
              </div>
              <div className="manageRight">
                <div className="managePart">
                  <div className="managePTitle">{t(`${baseLang}.service`)}</div>
                  <div className="rowGrayBar" />

                  <DivTwoPartImprove
                    display="grid"
                    gap={14}
                    margin="28px 0 0 0"
                    pc_font_size={18}
                    font_size={14}
                    pc_lineheight={27}
                  >
                    {proctorList.map((value, index) => {
                      return (
                        <DivTwoPartImprove
                          display="flex"
                          justify="space-between"
                        >
                          <DivTwoPartImprove
                            display="flex"
                            font_color="#55595f"
                          >
                            <Img
                              src={arrow2}
                              width="20px"
                              pc_width="22px"
                              margin="0px 10px auto 0"
                              pc_margin="2px 12px auto 0"
                            />
                            <div>{value[0]}</div>
                            {index === 5 && (
                              <Img
                                src={what}
                                width="20px"
                                margin="0 0 0 6px"
                                onclick={() => {
                                  setQuestionModal(true);
                                }}
                                cursor="pointer"
                              />
                            )}
                          </DivTwoPartImprove>
                          <DivTwoPartImprove
                            pc_display="grid"
                            font_color="#2276DC"
                            font_family="medium"
                            pc_text_align="end"
                          >
                            <TextLine text={value[1]} />
                          </DivTwoPartImprove>
                        </DivTwoPartImprove>
                      );
                    })}
                  </DivTwoPartImprove>
                </div>
                <div className="managePart">
                  <div className="managePTitle">
                    {t(`${baseLang}.verification`)}
                  </div>
                  <div className="rowGrayBar" />
                  <DivTwoPartImprove
                    display="grid"
                    gap={14}
                    margin="28px 0 0 0"
                    pc_font_size={18}
                    font_size={14}
                    pc_lineheight={27}
                  >
                    {verificationList.map((value, index) => {
                      return (
                        <DivTwoPartImprove
                          display="flex"
                          justify="space-between"
                        >
                          <DivTwoPartImprove
                            display="flex"
                            font_color="#55595f"
                            pc_width="260px"
                            width="70%"
                          >
                            <Img
                              src={arrow2}
                              width="20px"
                              pc_width="22px"
                              margin="0px 10px auto 0"
                              pc_margin="2px 12px auto 0"
                            />
                            <div>{value[0]}</div>
                            {index === 5 && (
                              <Img
                                src={what}
                                width="20px"
                                margin="0 0 0 6px"
                                onclick={() => {
                                  setQuestionModal(true);
                                }}
                                cursor="pointer"
                              />
                            )}
                          </DivTwoPartImprove>
                          <DivTwoPartImprove
                            pc_display="grid"
                            font_color="#2276DC"
                            font_family="medium"
                            pc_text_align="end"
                          >
                            <TextLine text={value[1]} />
                          </DivTwoPartImprove>
                        </DivTwoPartImprove>
                      );
                    })}
                  </DivTwoPartImprove>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lastBox">
          <div className="titlePart">{t(`${baseLang}.policy`)}</div>
          <div className="listPart">
            <div className="listOne">
              <div
                className="listClick"
                onClick={() => setPolicyOne(!policyOne)}
              >
                <div>{t(`${baseLang}.policy1`)}</div>
                {!policyOne ? (
                  <img src={arrow3} alt="" />
                ) : (
                  <img src={upArrow} alt="" />
                )}
              </div>
              {policyOne && (
                <div className="listDown">
                  <span>{t(`${baseLang}.policy1_1Title`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.policy1_1_1`)}</li>
                    <li style={{ color: "#C83B38" }}>
                      {t(`${baseLang}.policy1_1_2`)}
                    </li>
                    <li>{t(`${baseLang}.policy1_1_3`)}</li>
                  </ul>
                  <span style={{ marginTop: "14px" }}>
                    {t(`${baseLang}.policy1_2Title`)}
                  </span>
                  <ul>
                    <li>{t(`${baseLang}.policy1_2`)}</li>
                  </ul>
                </div>
              )}
            </div>
            {!policyOne ? <div className="rowGrayBar" /> : <></>}
            <div className="listOne">
              <div
                className="listClick"
                onClick={() => setPolicyTwo(!policyTwo)}
              >
                <div>{t(`${baseLang}.policy2`)}</div>
                {!policyTwo ? (
                  <img src={arrow3} alt="" />
                ) : (
                  <img src={upArrow} alt="" />
                )}
              </div>
              {policyTwo && (
                <div className="listDown">
                  <span>{t(`${baseLang}.policy2_1Title`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.policy2_1_1`)}</li>
                    <li>{t(`${baseLang}.policy2_1_2`)}</li>
                  </ul>
                  <span>{t(`${baseLang}.policy2_2Title`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.policy2_2_1`)}</li>
                    <li>
                      {t(`${baseLang}.policy2_2_2_1`)}
                      <br />
                      {t(`${baseLang}.policy2_2_2_2`)}
                      <br />
                      {t(`${baseLang}.policy2_2_2_3`)}
                    </li>
                  </ul>
                  <span>{t(`${baseLang}.policy2_3Title`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.policy2_3_1`)}</li>
                    <li>
                      {t(`${baseLang}.policy2_3_2_1`)}
                      <br />
                      {t(`${baseLang}.policy2_3_2_2`)}
                      <br />
                      {t(`${baseLang}.policy2_3_2_3`)}
                    </li>
                  </ul>
                  <span>{t(`${baseLang}.policy2_4Title`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.policy2_4_1`)}</li>
                    <li>{t(`${baseLang}.policy2_4_2`)}</li>
                  </ul>
                  <span>{t(`${baseLang}.policy2_5Title`)}</span>
                  <ul>
                    <li>{t(`${baseLang}.policy2_5_1`)}</li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </StServicePlan>
    </>
  );
};

const StServicePlan = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: #f0f1f2;
  .columnGrayBar {
    width: 0.5px;
    height: 100%;
    background-color: #d6d9dd;
  }
  .rowGrayBar {
    width: 100%;
    height: 0.5px;
    background-color: #d9d9d9;
  }
  .titleBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 19px;
    font-family: "bold";
    line-height: 28px;
    margin-bottom: 36px;
    div {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 12px;
      .convert {
        position: absolute;
        top: -9px;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 26px;
        width: 168px;
        height: 49px;
        font-size: 16px;
        font-family: "regular";
        z-index: 61;
        cursor: pointer;
      }
    }
    img {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
  .inBox {
    display: flex;
    flex-direction: column;
    max-width: 865px;
    margin: 0 auto;
    background: #ffffff;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    .imgPart {
      display: grid;
      /* grid-template-columns: 1fr auto; */
      align-items: center;
      background-color: ${(props) =>
        props.hostPlan === "individualPlan" ? "#20315B" : "#F97E0D"};
      border-radius: 12px 12px 0px 0px;
      gap: 20px;
      img {
        margin-left: auto;
      }
      .imgPLeft {
        display: flex;
        flex-direction: column;
        font-size: 24px;
        font-family: "extraBold";
        color: white;
        line-height: 36px;
        span {
          font-size: 12px;
          font-family: "semibold";
          line-height: 18px;
        }
      }
    }
    .planPart {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .planPPrice {
        display: flex;
        margin-top: 16px;
        .priceLeft {
          display: grid;
          gap: 3px;
          color: #2276dc;
          font-size: 20px;
          font-family: "semiBold";
          line-height: 33px;
        }
        .priceRight {
          display: grid;
          gap: 3px;
          div {
            display: flex;
            font-size: 24px;
            font-family: "bold";
            line-height: 33px;
            span {
              font-size: 16px;
              font-family: "regular";
              line-height: 33px;
              margin-left: 3px;
            }
          }
          span {
            font-size: 14px;
            font-family: "regular";
            line-height: 17px;
          }
        }
      }
      .planPInfo {
        display: flex;
        height: 44px;
        border-radius: 8px;
        border: 0.6px solid #bdbdbd;
        padding: 16px 0;
        margin-top: 24px;
        /* box-sizing: border-box; */
        .infoUnder {
          width: 49%;
          display: grid;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          gap: 7px;
          text-align: center;
          div {
            color: #818181;
            font-size: 12px;
            font-family: "medium";
            line-height: 14px;
          }
          span {
            display: flex;
            justify-content: center;
            color: black;
            font-size: 19px;
            font-family: "bold";
            line-height: 23px;
            div {
              margin-left: 3px;
              font-size: 14px;
              font-family: "regular";
              line-height: 23px;
            }
          }
        }
      }
      .planPBottom {
        display: flex;
        margin-top: 12px;
        font-size: 11px;
        font-family: "regular";
        line-height: 16px;
        color: #444444;
      }
    }
    .managePart {
      display: flex;
      flex-direction: column;
      padding: 24px 24px 16px;
      box-sizing: border-box;
      .managePTitle {
        font-size: 13px;
        font-family: "semibold";
        line-height: 19px;
        margin-bottom: 4px;
      }
      .managePlist {
        display: grid;
        flex-direction: column;
        margin-top: 24px;
        gap: 6px;
        .listOne {
          display: flex;
          align-items: center;
          line-height: 21px;
          color: #55595f;
          font-size: 18px;
          div {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: center;
            gap: 4px;
          }
          .check {
            width: 12px;
            height: 12px;
            margin-right: 14px;
          }
          span {
            font-family: "medium";
            font-size: 18px;
            color: #2276dc;
            margin-left: auto;
          }
          .rightOne {
            font-family: "regular";
            font-size: 14px;
            color: #bdbdbd;
            margin-left: auto;
          }
        }
      }
    }
  }
  .lastBox {
    margin-top: 36px;
    .listPart {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .listOne {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding: 14px 0px; */
        box-sizing: border-box;
        font-size: 16px;
        font-family: "regular";
        line-height: 24px;
        .listClick {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
        }
        .listDown {
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;
          background-color: #fafafa;
          border-top: 1px solid #d9d9d9;
          span {
            font-family: "medium";
            font-size: 15px;
            margin-bottom: 8px;
          }
          ul {
            margin: 0;
            font-family: "medium";
            font-size: 14px;
            padding-left: 17px;
            li {
              font-family: "regular";
            }
          }
        }
        img {
          margin-left: auto;
          cursor: pointer;
        }
      }
    }
  }
  @media ${device.pc} {
    display: grid;
    gap: 60px;
    padding: 80px 0px;
    margin: 0 auto;
    box-sizing: border-box;
    .onlyForTabMob {
      display: none;
    }
    .layerUp {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      min-width: 1228px;
    }
    .titleBox {
      display: flex;
      margin: 0 auto 60px;
      width: 100%;
      .titleLeft {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 19px;
        span {
          font-size: 28px;
          font-family: "bold";
          line-height: 41px;
        }
        img {
          width: 28px;
          height: 28px;
        }
      }
    }
    .inBox {
      .imgPart {
        display: flex;
        justify-content: space-between;
        padding: 36px 60px;
        .imgPLeft {
          display: grid;
          gap: 18px;
          .individual {
            font-size: 32px;
            font-family: "extraBold";
            line-height: 38px;
          }
          .individualInfo {
            font-size: 18px;
            font-family: "semiBold";
            line-height: 27px;
          }
        }
      }
      .planPart {
        padding: 60px 60px 12px;
        .planPTitle {
          font-size: 18px;
          font-family: "medium";
          line-height: 27px;
        }
        .pcArrayPlan {
          display: flex;
          justify-content: space-between;
          .planPPrice {
            display: grid;
            flex-direction: column;
            margin: auto 0;
            gap: 6px;
            .priceRight {
              display: flex;
              align-items: baseline;
              div {
                font-size: 32px;
                font-family: "bold";
                line-height: 47px;
                align-items: baseline;
              }
              span {
                font-size: 20px;
                line-height: 40px;
                font-family: "regular";
              }
            }
            .priceLeft {
              display: flex;
              gap: 0;
              font-size: 22px;
              font-family: "semibold";
              line-height: 33px;
            }
          }
          .pcArrayPlanInfo {
            display: flex;
            flex-direction: column;
            width: 50%;
            .planPInfo {
              height: 115px;
              box-sizing: border-box;
              .infoUnder {
                margin: auto 0;
                div {
                  font-size: 13px;
                  font-family: "medium";
                  line-height: 19px;
                }
                span {
                  display: flex;
                  font-size: 22px;
                  font-family: "bold";
                  line-height: 33px;
                  align-items: baseline;
                  div {
                    display: flex;
                    font-size: 15px;
                    font-family: "regular";
                    color: black;
                  }
                }
              }
            }
            .planPBottom {
              margin-left: auto;
            }
          }
        }
      }
      .manageBox {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;
        padding: 48px 60px 60px;
        .manageRight {
          display: grid;
          flex-direction: column;
          gap: 60px;
          width: 342.5px;
          .managePart {
            padding: 0;
            .managePTitle {
              font-size: 18px;
              font-family: "medium";
              line-height: 27px;
            }
            .managePlist {
              display: grid;
              gap: 14px;
              .listOne {
                .check {
                  width: 14px;
                  height: 14px;
                }
              }
            }
          }
        }
      }
    }
    .lastBox {
      max-width: 990px;
      width: 100%;
      margin: 0 auto;
      background: #ffffff;
      box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
      border-radius: 12px;
      padding: 40px;
      box-sizing: border-box;
      .titlePart {
        padding: 20px 24px;
        box-sizing: border-box;
        font-size: 28px;
        font-family: "medium";
        line-height: 33px;
        color: black;
      }
      .listPart {
        width: 100%;
        padding: 0 24px;
        .listOne {
          .listClick {
            padding: 16px 0;
            cursor: pointer;
          }
          .listDown {
            padding: 0 24px 24px;
            span {
              margin-top: 24px;
            }
          }
        }
        .rowGrayBar {
          height: 1px;
          background-color: #d9d9d9;
        }
      }
    }
  }
  @media ${device.tabMob} {
    padding: 24px 24px 15px;
    .inBox {
      .imgPart {
        position: relative;
        padding: 22px 24px;
        .imgPLeft {
          position: absolute;
          left: 22px;
          display: grid;
          gap: 8px;
        }
      }
      .planPart {
        padding: 24px 24px 12px;
        .planPTitle {
          font-size: 13px;
          font-family: "semibold";
          line-height: 19px;
        }
        .planPPrice {
          justify-content: space-between;
          .priceRight {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    .lastBox {
      padding: 0 0 38px;
      box-sizing: border-box;
      .titlePart {
        font-size: 14px;
        font-family: "medium";
        line-height: 21px;
        color: #909090;
        margin: 0 0 5px 0px;
      }
      .listPart {
        display: grid;
        grid-template-rows: auto auto auto;
        gap: 14px;
        width: calc(100% + 48px);
        margin-left: -24px;
        padding: 14px 24px;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        background-color: #ffffff;
        .listOne {
          .listClick {
            /* padding: 14px 0; */
          }
          .listDown {
            padding: 14px 12px;
            margin-top: 14px;
            span {
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
`;

export default ServicePlan;
