import { useQuery } from "react-query";
import apis from "../api/getApi";

const useGetMyCredit = () => {
  const fetcher = async () => {
    const utc = new Date().getTimezoneOffset();
    const { data } = await apis.getMyCredit({ utc });
    return data;
  };
  return useQuery(["credit"], fetcher);
};

export default useGetMyCredit;
