import React, { useEffect } from "react";
import styled from "styled-components";
import { device } from "../../../hooks/device";
import useScrollLockThree from "../../../hooks/useScrollLockThree";
import { ModalBackD } from "../../../components/Style";
import TextLine from "../../../atomic/atom/TextLine";
import { useTranslation } from "react-i18next";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";

const CreditInfo = () => {
  const { t } = useTranslation();
  const baseLang = "servicePlan.g5_1_5";

  useScrollLockThree();

  return (
    <>
      <ModalBackD modalOff={false} />
      <StModal>
        <div className="titlePart">
          <TextLine text={t(`${baseLang}.title`)} />
        </div>
        <DivTwoPartImprove
          overflowX="hidden"
          scroll_hide={false}
          pc_scroll_hide={true}
          scroll_width="2px"
          tab_height={412}
          // pc_height={100}
          margin="20px 0 33.5px 0"
        >
          <div className="titleInfo">
            {t(`${baseLang}.body1`)}
            <br />
            <br />
            {t(`${baseLang}.body2`)}
          </div>
          <div className="middlePart">※ {t(`${baseLang}.notice`)} </div>
          <ul>
            <li>{t(`${baseLang}.list1`)}</li>
            <li>{t(`${baseLang}.list2`)}</li>
            <li>{t(`${baseLang}.list3`)}</li>
          </ul>
        </DivTwoPartImprove>
        <div
          className="confirmPart"
          onClick={() => {
            window.history.back();
          }}
        >
          {t(`${baseLang}.button`)}
        </div>
      </StModal>
    </>
  );
};

const StModal = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  .titlePart {
    font-size: 20px;
    font-family: "bold";
    line-height: 30px;
  }
  .titleInfo {
    font-size: 16px;
    font-family: "regular";
    line-height: 24px;
  }
  .middlePart {
    font-size: 16px;
    font-family: "semiBold";
    line-height: 24px;
    margin-top: 20px;
  }
  .confirmPart {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    color: #0072de;
    margin: 0 auto;
  }
  ul {
    padding-left: 24px;
    margin: 4px 0 0;
    line-height: 24px;
  }
  @media ${device.tabMob} {
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 53;
    width: calc(100% - 16px);
    border-radius: 24px;
    padding: 24px;
  }
  @media ${device.pc} {
    width: 580px;
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 60px;
    .titlePart {
      font-size: 28px;
      line-height: 41px;
    }
    .titleInfo {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      margin-top: 48px;
    }
    .confirmPart {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      border: 1px solid #000000;
      margin-top: 32px;
      color: black;
      border-radius: 43px;
      cursor: pointer;
    }
  }
`;

export default CreditInfo;
