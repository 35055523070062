import React from "react";
import ModalOneButtonTemplate from "../../../atomic/template/ModalOneButtonTemplate";
import { ModalHeader } from "../../../components/Style";
import { useTranslation } from "react-i18next";
import DivTwoPartImprove from "../../../atomic/atom/DivTwoPartImprove";
import TextLine from "../../../atomic/atom/TextLine";
import Button from "../../../atomic/atom/Button";
import { useLocation, useNavigate } from "react-router-dom";
import useScrollLockThree from "../../../hooks/useScrollLockThree";

interface navigateState {
  couponTitle: string;
  point: number;
}

const CouponApplied = () => {
  const { t } = useTranslation();
  const baseLang: string = "servicePlan.g4_2";
  const data: navigateState = useLocation().state;
  const navigate = useNavigate();

  useScrollLockThree();

  return (
    <ModalOneButtonTemplate>
      {/* <ModalHeader
        title={t(`${baseLang}.title2`)}
        cancelIs={true}
        off={false}
      /> */}
      <DivTwoPartImprove>
        <TextLine
          text={t(`${baseLang}.title2`)}
          fontsize={20}
          fontFamily="bold"
          lineheight={30}
          pc_fontsize={28}
          pc_lineheight={41}
        />
      </DivTwoPartImprove>
      <DivTwoPartImprove
        display="flex"
        justify="center"
        margin="20px 0"
        pc_margin="48px 0"
      >
        <TextLine
          text={data.point}
          pc_lineheight={41}
          pc_fontsize={28}
          fontsize={20}
          lineheight={24}
          fontFamily="bold"
          fontcolor="#2276DC"
          //   margin="0 auto"
        />
        &nbsp;
        <TextLine
          text="C"
          pc_lineheight={41}
          pc_fontsize={28}
          fontsize={20}
          lineheight={24}
          fontFamily="semibold"
          fontcolor="#2276DC"
          //   margin="0 auto"
        />
      </DivTwoPartImprove>
      <TextLine
        text={t(`${data.couponTitle}`) + " " + t(`${baseLang}.body`)}
        pc_fontsize={18}
        fontsize={16}
        lineheight={24}
        pc_lineheight={27}
        pc_margin="0 0 48px"
        margin="0 0 24px"
      />
      <Button
        text={t(`${baseLang}.button`)}
        pc_width="204px"
        height={36}
        pc_height={52}
        pc_fontsize={17}
        font_size={18}
        font_family="medium"
        margin="0 auto"
        backgroundcolor="white"
        border="none"
        pc_border="1px solid #000;"
        pc_border_radius={43}
        font_color="#0072de"
        pc_fontcolor="black"
        onclick={() => {
          navigate(`/myPoint`);
        }}
      />
    </ModalOneButtonTemplate>
  );
};

export default CouponApplied;
