import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { deleteQnA } from "../../api/axiosTwo";
import AnswerQnA from "../../components/AnswerQnA";
import useGetQnADetail from "../../hooks/useGetQnADetail";
import arrow from "../../img/arrow.svg";
import etc from "../../img/etc.svg";
import Photo from "../../modal/Photo";
import { device } from "../../hooks/device";
import QnADelete from "../../modal/QnADelete";
import dayjs from "dayjs";
import ReAnswer from "../../modal/QnADetailReAnswer";
import useToast from "../../hooks/useToast";
import { useTranslation } from "react-i18next";
import DivTwoPartImprove from "../../atomic/atom/DivTwoPartImprove";

const QnADetail = ({ setModalOn }) => {
  const { t } = useTranslation();
  const baseLang = "customer.h3_1_1";
  const baseLang2 = "customer.deleteQuestion";
  const navigate = useNavigate();
  const [commentUpdate, setCommentUpdate] = useState(false);
  const { uid } = useParams();
  const { data } = useGetQnADetail({ uid, commentUpdate });
  const questionUuid = useParams().uid;

  const question = data?.question;
  const showToast = useToast();

  const [main, setMain] = useState();
  const [answers, setAnswers] = useState();
  const [clickedNumber, setClickedNumber] = useState();
  const [clicked, setClicked] = useState(false);
  const [editCommentUid, setEditCommentUid] = useState();

  // modal State
  const [qnaDeleteModal, setQnaDeleteModal] = useState(false);
  const [pictureModalOn, setPictureModalOn] = useState(false);
  const [reAnswerModal, setReAnswerModal] = useState(false);
  const [commentEditModal, setCommentEditModal] = useState(false);
  const [questionComponentUuid, setQuestionComponentUuid] = useState();

  useEffect(() => {
    let a = question?.component;
    let b = a?.shift();
    setMain(b);
    setAnswers(a);
  }, [question]);

  const { mutate } = useMutation(deleteQnA, {
    onSuccess: () => {
      navigate(`/qna/1`);
      showToast({ message: t(`errorCode.deleteQnA`) });
    },
    onError: ({ response }) => {
      if (response.data.errorCode === "HOST-001") {
        showToast({
          message: t(`errorCode.unknown_server_error`),
        });
      }
    },
  });

  const deleteQna = () => {
    mutate({
      uid: uid,
    });
  };

  return (
    <>
      {pictureModalOn && (
        <Photo
          list={main?.signedImageList}
          clickedNumber={clickedNumber}
          setPictureModalOn={setPictureModalOn}
        />
      )}
      {qnaDeleteModal && (
        <QnADelete
          close={setQnaDeleteModal}
          deleting={deleteQna}
          title={t(`${baseLang2}.title`)}
          textOne={t(`${baseLang2}.body`)}
        />
      )}
      {reAnswerModal && (
        <ReAnswer
          setReAnswerModal={setReAnswerModal}
          setCommentUpdate={setCommentUpdate}
          commentUpdate={commentUpdate}
        />
      )}
      {/* <ReAnswer setReAnswerModal={setReAnswerModal} /> */}
      <StQnADetail clicked={clicked}>
        <div className="container">
          <div className="bucket">
            <div className="upBox">
              <div className="middleTitleBox">
                <img
                  className="arrow"
                  onClick={() => {
                    navigate(`/qna/1`);
                  }}
                  src={arrow}
                  alt=""
                />
                <div className="middleTitle">{t(`${baseLang}.title`)}</div>
              </div>
              {!clicked && (
                <div
                  className="clickPart onlyForMobile"
                  onClick={() => {
                    setClicked(!clicked);
                  }}
                >
                  <img src={etc} className="etc" alt="" />
                </div>
              )}
              {clicked && (
                <>
                  <div
                    className="deleteBackground onlyForMobile"
                    onClick={() => {
                      setClicked(false);
                    }}
                  />
                  <div className="deleteButtonBox onlyForMobile">
                    {answers.length === 0 && (
                      <div
                        className="deleteButton"
                        onClick={() => {
                          navigate(`/qnaEdit/${uid}`, {
                            state: {
                              category: data?.question?.category,
                              title: data?.question?.title,
                              context: main?.context,
                            },
                          });
                        }}
                      >
                        {t(`${baseLang}.edit`)}
                      </div>
                    )}
                    <div
                      className="deleteButton"
                      onClick={() => setQnaDeleteModal(true)}
                    >
                      {t(`${baseLang}.delete`)}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="noticeDetailBody">
              <div className="noticeDetailTitleBucket">
                <div className="noticeDetailTitleBox">
                  <div className="noticeDetailCategory">
                    {question?.category}{" "}
                    {question?.isAnswered && (
                      <div className="completeMark">
                        {t(`${baseLang}.answered`)}
                      </div>
                    )}
                  </div>
                  <div className="noticeDetailTitle">{question?.title}</div>
                  <div className="noticeDetailTime">
                    {dayjs(question?.createdAt).format("hh:mm AㆍM/D/YYYY")}
                  </div>
                </div>
                <div className="onlyForPc">
                  {!clicked && (
                    <div
                      className="clickPart"
                      onClick={() => {
                        setClicked(!clicked);
                      }}
                    >
                      <img src={etc} className="etc" alt="" />
                    </div>
                  )}
                  {clicked && (
                    <>
                      <div
                        className="deleteBackground"
                        onClick={() => {
                          setClicked(false);
                        }}
                      />
                      <div className="deleteButtonBox">
                        {answers.length === 0 && (
                          <div
                            className="deleteButton"
                            onClick={() => {
                              navigate(`/qnaEdit/${uid}`, {
                                state: {
                                  category: data?.question?.category,
                                  title: data?.question?.title,
                                  context: main?.context,
                                },
                              });
                            }}
                          >
                            {t(`${baseLang}.edit`)}
                          </div>
                        )}
                        <div
                          className="deleteButton"
                          onClick={() => setQnaDeleteModal(true)}
                        >
                          {t(`${baseLang}.delete`)}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="greyBar" />
              <div className="noticeDetailContext">{main?.context}</div>
              <div className="noticeDetailImgBox">
                {main?.signedImageList?.map((value, index) => (
                  <img
                    className="noticeDetailImg"
                    src={value}
                    onClick={() => {
                      setClickedNumber(index + 1);
                      setPictureModalOn(true);
                    }}
                    alt=""
                    key={index}
                  />
                ))}
              </div>
              {answers?.map((value, index) => {
                return (
                  <AnswerQnA
                    type={value.type}
                    count={answers?.length}
                    index={index + 1}
                    setCommentUpdate={setCommentUpdate}
                    commentUpdate={commentUpdate}
                    uid={value.questionUuid}
                    componentUid={value.questionComponentUuid}
                    context={value.context}
                    createdAt={value.createdAt}
                    imgList={value.signedImageList}
                    name={value.ownerEmail}
                    setReAnswerModal={setReAnswerModal}
                    setEditCommentUid={setEditCommentUid}
                    setCommentEditModal={setCommentEditModal}
                    setQuestionComponentUuid={setQuestionComponentUuid}
                    key={index}
                  />
                );
              })}
              <DivTwoPartImprove>
                <div
                  className="noticeDetailButton"
                  onClick={() => {
                    navigate(`/qna/1`);
                  }}
                >
                  {t(`${baseLang}.return`)}
                </div>
              </DivTwoPartImprove>
            </div>
          </div>
        </div>
      </StQnADetail>
      <Outlet
        context={{
          questionUuid,
          questionComponentUuid,
          commentUpdate,
          setCommentUpdate,
        }}
      />
    </>
  );
};

const StQnADetail = styled.div`
  min-width: 360px;
  .container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .noticeDetailBody {
      .noticeDetailTitleBox {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        .noticeDetailImportant {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #fee0e0;
          color: #ef4444;
          width: 32px;
          height: 18px;
          font-size: 9px;
          border-radius: 4px;
        }
        .noticeDetailCategory {
          display: flex;
          align-items: center;
          color: #55595f;
          min-width: 42px;
          border-radius: 4px;
          .completeMark {
            display: flex;
            justify-content: center;
            align-items: center;
            /* width: 58px; */
            padding: 4px 8px;
            box-sizing: border-box;
            /* height: 24px; */
            border-radius: 4px;
            background-color: #444444;
            color: white;
            font-size: 10px;
            line-height: 15px;
            margin-left: 20px;
          }
        }
        .noticeDetailTime {
          color: #888888;
        }
      }
      .noticeDetailContext {
        width: 100%;
        min-height: 280px;
        box-sizing: border-box;
        word-break: break-all;
      }
      .noticeDetailImgBox {
        display: grid;
        gap: 12px;
        width: 100%;
        .noticeDetailImg {
          width: 96px;
          height: 96px;
          object-fit: cover;
          border-radius: 12px;
          cursor: pointer;
        }
      }
      .noticeDetailButton {
        display: inline-block;
        justify-content: center;
        align-items: center;
        /* width: 115px; */
        height: 32px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        margin-top: 16px;
        font-family: "medium";
        font-size: 14px;
        line-height: 24px;
        padding: 4px 14px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }
    .upBox {
      position: relative;
      display: flex;
      .etc {
        width: 24px;
        height: 24px;
      }
      .deleteButtonBox {
        position: absolute;
        z-index: 11;
        top: 0;
        right: 0;
        display: ${(props) => (props.clicked ? "flex" : "none")};
        flex-direction: column;
        width: 168px;
        border-radius: 26px;
        background-color: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        .deleteButton {
          /* display: flex;
                    justify-content: center;
                    align-items: center; */
          height: 49px;
          padding: 15px 24px;
          box-sizing: border-box;
          cursor: pointer;
        }
      }
      .deleteBackground {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
      .middleTitleBox {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        gap: 19px;
        .middleTitle {
          font-family: "bold";
          font-size: 28px;
          line-height: 41px;
        }
        .arrow {
          width: 28px;
          height: 28px;
          cursor: pointer;
        }
      }
    }
    .title {
      display: none;
    }
  }
  @media ${device.pc} {
    width: 100%;
    background: #f0f1f2;
    padding: 80px 0px;
    box-sizing: border-box;
    min-height: calc(100vh - 353px);
    .onlyForMobile {
      display: none !important;
    }
    .container {
      max-width: 1228px;
      margin: 0 auto;
      .upBox {
        display: flex;
        justify-content: space-between;
        .clickPart {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 44px;
          cursor: pointer;
        }
        .middleTitleBox {
          .middleTitle {
            font-size: 28px;
            font-family: "bold";
            line-height: 41px;
          }
        }
      }
      .noticeDetailBody {
        display: grid;
        /* gap: 32px; */
        width: 1228px;
        background: #ffffff;
        box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        padding: 60px;
        margin: 60px auto 0;
        box-sizing: border-box;
        .greyBar {
          width: 100%;
          height: 1px;
          margin: 32px 0;
          background-color: rgba(217, 217, 217, 0.5);
        }
        .noticeDetailTitleBucket {
          display: flex;
          .onlyForPc {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 44px;
            height: 44px;
            margin: 0 0 auto auto;
            .etc {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
            .deleteButtonBox {
              position: absolute;
              z-index: 11;
              top: 0;
              right: 0;
              display: ${(props) => (props.clicked ? "flex" : "none")};
              flex-direction: column;
              width: 168px;
              border-radius: 26px;
              background-color: #ffffff;
              box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
              .deleteButton {
                /* display: flex;
                                justify-content: center;
                                align-items: center; */
                height: 49px;
                padding: 15px 24px;
                box-sizing: border-box;
                cursor: pointer;
              }
            }
            .deleteBackground {
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              z-index: 10;
            }
          }
          .noticeDetailTitleBox {
            .noticeDetailCategory {
              font-size: 14px;
              font-family: "regular";
              line-height: 21px;
              .completeMark {
                display: flex;
                justify-content: center;
                align-items: center;
                /* width: 58px; */
                padding: 4px 8px;
                box-sizing: border-box;
                /* height: 24px; */
                border-radius: 4px;
                background-color: #444444;
                color: white;
                font-size: 12px;
                line-height: 16px;
                margin-left: 14px;
              }
            }
            .noticeDetailTitle {
              margin: 14px 0 10px;
              font-size: 22px;
              font-family: "semibold";
              line-height: 33px;
            }
            .noticeDetailTime {
              font-size: 16px;
              line-height: 24px;
            }
          }
        }
        .noticeDetailContext {
        }
        .noticeDetailImgBox {
          display: grid;
          grid-template-columns: repeat(auto-fill, 96px);
          gap: 12px;
          margin: 32px 0;
        }
      }
    }
  }
  @media ${device.tabMob} {
    min-width: 360px;
    .onlyForPc {
      display: none !important;
    }
    .container {
      width: 100%;
      max-width: 752px;
      margin: 0 auto;
      padding: 0px 24px 16px;
      .upBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0 16px;
        .middleTitleBox {
          display: grid;
          gap: 12px;
          .arrow {
            width: 18px;
            height: 18px;
          }
          .middleTitle {
            font-size: 19px;
            line-height: 28px;
          }
        }
        .deleteButtonBox {
          top: 25px;
        }
      }
      .noticeDetailBody {
        .noticeDetailTitleBox {
          padding: 24px 0 36px;
          height: 132px;
          .noticeDetailCategory {
            height: 18px;
            font-size: 12px;
            line-height: 20px;
          }
          .noticeDetailTitle {
            margin: 8px 0 4px;
            font-family: "medium";
            font-size: 20px;
            line-height: 28px;
          }
          .noticeDetailTime {
            font-size: 12px;
            line-height: 14px;
          }
        }
        .noticeDetailContext {
          padding: 24px 0;
          min-height: 260px;
        }
        .noticeDetailImgBox {
          display: grid;
          grid-template-columns: repeat(auto-fill, 96px);
          flex-wrap: wrap;
          gap: 12px;
          margin-bottom: 24px;
        }
        .noticeDetailButton {
          margin-top: 40px;
        }
        .greyBar {
          margin-left: -24px;
          width: calc(100% + 48px);
          height: 1px;
          background-color: rgba(217, 217, 217, 0.5);
        }
      }
    }
  }
`;

export default QnADetail;
