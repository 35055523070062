import { UseQueryResult, useQuery } from "react-query";
import { GetMyPageResponse } from "../../interface/apiInterface";
import getApi from "../../api/getApi";

const useGetMyPageInfo = ({
  token,
}: {
  token: string;
}): UseQueryResult<GetMyPageResponse["getHostDataForMyPage"], unknown> => {
  const fetcher = async () => {
    const response = await getApi.getMyPageInfo();
    return response.data.getHostDataForMyPage;
  };
  return useQuery(["mypageInfo"], fetcher, {
    enabled: Boolean(token),
  });
};

export default useGetMyPageInfo;
