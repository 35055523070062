import { createContext, useContext, useState } from "react";

const OutletDataContext = createContext();

export const OutletDataProvider = ({ children }) => {
  const [data, setData] = useState(null);

  return (
    <OutletDataContext.Provider value={{ data, setData }}>
      {children}
    </OutletDataContext.Provider>
  );
};

export const useOutletData = () => useContext(OutletDataContext);
