import React, { useState } from "react";
import DivTwoPartImprove from "../atom/DivTwoPartImprove";
import CheckCircleWithText from "../molecule/CheckCircleWithText";

interface CheckProps {
  /** 체크 리스트를 나열해준다. */
  list: string[];
  /** 선택된 index 번호와 choose의 순서가 같은지 비교하여 선택여부 나타냄 */
  choosed: number;
  setChoosed: React.Dispatch<React.SetStateAction<number>>;
  item_width?: string;
  pc_item_width?: string;
  display?: string;
  grid_template_column?: string;
  pc_grid_template_column?: string;
  gap?: number;
  fontfamily?: string;
  fontsize?: number;
  pc_fontsize?: number;
  pc_gap?: number;
  /** 리스트에 대한 justify */
  list_justify?: string;
  /** 각각 아이템에 대한 justify */
  item_justify?: string;
}

const CheckCircleList: React.FC<CheckProps> = (props) => {
  return (
    <DivTwoPartImprove
      display={props.display}
      grid_template_column={props.grid_template_column}
      gap={props.gap}
      pc_gap={props.pc_gap}
      justify={props.list_justify}
    >
      {props.list.map((value, index) => {
        return (
          <CheckCircleWithText
            width={props.item_width}
            pc_item_width={props.pc_item_width}
            key={index}
            text={value}
            gap={3}
            onclick={() => props.setChoosed(index)}
            choosed={props.choosed}
            count={index}
            justify={props.item_justify}
            fontsize={props.fontsize}
            pc_fontsize={props.pc_fontsize}
            fontfamily={props.fontfamily}
          />
        );
      })}
    </DivTwoPartImprove>
  );
};

export default CheckCircleList;
