import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";

//recoil
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { userEmail } from "../atoms";

//style
import styled from "styled-components";
import style from "../modal/modal.module.css";

//device size
import { device } from "../hooks/device";

//img
import checkImg from "../img/check.svg";
import inputReset from "../img/inputClean.svg";

import ToastPopup from "./ToastPopup";
import SignUpEmail from "../modal/SignUpEmail";
import axios from "axios";
import { baseURL } from "../api/handler";
import { useTranslation } from "react-i18next";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";
import Img from "../atomic/atom/Img";
import TextLine from "../atomic/atom/TextLine";

const EmailInput = ({
  password = "",
  setPassword,
  passwordCheck = "",
  setPasswordCheck,
  email,
  setEmail,
  emailAuthModal,
  setEmailAuthModal,
  emailRef,
  passwordRef,
  passwordCheckRef,
  setModalLevel,
  emailErr,
  setEmailErr,
  errPwMessage,
  setErrPwMessage,
  errPwChMessage,
  setErrPwChMessage,
  /** 티켓 41관련 추가, Noah */
  hostName,
}) => {
  const { t } = useTranslation();
  const baseLang = "login.e4";
  const { certified } = useRecoilValue(userEmail);
  const setUserEmailInfo = useSetRecoilState(userEmail);
  const resetUserEmail = useResetRecoilState(userEmail);
  const [toast, setToast] = useState(0); //토스트 팝업 오프너
  const [toastText, setToastText] = useState(""); //토스트 내용

  // error

  let emailValue = email || "";

  /** 이메일 인증번호 입력 모달 켜기 */
  const openEmailAuth = () => {
    setEmailAuthModal(true);
  };

  const toastOut = () =>
    setTimeout(() => {
      setToast(0);
    }, 2000);

  const utcInfo = new Date().getTimezoneOffset();

  /** 비밀번호 재설정을 위한 이메일 인증 보내기 */
  const sendFindPassword_action = () => {
    axios
      .post(`${baseURL}/api/v1/verify?offset=${utcInfo}&lang=en`, {
        purpose: "host-find-pwd",
        email: email,
        /**
         * 티켓링크 : https://www.notion.so/edint/1-596b2fb174d34f4198547dd1ea5ada12?pvs=4
         * 티켓ID : 41
         * 주석작성자 : Noah
         * 주석작성일자 : 2024.06.11
         * 티켓내용 : 비밀번호 재설정 시 이메일 인증 버튼을 눌리면 이름과 이메일주소를 매칭하지않고 이메일이 전송됨.
         * 주석설명 : 이메일 인증 발송 API 키 값 추가로 인한 수정
         */
        hostName: hostName,
      })
      .then(() => {
        openEmailAuth();
      })
      .catch(({ response }) => {
        if (response?.data?.errorCode === "HOST-001") {
          setEmailErr(t("errorCode.host_001"));
        } else if (response?.data?.errorCode === "JOI-EXCEPTION") {
          setEmailErr(t("errorCode.emailform_error"));
          /** 티켓 41관련 추가, Noah */
        } else if (response?.data?.errorCode === "HOST-013") {
          setEmailErr(t("errorCode.host_013"));
        } else {
          setToast(true);
          setToastText(t("errorCode.unknown_server_error"));
          toastOut();
        }
      });
  };

  const emailModalOpen = () => {
    window.scrollTo({ top: 0 });

    // eslint-disable-next-line no-useless-escape
    const regExp =
      /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
    if (!regExp.test(emailValue)) {
      setEmailErr(t("errorCode.emailform_error"));
      emailRef.current.focus();
    } else if (
      (emailValue || "").includes("encoded_") ||
      (emailValue || "").includes("edint_")
    ) {
      setEmailErr(t("errorCode.invalid_email_character_error"));
      emailRef.current.focus();
    } else {
      sendFindPassword_action();
      setEmailErr(null);
    }
  };

  const onChangeEmailInput = (e) => {
    setEmailErr(null);
    setEmail(e.target.value);
    setUserEmailInfo((state) => ({ ...state, certified: false }));
  };

  const onChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    setErrPwMessage(null);
  };

  const onChangePasswordCheck = (e) => {
    setPasswordCheck(e.target.value);
    setErrPwChMessage(null);
  };

  //값과 인풋이 포커스 되었을 때 리셋버튼 활성화 값이 없거나, 포커스아웃되면 리셋버튼 비활성화
  const [email_resetBtnDisabled, set_email_ResetBtnDisabled] = useState();
  const [email_inputFocus, set_email_InputFocus] = useState();

  const [pw_resetBtnDisabled, set_pw_ResetBtnDisabled] = useState();
  const [pw_inputFocus, set_pw_InputFocus] = useState(true);

  const [pwch_resetBtnDisabled, set_pwch_ResetBtnDisabled] = useState();
  const [pwch_inputFocus, set_pwch_InputFocus] = useState(true);

  const resetBtn = (currentValue, focusState, setFocusState) => {
    setTimeout(() => {
      if (currentValue && focusState) {
        // console.log('값이 있고 포커스도 있고')
        setFocusState(true);
      } else {
        // console.log('값이 없고 포커스도 나가고')
        setFocusState(false);
      }
    }, 200);
  };

  useEffect(() => {
    //이메일
    resetBtn(email, email_inputFocus, set_email_ResetBtnDisabled);
  }, [email, email_inputFocus, email_resetBtnDisabled]);

  useEffect(() => {
    //비밀번호
    resetBtn(password, pw_inputFocus, set_pw_ResetBtnDisabled);
  }, [
    password,
    // writingData?.password,
    pw_inputFocus,
    pw_resetBtnDisabled,
  ]);

  useEffect(() => {
    //비밀번호 확인
    resetBtn(passwordCheck, pwch_inputFocus, set_pwch_ResetBtnDisabled);
  }, [
    passwordCheck,
    // writingData?.passwordCheck,
    pwch_inputFocus,
    pwch_resetBtnDisabled,
  ]);

  // console.log(entryPoint);
  useEffect(() => {
    return () => resetUserEmail();
  }, []);
  return (
    <EmailPasswordst>
      <EmailInputContainer checkImg={checkImg} authState={certified}>
        <p className={style.subTitle}>{t(`${baseLang}.login.title`)}</p>
        <div className="emailInput">
          <div>
            <input
              type="text"
              ref={emailRef}
              className={` ${emailErr && "err"} fontplz`}
              placeholder={t(`${baseLang}.login.placeholder1`)}
              onChange={(e) => onChangeEmailInput(e)}
              value={emailValue}
              onBlur={() => {
                setEmailErr(null);
                set_email_InputFocus(false);
              }}
              onFocus={() => {
                set_email_InputFocus(true);
              }}
            />
            {email_resetBtnDisabled && (
              <img
                src={inputReset}
                alt=""
                onClick={() => {
                  setEmail("");
                  // if (location.state?.email) location.state.email = ''
                }}
              />
            )}
          </div>
          {!certified ? (
            <button
              onClick={() => {
                emailModalOpen();
              }}
              type="button"
            >
              {t(`${baseLang}.login.verify`)}
            </button>
          ) : (
            <DivTwoPartImprove padding="6px 12px" display="flex">
              <Img src={checkImg} width="12px" />
              <TextLine
                width="57px"
                textAlign="center"
                text={t(`${baseLang}.login.verified`)}
                fontsize={14}
                lineheight={16}
                fontFamily="medium"
                fontcolor="#2276DC"
                margin="0 0 0 5px"
              />
            </DivTwoPartImprove>
          )}
        </div>
        {emailErr && <div className={style.errorMessage}>{emailErr}</div>}
        <div className="passwordInput">
          <div>
            <input
              type="password"
              className={`${style.input} ${errPwMessage && "err"} fontplz`}
              placeholder={t(`${baseLang}.login.placeholder2`)}
              value={password || ""}
              onChange={(e) => onChangePassword(e)}
              ref={passwordRef}
              onBlur={() => {
                setErrPwMessage(null);
                set_pw_InputFocus(false);
              }}
              onFocus={() => {
                set_pw_InputFocus(true);
              }}
            />
            {pw_resetBtnDisabled && (
              <img src={inputReset} alt="" onClick={() => setPassword("")} />
            )}
          </div>
          {errPwMessage && <p className={style.errorMessage}>{errPwMessage}</p>}
          <div>
            <input
              type="password"
              className={`${style.input} ${errPwChMessage && "err"} fontplz`}
              placeholder={t(`${baseLang}.login.placeholder3`)}
              value={passwordCheck || ""}
              onChange={(e) => onChangePasswordCheck(e)}
              ref={passwordCheckRef}
              onBlur={() => {
                setErrPwChMessage(null);
                set_pwch_InputFocus(false);
              }}
              onFocus={() => {
                set_pwch_InputFocus(true);
              }}
            />
            {pwch_resetBtnDisabled && (
              <img
                src={inputReset}
                alt=""
                onClick={() => setPasswordCheck("")}
              />
            )}
          </div>
          {errPwChMessage && (
            <p className={style.errorMessage}>{errPwChMessage}</p>
          )}
        </div>

        <div className="description">
          <ul>
            <li>* {t(`${baseLang}.info1`)}</li>
            <li>* {t(`${baseLang}.info2`)}</li>
          </ul>
        </div>
      </EmailInputContainer>
      {emailAuthModal && (
        <SignUpEmail
          setModal={setEmailAuthModal}
          setModalLevel={setModalLevel}
          purpose="host-find-pwd"
          email={email}
        />
      )}
      {toast ? <ToastPopup toast={toast} toastText={toastText} /> : null}
    </EmailPasswordst>
  );
};

const EmailPasswordst = styled.div`
  display: flex;
  justify-content: center;
  .fontplz {
    font-family: "regular";
  }
  @media ${device.tablet} {
    justify-content: left;
  }
  @media ${device.mobile} {
    justify-content: left;
  }
`;
const EmailInputContainer = styled.div`
  width: 100%;
  .fontplz {
    font-family: "regular";
  }
  .emailInput {
    width: 100%;
    display: grid;
    grid-template-columns: auto 101px;
    gap: 7px;
    align-items: ${(props) => (props.authState ? "center" : "normal")};
    align-items: center;
    input {
      height: 36px;
      flex-grow: 1;
      font-size: 16px;
      line-height: 24px;
      padding: 6px 0;
      outline: none;
      border: none;
      font-family: "regular";
      &::placeholder {
        color: #bdbdbd;
        font-family: "light";
      }
      border-bottom: 1px solid rgba(217, 217, 217, 0.5);
      font-family: "regular";
      box-sizing: border-box;
    }
    div {
      /* position: relative; */
      display: flex;
      width: 100%;
      align-items: center;
      img {
        /* position: absolute; */
        /* width: 18px; */
        right: 0;
      }
    }
    button {
      font-family: "regular";
      padding: 0;
      font-size: 14px;
      padding: 6px 12px;
      color: #fff;
      line-height: 18px;
      text-align: center;
      border: none;
      background-color: #20315b;
      border-radius: 6px;
      width: 101px;
      height: 37px;
      cursor: pointer;
    }
    .complete {
      color: #2276dc;
      margin-left: 5px;
      font-size: 12px;
      ::before {
        content: "";
        width: 12px;
        height: 10px;
        display: inline-block;
        background-image: url(${(props) => props.checkImg});
        margin-right: 5px;
      }
    }
  }
  .passwordInput {
    div {
      position: relative;
      display: flex;
      align-items: center;
      margin-top: 5px;
      img {
        position: absolute;
        width: 18px;
        right: 0;
      }
    }
  }
  .description {
    margin-top: 16px;
    ul {
      padding: 0;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        list-style: none;
        color: #444444;
        font-size: 12px;
        line-height: 18px;
        font-family: "light";
        :first-child {
          margin-bottom: 8px;
        }
      }
    }
  }

  .err {
    &:focus {
      border-color: rgba(200, 59, 56, 1);
    }
  }

  @media ${device.tablet} {
    max-width: 424px;
    padding: 0;
    .passwordInput {
      div {
        margin-top: 10px;
      }
    }
    .emailInput {
      grid-template-columns: auto 101px;
      gap: 7px;
      button {
        font-size: 14px;
        line-height: 15px;
        width: 101px;
      }
    }
    .description {
      ul {
        li {
          font-size: 11px;
          line-height: 16px;
          :first-child {
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  @media ${device.mobile} {
    max-width: 424px;
    padding: 0;
    .passwordInput {
      div {
        margin-top: 10px;
      }
    }
    .emailInput {
      width: 100%;
      grid-template-columns: auto 101px;
      gap: 7px;
      button {
        padding: 6px;
        font-size: 14px;
        line-height: 16px;
        width: 101px;
      }
      input {
        width: calc(100% - 101px);
        height: 36px;
        font-size: 16px;
        line-height: 24px;
        padding: 6px 0;
        outline: none;
        border: none;
        border-bottom: 1px solid #dfdfdf;
        font-family: "regular";
        box-sizing: border-box;
      }
    }
    .description {
      ul {
        li {
          font-size: 11px;
          line-height: 16px;
          :first-child {
            margin-bottom: 6px;
          }
        }
      }
    }
  }
`;

export default EmailInput;
