import React from "react";

//style
import style from "../modal/modal.module.css";

//img
import inputReset from "../img/inputClean.svg";

//components
import styled from "styled-components";
import { device } from "../hooks/device";
import { useState } from "react";
import { useEffect } from "react";

const Input = ({
  subTitle,
  placeholder,
  setFc = null,
  inputValue,
  elementRef,
  err,
  max = "auto",
}) => {
  const [inputFocus, setInputFocus] = useState(false);
  const [resetBtnDisabled, setResetBtnDisabled] = useState(false);
  const reset = (e) => {
    e.preventDefault();
    setFc("");
  };

  //모바일에서는 맥스랭스가 안먹어서 따로 글자수 제한
  const maxLengthCheck = (e) => {
    if (Number(max)) {
      if (e.target.value.length > e.target.maxLength) {
        e.target.value = e.target.value.slice(0, e.target.maxLength);
      }
    }
  };

  const resetBtn = () => {
    setTimeout(() => {
      if (inputValue && inputFocus) {
        // console.log('값이 있고 포커스도 있고')
        setResetBtnDisabled(true);
      } else {
        // console.log('값이 없고 포커스도 나가고')
        setResetBtnDisabled(false);
      }
    }, 200);
  };

  useEffect(() => {
    resetBtn();
  }, [inputValue, inputFocus]);

  return (
    <InputStyle>
      <p className={style.subTitle}>{subTitle}</p>
      <div>
        <input
          type="text"
          className={`${style.input} ${err && "err"} fontplz`}
          placeholder={placeholder}
          value={inputValue || ""}
          maxLength={max}
          ref={elementRef}
          onChange={(e) => setFc(e.target.value)}
          onFocus={() => {
            setInputFocus(true);
          }}
          onBlur={() => {
            setInputFocus(false);
          }}
          onInput={(e) => {
            maxLengthCheck(e);
          }}
        />
        {resetBtnDisabled && (
          <img
            src={inputReset}
            alt=""
            onClick={(e) => {
              reset(e);
            }}
          />
        )}
      </div>
    </InputStyle>
  );
};

const InputStyle = styled.div`
  .fontplz {
    font-family: "regular";
  }
  div {
    position: relative;
    img {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      z-index: 9999999999999;
      cursor: pointer;
      width: 18px;
      @media ${device.tablet} {
        width: 18px;
      }
      @media ${device.mobile} {
        width: 18px;
      }
    }
  }
  .err {
    &:focus {
      border-color: rgba(200, 59, 56, 1);
    }
  }
`;
export default Input;
