import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ModalBackDTwo, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import { baseURL } from "../api/handler";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { languageIs, userInfo } from "../atoms";
import { removeCookie } from "../Cookie";
import { useNavigate } from "react-router-dom";
import customAxios from "../api/handler";
import useToast from "../hooks/useToast";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { useTranslation } from "react-i18next";

const QuitConfirm = ({ setConfirmModalOn, setMyPageScroll }) => {
  const { t } = useTranslation();
  const baseLang = "mypage.f5_2";
  const user = useRecoilValue(userInfo);
  const resetUserData = useResetRecoilState(userInfo);
  const navigate = useNavigate();
  const showToast = useToast();
  const [forNavigate, setForNavigate] = useState(false);

  const goBack = () => {
    setConfirmModalOn(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  const deleteUser = () => {
    customAxios
      .delete(`${baseURL}/api/v1/hosts/delete?hostPlan=${user?.hostPlan}`)
      .then(() => {
        resetUserData();
        setForNavigate(true);
        removeCookie("token");
        showToast({ message: t(`errorCode.deleteAccountComplete`) });
      })
      .catch(({ response }) => {
        showToast({
          message: t(`errorCode.unknown_server_error`),
        });
      });
  };

  useEffect(() => {
    if (forNavigate === true) {
      navigate(`/myexam`);
    }
  }, [forNavigate]);

  useScrollLockThree();

  return (
    <>
      <ModalBackDTwo />
      <StQuitConfirm>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        <div className="info">{t(`${baseLang}.body`)}</div>
        {/* <div className="warning">
          <div className="warnTitle">※ 주의사항</div>
          <ul className="warnList">
            <li>
              탈퇴 직후 N일 동안은 서비스 A/S를 위해 개인정보는 프록토매틱 서버
              내 유지될 수 있어요.{" "}
            </li>
            <li>
              서버 내 개인정보의 즉각 적인 삭제를 원하실 경우,{" "}
              <span
                onClick={() => {
                  navigate(`/myPage`);
                  setMyPageScroll("customerCenter");
                }}
              >
                고객센터
              </span>
              를 통해 연락주시면 신속하게 도와드릴게요.
            </li>
          </ul>
        </div> */}
        <div className="buttonBox">
          <div
            className="cancelButton"
            onClick={() => setConfirmModalOn(false)}
          >
            {t(`${baseLang}.button1`)}
          </div>
          <div className="columnBar onlyForMobile" />
          <div className="confirmButton" onClick={deleteUser}>
            {t(`${baseLang}.button2`)}
          </div>
        </div>
      </StQuitConfirm>
    </>
  );
};

const StQuitConfirm = styled.div`
  position: fixed;
  z-index: 55;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  background-color: white;
  @media ${device.pc} {
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .onlyForMobile {
      display: none;
    }
    .info {
      margin: 48px 0;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .warning {
      .warnTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      .warnList {
        margin: 4px 0 48px;
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        line-height: 24px;
        span {
          color: #2276dc;
          text-decoration: underline;
        }
      }
    }
    .buttonBox {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: center;
      gap: 21px;
      font-family: "medium";
      .cancelButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        cursor: pointer;
      }
      .confirmButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 204px;
        height: 52px;
        border: 1px solid #000000;
        border-radius: 43px;
        background: #20315b;
        color: white;
        cursor: pointer;
      }
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    left: 50%;
    bottom: 16px;
    transform: translate(-50%, 0);
    box-sizing: border-box;
    border-radius: 24px;
    .buttonBox {
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: center;
      height: 36px;
      gap: 42.5px;
      margin-top: 24px;
      color: #0072de;
      .columnBar {
        width: 1px;
        height: 16px;
        background-color: #e6e6e6;
      }
      .cancelButton {
        width: 62px;
        text-align: center;
        cursor: pointer;
      }
      .confirmButton {
        width: 62px;
        text-align: center;
        cursor: pointer;
      }
    }
    .info {
      margin-bottom: 20px;
      line-height: 24px;
    }
    .warning {
      .warnTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      .warnList {
        font-family: "medium";
        margin: 4px 0 24px;
        padding-left: 20px;
      }
    }
  }
`;

export default QuitConfirm;
