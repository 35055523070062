import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { useTranslation } from "react-i18next";

const AutonomyOut = ({ setAutonomyOutModal }) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d2_3";

  const goBack = () => {
    setAutonomyOutModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
      // window.history.back();
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StAutonomy>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        <div className="middleBox">{t(`${baseLang}.body`)}</div>
        <div className="listBox">
          <div className="listTitle">※ {t(`${baseLang}.notice`)}</div>
          <ul>
            <li>{t(`${baseLang}.list1`)}</li>
            <li>{t(`${baseLang}.list2`)}</li>
          </ul>
        </div>
        <div
          className="buttonBox"
          onClick={() => {
            window.history.back();
          }}
        >
          {t(`${baseLang}.button`)}
        </div>
      </StAutonomy>
    </>
  );
};

const StAutonomy = styled.div`
  position: fixed;
  background-color: white;
  @media ${device.pc} {
    z-index: 53;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    width: 580px;
    padding: 60px;
    box-sizing: border-box;
    display: grid;
    flex-direction: column;
    gap: 48px;
    .middleBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
    }
    .listBox {
      .listTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      ul {
        display: grid;
        flex-direction: column;
        gap: 5px;
        padding-left: 19px;
        margin-top: 4px;
        color: #444444;
        line-height: 24px;
      }
    }
    .buttonBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 0 auto;
      border: 1px solid #000000;
      border-radius: 43px;
      font-size: 17px;
      font-family: "medium";
      cursor: pointer;
    }
  }
  @media ${device.tabMob} {
    display: flex;
    flex-direction: column;
    left: 50%;
    bottom: 16px;
    z-index: 53;
    transform: translate(-50%, 0);
    width: 344px;
    padding: 24px;
    box-sizing: border-box;
    box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    .middleBox {
      line-height: 24px;
    }
    .listBox {
      margin: 20px 0 24px;
      .listTitle {
        font-family: "semibold";
        line-height: 24px;
      }
      ul {
        margin-top: 4px;
        line-height: 24px;
        padding-left: 20px;
      }
    }
    .buttonBox {
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      text-align: center;
      color: #0072de;
    }
  }
`;

export default AutonomyOut;
