import React, { useEffect } from "react";
import styled from "styled-components";
import { ModalBackD, ModalHeaderD } from "../components/Style";
import useScrollLockThree from "../hooks/useScrollLockThree";
import { device } from "../hooks/device";
import { useTranslation } from "react-i18next";
import scanEnvironment from "../img/scanEnvironment.svg";

const SettingInfo = ({ setSettingInfoModal }) => {
  const { t } = useTranslation();
  const baseLang = "myexam.d2_4";

  const goBack = () => {
    setSettingInfoModal(false);
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", goBack);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackD />
      <StPaymentInfo>
        <ModalHeaderD title={t(`${baseLang}.title`)} />
        <div className="bodyPart">
          {/**
            * 티켓링크 : https://www.notion.so/edint/WEB-1-f2ad947f86534c609b9b430f600a5762?pvs=4
            * 주석작성자 : Noah
            * 주석작성일자 : 2024.05.08
            * 티켓내용 : 재접속 시 주변환경 스캔정보환경 모달 내 이해를 돕는 이미지 추가 (1)
            * 주석설명 : 모달 내 이미지 추가
            */}
          <img className="scanEnvironment" alt="" src={scanEnvironment} />
          <ul>
            <li>
              {t(`${baseLang}.body1`)}
            </li>
            <li>
              {t(`${baseLang}.body2`)}
            </li>
          </ul>
          {t(`${baseLang}.body3`)}
        </div>
        <div className="confirmButton" onClick={() => window.history.back()}>
          {t(`${baseLang}.button`)}
        </div>
      </StPaymentInfo>
    </>
  );
};

const StPaymentInfo = styled.div`
  position: fixed;
  left: 50%;
  background: #ffffff;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  z-index: 53;
  @media ${device.pc} {
    width: 580px;
    padding: 60px;
    top: 50%;
    transform: translate(-50%, -50%);
    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 204px;
      height: 52px;
      margin: 48px auto 0;
      font-size: 17px;
      font-family: "medium";
      line-height: 25px;
      border: 1px solid #000000;
      border-radius: 43px;
    }
    .bodyPart {
      margin: 48px 0;
      font-size: 18px;
      font-family: "medium";
      line-height: 27px;
      .scanEnvironment {
        width: 100%;
      }
      ul {
        margin: 28px 0px;
        width: 430px;
        display: grid;
        flex-direction: column;
        gap: 4px;
        padding-left: 20px;
      }
    }
  }
  @media ${device.tabMob} {
    width: 344px;
    padding: 24px;
    bottom: 16px;
    transform: translate(-50%, 0);
    border-radius: 24px;
    .bodyPart {
      line-height: 24px;
      margin-bottom: 20px;
      .scanEnvironment {
        width: 100%;
      }
      ul {
        width: 270px;
        display: grid;
        flex-direction: column;
        gap: 4px;
        padding-left: 20px;
      }
    }
    .confirmButton {
      color: #0072de;
    }
  }
  .confirmButton {
    font-size: 18px;
    font-family: "medium";
    line-height: 27px;
    text-align: center;
    cursor: pointer;
  }
`;

export default SettingInfo;
