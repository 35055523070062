import React from "react";
import styled from "styled-components";
import { device } from "../../hooks/device";

interface HeaderProps {
  title: string;
}

const ModalHeader: React.FC<HeaderProps> = (props) => {
  return <StHeader>{props.title}</StHeader>;
};

const StHeader = styled.div`
  font-size: 28px;
  font-family: "bold";
  line-height: 41px;
  @media ${device.tabMob} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export default ModalHeader;
